import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private state: { scrollPosition: number; pageNumber: number; previousUrl: string; tabIndex?: number } | null = null;

  saveState(scrollPosition: number, pageNumber: number, url: string, tabIndex?: number) {
    this.state = {
      scrollPosition,
      pageNumber,
      previousUrl: url,
      tabIndex, // tabIndex can be undefined if not provided
    };
  }

  getState(): { scrollPosition: number; pageNumber: number; previousUrl: string; tabIndex?: number } | null {
    return this.state;
  }

  clearState() {
    this.state = null;
  }
}
