
<section class="login__wrapper">
  <div class="row">
    <!-- Left: Form Section -->
    <div class="col-lg-6 col-md-12 form-section">
      <!-- Logo at the top of the screen -->
      <div class="logo-container">
        <img [src]="logoUrl" alt="Logo" class="logo" />
      </div>

      <!-- Form container (with footer at the bottom) -->
      <div class="form-container">
        <div class="form-content">
          <h4 class="heading_1">Welcome To</h4>
          <h4 class="heading_2">ProTeamMate<sup>TM</sup> Portal</h4>
          <p class="subtext">Accelerate IT Talent Hiring with Smarter Automation</p>
          <form [formGroup]="loginform" (submit)="login()" id="loginform">
            <div class="form-group mb-4">
              <label for="email">Email
                <span style="color: red">*</span>
              </label>
              <input [(ngModel)]="email" (change)="handleEmailChange()" formControlName="username" type="email" id="email" placeholder="username@mailid.com" required />
            </div>
            <div class="form-group mb-4">
              <label for="password">Password
                <span style="color: red">*</span>
              </label>
              <div class="password-wrapper">
                <input
                  #loginPasswordInput
                  [(ngModel)]="password"
                  formControlName="password"
                  type="password"
                  id="password"
                  placeholder="********"
                  (change)="handlepasswordChange()"
                  required
                />
                <i #faIconLoginPasswordInputButton (click)="togglePassword()" class="fas fa-eye"></i>
              </div>
              <a class="forgot-password" [routerLink]="getForgotPassword()">Forgot Password?</a>
              <div *ngIf="errorMessage" class="error-message">
                {{ errorMessage }}
              </div>
              <div class="d-flex justify-content-center mb-3 mt-2 ">
              <span style="font-size: 13px"> Don't have an account?
                <span style="color: #1177bd ; cursor: pointer">
                  <a [routerLink]="openRegisterNow()">Register Now</a>
                </span>
              </span>
              </div>
            </div>
            <button *ngIf="!showloader" class="btn btn-theme w-100" type="submit">Login</button>
            <div *ngIf="showloader" class="loader-wrapper">
              <button class="btn btn-theme w-100" disabled>
                <div class="spinner-border" role="status"></div>
              </button>
            </div>
          </form>
        </div>
      </div>
      <app-common-footer></app-common-footer>
    </div>
    <!-- Right: Image Section -->
    <div class="col-lg-6 col-md-12 bg-img">
      <div class="informeson">
        <img src="../../assets/ClientLoginBackground.png" alt="Background Image" />
      </div>
    </div>
  </div>
</section>
