<div class="content-wrapper">
  <div class="row mb-3">
    <div class="col-md-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h5 class="page-title">Portal Settings</h5>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="content-wrapper-main-inner-content">
      <mat-tab-group mat-align-tabs="start" animationDuration="10ms" class="remove-border-bottom"
                     (selectedIndexChange)="handleCurrentTab($event)">
        <ng-container *ngIf="role !== 'TA'">
          <mat-tab *ngFor="let tab of tabs" [label]="tab.label" class="remove-border-bottom text-white">
            <div class="content-wrapper">
              <app-portal-setting
                [form]="getFormGroup(tab.form)"
                [resetFile]="resetFile"
                [logoLoader]="logoLoader"
                [title]="tab.label"
                [data]="data"
                [formType]="tab.formType"
                [totalDocuments]="totalDocuments"
                [pageSize]="pageSize"
                (editForm)="handleEditForm($event)"
                (editDocument)="handleEditDocument($event)"
                (toggleStatus)="handleToggleStatus($event)"
                (pageChange)="handlePageChange($event)"
                [currentPage]="currentPage"
                [errorUpdatingForm]="errorUpdatingForm"
              >
              </app-portal-setting>
            </div>
          </mat-tab>
        </ng-container>
      </mat-tab-group>
    </div>
  </div>
</div>
