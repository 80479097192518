import {
  AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  ToggleConfirmationDialogboxComponent
} from "../../../../util/toggle-confirmation-dialogbox/toggle-confirmation-dialogbox.component";
import {PopUpComponent} from "../../../../util/pop-up/pop-up.component";
import {ApiService} from "../../../../services/api.service";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FormUpdateEvent} from "../../../../shared/interfaces";
import {ToastrService} from "ngx-toastr";
import {debounceTime, distinctUntilChanged, filter, tap} from "rxjs/operators";
import {fromEvent} from "rxjs";
import {MatPaginator} from "@angular/material/paginator";
import {IsDataUpdatedService} from "../../../../services/isDataUpdated.service";

@Component({
  selector: 'app-vendor-settings',
  templateUrl: './vendor-settings.component.html',
  styleUrls: ['./vendor-settings.component.css']
})
export class VendorSettingsComponent implements OnChanges,OnInit, AfterViewInit {
  vendorLogoVisible: boolean;
  logoLoader = false;
  role: string | null;
  showloader = false;
  audit: any[] = [];
  totalAuditPages: number;
  @ViewChild('fileInput') fileInput: ElementRef<HTMLDivElement>;
  @Output() vendorImageChange = new EventEmitter<string>();
  @Input() vendorData: any;
  @Input() openDialogType: string;
  @Input() refreshFlag: boolean = false;
  previousFormValue: any;
  initializingForm: boolean = true;
  @Output() formUpdated = new EventEmitter<FormUpdateEvent>();
  pageSize = 5;
  totalAudit = 0;
  currentAuditPage: number = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  @ViewChild('input', {static: true}) input: ElementRef | any;
  selectedImage: string
  errorUploadingImage: boolean = false;

  constructor(
    private service: ApiService,
    public dialog: MatDialog,
    public router: Router,
    public snackBar: MatSnackBar,
    private isVendorsDataUpdated: IsDataUpdatedService,
    private toastr: ToastrService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['refreshFlag'] && changes['refreshFlag'].currentValue !== changes['refreshFlag'].previousValue) {
      this.getAuditHistory(this.vendorData?.id, this.currentAuditPage, this.pageSize);
    }
  }

  ngOnInit(): void {
    this.role = localStorage.getItem('role');
    this.selectedImage = this.vendorData?.logo
    this.getAuditHistory(this.vendorData?.id, this.currentAuditPage, this.pageSize);
    if (typeof this.vendorData.logo !== 'undefined') {
      if (this.vendorData.logo !== '') {
        this.vendorLogoVisible = true;
      } else {
        this.vendorLogoVisible = false;
      }
    } else {
      this.vendorLogoVisible = false;
    }
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(
      tap(() => {
        this.getAuditHistory(this.vendorData?.id, this.paginator.pageIndex, this.paginator.pageSize);

      })
    ).subscribe();
    if (this.input) {
      fromEvent(this.input?.nativeElement, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => this.getAuditHistory(this.vendorData?.id, this.paginator.pageIndex, this.paginator.pageSize)),
        )
        .subscribe();
    }
  }

  getAuditHistory(id: string, page: number, size: number): void {
    this.service.getAuditHistory(id, 'Vendor', page, size).then(resp => {
      this.audit = resp.content;
      this.totalAuditPages = resp['totalPages'];
      this.currentAuditPage = page;
      this.totalAudit = resp.totalElements
      this.paginator.pageIndex = resp.number;
      this.paginator.pageSize = resp.size;
    })
  }

  change(e: any) {
    if (this.role == 'ADMIN') {
      if (this.vendorData.status) {
        const dialogRef = this.dialog.open(ToggleConfirmationDialogboxComponent, {
          data: {
            title: 'Alert',
            message: 'Are you sure you want to De-Activate this',
            type: 'confirmation'
          }
        });

        dialogRef.afterClosed().subscribe(response => {
          if (response) {
            this.service.vendorInactive(this.vendorData.id).then(res => {
              this.isVendorsDataUpdated.setUpdated(true);
              this.openSnackBar('Vendor is successfully De-Activate', " ");
              this.vendorData.status = !this.vendorData.status;
              this.vendorData.status = false;
            });
          } else {
            e.source.checked = true;
            console.log("toggle should not change if I click the cancel button")
          }
        })
      } else {
        const dialogRefActive = this.dialog.open(ToggleConfirmationDialogboxComponent, {
          data: {
            title: 'Alert',
            message: 'Are you sure you want to Active this',
            type: 'confirmation'
          }
        });
        dialogRefActive.afterClosed().subscribe(response => {
          if (response) {
            this.service.vendorActive(this.vendorData.id, true).then(res => {
              this.isVendorsDataUpdated.setUpdated(true);
              this.openSnackBar('Vendor is successfully Active', " ");
              this.vendorData.status = true;
            });
          } else {
            e.source.checked = false;
          }
        })
      }
    } else {
      this.openSnackBar('Only ADMIN has authority to De-Activate/Activate the vendor', " ");
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getInitials(firstName: string, lastName: string): string {
    let firstNames = firstName.trim().replace(/_/g, '').split(' '),
      initials = firstNames[0].substring(0, 1).toUpperCase();
    let lastNames = lastName.trim().replace(/_/g, '').split(' '),
      initialsLast = lastNames[0].substring(0, 1).toUpperCase();
    if (firstNames.length > 1) {
      initials += firstNames[firstNames.length - 1].substring(0, 1).toUpperCase();
    }
    if (lastNames.length > 1) {
      initialsLast += lastNames[lastNames.length - 1].substring(0, 1).toUpperCase();
    }
    return (initials + initialsLast);
  }


  handleFileChange(event: File | null) {
    if (!event) return;
    this.logoLoader = true;
    const formData: FormData = new FormData();
    formData.append('name', event?.name);
    formData.append('file', event);
    this.service.uploadLogoNext(formData, this.vendorData.id).subscribe({
        next: (res) => {
          this.isVendorsDataUpdated.setUpdated(true);
          this.selectedImage = res?.logo;
          this.vendorImageChange.emit(this.selectedImage);
          this.errorUploadingImage = false;
        },
        error: (err: any) => {
          this.errorUploadingImage = true;
          this.toastr.error('Error', err.error.message);
        },
        complete: () => {
          this.logoLoader = false;
        },
      }
    );
  }

  handleProfileDelete(event: boolean): void {
    if (event) {
      const dialogRef2 = this.dialog.open(PopUpComponent, {
        data: {
          title: 'Alert',
          message: 'Are you sure you want to remove picture?',
          type: 'confirmation'
        }
      });
      dialogRef2.afterClosed().subscribe((res: boolean) => {
        if (res) {
          this.service.deleteLogo(this.vendorData.id).then(res => {
            this.isVendorsDataUpdated.setUpdated(true);
            this.vendorImageChange.emit('')
            this.selectedImage = '';
            this.vendorLogoVisible = false;
          }).catch((error: any) => {
            this.toastr.error('Error', error.error.message);
          });
        }
      })
    }
  }
}
