import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../../services/api.service";
import { NgDialogAnimationService } from "ng-dialog-animation";
import { PopUpComponent } from "../../../util/pop-up/pop-up.component";
import { CreateMemberComponent } from "./create-member/create-member.component";
import { DataService } from 'src/app/services/data.service';
import {MatSnackBar} from "@angular/material/snack-bar";
import {RoleEnum} from "../../../client/enums/dataType.enum";
import {IsDataUpdatedMembersService} from "../../../services/isDataUpdatedMembers.service";
@Component({
  selector: 'app-manage-members',
  templateUrl: './manage-members.component.html',
  styleUrls: ['./manage-members.component.scss']
})
export class ManageMembersComponent implements OnInit {
  members: any[] = [];
  pageSize = 12;
  currentPage =0;
  totalPages = 0;
  userId :any;
  role :any;
  firstName: any;
  adminAry:any[]=[];
  managerAry:any[]=[];
  taAry:any[]=[];
  salesAry:any[]=[];
  roles:any;
  index:number;
  loading:boolean;
  showInactive: boolean = false;
  currentTabIndex: number = 0;
  selectedRole:any

  constructor(
    private dataService: DataService,
    private service: ApiService,
    public dialog: NgDialogAnimationService,
    public snackBar: MatSnackBar,
    private isMembersDataUpdated :IsDataUpdatedMembersService,
  ) {

  }


  getRole(role:string){
    if(role=='SALES_HEAD'){
      return RoleEnum.SALES_HEAD;
    }else if(role=='BSE'){
      return RoleEnum.BSE;
    }else{
      return RoleEnum.BSM;
    }

  }
  ngOnInit(): void {

    this.tabOnChange(0);

    // this.loadMembers(this.roles,0);
   this.userId = localStorage.getItem( 'userId')
   this.role = localStorage.getItem( 'role');
  }
  toggleActiveMembers(event: any) {
    this.showInactive = event.checked;
    if (event.checked) {
      if (this.currentTabIndex === 0) {
        this.role='ADMIN'
        this.currentPage=0
        this.loadMembers([this.role], false, this.currentPage);
      } else if (this.currentTabIndex === 1) {
        this.role='MANAGER'
        this.currentPage=0
        this.loadMembers([this.role], false, this.currentPage);
      } else if (this.currentTabIndex === 2) {
        this.role='TA'
        this.currentPage=0
        this.loadMembers([this.role], false, this.currentPage);
      } else if (this.currentTabIndex === 3) {
        this.currentPage=0
          let selectedRole: string[];
            selectedRole=['SALES_HEAD', 'BSM', 'BSE'];
        this.loadMembers(selectedRole, false, this.currentPage);
      }
    } else {
      if (this.currentTabIndex === 0) {
        this.role='ADMIN'
        this.currentPage=0
        this.loadMembers([this.role], true, this.currentPage);
      } else if (this.currentTabIndex === 1) {
        this.role='MANAGER'
        this.currentPage=0
        this.loadMembers([this.role], true, this.currentPage);
      } else if (this.currentTabIndex === 2) {
        this.role='TA'
        this.currentPage=0
        this.loadMembers([this.role], true, this.currentPage);
      } else if (this.currentTabIndex === 3) {
        this.currentPage=0
          let selectedRole: string[];
          selectedRole=['SALES_HEAD', 'BSM', 'BSE'];
        this.loadMembers(selectedRole, true, this.currentPage);
      }
    }
  }


  loadMembers(role:any,active:boolean,page: number): void {
    this.loading=true;
    this.dataService.isLoading.next(true);
    this.service.getMembers(page,role,active).then(res => {
     this.adminAry=[];
      this.managerAry=[];
      this.taAry=[];
      this.salesAry=[];
      this.dataService.isLoading.next(false);
      console.log(res.content,'content')
       res.content.forEach((roleS:any) =>{
         if(roleS.role=='ADMIN'){
           this.roles='ADMIN';
           this.adminAry.push(roleS);
         }
         else if(roleS.role=='MANAGER'){
           this.roles='MANAGER';
           this.managerAry.push(roleS);
         }
         else if(roleS.role=='TA'){
           this.roles='TA';
           this.taAry.push(roleS);
         }
         else if (roleS.role === 'SALES_HEAD' || roleS.role === 'BSM' || roleS.role === 'BSE') {
           this.roles = roleS.role;
           this.salesAry.push(roleS);
         }
        });

      // console.log(this.adminAry,this.managerAry,this.taAry,'roel');
      this.totalPages = res['totalPages'];
      this.currentPage = page;

    }).finally(
      ()=>{
        this.loading=false;
      }
    );
  }
  disableDelete(){
    if(localStorage.getItem('role') =='ADMIN'){
      this.openSnackBar('Only other ADMIN can Delete/Remove you from the Organization', " OK");
    }else{
      this.openSnackBar('Only ADMIN has access to Delete/Remove member from the Organization', "OK ");
    }
  }

  disableEdit(){
      this.openSnackBar('Only other ADMIN can Edit member details', " OK");
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  hasDeletePermission(targetUserId: string) {
    return this.role == 'ADMIN' && (targetUserId != this.userId)
  }

  roleConvert(role:string){
    const result1 = role.charAt(0).toUpperCase() + role.slice(1).toLowerCase();
    return result1;
    console.log(result1); // 👉️ "Hello world"
  }

  openDialogActive(action: string, element: any): void {
    if (action === 'delete') {
      const dialogRef = this.dialog.open(PopUpComponent, {
        data: {
          message: 'Are you sure to make Member Active ',
          type: 'Active'
        }
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.service.activeMember([element.userId],element.role).then(() => {
            this.loadMembers(this.selectedRole,false,this.currentPage);
          });
        }else {
          this.openSnackBar('Only admin can delete the members', " ");
          console.log('Only admin can delete the members')
        }
      });
    }
  }

  openDialog(action: string, element: any): void {
    if (action === 'delete') {
      const dialogRef = this.dialog.open(PopUpComponent, {
        data: {
          message: 'Are you sure to Inactive Member',
          type: 'Inactive'
        }
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.service.InactiveMember([element.userId],element.role).then(() => {
            this.loadMembers(this.selectedRole,true,this.currentPage);
          });

        }else {
          this.openSnackBar('Only admin can delete the members', " ");
          console.log('Only admin can delete the members')
        }
      });
    } else if (action == 'new') {
      const dialogRef = this.dialog.open(CreateMemberComponent, this.dataService.dialogConfig({
        userId: null
      }));
      dialogRef.beforeClosed().subscribe(() => {
        this.isMembersDataUpdated.setUpdated(false);
        this.loadMembers(this.selectedRole,true,this.currentPage);
      });
    } else if (action == 'edit') {
      const dialogRef = this.dialog.open(CreateMemberComponent, this.dataService.dialogConfig({
        userId: element.userId
      }));
      dialogRef.afterClosed().subscribe(() => {
        if (this.isMembersDataUpdated.getBoolean()) {
          this.loadMembers([this.roles],true,this.currentPage);
          this.isMembersDataUpdated.setUpdated(false);
        }
      });
    }
  }

  tabOnChange(tabIndex: number) : void {
    this.currentPage = 0
    console.log(tabIndex,'tab index')
    console.log(tabIndex, 'tab index');
    this.currentTabIndex=tabIndex
    this.showInactive = false;
    const roles: string[] = ['ADMIN', 'MANAGER', 'TA', 'SALES',];

    if (tabIndex >= 0 && tabIndex < roles.length) {
      let selectedRole: string[];
      if(roles[tabIndex]=='SALES'){
         selectedRole=['SALES_HEAD', 'BSM', 'BSE'];
      }else{
        selectedRole = [roles[tabIndex]];
      }
      this.selectedRole = selectedRole;

      console.log(selectedRole,'selected role')
      this.loadMembers( selectedRole ,true, this.currentPage);
    } else {
      console.error('Invalid tabIndex:', tabIndex);
    }
    }

    protected readonly RoleEnum = RoleEnum;
}
