<div class="modal_wrapper">
  <div class="pt-3">
    <h5 class="theme-text-color">Add Attachments</h5>
    <hr />
  </div>
  <form [formGroup]="attachmentForm">
    <div>
      <mat-form-field appearance="outline" class="w-100 border-red">
        <mat-label>Document Label</mat-label>
        <input
          #attachmentAutoCompleter
          [formControl]="docControl"
          [matAutocomplete]="auto1"
          [(ngModel)]="urlType"
          type="text"
          matInput
          placeholder="e.g. Pan Card, Aadhar Card"
          aria-describedby="title"
          required
        />
        <mat-autocomplete
          #auto1="matAutocomplete"
          [displayWith]="displayDocFn"
        >
          <mat-option
            *ngFor="let type of filteredDocs | async"
            (onSelectionChange)="chooseDocType($event)"
            [value]="type"
          >
            {{ type.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <div *ngIf="(filteredDocs | async)?.length === 0" class="dropdown-skills position-relative">
        <ul>
          <li class="li_disabled">No match Found</li>
          <hr class="m-0 p-0" />
          <li class="font-weight-bold">
            <div class="d-flex justify-content-between align-items-center" (click)="addDocType($event, 'doc', attachmentAutoCompleter.value)">
              <span>Add this Document type:</span>
              <button type="button" class="p-0 bg-transparent border-0 fs-20"> +</button>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div *ngIf="data.value === 'Document'">
      <div class="">
        <div class="me-2">
          <img class="thumbnail-image" *ngIf="imageSrc" [src]="imageSrc" alt="Document Image" />
        </div>
        <app-image-upload
                          (fileChange)="handleFileChange($event)"
                          [logoLoader]="logoLoader" [errorUploadingImage]="errorUploadingImage"
                          [hideButton]="true"
                          [doesSupportDoc]="true"
        >
        </app-image-upload>
      </div>
    </div>

    <div *ngIf="data.value === 'Link'">
      <mat-form-field appearance="outline" class="w-100 border-red">
        <mat-label>Paste URL</mat-label>
        <input appMaxCharLimitUrl formControlName="url" type="text" matInput aria-describedby="title" required />
        <mat-error>
          {{ MaxCharLimits.URL }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="pt-1 pb-2 d-flex align-items-center justify-content-end mt-2">
      <app-button [width]="'100px'" [height]="'35px'" type="text" (buttonClick)="onClose()">Cancel</app-button>
      <app-button [height]="'35px'" type="primary" (buttonClick)="onSubmit()" [isLoading]="loading">Save Attachments</app-button>
    </div>
  </form>
</div>
