import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs/internal/Observable";
import {DocsType, DocType, VendorContact,} from "../../../../../shared/interfaces";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ApiService} from "../../../../../services/api.service";
import {ToastrService} from "ngx-toastr";
import {MatSnackBar} from "@angular/material/snack-bar";
import {map, startWith} from "rxjs/operators";
import {IsDataUpdatedForModalsService} from "../../../../../services/isDataUpdatedForModals.service";
import {IsDataUpdatedService} from "../../../../../services/isDataUpdated.service";
import {MaxCharLimits} from "../../../../../common/errorInputMaxValue";

@Component({
  selector: 'app-edit-vendor-contact',
  templateUrl: './edit-vendor-contact.component.html',
  styleUrls: ['./edit-vendor-contact.component.css']
})
export class EditVendorContactComponent implements OnInit {
  vendorContactForm: FormGroup;
  filteredDesignation: Observable<DocsType[]>;
  showloader = false;

  @ViewChild('designationAutoCompleter', {read: MatAutocompleteTrigger})
  designationAutoCompleter: MatAutocompleteTrigger;

  @ViewChild('verticalAutoCompleter', {read: MatAutocompleteTrigger})
  verticalAutoCompleter: MatAutocompleteTrigger;

  errorMessage = '';
  showArchived: boolean = false;
  displayError: boolean = false;
  totalPages = 0;
  currentPage: number = 0;
  contactDT: VendorContact ;
  role: string | null;
  designationRes: DocType[] = [];
  designationId: '';
  desTypeName = '';
  desControl = new FormControl('');
  desEditTypeName = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public customerContactData: any,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private service: ApiService,
    private toastr: ToastrService,
    public snackBar: MatSnackBar,
    public editCustomerDialogueRef: MatDialogRef<EditVendorContactComponent>,
    private isVendorsDataUpdated: IsDataUpdatedService,
    private isVendersContactDataUpdated: IsDataUpdatedForModalsService,
  ) { }

  ngOnInit(): void {
    this.role = localStorage.getItem( 'role');
    this.vendorContactForm = this.fb.group({
      name: ["", Validators.required],
      email: ["", [Validators.required, Validators.pattern("[a-zA-Z0-9._%+-]+@[a-z0-9A-Z.-]+.[a-z]{2,}$")]],
      phone: ["",Validators.required],
      designation: [''],
      linkedIn:[''],
      active:['']
    });
    if(this.customerContactData.customerContact.id ) {
      this.contactDT=  this.customerContactData.customerContact;
      this.vendorContactForm.get('name')?.setValue(this.contactDT.name);
      this.vendorContactForm.get('email')?.setValue(this.contactDT.email);
      this.vendorContactForm.get('phone')?.setValue(this.contactDT?.phone?.mobile);
      this.vendorContactForm.get('linkedIn')?.setValue(this.contactDT.linkedIn);
      this.vendorContactForm.get('designation')?.setValue(this.contactDT.designation);
    }
    this.getDesignation();

}
  _desFilter(name: string): DocsType[] {
    const filterValue = name.toLowerCase();
    return this.designationRes.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  getDesignation(): void {
    this.service.getDesignation().then(resp => {
      resp.map((e:DocType)=>{
        if(e.active==true){
          this.designationRes.push(e);
        }
      });
      this.filteredDesignation = this.desControl.valueChanges.pipe(
        startWith(''),
        map(value => {
            const name = typeof value === 'string' ? value : value?.name ?? "";
            return name ? this._desFilter(name as string) : this.designationRes.slice();
          }
        ),
      )
    });
  }

  getFormValidationErrors(): string {
    let error = '';
    Object.keys(this.vendorContactForm.controls).forEach(key => {
      const controlErrors = this.vendorContactForm.get(key)?.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          error = error + key + ', ';
        });
      }
    });
    return error;
  }

  updatedPayload(){
    return {
      ...this.vendorContactForm.value,
      phone: {
        countryCode: this.vendorContactForm?.value?.phone?.countryCode,
        dialCode: this.vendorContactForm?.value?.phone?.dialCode,
        e164Number: this.vendorContactForm?.value?.phone?.e164Number,
        internationalNumber: this.vendorContactForm?.value?.phone?.internationalNumber,
        nationalNumber: this.vendorContactForm?.value?.phone?.nationalNumber,
        mobile: this.vendorContactForm?.value?.phone?.number,
      },
    };
  }

  updateContact() {
    this.errorMessage = '';
    if (this.vendorContactForm.invalid) {
      this.showloader = false;
      this.errorMessage = 'please check the fields ' + this.errorMessage , this.getFormValidationErrors() + " ";
      return;
    }
    this.service.updateContact(this.updatedPayload(),this.customerContactData.vendorid, this.contactDT.id).then(res => {
      this.isVendorsDataUpdated.setUpdated(true);
      this.isVendersContactDataUpdated.setUpdated(true);
      this.toastr.success('Vendor contact update successfully.', 'Success')
      this.editCustomerDialogueRef.close();
      this.errorMessage='';
    }).catch(e => {
      if (e.error.code == 4000) {
        this.toastr.error('Sorry! Vendor contact with this email already exists.', 'Error');
        this.editCustomerDialogueRef.close();
      } else if (e.error.status == 500) {
        this.errorMessage = e.error.message;
      }else if (e.error.status == 400) {
        this.errorMessage = e.error.message;
      }
    });
  }

  chooseDesType(data: any, group?: any): void {
    if (data.isUserInput && !group) {
      this.designationId = data?.source?.value?.id;
      this.desTypeName = data.source.value?.name;
      this.vendorContactForm.get('designation')?.setValue(data.source.value.name);
    } else if (data.isUserInput && group) {
      this.designationId = data?.source?.value?.id;
      this.desEditTypeName = data.source.value?.name;
      group.get('designation')?.setValue(data.source.value.name);
    }
  }

  displayDocFn(doc: DocsType): string {
    return doc && doc.name ? doc.name : '';
  }

  addDesType(event: any): void {
    if (this.desTypeName) {
      let payload = {
        'name': this.desTypeName,
      }
      this.service.addOrgDesType(payload).then(res => {
        this.isVendorsDataUpdated.setUpdated(true);
        this.isVendersContactDataUpdated.setUpdated(true);
        this.getDesignation();
        this.desTypeName = res.name;
        this.vendorContactForm.get('designation')?.setValue(res.name);
        this.displayDocFn(res);
      }, (e) => {
        console.log('error', e.error.message);
      })
    } else if (this.desEditTypeName) {
      let payload = {
        'name': this.desEditTypeName,
      }
      this.service.addOrgDesType(payload).then(res => {
        this.isVendorsDataUpdated.setUpdated(true);
        this.isVendersContactDataUpdated.setUpdated(true);
        this.getDesignation();
        this.desEditTypeName = res.name;
        this.vendorContactForm.get('designation')?.setValue(res.name);
        this.displayDocFn(res);
      }, (e) => {
        console.log('error', e.error.message);
      })
    }
  }

  closeEditCustomer() {
    this.editCustomerDialogueRef.close();
  }

  getFormControl<T>(controlName: string): any {
    return this.vendorContactForm.get(controlName);
  }

  MaxCharLimits = MaxCharLimits;
}
