<div class="modal-popUp-content content-wrapper-main-inner-content">
  <div class="member_wrapper theme-bg-color" mat-dialog-title>
    <div class="row modal-inner-content">
      <div class="col-md-4 content-header">
        <div>
          <h4 class="text-white">
            <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.99992 29.8334C7.26658 29.8334 6.63903 29.5725 6.11725 29.0508C5.59459 28.5281 5.33325 27.9001 5.33325 27.1668V5.83342C5.33325 5.10008 5.59459 4.47208 6.11725 3.94941C6.63903 3.42764 7.26658 3.16675 7.99992 3.16675H18.6666L26.6666 11.1667V16.5001H23.9999V12.5001H17.3332V5.83342H7.99992V27.1668H15.9999V29.8334H7.99992ZM7.99992 27.1668V5.83342V27.1668ZM24.3999 19.8668L25.8332 21.3001L20.6666 26.4334V27.8334H22.0666L27.2332 22.7001L28.6332 24.1001L22.8999 29.8334H18.6666V25.6001L24.3999 19.8668ZM28.6332 24.1001L24.3999 19.8668L26.3332 17.9334C26.5777 17.689 26.8888 17.5668 27.2666 17.5668C27.6444 17.5668 27.9555 17.689 28.1999 17.9334L30.5666 20.3001C30.811 20.5445 30.9332 20.8556 30.9332 21.2334C30.9332 21.6112 30.811 21.9223 30.5666 22.1668L28.6332 24.1001Z"
                fill="white"/>
            </svg>
            {{type === 'edit' ? 'Edit Member' : 'Create Member'}} </h4>
        </div>
      </div>
      <div class="col-md-8 d-flex justify-content-end">
        <a style="cursor: pointer" class="cursor-pointer" (click)="close()">
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.2872 1L1 17.2871" stroke="white" stroke-width="2" stroke-linecap="round"/>
            <path d="M1 1L17.2872 17.2871" stroke="white" stroke-width="2" stroke-linecap="round"/>
          </svg>
        </a>
      </div>
    </div>
  </div>

  <mat-dialog-content class="mat-typography m-0">
    <div class="d-flex justify-content-center">
      <div class="create-vendor col-md-10 mt-5">
        <form [formGroup]="memberForm" ngNativeValidate class="member-form px-5 mt-5">
          <div class="form-row mb-4 create_header" style="justify-content: center; display: flex;">
            <label style="font-weight: bolder;font-size: 30px;">Member Information</label>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <mat-form-field appearance="outline" class="w-100 border-red">
                <mat-label>First Name</mat-label>
                <input appMaxCharLimitInput matInput placeholder="First name" formControlName="firstName" #inputName appNoSpecialChars>
                <mat-error>
                  {{MaxCharLimits.INPUT}}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group col-md-6">
              <mat-form-field appearance="outline" class="w-100 border-red">
                <mat-label>Last Name</mat-label>
                <input appMaxCharLimitInput matInput formControlName="lastName" placeholder="Last name" appNoSpecialChars>
                <mat-error>
                  {{MaxCharLimits.INPUT}}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <mat-form-field appearance="outline" class="w-100 border-red">
                <mat-label>Email Id</mat-label>
                <input appMaxCharLimitUrl matInput type="email" formControlName="email" placeholder="Email Id" id="inputEmail1" [readonly]="userId" required>
                <mat-error>
                  {{MaxCharLimits.URL}}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group col-md-6">
              <app-country-phone-input
                [modal]="true"
                [formControl]="getFormControl('number')">
              </app-country-phone-input>
            </div>
          </div>
          <div class="form-row mt-4 d-flex justify-content-center">
            <ul class="form-group" style="display: flex;
    list-style: none;">
              <li class="radio">
                <input id="radio-2" name="role" type="radio" formControlName="role" value="ADMIN" (change)="onRoleChange('ADMIN')">
                <label for="radio-2" class="radio-label mb-0">Admin</label>
              </li>
              <li class="radio">
                <input id="radio-3" name="role" type="radio" formControlName="role" value="MANAGER" (change)="onRoleChange('MANAGER')">
                <label for="radio-3" class="radio-label mb-0">Manager</label>
              </li>
              <li class="radio">
                <input id="radio-1" name="role" type="radio" formControlName="role" value="TA" (change)="onRoleChange('TA')">
                <label for="radio-1" class="radio-label mb-0" >TA</label>
              </li>
              <li class="radio">
                <input id="radio-4" name="role" type="radio" formControlName="role" value="SALES" (change)="onRoleChange('SALES')">
                <label for="radio-4" class="radio-label mb-0" >SALES</label>
              </li>
              <mat-form-field *ngIf="openSalesDropdown">
                <mat-label>Select Role</mat-label>
                <mat-select formControlName="role" (change)="onSaleRoleChange($event)">
                  <mat-option value="SALES_HEAD">SALES HEAD</mat-option>
                  <mat-option value="BSM">SALES MANAGER</mat-option>
                  <mat-option value="BSE">SALES EXECUTIVE</mat-option>
                </mat-select>
              </mat-form-field>
            </ul>
          </div>
          <div class="d-flex justify-content-center align-items-baseline ">
            <div class="form-group btns save-form">
              <div *ngIf="errorMessage" class="alert alert-danger">
                {{errorMessage}}
              </div>
              <div class="d-flex justify-content-center">
                <button class="action-btn action-btn-cancel me-3 " (click)="close()" >Cancel</button>
                <button (click)="addMember()" *ngIf="!showloader" class="action-btn theme-btn-color" type="submit">
                  {{type === 'edit' ? 'Update ' : 'Create '}}
                </button>
                <div *ngIf="showloader" disabled>
                  <button class="btn btn-saveform" disabled>
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </mat-dialog-content>
</div>
