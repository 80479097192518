import {Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges} from '@angular/core';
import {FormControl} from "@angular/forms";
import * as _moment from "moment/moment";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {Moment} from "moment/moment";
import {MatDatepicker} from "@angular/material/datepicker";

const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'passing-year',
  templateUrl: './passing-year.component.html',
  styleUrls: ['./passing-year.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class PassingYearComponent implements OnInit, OnChanges {
  date = new FormControl();
  @Output() passingYearDate = new EventEmitter<any>();
  @Input() passingDate: any;
  @Input() isSubmitting!: boolean;

  constructor() {
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.passingDate && changes.passingDate.currentValue) {
      const parsedDate = moment(this.passingDate, ['MM-YYYY', 'MM/YYYY']);
      if (parsedDate.isValid()) {
        this.date.setValue(parsedDate);
      }
    }
    if(this.isSubmitting){
      this.date.markAsTouched()
    }
  }

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value ?? moment();
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.date.setValue(ctrlValue);
    this.passingYearDate.emit(this.date.value.format('MM/YYYY'));
    if (datepicker.monthSelected) {
      datepicker.close();
    }
  }
}
