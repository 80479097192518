<div class="consultant_skills_wrap">
 <div class="shadow-sm ms-2 me-2 pt-2 pb-2">
   <app-skills-component
     [id]="data.consultantData?.id"
     [customerInfoData]="data?.consultantData?.skills ?? []"
     [handleSkillUpdate]="handleSkillUpdate.bind(this)"
     [limited]="true"
     (hasSkillDataUpdated)="handleSkillDataUpdated($event)"
   >
   </app-skills-component>
 </div>
  <div class="p-4 shadow-sm m-2 rounded-3">
    <h2 class="mb-4">English Communication & Articulation Rating</h2>
    <mat-form-field appearance="outline" class="w-100 border-red">
      <mat-label>English Communication & Articulation</mat-label>
      <mat-select [formControl]="consultantRatingForm" [required]="true">
        <mat-option *ngFor="let rate of rating" [value]="rate" class="dropItems" (click)="addRating(rate)">
          <a class="dropdown-item">{{ rate }}</a>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>




