import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {cloneDeep, debounce} from "lodash";
import {NgDialogAnimationService} from "ng-dialog-animation";
import {DataService} from "../../../services/data.service";
import {MatPaginator} from "@angular/material/paginator";
import {debounceTime, distinctUntilChanged, filter, tap} from "rxjs/operators";
import {fromEvent} from "rxjs";
import {IsDataUpdatedService} from "../../../services/isDataUpdated.service";
import {PopUpComponent} from "../../../util/pop-up/pop-up.component";
import {ModifyVendorComponent} from "./modify-vendor/modify-vendor.component";
import {NumberFields, VendorFilters} from "../../../shared/interfaces";
import {ResetFiltersService} from "../../../services/resetFilters.service";
import {renderMobileNumber} from "../../../common/renderMobileNumber";

export interface SortTable {
  field: string;
  direction: 'asc' | 'desc' | null;
  active: boolean;
}

@Component({
  selector: 'app-admin-vendors',
  templateUrl: './admin-vendors.component.html',
  styleUrls: ['./admin-vendors.component.css']
})
export class AdminVendorsComponent implements OnInit, AfterViewInit {
  vendors: any[] = [];
  currentPage = 0;
  totalVendors = 0;
  search = '';
  pageSize = 12;
  sort = '';
  query = '';
  showArchived: boolean = false;
  status: boolean;
  showLoader: boolean = true;
  sorts: SortTable[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  @ViewChild('input', {static: true}) input: ElementRef | any;
  searchFieldName = ['vendorName', 'contactNumber', 'email', 'skills', 'location', 'spocIds', 'dateSearchCriteria',];
  vendorsCopy: any[] = []

  filters: VendorFilters = {
    query: "",
    vendorName: "",
    contactNumber: "",
    email: "",
    location: "",
    dateSearchCriteria: {
      filterType: "CREATED",
      from: "",
      to: "",
    },
    skills: [],
    cities: [],
    states: [],
    countries: [],
    showArchived: false,
    spocIds: [],
  };

  constructor(
    private service: ApiService,
    private dataService: DataService,
    public dialog: NgDialogAnimationService,
    private isVendorsDataUpdated: IsDataUpdatedService,
    private resetFilters: ResetFiltersService,
  ) {
  }

  ngOnInit(): void {
    this.sorts.push({active: false, field: 'vendorName', direction: null});
    this.sorts.push({active: false, field: 'createdDate', direction: null});
    this.loadVendors(0, this.pageSize, this.filters);
    this.loadVendors = debounce<any>(this.loadVendors, 600);
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(
      tap(() => {
        this.sorts.forEach(column => {
          column.active = false;
          column.direction = null;
        });
        this.sort = '';
        this.resetToDefaultData();
        this.filters.query = this.search;
        this.loadVendors(this.paginator.pageIndex, this.paginator.pageSize, this.filters);
      })
    ).subscribe();

    if (this.input) {
      fromEvent(this.input.nativeElement, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => {
            this.filters.query = this.search;
            console.log('query', this.search)
            this.paginator.pageIndex = 0;
            this.loadVendors(this.paginator.pageIndex, this.paginator.pageSize, this.filters);
          }),
        ).subscribe();
    }
  }

  updateSearch() {
    this.filters.query = this.search;
    this.paginator.pageIndex = 0;
    this.loadVendors(this.paginator.pageIndex, this.paginator.pageSize, this.filters);
  }

  getSkills(skills: any): { skillsString: string, pendingSkillsCount: number, pendingSkills: string[] } {
    let skillsString = '';
    let count = 0;
    let pendingCount = 0;
    let pendingSkills: string[] = [];
    skills.forEach((e: any, index: number) => {
      if (count < 5) {
        skillsString += e.title;
        if (count < 4 && index < skills.length - 1) {
          skillsString += ' | ';
        }
        count++;
      } else {
        pendingSkills.push(e.title);
        pendingCount++;
      }
    });
    return {
      skillsString: skillsString,
      pendingSkillsCount: pendingCount,
      pendingSkills: pendingSkills
    };
  }

  getSort(field: string): SortTable {
    return this.sorts.find(column => column.field === field)!;
  }

  toggleSort(field: string): void {
    const sortColumn = this.getSort(field);
    this.sorts.forEach(column => {
      if (column.field !== field) {
        column.active = false;
        column.direction = null;
      }
    });
    if (!sortColumn.active) {
      sortColumn.active = true;
      sortColumn.direction = 'asc';
    } else if (sortColumn.direction === 'asc') {
      sortColumn.direction = 'desc';
    } else {
      sortColumn.direction = null;
      sortColumn.active = false;
    }
    if (sortColumn.direction) {
      this.sort = `${sortColumn.field},${sortColumn.direction}`;
      this.sortVendors(sortColumn.field, sortColumn.direction);
    } else {
      this.resetToDefaultData();
    }
  }

  resetToDefaultData(): void {
    this.vendors = cloneDeep(this.vendorsCopy);
  }

  sortVendors(field: string, direction: string): void {
    this.vendors.sort((a, b) => {
      let valueA = a[field];
      let valueB = b[field];
      if (valueA == null) valueA = '';
      if (valueB == null) valueB = '';
      if (typeof valueA === 'string') valueA = valueA.toLowerCase();
      if (typeof valueB === 'string') valueB = valueB.toLowerCase();
      if (!isNaN(parseFloat(valueA)) && !isNaN(parseFloat(valueB))) {
        return direction === 'asc'
          ? parseFloat(valueA) - parseFloat(valueB)
          : parseFloat(valueB) - parseFloat(valueA);
      }
      return direction === 'asc'
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    });
  }

  loadVendors(page: number, size: number, payload: any): void {
    this.showLoader = true;
    this.dataService.isLoading.next(true);
    this.service.getVendors(page, size, '', payload).subscribe(res => {
      this.vendorsCopy = cloneDeep(res.content);
      this.vendors = res.content;
      this.paginator.pageIndex = res.page.number;
      this.paginator.pageSize = res.page.size;
      this.pageSize = res.page.size;
      this.totalVendors = res.page.totalElements;
      this.dataService.isLoading.next(false);
      this.showLoader = false;
    }, () => {
      this.dataService.isLoading.next(false);
      this.showLoader = false;
    });
  }

  toggleActiveVendors(event: any) {
    this.showArchived = event.checked;
    this.filters.showArchived = this.showArchived;
    this.loadVendors(this.currentPage, this.pageSize, this.filters);
  }

  openDialog(action: string, element: any): void {
    if (action === 'delete') {
      const dialogRef = this.dialog.open(PopUpComponent, {
        data: {
          message: 'Are you sure to delete',
          type: 'confirmation'
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.service.deleteVendors([element.id]).then(() => {
            this.loadVendors(this.currentPage, this.pageSize, this.filters);
          });
        }
      });
    } else if (action === 'new') {
      const dialogRef = this.dialog.open(ModifyVendorComponent, this.dataService.dialogConfig({
        vendorId: null
      }));
      dialogRef.beforeClosed().subscribe(() => {
        this.isVendorsDataUpdated.setUpdated(false);
        this.loadVendors(this.currentPage, this.pageSize, this.filters);
      });
    } else if (action === 'edit') {
      const dialogRef = this.dialog.open(ModifyVendorComponent, this.dataService.dialogConfig({
        vendorId: element.id,
        vendorData: element
      }));
      dialogRef.afterClosed().subscribe(() => {
        if (this.isVendorsDataUpdated.getBoolean()) {
          this.loadVendors(this.currentPage, this.pageSize, this.filters);
          this.isVendorsDataUpdated.setUpdated(false);
        }
      });
    }
  }

  onFiltersChanged(updatedFilters: any) {
    this.filters = {
      ...updatedFilters,
      query: this.search
    };
    this.paginator.pageIndex = 0;
    this.filters.query = this.search;
    const fromDate = this.filters.dateSearchCriteria.from;
    const toDate = this.filters.dateSearchCriteria.to;
    const datesValid = (fromDate && toDate) || (!fromDate && !toDate);
    if (datesValid) {
      this.loadVendors(this.paginator.pageIndex, this.pageSize, this.filters);
    }
  }

  dateTypeChanged($event: boolean) {
    if ($event === true) {
      this.filters.dateSearchCriteria.filterType = "UPDATED";
    } else if ($event === false) {
      this.filters.dateSearchCriteria.filterType = "CREATED";
    }
    this.loadVendors(0, this.pageSize, this.filters);
    console.log(this.filters)
  }

  clearFilter() {
    this.filters.query = '';
    this.filters.vendorName = "",
      this.filters.contactNumber = "",
      this.filters.email = "",
      this.filters.location = "",
      this.filters.dateSearchCriteria = {
        filterType: "CREATED",
        from: "",
        to: "",
      },
      this.filters.skills = [],
      this.filters.cities = [],
      this.filters.states = [],
      this.filters.countries = [],
      this.filters.spocIds = [],
      this.search = ''
    this.showArchived = false;
    this.filters.showArchived = this.showArchived;
    this.resetFilters.emitChange(true);
    this.loadVendors(0, this.pageSize, this.filters);
  }

  isFilterApplied(): boolean {
    return !!(
      this.filters?.query ||
      this.filters?.vendorName ||
      this.filters?.contactNumber ||
      this.filters?.email ||
      this.filters?.location ||
      (this.filters?.dateSearchCriteria?.from || this.filters?.dateSearchCriteria?.to) ||
      (Array.isArray(this.filters?.skills) && this.filters.skills.length > 0) ||
      (Array.isArray(this.filters?.cities) && this.filters.cities.length > 0) ||
      (Array.isArray(this.filters?.states) && this.filters.states.length > 0) ||
      (Array.isArray(this.filters?.countries) && this.filters.countries.length > 0) ||
      (Array.isArray(this.filters?.spocIds) && this.filters.spocIds.length > 0) ||
      this.showArchived
    );
  }

  renderMobileNumber = renderMobileNumber;
}
