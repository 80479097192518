import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ApiService} from "../services/api.service";
import {DataService} from "../services/data.service";
import {LoggedInUser, SessionMap} from "../shared/interfaces";
import {Common} from "../shared/Common";
import {ThemeLoaderService} from "../services/theme-loader.service";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {ForgotPasswordEmailService} from "../services/forgotPasswordEmail.service";
import {Message} from "./login-interface/interface";
import {LoginService} from "./service/login.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  loginRadio: FormGroup | any;
  visible1 = true;
  visible2 = false;
  password = '';
  email: string = '';
  loginform: FormGroup;
  showloader = false;
  errorMessage: string = '';
  logoUrl: string | undefined;
  name: string | undefined;
  sessions: {userEmail: string, sessionId: string}[] = []

  @ViewChild('loginPasswordInput') loginPasswordInput: ElementRef<HTMLInputElement>;
  @ViewChild('faIconLoginPasswordInputButton') faIconLoginPasswordInputButton: ElementRef<HTMLElement>;

  constructor(
    private fb: FormBuilder,
    private service: ApiService,
    private router: Router,
    private loginService: LoginService,
    private emailService: ForgotPasswordEmailService
  ) {
  }

  ngOnInit(): void {
    this.sessions = JSON.parse(localStorage.getItem('sessions') || '[]');
    this.loginRadio = this.fb.group({
      type: [''],
    });
    localStorage.setItem('loginType', 'Vendor');
    this.loginRadio.get('type').setValue('Vendor');
    this.loginform = this.fb.group({
      username: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}')]],
      password: ['', Validators.required]
    });
    if (localStorage.getItem('access_token') && localStorage.getItem('role') == 'ADMIN') {
      this.router.navigate(['/dashboard']);
    } else if (localStorage.getItem('access_token') && localStorage.getItem('role') == 'Sales') {
      this.router.navigate(['/client']);
    }
    this.loginform.get('username')?.valueChanges.pipe(debounceTime(100), distinctUntilChanged()).subscribe(() => {
      this.handleEmailChange();
      this.emailService.setInputData(this.email);
    });
    this.loginform.get('password')?.valueChanges.subscribe(() => {
      this.handlepasswordChange();
    });

    this.loginService.fetchLogo();

    this.loginService.logoUrl$.subscribe(logoUrl => {
      this.logoUrl = logoUrl;
    });
    this.loginService.name$.subscribe(name => {
      this.name = name;
    });

  }

  login(): void {
    this.showloader = true;
    this.errorMessage = '';
    let payload = this.loginform.value;
    if (this.sessions) {
        this.sessions.forEach(map => {
          if (map.userEmail === payload.username) {
            payload.sessionId = map.sessionId;
          }
        });
    }
    if (this.loginform.invalid) {
      this.showloader = false;
      if (this.loginform.get('password')?.errors) {
        if (this.loginform.get('password')?.errors?.required) {
          this.errorMessage = 'Password cannot be empty.';
        }
      }
      if (this.loginform.get('username')?.errors) {
        if (this.loginform.get('username')?.errors?.required) {
          this.errorMessage = 'Email is required.';
        } else if (this.loginform.get('username')?.errors?.pattern) {
          this.errorMessage = 'Please enter a valid email address.';
        }
      }
      if (this.loginform.get('username')?.errors || this.loginform.get('password')?.errors) {
        return;
      }
    } else {
      this.errorMessage = '';
    }
    this.service.login(payload).then(res => {
      if (res.message === Message.OTP_REQUIRED) {
        localStorage.setItem('userRequestId', res.userRequestId);
        localStorage.setItem('message', res.message);
        this.router.navigate(['/admin/login/otp'],
          {
            state: {
              username: this.loginform.get('username')?.value
            }
          });
      } else {
        if(res.session_id){
          let existingSession = this.sessions.find((map: any) => map.userEmail === payload.username);   //existing session of current user
          if(!existingSession || res.session_id !== existingSession.sessionId){
            this.sessions = this.sessions.filter(s => s.userEmail !== payload.username)
            this.sessions.push({userEmail: payload.username, sessionId: res.session_id});
            localStorage.setItem('sessions', JSON.stringify(this.sessions));
          }
        }
        this.loginService.userLogin(res)
      }
    }, (e) => {
      this.showloader = false;
      if (e.error) {
            this.errorMessage = e.error.reason;
        }else {
        this.errorMessage = 'An unexpected error occurred. Please try again.';
      }
    });
  }


  togglePassword(): void {
    if (this.loginPasswordInput.nativeElement.type == 'text') {
      this.loginPasswordInput.nativeElement.type = 'password';
      this.faIconLoginPasswordInputButton.nativeElement.className = 'fas fa-eye';
    } else {
      this.faIconLoginPasswordInputButton.nativeElement.className = 'fas fa-eye-slash';
      this.loginPasswordInput.nativeElement.type = 'text';
    }
  }

  getForgotPassword(): string {
    return '/admin/forget-password/';
  }

  handleEmailChange(): void {
    const usernameControl = this.loginform.get('username');
    if (usernameControl?.touched || usernameControl?.dirty) {
      if (usernameControl?.invalid) {
        this.showloader = false;
        if (usernameControl.errors) {
          if (usernameControl.errors.required) {
            this.errorMessage = 'Email is required.';
          } else if (usernameControl.errors.pattern) {
            this.errorMessage = 'Please enter a valid email address.';
          }
        }
      } else {
        this.errorMessage = '';
      }
    }
  }

  handlepasswordChange(): void {
    const passwordControl = this.loginform.get('password');
    if (passwordControl?.touched || passwordControl?.dirty) {
      if (passwordControl?.invalid) {
        this.showloader = false;
        if (passwordControl.errors) {
          if (passwordControl.errors.required) {
            this.errorMessage = 'Password cannot be empty.';
          }
        }
      } else {
        this.errorMessage = '';
      }
    }
  }
}
