import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {DocsType, DocType, FormUpdateEvent} from "../../../../shared/interfaces";
import {Observable} from "rxjs/internal/Observable";
import {PopUpComponent} from "../../../../util/pop-up/pop-up.component";
import {ApiService} from "../../../../services/api.service";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ToastrService} from "ngx-toastr";
import {map, startWith} from "rxjs/operators";
import * as moment from "moment/moment";
import {DatePipe} from "@angular/common";
import {MatPaginator} from "@angular/material/paginator";
import {IsDataUpdatedService} from "../../../../services/isDataUpdated.service";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";

@Component({
  selector: 'app-vendor-documents',
  templateUrl: './vendor-documents.component.html',
  styleUrls: ['./vendor-documents.component.css']
})
export class VendorDocumentsComponent implements OnInit {
  role: string | null;
  docTypeName = '';
  errorMessage: string | null;
  filteredDocs: Observable<DocsType[]>;
  typeId = '';
  docNameOther = '';
  documentExpiryDate: '' | undefined;
  documentStartDate: '' | undefined;
  vendorDocuments = '';
  typeRes: DocsType[] = [];
  @ViewChild('docInput') docInput: ElementRef<HTMLDivElement>;
  @ViewChild('documentSelectDiv') documentSelectDiv: ElementRef<HTMLDivElement>;
  doc: File | any;
  docError = '';
  docName = '';
  vendorDocs: any[] = [];
  totalDocuments = 0;
  currentDocumentPage: number = 0;
  docId = '';
  showArchived: boolean = false;
  showloader = false;
  @Input() vendorData: any;
  @Input() openDialogType: string;
  docControl: FormControl;
  minEndDate: string | null;
  previousFormValue: any;
  initializingForm: boolean = true;
  @Output() formUpdated = new EventEmitter<FormUpdateEvent>();
  totalDocument = 0;
  pageSize = 5;
  currentPage: number = 0;
  @ViewChild('autoCompleteInput1', {read: MatAutocompleteTrigger})
  autoCompleteInput1: MatAutocompleteTrigger;
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  @ViewChild('input', {static: true}) input: ElementRef | any;
  errorUploadingDoc: boolean;
  resetFile: boolean = false;

  constructor(
    private service: ApiService,
    public dialog: MatDialog,
    public router: Router,
    public snackBar: MatSnackBar,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private isVendorsDataUpdated: IsDataUpdatedService,
  ) {
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
    this.getDocType();
    this.getAllVendorDocuments(this.currentPage, this.pageSize, this.showArchived)
    this.role = localStorage.getItem('role');
    this.docControl = new FormControl('', Validators.required);
  }

  displayDocFn(doc: DocsType): string {
    return doc && doc.name ? doc.name : '';
  }

  chooseDocType(data: any): void {
    const _type = data?.source?.value?.id;
    if (data.isUserInput) {
      this.typeId = _type;
      this.docTypeName = data.source.value?.name;
    } else {
      return
    }
  }

  scrollEvent = (event: any): void => {
    if (this.autoCompleteInput1?.panelOpen)
      this.autoCompleteInput1.updatePosition();
  };

  getDocType() {
    this.service.getDocumentType().then(response => {
      response.map((e: DocType) => {
        if (e.active == true) {
          this.typeRes.push(e);
        }
      });
      this.filteredDocs = this.docControl.valueChanges.pipe(
        startWith(''),
        map(value => {
            const name = typeof value === 'string' ? value.trim() : value?.name.trim() ?? "";
            return name ? this._docsFilter(name as string) : this.typeRes.slice();
          }
        ),
      )
      this.typeRes.push({name: 'others', id: 0, seq: '0', active: true});
    });
  }

  _docsFilter(name: string): DocsType[] {
    const filterValue = name.toLowerCase();
    return this.typeRes.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  changeArchived(event: any) {
    this.showArchived = event.checked;
    this.getAllVendorDocuments(0, this.pageSize, this.showArchived)
  }

  goToLink(url: string) {
    window.open(url, " ");
  }

  restoreTrashedDocument(docId: string) {
    this.service.restoreDocument(docId).then(res => {
      this.isVendorsDataUpdated.setUpdated(true);
      this.toastr.success('Document Restored', 'Success');
      this.getAllVendorDocuments(0, this.pageSize, this.showArchived)
    }, (e) => {
      console.log(e);
    })
  }

  hideWidget(creationTime: any) {
    let date = moment(creationTime).diff(new Date(), "days");
    return date === 0;
  }

  addDocType(event: any, type: string): void {
    if (type == 'doc') {
      let payload = {
        'name': this.docTypeName,
      }
      this.service.addOrgDocType(payload).then(res => {
        this.isVendorsDataUpdated.setUpdated(true);
        this.getDocType();
        this.typeId = res?.id;
        this.displayDocFn(res);
      }, (e) => {
        console.log('error', e.error.message);
      })
    }
  }

  handlePageDocument(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getAllVendorDocuments(this.currentPage, this.pageSize, this.showArchived)
  }

  getAllVendorDocuments(page: number, size: number, archived: boolean): void {
    this.service.getVendorDocuments(this.vendorData.id, page, size, archived).then(resp => {
      this.vendorDocs = resp.content;
      this.totalDocuments = resp.totalPages;
      this.currentDocumentPage = page
      this.totalDocument = resp.totalElements
      this.paginator.pageIndex = resp.number;
      this.paginator.pageSize = resp.size;
      this.pageSize = resp.size;
      this.currentPage = resp.number;
      this.typeId = '';
      if (this.vendorDocuments !== '') {
        this.vendorDocuments = resp.content.url;
        this.docId = resp.content.id;
      }
    });
  }

  deleteConfirmation(action: string, docId: string, archived: boolean): void {
    if (action === 'Delete') {
      const dialogRef1 = this.dialog.open(PopUpComponent, {
        data: {
          title: 'Alert',
          message: 'Are you sure you want to delete this document?',
          type: 'Delete'
        }
      });
      dialogRef1.afterClosed().subscribe((res) => {
        if (this.vendorDocuments != '') {
          this.vendorDocuments = '';
          this.docName = '';
          this.doc = null;
          this.docError = '';
        } else if (res) {
          if (!archived) {
            this.service.deleteDocument(this.vendorData.id, docId).then(() => {
              this.isVendorsDataUpdated.setUpdated(true);
              this.getAllVendorDocuments(0, this.pageSize, this.showArchived)
              this.vendorDocuments = '';
              this.doc = null;
              this.docName = '';
              this.toastr.success('Document Deleted', 'Success');
            }).catch((err) => {
              this.toastr.error(err.error.reason, 'Request Failed');
            })
          }
        }

      });
    } else if (action === 'confirmation') {
      const dialogRef2 = this.dialog.open(PopUpComponent, {
        data: {
          title: 'Alert',
          message: 'Are you sure you want to Archieve this document?',
          type: 'confirmation'
        }
      });
      dialogRef2.afterClosed().subscribe((res) => {
        if (res) {
          this.service.documentTrash(docId).then(() => {
            this.isVendorsDataUpdated.setUpdated(true);
            this.getAllVendorDocuments(0, this.pageSize, this.showArchived)
            this.vendorDocuments = '';
            this.doc = null;
            this.docName = '';
            this.toastr.success('Document Archived', 'Success');
          }).catch((err) => {
            this.toastr.error(err.error.reason, 'Request Failed');
          })
        }
      })

    }
  }

  onDocupload(): void {
    this.showloader = true;
    if (this.docControl.invalid) {
      this.toastr.error('Document label is required');
      this.showloader = false;
      return;
    }
    const formData: FormData = new FormData();
    formData.append('fileName', this.docName);
    formData.append('file', this.doc);
    formData.append('startDate', this.datePipe.transform(this.documentStartDate ?? '', 'yyyy-MM-dd') || '');
    formData.append('endDate', this.datePipe.transform(this.documentExpiryDate ?? '', 'yyyy-MM-dd') || '');
    if (this.docNameOther && this.typeId == '0') {
      formData.append('name', this.docNameOther);
    }
    formData.append('typeId', this.typeId);
    this.service.uploadDocuments(formData, this.vendorData.id).subscribe((res => {
        if (res.type === 4) {
          this.isVendorsDataUpdated.setUpdated(true);
          this.docTypeName = '';
          this.showloader = false;
          this.vendorDocuments = res.body.url
          this.docId = res.body.id;
          this.doc = null;
          this.typeId = '';
          this.documentStartDate = '';
          this.documentExpiryDate = '';
          this.docTypeName = '';
          this.docControl.reset();
          this.docControl.setErrors(null);
          this.errorUploadingDoc = false
          this.resetFile = true;
          setTimeout(() => this.resetFile = false, 0);
          this.toastr.success('Document Successfully uploaded');
          this.getAllVendorDocuments(this.currentPage, this.pageSize, this.showArchived)
        }
      }), (e) => {
        this.showloader = false;
        this.errorUploadingDoc = true
        this.toastr.error('Document error on upload');
        if (e.error.status == 400) {
          this.docError = 'Please select a valid file ';
        } else if (e.error.status == 500) {
          this.docError = e.error.message;
        }
      }
    );
  }

  updateEndDateMinDate() {
    if (this.documentStartDate) {
      const startDate = new Date(this.documentStartDate);
      startDate.setDate(startDate.getDate() + 1);
      this.minEndDate = this.datePipe.transform(startDate, 'yyyy-MM-dd');
    } else {
      this.minEndDate = null;
    }
  }

  handleFileChange(event: File | null) {
    if (!event) {
      return;
    } else {
      this.doc = event
    }
  }

}
