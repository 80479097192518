import {ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ComponentName, FormUpdateEvent, Members, PlaceDto, VendorCorp} from "../../../../shared/interfaces";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ApiService} from "../../../../services/api.service";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ToastrService} from "ngx-toastr";
import {debounceTime, distinctUntilChanged, filter, map, startWith, takeUntil, tap} from "rxjs/operators";
import {Subject} from "rxjs";
import {Observable} from "rxjs/internal/Observable";
import {IsDataUpdatedService} from "../../../../services/isDataUpdated.service";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {CustomValidators} from "../../../../common/custom-validators";
import {MaxCharLimits} from "../../../../common/errorInputMaxValue";

export interface EmployeeRange {
  range: string
}

@Component({
  selector: 'app-vendor-info',
  templateUrl: './vendor-info.component.html',
  styleUrls: ['./vendor-info.component.css']
})

export class VendorInfoComponent implements OnInit {
  vendorForm: FormGroup;
  countryControl = new FormControl();
  countryServerCtrl = new FormControl();
  searchCountry: boolean = false;
  selectedCountry: any | undefined;
  stateControl = new FormControl('');
  stateServerCtrl = new FormControl();
  searchState: boolean = false;
  selectedState: any | undefined;
  cityControl = new FormControl('');
  cityServerCtrl = new FormControl();
  searchCity: boolean = false;
  displayError: boolean = false;
  selectedCity: any | undefined;
  countries: PlaceDto[] = [];
  states: PlaceDto[] = [];
  cities: PlaceDto[] = [];
  regex = '^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$';
  errorMessage: string | null;
  errorMessagePhone: string | null;
  showloader = false;
  vendor: VendorCorp = {} as any;
  win: boolean | null;
  field = '';
  role: string | null;
  prevLink1;
  prevLink2: string;
  errors: Set<string> = new Set<string>();
  @ViewChild('noEmployeesAutoCompleter', {read: MatAutocompleteTrigger})
  noEmployeesAutoCompleter: MatAutocompleteTrigger;
  numberOfEmployeesOptions: string[] = [];
  filteredRange: Observable<string[]>;
  selectedemployeeRange: any;
  protected _onDestroy = new Subject<void>();
  @Input() vendorData: any;
  @Input() openDialogType: string;
  spocControl = new FormControl('');
  selectedSpcId: string;
  filteredSpoc: Observable<Members[]>;
  @ViewChild('SPOCAutoCompleter', {read: MatAutocompleteTrigger})
  SPOCAutoCompleter: MatAutocompleteTrigger;
  SPOC_Options: Members[] = [];
  previousFormValue: any;
  initializingForm: boolean = true;
  @Output() formUpdated = new EventEmitter<FormUpdateEvent>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private service: ApiService,
    public dialog: MatDialog,
    public router: Router,
    public snackBar: MatSnackBar,
    private toastr: ToastrService,
    private isVendorsDataUpdated: IsDataUpdatedService,
    private cdr:ChangeDetectorRef,
  ) {
    this.prevLink1 = 'https://www.'
    this.prevLink2 = 'https://www.'
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
    this.role = localStorage.getItem('role');
    this.filteredSpoc = this.spocControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterSPOC(value))
    );
    this.getSPOC();
    this.getEmployeeRange();
    this.onSearchCountry();
    this.onSearchState();
    this.onSearchCities();
    this.vendorForm = this.fb.group({
      gstNumber: ["", [Validators.pattern(this.regex)]],
      ceoName: ["", [Validators.required]],
      vendorName: [""],
      websiteUrl: ["", [Validators.required,CustomValidators.urlValidator()]],
      phoneNumber: [""],
      number: ["", Validators.required],
      emailAddress: ["", [Validators.required, CustomValidators.validEmail()]],
      employeeRange: this.employeeRange,
      picture: [''],
      spocId: [null, Validators.required],
      countryControl: [''],
      address: this.fb.group({
        streetAddress: ['', Validators.required],
        postalCode: ['', Validators.pattern("[0-9]{6}")],
        country: this.fb.group({
          id: [''],
          name: [''],
          formattedAddress: [''],
        }, Validators.required),
        state: this.fb.group({
          id: [''],
          name: [''],
          formattedAddress: [''],
        }, Validators.required),
        city: this.fb.group({
          id: [''],
          name: [''],
          formattedAddress: [''],
        }, Validators.required),
      }),
    });
    if (this.vendorData.id) {
      this.service.getVendorById(this.vendorData.id).subscribe({
          next: res => {
            this.vendor = res;
            this.vendor.vendorName = res.vendorName;
            this.vendor.id = this.data.vendorId;
            this.selectedemployeeRange = res.employeeRange
            this.vendorForm.get("gstNumber")?.setValue(res.gstNumber);
            this.vendorForm.get("vendorName")?.setValue(res.vendorName);
            this.vendorForm.get("ceoName")?.setValue(res.ceoName);
            this.vendorForm.get("phoneNumber")?.setValue(res.phoneNumber?.mobile);
            this.vendorForm.get("number")?.setValue(res.number?.mobile);
            this.vendorForm.get("websiteUrl")?.setValue(res.websiteUrl);
            this.vendorForm.get("emailAddress")?.setValue(res.emailAddress);
            this.vendorForm.get("picture")?.setValue(res.logo);
            this.vendorForm.get("employeeRange")?.setValue(res.employeeRange);
            this.employeeRange.setValue(this.selectedemployeeRange)
            if (res.spoc?.firstName) {
              this.spocControl.setValue(res.spoc);
              this.selectedSpcId = res.spoc?.id ?? null;
              this.vendorForm.get('spocId')?.setValue(this.selectedSpcId) ?? null;
            }
            if (res.address.country) {
              this.selectedCountry = res?.address.country;
              if (res.address.country) {
                this.countries = [res.address.country];
                this.vendorForm?.get('address')?.get("country")?.get('id')?.setValue(res?.address.country.id);
                this.vendorForm?.get('address')?.get("country")?.get('name')?.setValue(res?.address.country.name);
                this.vendorForm?.get('address')?.get("country")?.get('formattedAddress')?.setValue(res?.address.state?.formattedAddress);
                this.countryControl.setValue(this.selectedCountry);
              }
              this.selectedState = res.address.state;
              if (res.address.state) {
                this.states = [res.address.state];
                this.vendorForm?.get('address')?.get("state")?.get('id')?.setValue(res?.address.state.id);
                this.vendorForm?.get('address')?.get("state")?.get('name')?.setValue(res?.address.state.name);
                this.vendorForm?.get('address')?.get("state")?.get('formattedAddress')?.setValue(res?.address.state?.formattedAddress);
                this.stateControl.setValue(this.selectedState);
              }
              this.selectedCity = res.address.city
              if (res.address.city) {
                this.cities = [res.address.city]
                this.vendorForm?.get('address')?.get("city")?.get('id')?.setValue(res?.address.city.id);
                this.vendorForm?.get('address')?.get("city")?.get('name')?.setValue(res?.address.city.name);
                this.vendorForm?.get('address')?.get("city")?.get('formattedAddress')?.setValue(res?.address.state?.formattedAddress);
                this.cityControl.setValue(this.selectedCity);
              }
            } else {
              this.selectedCountry = {id: 101, name: "India", formatedAddress: "India"};
              this.countries = [{id: 101, name: "India", formattedAddress: "India"}];
            }
            this.vendorForm?.get('address')?.get("postalCode")?.setValue(res?.address.postalCode);
            this.vendorForm?.get('address')?.get("streetAddress")?.setValue(res?.address.streetAddress);
          },
          error: err => {
            console.error(err);
          },
          complete: () => {
            this.previousFormValue = this.vendorForm.value;
            this.initializingForm = false;
          }
        }
      )
    }
    this.vendorForm.valueChanges.pipe(
      debounceTime(500),
      filter(() => !this.initializingForm),
      map(currentValue => {
        const prevValue = JSON.parse(JSON.stringify(this.previousFormValue, (key, value) => value === undefined ? "" : value));
        const currValue = JSON.parse(JSON.stringify(currentValue, (key, value) => value === undefined ? "" : value));
        let prevNumber = prevValue.number;
        let currNumber = currValue.number?.number;
        if (currValue.number?.countryCode === 'IN') {
          if (prevNumber?.startsWith('0')) {
            prevNumber = prevNumber.replace(/^0+/, '');
          }
          if (currNumber?.startsWith('0')) {
            currNumber = currNumber.replace(/^0+/, '');
          }
          prevNumber = prevNumber.replace(/\s+/g, '');
          currNumber = currNumber.replace(/\s+/g, '');
        }
        let prevEmergencyNumber = prevValue.phoneNumber;
        let currEmergencyNumber = currValue.phoneNumber?.number;
        if (currValue.phoneNumber?.countryCode === 'IN') {
          if (prevEmergencyNumber?.startsWith('0')) {
            prevEmergencyNumber = prevEmergencyNumber.replace(/^0+/, '');
          }
          if (currEmergencyNumber?.startsWith('0')) {
            currEmergencyNumber = currEmergencyNumber.replace(/^0+/, '');
          }
          prevEmergencyNumber = prevEmergencyNumber.replace(/\s+/g, '');
          currEmergencyNumber = currEmergencyNumber.replace(/\s+/g, '');
        }
        delete prevValue.number;
        delete currValue.number;
        delete prevValue.phoneNumber;
        delete currValue.phoneNumber;
        const isNumberUpdated = JSON.stringify(currValue) !== JSON.stringify(prevValue);
        const isEmergencyNumberUpdated = (prevNumber ?? '') !== (currNumber ?? '') || (prevEmergencyNumber ?? '') !== (currEmergencyNumber ?? '');
        return isNumberUpdated || isEmergencyNumberUpdated;
      }),
      distinctUntilChanged()
    ).subscribe((isFormUpdated: any) => {
      this.formUpdated.emit({updated: isFormUpdated, componentName: ComponentName.CustomerInfo});
    });
  }

  scrollEvent = (): void => {
    if (this.noEmployeesAutoCompleter?.panelOpen) {
      this.noEmployeesAutoCompleter.updatePosition();
    } else if (this.SPOCAutoCompleter?.panelOpen) {
      this.SPOCAutoCompleter.updatePosition();
    }
  };

  getMaximumNumberOfEmployees(): number {
    let maxNumber = Number.MIN_SAFE_INTEGER;
    this.numberOfEmployeesOptions?.forEach(option => {
      const range = option.split('-').map(Number);
      const maxInRange = Math.max(...range);
      if (maxInRange > maxNumber) {
        maxNumber = maxInRange;
      }
    });
    return maxNumber;
  }

  customMaxValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const predefinedOptions = this.numberOfEmployeesOptions.map(option => option.toLowerCase());
    if (value) {
      const lowerValue = value.toLowerCase();
      if (predefinedOptions.includes(lowerValue)) {
        return null;
      }
      const maxValue = this.getMaximumNumberOfEmployees();
      const pattern = /^\d{1,4}-\d{1,4}$|^\d{1,4}\+$/;
      if (!pattern.test(value)) {
        return {pattern: true};
      }
      if (value === '1000+') {
        return null;
      } else if (value.endsWith('+')) {
        return {pattern: true};
      } else {
        const range = value.split('-').map(Number);
        const firstNumber = range[0];
        const secondNumber = range[1];
        if (firstNumber >= secondNumber) {
          return {invalidRange: true};
        }
        if (firstNumber <= maxValue || secondNumber <= maxValue) {
          return {max: true};
        }
      }
    }
    return null;
  };

  employeeRange = new FormControl('', [
    Validators.pattern(/^\d{1,4}-\d{1,4}$|^\d{1,4}\+$/),
    this.customMaxValidator
  ]);

  validateForm(): Promise<void> {
    return new Promise((resolve, reject) => {
      let hasErrors = false;
      const validateControl = (control: AbstractControl) => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach(subControl => validateControl(subControl));
        } else {
          if (control.invalid) {
            control.markAsTouched();
            hasErrors = true;
          }
        }
      };
      Object.values(this.vendorForm.controls).forEach(control => validateControl(control));
      if (this.vendorForm.invalid || hasErrors) {
        this.showloader = false;
        reject();
        return;
      }
      resolve();
    });
  }

  updateVendor(): void {
    console.log(this.vendorForm.controls);
    this.errorMessage = '';
    this.errors.clear();
    this.validateForm().then(() => {
      let payload: any = {
        ...this.vendorForm.value,
        skills: this.vendor.skills,
      };
      if (this.vendorForm?.value?.number) {
        payload.number = {
          countryCode: this.vendorForm?.value?.number?.countryCode,
          dialCode: this.vendorForm?.value?.number?.dialCode,
          e164Number: this.vendorForm?.value?.number?.e164Number,
          internationalNumber: this.vendorForm?.value?.number?.internationalNumber,
          nationalNumber: this.vendorForm?.value?.number?.nationalNumber,
          mobile: this.vendorForm?.value?.number?.number,
        };
      }
      if (this.vendorForm?.value?.phoneNumber) {
        payload.phoneNumber = {
          countryCode: this.vendorForm?.value?.phoneNumber?.countryCode,
          dialCode: this.vendorForm?.value?.phoneNumber?.dialCode,
          e164Number: this.vendorForm?.value?.phoneNumber?.e164Number,
          internationalNumber: this.vendorForm?.value?.phoneNumber?.internationalNumber,
          nationalNumber: this.vendorForm?.value?.phoneNumber?.nationalNumber,
          mobile: this.vendorForm?.value?.phoneNumber?.number,
        };
      }
      this.service.updateVendors(payload, this.vendorData.id).then(
        () => {
          this.isVendorsDataUpdated.setUpdated(true);
          this.win = true;
          this.showloader = false;
          this.formUpdated.emit({updated: false, componentName: ComponentName.VendorInfo});
          this.toastr.success('Company Information Updated', 'Success', {
            positionClass: 'toast-bottom-right'
          });
          this.errorMessage = '';
          this.errorMessagePhone = '';
          this.formUpdated.emit({updated: false, componentName: ComponentName.CustomerInfo});
        },
        (e) => {
          this.showloader = false;
          this.toastr.error(e.error.message, 'Failed', {
            positionClass: 'toast-top-right'
          });
          if (e.error.status == 400) {
            this.errorMessage = e.error.message;
          } else if (e.error.code == 4000) {
            this.errorMessage = e.error.reason;
          } else if (e.error.status == 500) {
            this.errorMessage = e.error.message;
          } else if (e.error.status == 403) {
            this.errorMessage = 'Access Denied';
          } else if (e.error.status == 409) {
            this.errorMessage = e.error.message;
          } else {
            this.errorMessage = 'Error occurred, Please contact the server team';
          }
        }
      );
    });
  }

  getError() {
    return 'Error/Required field(s)' + Array.from(this.errors).join(",")
  }

  // onKeyPress(event: Event): void {
  //   const inputElement = event.target as HTMLInputElement;
  //   const inputValue = inputElement.value;
  //   const numericValue = inputValue.replace(/\D/g, '');
  //   if (numericValue.length === 10) {
  //     event.preventDefault();
  //     this.displayError = false;
  //     this.errorMessage = "";
  //   } else {
  //     this.displayError = true;
  //     this.errorMessage = "Please enter a valid 10-digit mobile number";
  //   }
  //   inputElement.value = numericValue;
  // }
  //
  // onKeyPressPhone(event: KeyboardEvent): void {
  //   const allowedChars = /[0-9]/;
  //   const key = event.key;
  //   const isValidInput = allowedChars.test(key);
  //   if (!isValidInput) {
  //     event.preventDefault();
  //     this.errorMessagePhone = "Please enter valid phone number";
  //     console.log("please enter only number")
  //   } else {
  //     this.errorMessagePhone = "";
  //   }
  // }

  compareFunction(optionValue: PlaceDto, selectedValue: PlaceDto): boolean {
    return optionValue && selectedValue && optionValue === selectedValue;
  }

  onSearchCountry() {
    this.countryServerCtrl.valueChanges.pipe(
      filter(search => !!search),
      tap(() => this.searchCountry = true),
      takeUntil(this._onDestroy),
      debounceTime(500),
      distinctUntilChanged(),
      map(search => {
        return this.getCountries(search)
      }),
    ).subscribe({
      next: filteredCountries => {
        this.searchCountry = false;
      }, error: err => {
        this.searchCountry = false;
      }
    });
  }

  getCountries(query: string): void {
    this.service.getCountries(query).then((res: any) => {
      this.countries = this.selectedCountry ? [this.selectedCountry, ...res] : res;
      return (res);
    });
  }

  selectCountry(country: PlaceDto): void {
    this.vendorForm?.get("address")?.get("country")?.setValue(country);
    this.selectedState = undefined;
    this.selectedCity = undefined;
    this.selectedCountry = country;
    this.vendorForm?.get("address")?.get("state")?.reset();
    this.vendorForm?.get("address")?.get("city")?.reset();
  }

  onSearchState() {
    this.stateServerCtrl.valueChanges.pipe(
      filter(search => !!search),
      tap(() => this.searchState = true),
      takeUntil(this._onDestroy),
      debounceTime(500),
      distinctUntilChanged(),
      map(search => {
        return this.getStates(search)
      }),
    ).subscribe({
      next: filteredCountries => {
        this.searchState = false;
      }, error: err => {
        this.searchState = false;
      }
    });
  }

  getStates(query: string): void {
    this.service.getStates(this.vendorForm?.get("address")?.get("country")?.get('id')?.value, query).then(res => {
      this.states = this.selectedState ? [this.selectedState, ...res] : res;
    });
  }

  selectState(state: PlaceDto): void {
    this.vendorForm?.get("address")?.get("state")?.get('id')?.setValue(state.id);
    this.vendorForm?.get("address")?.get("state")?.get('name')?.setValue(state.name);
    this.vendorForm?.get("address")?.get("state")?.get('formattedAddress')?.setValue(state.formattedAddress);
    this.selectedState = state;
    this.selectedCity = undefined;
    this.vendorForm?.get("address")?.get("city")?.reset();
  }

  onSearchCities() {
    this.cityServerCtrl.valueChanges.pipe(
      filter(search => !!search),
      tap(() => this.searchCity = true),
      takeUntil(this._onDestroy),
      debounceTime(500),
      distinctUntilChanged(),
      map(search => {
        return this.getCities(search)
      }),
    ).subscribe({
      next: filteredCountries => {
        this.searchCity = false;
      }, error: err => {
        this.searchCity = false;
      }
    });
  }

  getCities(query: string): void {
    this.service.getCities(this.vendorForm?.get("address")?.get("state")?.get('id')?.value, query).then(res => {
      this.cities = this.selectedCity ? [this.selectedCity, ...res] : res;
    });
  }

  selectCity(city: PlaceDto): void {
    this.vendorForm?.get("address")?.get("city")?.setValue(city);
  }

  getEmployeeRange() {
    this.service.getEmployeeCount().subscribe((res) => {
      this.numberOfEmployeesOptions = res?.map((res: any) => res?.range) || [];
      this.filteredRange = this.employeeRange.valueChanges.pipe(
        startWith(''),
        map(value => this._filterTimeZones(value))
      );
    }, (error) => {
      console.log('err', error);
    });
  }

  private _filterTimeZones(value: string): string[] {
    if (typeof value !== 'string') {
      return this.numberOfEmployeesOptions;
    }
    const filterValue = value.toLowerCase();
    return this.numberOfEmployeesOptions.filter(timeZone => timeZone.toLowerCase().includes(filterValue));
  }

  createDateRangeForEmployee() {
    const range = this.employeeRange.value;
    const payload = {range: range};
    this.service.createEmployeeRange(payload).subscribe(
      res => {
        this.isVendorsDataUpdated.setUpdated(true);
        this.toastr.success('Employee range created successfully', 'Success');
        this.getEmployeeRange();
      },
      error => {
        console.log('error', error);
        this.toastr.error('Failed to add employee range.', 'Error');
      }
    );
  }

  onRangeSelected(event: any) {
    const workTimeZone = event.option.value;
    this.vendorForm.get('employeeRange')?.setValue(workTimeZone);
  }

  getSPOC() {
    this.service.getSpocList().subscribe((res: any) => {
      this.SPOC_Options = res.filter((active: any) => active.active);
      this.filteredSpoc = this.spocControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterSPOC(value))
      );
    });
  }

  private _filterSPOC(value: string): Members[] {
    if (typeof value !== 'string') {
      return [];
    }
    const filterValue = value.toLowerCase();
    return this.SPOC_Options.filter(option =>
      (option?.firstName && option.firstName.toLowerCase().includes(filterValue)) ||
      (option?.lastName && option.lastName.toLowerCase().includes(filterValue)) ||
      (option?.role && option.role.toLowerCase().includes(filterValue))
    );
  }

  chooseSpoc(event: any) {
    this.selectedSpcId = event?.option?.value?.userId
    this.vendorForm.get('spocId')?.setValue(event?.option?.value?.userId)
  }

  formatSPOC(option: Members): string {
    if (!option) {
      return '';
    }
    return option.role + ' | ' + option.firstName + ' ' + option.lastName;
  }

  getFormControl<T>(controlName: string): any {
    return this.vendorForm.get(controlName);
  }

  MaxCharLimits = MaxCharLimits;
}
