import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormControl, ValidationErrors} from "@angular/forms";
import {CandidateSkills} from "../../../../shared/interfaces";
import {Observable} from "rxjs/internal/Observable";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {MatDialog} from "@angular/material/dialog";
import {ApiService} from "../../../../services/api.service";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {map, startWith} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'app-skills-component',
  templateUrl: './skills-component.component.html',
  styleUrls: ['./skills-component.component.css']
})
export class SkillsComponentComponent implements OnInit, AfterViewInit {
  @Input() customerInfoData: CandidateSkills[];
  @Input() id!: number;
  @Input() limited: boolean = false;
  selectedSkill: CandidateSkills[] = [];
  skillName = '';
  filteredSkills: Observable<CandidateSkills[]>;
  @ViewChild("chipGrid") chipGrid: ElementRef;
  skillsRes: CandidateSkills[] = [];
  showloader = false;
  role: string | null;
  @ViewChild('skillsAutoCompleter', {read: MatAutocompleteTrigger})
  skillsAutoCompleter: MatAutocompleteTrigger;
  loading: boolean = false;
  @Input() handleSkillUpdate!: (jobCandidateId: number, payload: any) => Observable<any>;
  selectedLevel: string = 'MASTER';
  showDropdown: boolean = false;
  @ViewChild('dropdown') dropdown: ElementRef;
  @ViewChild('skillInput') skillInput: ElementRef;
  skillLevels = [
    {id: 'MASTER', label: 'Master'},
    {id: 'INTERMEDIATE', label: 'Intermediate'},
    {id: 'BEGINNER', label: 'Beginner'}
  ];
  selectedSkillFromDropdown: boolean = false;
  private isScrolling = false;
  hasError: boolean = false;
  @Input() modal!: boolean;
  skillControl = new FormControl('', {
    validators: [this.skillsRequiredValidator(() => this.selectedSkill)]
  });
  @Input() removeButton: boolean = false;
  @Output() selectedSkills = new EventEmitter<{ skillId: number; skillType: string; }[]>();
  @Output() hasErrors = new EventEmitter<boolean>();
  @Input() isSubmitting: boolean = false;
  private selectedSkillsSubject = new BehaviorSubject<{ skillId: number; skillType: string; }[]>([]);
  selectedSkills$ = this.selectedSkillsSubject.asObservable();
  @Input() disabled: boolean = false;
  @Output() hasSkillDataUpdated = new EventEmitter<boolean>();

  constructor(
    private service: ApiService,
    public dialog: MatDialog,
    public router: Router,
    public snackBar: MatSnackBar,
    public toastr: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.getSkillType();
    this.role = localStorage.getItem('role');
    this.selectedSkills$.subscribe(skillRequests => {
      this.selectedSkills.emit(skillRequests)
    });
  }

  ngAfterViewInit() {
    this.getSkillType();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['customerInfoData'] && changes['customerInfoData'].currentValue) {
      this.selectedSkill = this.customerInfoData?.map(skill => ({
        ...skill,
        level: skill.skillType
      }));
      this.emitSelectedSkills()
    }
  }

  private emitSelectedSkills(): void {
    const skillRequests = this.selectedSkill.map(skill => ({
      skillId: (skill?.skill?.id || skill?.id) || 0,
      skillType: skill.skillType
    }));
    this.selectedSkillsSubject.next(skillRequests);
  }

  removeSkill(event: any, skill: CandidateSkills): void {
    if (this.disabled) return;
    event.preventDefault();
    event.stopPropagation();
    const index = this.selectedSkill.findIndex(s => s.skill?.id === skill?.skill?.id);
    if (index >= 0) {
      this.selectedSkill.splice(index, 1);
      this.skillsRes.push(skill);
      this.refreshFilteredSkills();
      this.skillControl.updateValueAndValidity();
      if (this.selectedSkill.length === 0) {
        this.skillControl.markAsTouched();
      }
    } else {
      console.log('Skill not found in selectedSkill:', skill);
    }
  }

  selectSkills(skill: CandidateSkills): void {
    const skillExists = this.selectedSkill.find(s => s.id === skill.id);
    if (!skillExists) {
      const skillWithLevel = {skill, skillType: this.selectedLevel} as CandidateSkills;
      this.selectedSkill.push(skillWithLevel);
      this.refreshFilteredSkills();
      this.skillControl.setValue(this.skillName);
      this.skillControl.updateValueAndValidity();
    }
    this.selectedSkillFromDropdown = true;
  }

  private refreshFilteredSkills(): void {
    this.filteredSkills = this.skillControl.valueChanges.pipe(
      startWith(this.skillControl.value),
      map(value => this._filterSkills(value))
    );
    this.emitSelectedSkills()
    this._filterSkills(this.skillControl.value);
  }

  onKeyUp(): void {
    this.showDropdown = this.skillName.trim() !== '';
    this.refreshFilteredSkills();
  }

  getLevelColor(level: string): string {
    const levelColors: { [key: string]: string } = {
      MASTER: '#22C55E',
      INTERMEDIATE: '#EAB308',
      BEGINNER: '#EF4444',
    };
    return levelColors[level] || '#ccc';
  }

  addSkillType(): void {
    if (this.skillName === "") {
      return;
    } else {
      let payload = {
        'title': this.skillName,
      };
      this.service.addOrgSkills(payload).then(res => {
        this.getSkillType();
        const skillWithLevel = {skill: res, skillType: this.selectedLevel} as CandidateSkills;
        this.selectedSkill.push(skillWithLevel);
        const skillRequests = this.selectedSkill.map(skill => ({
          skillId: skill.skill?.id,
          skillType: skill.skillType
        }));
        this.selectedSkills.emit(skillRequests);
        this.skillName = '';
        this.skillControl.patchValue('', {emitEvent: false});
      }, (e) => {
        console.log('error', e.error.message);
        this.toastr.error('Error', e.error.reason);
      });
    }
  }

  disableSaveButton(): boolean {
    if (this.selectedSkill.length === 0) return true;
    if (!this.customerInfoData || !this.selectedSkill) return true;
    const initialSkills = this.customerInfoData?.map(skill => ({
      skillId: skill.skill?.id,
      skillType: skill.skillType
    }));
    const updatedSkills = this.selectedSkill?.map(skill => ({
      skillId: skill.skill?.id,
      skillType: skill.skillType
    }));
    this.hasSkillDataUpdated.emit(JSON.stringify(initialSkills.sort(this.compareSkills)) === JSON.stringify(updatedSkills.sort(this.compareSkills)))
    return JSON.stringify(initialSkills.sort(this.compareSkills)) === JSON.stringify(updatedSkills.sort(this.compareSkills));
  }

  compareSkills(a: any, b: any) {
    return a.skillId - b.skillId || a.skillType?.localeCompare(b.skillType);
  }

  addSkill(): void {
    this.skillControl.markAsTouched();
    if (this.skillControl.invalid) {
      this.hasError = true;
      return;
    }
    if (this.disableSaveButton()) return;
    this.loading = true;
    const skillRequests = this.selectedSkill.map(skill => ({
      skillId: skill.skill?.id,
      skillType: skill.skillType
    }));
    const payload = {skillRequests};
    this.selectedSkills.emit(skillRequests)
    this.handleSkillUpdate(this.id, payload).subscribe(
      (res: any) => {
        this.customerInfoData = res.skills;
        this.selectedSkill = res.skills?.map((skill: any) => ({
          ...skill,
          level: skill.skillType
        }));
        this.loading = false;
        this.skillName = '';
        this.showloader = false;
        this.getSkillType();
      },
      (error: any) => {
        console.log('Error:', error.message);
        this.loading = false;
      }
    );
  }

  getSkillType(): void {
    this.service.getSkills().then((res: any[]) => {
      this.skillsRes = res?.filter((skill) => skill?.title !== "");
      this.filteredSkills = this.skillControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterSkills(value))
      );
    });
  }

  private _filterSkills(value: string): CandidateSkills[] {
    const filterValue = value.trim().toLowerCase();
    const selectedSkillIds = this.selectedSkill?.map(skill => skill?.skill?.id || skill?.id);
    let filteredSkills = this.skillsRes.filter(skill =>
      skill?.title?.toLowerCase().includes(filterValue) &&
      !selectedSkillIds.includes(skill.id ?? 0)
    )
    if (this.limited) {
      filteredSkills = filteredSkills.slice(0, 20);
    }
    this.emitSelectedSkills();
    return filteredSkills;
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    if (this.isScrolling) return;

    const clickedInside = this.skillInput?.nativeElement?.contains(event.target) ||
      this.dropdown?.nativeElement?.contains(event.target);
    if (!clickedInside) {
      this.showDropdown = false;
      this.skillControl.reset('');
    }
  }

  handleInputFocus = () => {
    if (this.disabled) return
    this.showDropdown = true;
    setTimeout(() => {
      requestAnimationFrame(() => {
        if (this.skillInput && this.dropdown) {
          const dropdownRect = this.dropdown.nativeElement.getBoundingClientRect();
          const isDropdownOutOfView = dropdownRect.bottom > window.innerHeight;
          if (isDropdownOutOfView) {
            this.dropdown.nativeElement.scrollIntoView({behavior: "smooth", block: "nearest"});
          }
        }
      });
    }, 200);
  };

  isSkillAlreadySelected(skillTitle: string): boolean {
    return this.selectedSkill.some(skill =>
      (skill.title && skill.title.toLowerCase() === skillTitle.toLowerCase()) ||
      (skill.skill?.title && skill.skill.title.toLowerCase() === skillTitle.toLowerCase())
    );
  }


  isSkillInFilteredList(skillTitle: string): boolean {
    return this.skillsRes.some(skill => skill?.title?.toLowerCase() === skillTitle.toLowerCase());
  }

  skillsRequiredValidator(selectedSkills: () => CandidateSkills[]) {
    return (): ValidationErrors | null => {
      return selectedSkills().length === 0 ? {requiredSkills: true} : null;
    };
  }

  handleModelChange(): void {
    if (this.isSubmitting && this.skillControl.invalid) {
      this.skillControl.markAsTouched();
      this.skillControl.updateValueAndValidity();
      this.hasError = true
    } else {
      this.hasError = false
    }
  }

}
