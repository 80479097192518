import {SortDirection} from "@angular/material/sort";
import {UserDeviceUsed, UserLocation} from "../content/interface/admin/MemberAnalytics";

export interface VendorCorp {

  id: any;
  vendorName: string;
  emailAddress: string;
  websiteUrl: string;
  mobileNumber: string;
  active: boolean;
  numberOfEmployees: number;
  gstNumber: string;
  ceoName: string;
  logo: string;
  logoCid: string;
  createdDate: number;
  createdBy: string;
  streetAddress: string;
  areaCode: string;
  status: boolean;
  skills: Skill[],
  address: address;
  number:NumberFields,
  phoneNumber:NumberFields,
  // state: PlaceDto;
  // city: PlaceDto;
  // country: PlaceDto;
  freelancer: boolean;
  spoc?:Spoc
}

export interface Consultant {
  id: any,
  firstName: string;
  middleName: string;
  lastName: string;
  consultantId: string;
  title: string;
  education: Education;
  vendor: VendorSummary;
  status: ConsultantStatus;
  skills?: CandidateSkills[],
  primarySkills?: Skill[],
  secondarySkills?: Skill[],
  availabilityToJoin: string,
  passingYear: string;
  experienceYear: number;
  experienceMonth: number;
  address: address;
  communicationRating: string;
  summary: string;
  gender: Gender;
  jobType: string;
  shortlisted: boolean,
  showProfilePicture: boolean,
  profilePicture: string
  active: boolean,
  resume: resume,
  video: video,
  startDate?: string,
  preferredLocations: PreferredLocations[],
  updatedDate: string,
  createdDate: string,
  solutionId?: number,
  name?: string
  spoc?: spocPayloadDT,
  consultantSkills:CandidateSkills
}

export interface Candidate {
  id: any,
  firstName: string,
  middleName: string,
  lastName: string,
  title: string,
  status: any,
  noticePeriod: string,
  skills: webCandidateSkills,
  primarySkills?: Skill[],
  secondarySkills?: Skill[],
  availabilityToJoin: string,
  passingYear: string,
  experienceYear: number,
  experienceMonth: number,
  address: address,
  communicationRating: string;
  summary: string,
  gender: Gender,
  jobType: string,
  shortlisted: boolean,
  startDate?: string,
  preferredLocations: PreferredLocations[],
  updatedDate: string,
  createdDate: string,
  appliedDate?: string,
  currentLocation: CurrentLocation,
  addressInformation?: CurrentLocation
  mobile: number,
  email: string,
  linkedInURL: string,
  currentCtc: string,
  expectedCtc: string,
  notes: string,
  createdOn?: string,
  updatedOn?: string
  company?: string,
  linkedIn?: string,
  isVerified?: boolean,
  verificationStatus?: string;
  candidateSkills: CandidateSkills[],
  communicationSkillsRating?:number,
  number: {
    internationalNumber: number,
    nationalNumber: number,
    e164Number: number,
    countryCode: number,
    dialCode: number
    mobile: number,
  },
  updatedBy: {
    firstName: string,
    lastName: string
  },
}

export interface CandidateSkills {
  skill: {
    id: number,
    title: string,
    active: boolean
  },
  id?: number,
  title?:string,
  active?: boolean,
  skillType: string,
}

export interface CustomerWeb {
  id: any,
  firstName: string,
  middleName: string,
  lastName: string,
  title: string,
  status: ConsultantStatus,
  jobType: string,
  preferredLocations: PreferredLocations[],
  updatedOn: string,
  createdOn: string,
  appliedDate?: string,
  currentLocation: CurrentLocation,
  mobile: number,
  email: string,
  company: string,
  verificationStatus?: string,
  addressInformation?: address
  number: NumberFields
}

interface CurrentLocation {
  postalCode: string,
  streetAddress: string,
  address?: string,
  pinCode?: number,
  cityId: number,
  stateId: number,
  countryId: number
  country?: {
    id: number,
    name: string,
    formattedAddress: string
  },
  city?: {
    id: number,
    name: string,
    formattedAddress: string
  },
  state?: {
    id: number,
    name: string,
    formattedAddress: string,
    stateCode: string,
    countryId: number
  }
}

export interface webCandidateSkills {
  primarySkills: PrimarySkill[];
}

export interface PrimarySkill {
  id: number;
  title: string;
  description: string;
  active: boolean;
}

export interface video {
  url: string
  visible: boolean
}

export interface VendorSummary {
  id: number;
  vendorName: string;
  contact: string;
  phoneNumber?: string;
  createdDate?: string;
  modifiedDate?: string;
  email: string;
  freelancer: boolean;
  status?: boolean;
}

export enum ConsultantStatus {
  AVAILABLE = 'AVAILABLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  DEPLOYED = 'DEPLOYED',
  HOLD = 'HOLD',
}

export interface resume {
  resumeStatus: boolean
  resumeName: string
  fileUrl: string
}

export interface PreferredLocations {
  id: string,
  name: string | ''
}

export interface ConsultantAdmin {
  "id": any,
  "consultantId": string,
  "firstName": string,
  "middleName": string,
  "lastName": string,
  "title": string,
  "designation": string,
  "education": Education,
  "passingYear": string,
  experienceYear?: number;
  experienceMonth?: number;
  "location": string,
  primarySkills?: Skill[],
  "active": boolean,
  "enableSearch": boolean,
  "showProfilePicture": boolean,
  "vendorContactDto": [],
  "profilePicture": string
}


export interface Education {
  "id": number,
  "seq": number,
  "name": string,
  "active": boolean
}

export interface Customer {
  id: any;
  customerId: string;
  companyName: string;
  ceoName: string;
  email: string;
  website: string;
  category: string;
  createdDate: any;
  updateDate: string;
  address: address;
  active: boolean;
  createdBy: createdBy
  updatedBy: updatedBy
  logo: string;
  number: {
    internationalNumber: number,
    nationalNumber: number,
    e164Number: number,
    countryCode: number,
    dialCode: number
    mobile: number,
  }
}

export interface createdBy {
  id: string;
  firstName: string;
  lastName: string;
  active: boolean;
}

export interface updatedBy {
  id: string;
  firstName: string;
  lastName: string;
  active: boolean;
}

export interface CreateCustomer {
  id: any,
  companyName: string,
  ceoName: string,
  ceoContactNo: string,
  email: string,
  website: string,
  address: any;
  logoCid: string;
  logo: string;
}

export interface VendorCreateInterface {

  id: any;
  vendorName: string;
  logoCid: string;

}

export interface spocPayloadDT {
  "id": number,
  "firstName": string,
  "lastName": string,
  "active": boolean,
  role?: string
}

export interface SPOC {
  id: string;
  name: string;
}

export interface consultantFilters {
  skills: Skill[];
  jobType: any[];
  experience: any[],
  locations: PlaceDto[],
}


export interface DefaultFilters {
  skills: Skill[];
  locations: PlaceDto[];
}

export class EmployeeRangeOption {
  id: number;
  range: string;

}

export class Skill {
  id: number;
  title: string;
  name?: string;
  description: string;
  isSelected: boolean = false;
  primary: boolean = false;
}

export interface Skills {
  title: string;
}

export class Members {
  userId: number;
  firstName: string;
  lastName: string;
  role: string;
  createdDate: string;
  orgId: string;
  active: boolean;
  email: string;

}

export interface SortTable {
  active: boolean;
  field: string;
  direction: SortDirection;
}

export interface DocType {
  id: number;
  name: string;
  tagName: string;
  createdDate: string;
  seq: string;
  active: boolean;
  title: string,
}

export class DocsType {
  id: number;
  name: string;
  seq: string;
  active: boolean;
}

export interface socialLink {
  id: number;
  label: string;
  value: String;
  seq: number;

}

export class CityDto {
  id: number;
  name: string;

}

export class PlaceDto {
  id: number;
  name: string;
  formattedAddress: string;
  isSelected?: boolean = false;
}

export class JwtUserHash {
  userId: number;
  orgId: number;
  hash: string;
}

export class userDT {
  userId: number;
  orgId: number;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  createdDate: string;
}

export class VendorContact {
  id: any;
  name: string;
  email: string;
  phone: NumberFields;
  designation: string;
  linkedIn: string;
  active: boolean;
  selected?: boolean;
}

export enum LoggedInUser {
  ADMINV = 'Vendor', CLIENT = 'Client'
}


export enum taskStatus {
  ON_HOLD = 'On Hold',
  COMPLETED = 'Completed',
  IN_PROGRESS = 'In Progress',

}

export enum Gender {
  MALE = 'Male', FEMALE = 'Female', Other = 'Other'
}

export enum JobStatus {
  SAVED = 'SAVED', POSTED = 'POSTED'
}

export enum JobType {
  REMOTE = 'REMOTE', ONSITE = 'ONSITE', HYBRID = 'HYBRID'
}


export interface ConsultantDT {
  "id": number,
  "consultantId": string,
  "consultantData": Consultant,
  gender?: string;
  profilePicture?: string
}

export interface customerDT {
  "id": number,
  "customerId": string,
  "customerData": Customer
}


export interface address {
  "country": AddressDTO,
  "state": AddressDTO,
  "city": AddressDTO,
  "postalCode": string,
  "streetAddress": number
}


export interface AddressDTO {
  "id": number,
  "name": string,
  "formattedAddress": string,
  "stateCode": string,
  "countryId": number
}


export interface jobIdDt {
  "id": number,
  "jobId": number,
  "title": string,
  "minExperience": number,
  "maxExperience": number,
}

export interface jobDTO {
  "id": number,
  "jobId": string,
  "title": string,
  "minExperience": number,
  "maxExperience": number,
  "description": string,
  "skills": Skill [],
  "active": boolean
}


export interface adminJob {
  "id": number,
  "jobId": string,
  "title": string,
  "minExperience": number,
  "maxExperience": number,
  "noOfOpenings": number,
  "description": string,
  "notes": string,
  "createdDate": number,
  "skills": skillClient[],
  "active": boolean,
  "tentativeStartDate": string,
  "duration": string,
  "workTimeZone": string,
  createdBy: createdBy
  "status": string,
  "customerContact": customerContact,
}

export interface skillClient {
  "id": number,
  "title": string,
  "active": boolean
  level?: string
}

export interface customerContact extends NumberFormat{
  "id": number,
  "createdOn": number,
  "updatedOn": number,
  "firstName": string,
  "lastName": string,
  "name": string;
  "designation": string,
  "email": string,
  "mobile": string,
  "linkedIn": string,
  "vertical": string,
  "sentEmailStatus": boolean,
  "spoc": spocPayloadDT,
  "customer": Customer
  "createdBy": createdBy,
  "active": boolean
}

export interface NumberFormat {
  number: NumberFields
}

export interface NumberFields {
  internationalNumber: number,
  nationalNumber: number,
  e164Number: number,
  countryCode: number,
  dialCode: number
  mobile: number,
}

export interface myListDTOListing {
  "consultantId": string,
  "title": string,
  "requestSentDate": number,
  "skills": Skill[],
  "experience": number,
  "job": adminJob,
  jobDescription: string
  numberOfOpenings: number,
  workTimeZone: string,
  "assignee": Assigns,
  duration?: any
}

export interface Assigns {
  "id": number,
  "firstName": string,
  "lastName": string,
  "active": boolean
}

export interface myListDTO {
  "id": 0,
  "createdOn": "2023-10-14T05:55:33.599Z",
  "jobDto": adminJob
  "consultantDto": Consultant
  "customerDto": Customer
  "interested": true,
  "requestCv": true,
  "active": true,
  "workTimeZone": "string",
  "tentativeStartDate": "2023-10-14"
}

export interface myTaskDTO {
  "uniqueId": number,
  "user": Assigns,
  "startDate": number,
  "closingDate": number,
  "customer": Customer,
  "sender": customerContact,
  "subject": Consultant,
  "job": adminJob,
  "status": string,
  "assignee": Assigns,
  "myTask": myTask
}

interface myTask {
  "uniqueId": number,
  "user": Assigns,
  "startDate": number,
  "closingDate": number,
  "customer": Customer,
  "sender": customerContact,
  "subject": Consultant,
  "job": adminJob,
  "status": string,
  "assignee": Assigns,
  jobDescription: string,
  consultantWorkTimeZone: string,
  duration?: number
  workTimeZone?: string,
  tentativeStartTime?: string,
  notes?: string
}

export interface TimeZone {
  label: string;
  value: string;
  offset: string;
}

export interface ChildFormUpdates {
  [key: string]: boolean;
}

export interface commentList {
  "id": string,
  "comment": string,
  "consultantCustomerContactJobDto": myTaskDTO,
  "user": Assigns,
  "createdDate": number,
  "updatedDate": number,
}


export enum ComponentName {
  VendorInfo = 'vendor_info',
  VendorSkills = 'vendor_skills',
  VendorAttachments = 'vendor_attachments',
  VendorCommunications = 'vendor_communications',
  VendorSocialLinks = 'vendor_social_links',
  VendorContacts = 'vendor_contacts',

  ConsultantInfo = 'consultant_info',
  ConsultantSkills = 'consultant_skills',
  ConsultantAttachments = 'consultant_attachments',
  ConsultantCommunications = 'consultant_communications',
  ConsultantStatus = 'consultant_status',

  CustomerInfo = 'customer_info',
  CustomerContacts = 'customer_contacts',
  CustomerCommunications = 'customer_communications',
  /* Add other component names as needed */
}

export interface FormUpdateEvent {
  updated: boolean;
  componentName: ComponentName;
  consultheaderDetails?: ConsultheaderDetails;
}

export interface ConsultheaderDetails {
  firstName: string;
  middleName?: string;
  lastName: string;
  experienceYear: number;
  experienceMonth: number;
  occupation: string;
  venderName?: string;
  passingYear?: string
}

export interface CustomerContact {
  id: number;
  createdOn: string;
  updatedOn: string;
  name: string;
  firstName: string;
  lastName: string;
  designation: string;
  email: string;
  mobile: string;
  linkedIn: string;
  vertical: string;
  spoc: spocPayloadDT;
  customer: Customer;
  createdBy: string;
  active: boolean;
  consultantDtoList?: Consultant[];
  sentEmailStatus?: boolean
}

export class ResumeDto {
  resumeName: string;
  resumeStatus: boolean;
}

export class VideoDto {
  url: string;
  visible: boolean;

  constructor(url: string = '', visible: boolean = false) {
    this.url = url;
    this.visible = visible;
  }
}

export interface SessionMap {
  userEmail: string;
  sessionId: string;
}

export enum VideoToggleType {
  Enable = 'enable',
  Disable = 'disable'
}

export interface CustomerAnalytics {
  customerContactId?: number,
  sessionId?: string,
  activity: string;
  id: number;
  customerContact?: string;
  customer?: Customer
  customerName?: string;
  assignedSpoc?: string;
  userDeviceUsed?: UserDeviceUsed;
  userLocation?: UserLocation;
  loginTime?: Date;
  logOutTime?: Date;
  duration?: string;
  customerContactEmail?: string;
  customerContactPhone?: string;
}

export interface CustomerAnalyticsSearch {
  customerContact: string;
  customerName: string;
  assignedSpoc: string;
  deviceUsed: string;
  location: string;
}

export interface InputField {
  label: string;
  key: string;
  ngModel: string;
  pattern?: string;
}

export interface myListPayload {
  consultantStatus?: string;
  consultantId: string;
  consultantTitle?: string;
  skillIds: number[];
  jobIds: number[];
  experience: { min: number; max: number }[];
  dateSearchCriteria: {
    from: string;
    to: string;
  };
}

export interface DateSearchCriteria {
  filterType: string,
  from: string;
  to: string;
}

export interface SearchFilters {
  query: string;
  status: string;
  assigneeId: number[];
  customerName: string;
  sender: string;
  subject: string;
  assignee: string;
  uniqueId: number;
  dateSearchCriteria: DateSearchCriteria;
}

export interface VendorFilters {
  query: string;
  vendorName: string;
  contactNumber: string;
  email: string;
  location: string;
  dateSearchCriteria: DateSearchCriteria;
  skills: number[];
  cities: number[];
  states: number[];
  countries: number[];
  showArchived: boolean;
  spocIds: number[]
}

export interface SolutionsData {
  id: number;
  solutionId: string;
  name: string;
  createdBy: createdBy;
  modifiedBy: createdBy;
  createdDate: string;
  updatedDate: string;
  active: boolean;
  logoCid: string;
  logoUrl: string;
  compliance: string;
  inProduction: string;
  productionDescription: string;
  integration: boolean;
  integrationDescription: string;
  solutionType: string;
  whiteLabeled: string;
  sellingModel: string;
  sellingModelDescription: string;
  demoReady: boolean;
  demoReadyDescription: string;
  implementationTimeframe: string;
  effortInvolved: string;
  pricingDetails: string;
  solutionInfo: solutionInfo;
  solutionTechnology: solutionTechnology;
}

export interface solutionTechnology {
  solutionType: string;
  inProduction: string;
  productionDescription: string;
  integration: string;
  integrationDescription: string;
  whiteLabled: string;
  sellingModel: string;
  demoReady: string;
  compliance: string;
  implementationTimeframe: string;
  effortInvolved: string;
  pricingDetails: string;
  techStackUsed: techStackUsed[];
}

export interface techStackUsed {
  id: string;
  title: string;
  description: string;
}

export interface solutionInfo {
  industryVerticals: IndustryVertical[];
  description: string;
  category: string;
  vendor: VendorSummary;
  spoc: spocPayloadDT;
  solutionTypes: []

}

interface Theme {
  id: number;
  name: string;
  colour: string;
  active: boolean;
  custom: boolean;
}

interface User {
  id: number;
  firstName: string;
  lastName: string;
  theme: Theme;
  active: boolean;
}

interface Vendor {
  id: number;
  vendorName: string;
  phoneNumber: string;
  status: boolean;
  createdDate: number;
  modifiedDate: number;
  freelancer: boolean;
}

export interface Spoc {
  id: number;
  firstName: string;
  lastName: string;
  role: string;
  active: boolean;
}

interface SolutionInfo {
  description: string;
  category: string;
  vendor: Vendor;
  spoc: Spoc;
  industryVerticals: IndustryVertical[];
}

interface TechStack {
  id: number;
  title: string;
  active: boolean;
}

interface SolutionTechnology {
  compliance: string;
  techStackUsed: TechStack[];
  inProduction: string;
  productionDescription: string;
  integration: boolean;
  integrationDescription: string;
  solutionType: string;
  whiteLabled: string;
  sellingModel: string;
  sellingModelDescription: string;
  demoReady: boolean;
  demoReadyDescription: string;
  implementationTimeframe: string;
  effortInvolved: string;
  pricingDetails: string;
}

export interface Solution {
  id: number;
  solutionId: string;
  name: string;
  createdBy: User;
  modifiedBy: User;
  createdDate: number;
  updatedDate: number;
  active: boolean;
  solutionInfo: SolutionInfo;
  solutionTechnology: SolutionTechnology;
  logoUrl: string;
  logoCid: string;
}

interface Address {
  streetAddress: string;
  postalCode: string;
  city: City;
  state: State;
  country: Country;
}

// Interfaces for user, skill, vendor, and SPOC
interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  pictureCid: string;
  picture: string;
  role: 'ADMIN' | string;
  createdDate: string;
  modifiedDate: string;
  theme: Theme;
  active: boolean;
}

interface Theme {
  id: number;
  name: string;
  colour: string;
  active: boolean;
  custom: boolean;
}

interface City {
  id: number;
  name: string;
  formattedAddress: string;
}

interface State {
  id: number;
  name: string;
  formattedAddress: string;
  stateCode: string;
  countryId: number;
}

interface Country {
  id: number;
  name: string;
  formattedAddress: string;
}

export interface IndustryVertical {
  id?: number;
  name?: string;
  createdDate?: string;
  active?: true
}

export interface CreateSolution {
  name: string;
  description: string;
  category: string;
  spocId: number;
  industryVerticalId: []
}

export interface CreateSolutionTechnology {
  compliance: string;
  inProduction: string;
  productionDescription: string;
  integration: boolean;
  integrationDescription: string;
  solutionType: string;
  whiteLabled: string;
  sellingModel: string;
  sellingModelDescription: string;
  demoReady: boolean;
  demoReadyDescription: string;
  implementationTimeframe: string;
  effortInvolved: string;
  pricingDetails: string;
}

export interface informationTags {
  id: number;
  tagName: string;
  createdDate: string
}

export interface PageableResponse<T> {
  page: {
    size: number;
    number: number;
    totalElements: number;
    totalPages: number
  };
  content: T[];
}

export interface solutionAttachment {
  id: number,
  solutionName: string,
  archived: true,
  publish: true,
  type: string,
  createdAt: string,
  attachmentLabelType: string;
  createdBy: CreatedBy;
}

interface CreatedBy {
  firstName: string;
  lastName: string;
}

export interface SolutionListingPayload {
  query: string;
  showActive: boolean;
  solutionId: string;
  solutionName: string;
  informationTags: number[];
  industryVertical: number[];
  solutionTypes: string[];
  techStacks: number[];
  demoReady: boolean | undefined;
  vendorName: string;
  enableSearch?: boolean ;
  dateSearchCriteria: DateSearchCriteria;
}

export interface FeaturedConsultantPayload {
  consultantId?: string;
  title?: string;
  experienceYears?: { min: number, max: number }[];
  skills?: number[];
  query: string;
}

export interface Experience {
  experienceYears?: { min: number, max: number }[];
}

export interface AttachmentContent {
  id: number;
  createdAt: string;
  documentType: DocumentType;
  attachmentLabelType: string;
  type: string;
  filename?: string; // Optional property
  url: string; // This is mandatory in all cases
  active: boolean
}

export interface DocumentType {
  id: number;
  seq: number;
  name: string;
  active: boolean;
}
