<div >
  <div class="d-flex align-items-center justify-content-between theme-bg-color">
    <h5 class="m-2 pt-2 pb-2 ps-2 text-white">Crop Image</h5>
    <div (click)="close()" class="cursor-p px-3">
      <svg width="15" height="15" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.2872 1L1 17.2871" stroke="white" stroke-width="2" stroke-linecap="round"/>
        <path d="M1 1L17.2872 17.2871" stroke="white" stroke-width="2" stroke-linecap="round"/>
      </svg>
    </div>
  </div>
  <div class="w-100 mt-4">
    <div class="image-cropper-container">
      <image-cropper
        *ngIf="showCropper"
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="false"
        [containWithinAspectRatio]="containWithinAspectRatio"
        [aspectRatio]="4 / 4"
        [resizeToWidth]="256"
        [cropperMinWidth]="200"
        [cropperMinHeight]="200"
        [onlyScaleDown]="false"
        [roundCropper]="false"
        [canvasRotation]="canvasRotation"
        [transform]="transform"
        [alignImage]="'center'"
        [backgroundColor]="'transparent'"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady($event)"
        (loadImageFailed)="loadImageFailed()"
        class="cropper"
      >
      </image-cropper>
      <div *ngIf="loading" class="loading-indicator" aria-live="polite" role="status">
        Loading...
      </div>
      <div *ngIf="error" class="error-message" aria-live="assertive" role="alert">
        {{ error }}
      </div>
    </div>
  </div>

  <div class="mt-2" >
    <div class="d-flex align-items-center justify-content-center mt-3">
      <div (click)="zoomOut()" class="zoom-button m-2 theme-bg-color cursor-p" aria-label="Zoom Out" role="button">
        <i class="fas fa-search-minus p-2 text-white"></i>
      </div>
      <div (click)="zoomIn()" class="zoom-button m-2 theme-bg-color cursor-p" aria-label="Zoom In" role="button">
        <i class="fas fa-search-plus p-2 text-white"></i>
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center justify-content-end mt-3 px-3">
    <button mat-button (click)="close()" class="mr-2 theme-btn-sec-color" aria-label="Cancel">Cancel</button>
    <button mat-button (click)="save()" class="theme-btn-color text-white" aria-label="Save">Save</button>
  </div>
</div>
