import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyProfileService {
  private imageUrl = new BehaviorSubject<string | null>(null);

  setImageUrl(url: string | null): void {
    this.imageUrl.next(url);
  }

  getImageUrl(): Observable<string | null> {
    return this.imageUrl.asObservable();
  }
}
