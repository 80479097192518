import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ApiService} from "../../../../services/api.service";
import {MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute} from "@angular/router";
import {ImageStorageService} from "../../../../services/image-storage.service";
import {PopUpComponent} from "../../../../util/pop-up/pop-up.component";
import {ConsultantDT} from "../../../../shared/interfaces";

@Component({
  selector: 'app-solution-settings',
  templateUrl: './solution-settings.component.html',
  styleUrls: ['./solution-settings.component.css']
})
export class SolutionSettingsComponent implements OnInit, OnChanges {
  @Input() consultantData: ConsultantDT;
  @ViewChild('fileInput') fileInput: ElementRef<HTMLDivElement>;
  file: File | null;
  logoLoader = false;
  type = '';
  consultantId: number;
  showLoader = false;
  formData: FormData;
  imageSrc!: string;
  @Input() data!: any;
  @Input() createSolutionId!: number;
  solutionId: number;
  selectedImage: string
  errorUploadingImage: boolean = false;

  constructor(
    private service: ApiService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private imageStorageService: ImageStorageService
  ) {

  }

  ngOnInit(): void {
    this.solutionId = this.route?.snapshot?.params['solutionId'];

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && changes['data'].currentValue) {
      this.selectedImage = this.data?.logoUrl
    }
  }

  toggleActiveSolution(event: any) {
    if (event.checked) {
      this.service.markSolutionAsActive(this.solutionId ?? this.createSolutionId).subscribe(
        {
          next: (res) => {
            this.toastr.success('Solution marked as active', 'Success');
          },
          error: (e: any) => {
            this.toastr.error('Failed to mark solution as active', 'Error');
          }
        }
      );
    } else {
      this.service.markSolutionAsInActive(this.solutionId ?? this.createSolutionId).subscribe(
        {
          next: (res) => {
            this.toastr.success('Solution marked as inactive', 'Success');
          },
          error: (e: any) => {
            this.toastr.error('Failed to mark solution as inactive', 'Error');
          }
        }
      );
    }
  }

  toggleSearchEnable(event: any) {
    if (event.checked) {
      this.service.enableSearchForSolution(this.solutionId ?? this.createSolutionId).subscribe(
        {
          next: (res) => {
            this.toastr.success('Search enabled for solution', 'Success');
          },
          error: (e: any) => {
            this.toastr.error('Failed to enable search for solution', 'Error');
          }
        }
      );
    } else {
      this.service.disableSearchForSolution(this.solutionId ?? this.createSolutionId).subscribe(
        {
          next: (res) => {
            this.toastr.success('Search enable for solution', 'Success');
          },
          error: (e: any) => {
            this.toastr.error('Failed to enable search for solution', 'Error');
          }
        }
      );
    }
  }

  handleFileChange(event: File | null) {
    if (!event) return;
    this.logoLoader = true;
    const formData: FormData = new FormData();
    formData.append('name', event?.name);
    formData.append('file', event);
    this.service.uploadLogoForSolution(this.solutionId ?? this.createSolutionId, formData).subscribe({
        next: (res) => {
          this.selectedImage = res?.logoUrl;
          this.errorUploadingImage = false;
          this.imageStorageService.setImageSrc(res?.logoUrl);
        },
        error: (err: any) => {
          this.errorUploadingImage = true;
          this.toastr.error('Error', err.error.message);
        },
        complete: () => {
          this.logoLoader = false;
        }
      }
    );
  }

  handleProfileDelete(event: boolean): void {
    if (event) {
      const dialogRef2 = this.dialog.open(PopUpComponent, {
        data: {
          title: 'Alert',
          message: 'Are you sure you want to remove picture?',
          type: 'confirmation'
        }
      });
      dialogRef2.afterClosed().subscribe((res: boolean) => {
        if (res) {
          this.service.deleteLogoForSolution(this.createSolutionId ?? this.solutionId).subscribe({
            next: (res) => {
              this.imageStorageService.setImageSrc('');
              this.selectedImage = '';
            },
            error: (e: any) => {
              this.toastr.error('Error', e.error.message);
            }
          })
        }
      })
    }
  }

}
