import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {NgDialogAnimationService} from "ng-dialog-animation";
import {FormControl} from "@angular/forms";
import {DataService} from 'src/app/services/data.service';
import {DefaultFilters, VendorCorp} from 'src/app/shared/interfaces';
import {ModifyVendorComponent} from "../admin/admin-vendors/modify-vendor/modify-vendor.component";
import {Router} from "@angular/router";
import {Title} from '@angular/platform-browser';
import {MatPaginator} from "@angular/material/paginator";
import {debounceTime, distinctUntilChanged, filter, tap} from "rxjs/operators";
import {fromEvent} from "rxjs";
import {IsDataUpdatedService} from "../../services/isDataUpdated.service";
import {debounce} from "lodash";
import {ClientJobSearchPayload} from "../../client/client-jobs/client-jobs.component";
import {ResetFiltersService} from "../../services/resetFilters.service";

export interface VendorsSearchPayload {
  query?: string;
  vendorName?: string;
  location?: string;
  skills?: number[];
}
@Component({
  selector: 'app-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.css']
})
export class VendorsComponent implements OnInit, OnDestroy ,AfterViewInit{
  vendors: VendorCorp[] = [];
  currentPage: number = 0;
  address: any;
  totalPages = 0;
  length: any;
  search = '';
  loading = false;
  message: string | null;
  myControl = new FormControl();
  defaultFilters: DefaultFilters;
  selectedLocationsCsv: any[] = [];
  skill: any[] = [];
  role: string | any;
  totalVendors=0;
  pageSize = 12;
  searchFieldName = ['vendorName', 'location', 'skills'];
  filters: VendorsSearchPayload = {
    query: "",
    vendorName: "",
    location: "",
    skills: [],
  };
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  @ViewChild('input',{static:true}) input:ElementRef | any;
  constructor(private resetFilters: ResetFiltersService,
              private service: ApiService,
              private dataService: DataService,
              private router: Router,
              private titleService: Title,
              public dialog: NgDialogAnimationService,
              private isVendorsDataUpdated: IsDataUpdatedService,
              ) {
  }

  ngOnDestroy(): void {
    this.titleService.setTitle('Vendor Portal');
  }

  ngOnInit(): void {
    this.titleService.setTitle('Vendor Portal - Search');
    this.role = localStorage.getItem('role');
    this.loadVendors(this.currentPage,this.pageSize,this.search);
    this.loadVendors = debounce<any>(this.loadVendors, 600);
    this.refreshOnBackButtonClick();
    // this.filterVendorsBySkill(0);
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(
        tap(() => {
          this.loadVendors(this.paginator.pageIndex, this.paginator.pageSize,this.filters);
        })
    ).subscribe();
    if (this.input) {
      fromEvent(this.input?.nativeElement, 'keyup')
          .pipe(
              filter(Boolean),
              debounceTime(500),
              distinctUntilChanged(),
              tap(() => this.loadVendors(this.paginator.pageIndex, this.paginator.pageSize,this.filters)),
          )
          .subscribe();
    }
  }

  refreshOnBackButtonClick(): void {
    this.router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        window.location.reload();
      }
    });
  }

  openDialog(action: string, element: any): void {
    const dialogRef = this.dialog.open(ModifyVendorComponent, this.dataService.dialogConfig({
      vendorId: element.id,
      vendorData: element,
      type: 'READONLY',
    }));
    dialogRef.afterClosed().subscribe(() => {
      if (this.isVendorsDataUpdated.getBoolean()) {
          this.loadVendors(this.currentPage, this.pageSize,this.filters);
        this.isVendorsDataUpdated.setUpdated(false);
      }
    });
  }



  loadVendors(page: number,size:number,payload: any): void {
    this.dataService.isLoading.next(true);
    this.loading = true;
    if (this.search) {
      this.vendors = [];
      /* this.currentPage = 0;*/
      this.currentPage = page;
    } else {
      this.currentPage = page;
    }
    this.service.getVendors(page, size, '',payload).subscribe(res => {
      this.dataService.isLoading.next(false);
      this.loading = false;
      this.vendors = res.content;
      this.totalVendors=res.page.totalElements
      this.paginator.pageIndex=res.page.number;
      this.paginator.pageSize=res.page.size;
      this.pageSize=res.page.size;
      this.length = res.page.totalElements
      if (res.content.length === 0) {
        this.message = 'No Match Found';
      } else {
        this.message = null;
      }
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  payload() {
    return {
      query: this.search,
      skills: this.skill,
      state: this.selectedLocationsCsv,
      showArchived: false
    };
  }

  onFiltersChanged(updatedFilters: any) {
    this.filters = {
      ...updatedFilters,
      query: this.search
    };
    this.filters = updatedFilters;
    this.currentPage = 0;
      this.loadVendors(this.currentPage, this.pageSize,this.filters);
  }

  updateSearch() {
    this.filters.query = this.search;
    this.loadVendors(0, this.pageSize, this.filters);
  }

  clearFilter() {
    this.search=''
    this.filters.query='';
    this.filters.vendorName = '';
    this.filters.location = '';
    this.filters.skills= [];
    this.resetFilters.emitChange(true);
      this.loadVendors(0, this.pageSize,this.filters);

  }
  isFilterApplied(): boolean {
    return !!(
      this.search ||
      this.filters?.query ||
      this.filters?.vendorName ||
      this.filters?.location ||
      (this.filters?.skills?.length ?? 0) > 0
    );
  }



}
