<div *ngIf="solutionId" class="content-wrapper mt-0 d-flex align-items-center">
  <div class="pe-3">
    <img *ngIf="imageUrl || imageSrc" style="border-radius: 50%; width:100px; height: 100px"
         [src]="imageSrc ? imageSrc : imageUrl" alt="logo"/>
    <img *ngIf="!imageUrl && !imageSrc" style="border-radius: 50%; width:100px; height: 100px"
         [src]="'../../../../../../assets/images/maleDefault.jpg'" alt="logo"/>
  </div>
  <div class="d-flex flex-column">
    <h6>{{ data?.name }}</h6>
    <span>Solution ID: {{ data?.solutionId }}</span>
    <span>Created by: {{ data?.createdBy?.firstName + ' ' + data?.createdBy?.lastName + ' ' + 'on' + ' ' + formatDate(data?.createdDate) }}</span>
    <span
      *ngIf="data?.modifiedBy">Last Modified by: {{ data?.modifiedBy?.firstName + ' ' + data?.modifiedBy?.lastName + ' ' + 'on' + ' ' + formatDate(data?.updatedDate) }}</span>
  </div>
</div>

<div class="content-wrapper">
  <div>
    <h6>Solution Information</h6>
  </div>
  <form [formGroup]="solutionForm" (ngSubmit)="onSubmit()">
    <div class="row" *ngFor="let row of groupedSolutionInfo">
      <div *ngFor="let field of row"
           [class]="['Solution Name', 'Information Tags', 'Solution Description'].includes(field.label) ? 'col-12' : 'col-6'">
        <!-- Handle text input fields -->
        <mat-form-field appearance="outline" class="w-100"
                        *ngIf="field.type === 'text' && field.label !== 'Solution Description'">
          <mat-label>{{ field.label }}</mat-label>
          <input appMaxCharLimitInput  #nameInput matInput [formControlName]="field.name" [placeholder]="field.placeholder || ''" appNoSpecialChars required>
          <mat-error>
            {{MaxCharLimits.INPUT}}
          </mat-error>
        </mat-form-field>

        <div *ngIf="field.type === 'text' && field.label === 'Solution Description'">
          <app-expandable-texteditor
            label="Solution Description *"
            placeholder="Solution Description *"
            [formControl]="getFormControl('description')"
            [focusTextArea]="focusTextArea"
            #descriptionInput
            required
          >
          </app-expandable-texteditor>
        </div>

        <!-- Handle select fields -->
        <mat-form-field appearance="outline" class="w-100" *ngIf="field.type === 'select'">
          <mat-label>{{ field.label }}</mat-label>
          <mat-select #categorySelect [formControlName]="field.name" required>
            <mat-option *ngFor="let option of field.options" [value]="option">{{ option }}</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Handle multi-select fields -->
        <mat-form-field appearance="outline" class="w-100 border-red"
                        *ngIf="field.type === 'matAutoComplete' && field.label==='Vendor SPOC'">
          <mat-label>Vendor SPOC</mat-label>
          <input #SPOCAutoCompleter #spocInput type="text" matInput placeholder="SPOC" [formControl]="spocControl"
                 [matAutocomplete]="autoSPOC" required>
          <mat-autocomplete #autoSPOC="matAutocomplete" (optionSelected)="chooseSpoc($event)"
                            [displayWith]="formatSPOC">
            <mat-option *ngFor="let option of filteredSpoc | async" [value]="option">
              {{ formatSPOC(option) }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="vendorControl.hasError('invalidSelection')">
            Please select a valid vendor from the list.
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-100 border-red"
                        *ngIf="field.type === 'matAutoComplete' && field.label==='Vendor Name'">
          <mat-label>Vendor Name</mat-label>
          <input #autoCompleteInput1
                 #vendorInput
                 [formControl]="vendorControl"
                 [(ngModel)]="vendorName"
                 [matAutocomplete]="auto1"
                 type="text" matInput placeholder="Search Vendor Name"
                 aria-describedby="title" required>
          <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete"
                            [displayWith]="displayVendorFn" (optionSelected)="onVendorChange($event)">
            <mat-option *ngFor="let vendor of filteredVendors | async" [value]="vendor">
              {{ vendor.vendorName }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="vendorControl.hasError('invalidSelection')">
            Please select a valid SPOC from the list.
          </mat-error>
        </mat-form-field>

        <div *ngIf="field.type === 'multiSelect' && field.label === 'Industry Vertical'">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Industry Vertical *</mat-label>
            <mat-chip-list #chipList aria-label="Industry Vertical selection m-0" [formControl]="verticalControl">
              <mat-chip
                class="matChip"
                *ngFor="let vertical of selectedVertical"
                (removed)="removeVertical(vertical)">
                {{ vertical.name }}
                <button class="removeButton" matChipRemove><span>&#x2716;</span></button>
              </mat-chip>
              <input
                #verticalInput
                #verticalAutoCompleter
                matInput
                [formControl]="verticalControl"
                [(ngModel)]="verticalName"
                [matAutocomplete]="autoVertical"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addVertical($event)"
                #autoTrigger='matAutocompleteTrigger'
                (click)="openAutocomplete(autoTrigger)"
                [required]="selectedVertical.length === 0"
              >
            </mat-chip-list>
            <mat-autocomplete #autoVertical="matAutocomplete" (optionSelected)="selectVertical($event)">
              <mat-option *ngFor="let vertical of filteredVertical | async" [value]="vertical"
                          [disabled]="disableVertical(vertical)">
                {{ vertical.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div>
            <div *ngIf="(filteredVertical | async)?.length === 0 && verticalControl.value"
                 class="dropdown-skills position-relative">
              <ul>
                <li class="li_disabled">
                  No match Found
                </li>
                <hr class="m-0 p-0">
                <li class="font-weight-bold">
                  <div class="d-flex justify-content-between align-items-center" (click)="addVerticalType()">
                    <span>Add Industry Vertical</span>
                    <button class="p-0 bg-transparent border-0 fs-20"> +</button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div *ngIf="field.type === 'multiSelect' && field.label === 'Information Tags'">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Information Tags *</mat-label>
            <mat-chip-list #chipList aria-label="Information Tags" [formControl]="tagControl">
              <mat-chip
                class="matChip"
                *ngFor="let tag of selectedTags"
                (removed)="removeTags(tag)">
                {{ tag.tagName }}
                <button class="removeButton" matChipRemove><span>&#x2716;</span></button>
              </mat-chip>
              <input
                #tagAutoCompleter
                [formControl]="tagControl"
                [(ngModel)]="tagName"
                [matAutocomplete]="autoInformation"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addTags($event)"
                #autoTrigger='matAutocompleteTrigger'
                (click)="openAutocomplete(autoTrigger)"
                [required]="selectedTags.length === 0"
              >
            </mat-chip-list>
            <mat-autocomplete #autoInformation="matAutocomplete" (optionSelected)="selectTags($event)">
              <mat-option *ngFor="let tag of filteredTags | async" [value]="tag"
                          [disabled]="disableTags(tag)">
                {{ tag?.tagName }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div>
            <div *ngIf="(filteredTags | async)?.length === 0 && tagControl.value"
                 class="dropdown-skills position-relative">
              <ul>
                <li class="li_disabled">
                  No match Found
                </li>
                <hr class="m-0 p-0">
                <li class="font-weight-bold">
                  <div class="d-flex justify-content-between align-items-center" (click)="createInformationTags()">
                    <span>Add Information Tag</span>
                    <button class="p-0 bg-transparent border-0 fs-20"> +</button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <ng-container *ngIf="field.label === '`Solution` Description'">
          <app-expandable-textarea
            [formControl]="getFormControl('description')"
            label="Description">
          </app-expandable-textarea>
        </ng-container>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-end">
      <button *ngIf="!showloader" mat-raised-button
              class="theme-bg-color text-white">{{ this.solutionId ? 'Update Details' : 'Save Details' }}
      </button>
      <div *ngIf="showloader" class="d-flex align-items-center justify-content-end">
        <button mat-raised-button class="theme-bg-color text-white">
          <div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only"></span>
          </div>
          Save Details
        </button>
      </div>
    </div>
  </form>
</div>
