<mat-form-field appearance="outline" class="w-100">
  <mat-label>{{ label }}</mat-label>
  <input #SPOCAutoCompleter type="text" matInput [placeholder]="placeHolder"
         [formControl]="spocControl" [matAutocomplete]="autoSPOC">
  <mat-autocomplete #autoSPOC="matAutocomplete" (optionSelected)="chooseSpoc($event)"
                    [displayWith]="formatSPOC">
    <mat-option *ngFor="let option of filteredSpoc | async" [value]="option">
      {{ formatSPOC(option) }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
