import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {DataService} from "../../../services/data.service";
import {MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {MatSnackBar} from "@angular/material/snack-bar";
import {environment} from '../../../../environments/environment';
import {FormType} from "./portal-setting/portal-setting.component";
import {Observable} from "rxjs/internal/Observable";
import {PageEvent} from "@angular/material/paginator";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  protected environment = environment;
  editDocumentForm: FormGroup;
  editDesignationForm: FormGroup;
  editSkillForm: FormGroup;
  editVerticalForm: FormGroup;
  editEducationForm: FormGroup;
  designationForm: FormGroup;
  role: string | null;
  type: string | null;
  showLoader = false;
  errorMessage: string | null;
  totalDocuments = 0;
  pageSize = 12;
  currentPage: number = 0;
  data: any = []
  logoLoader: boolean = false;
  resetFile: boolean = false;
  activeTabIndex: number = 0;
  errorUpdatingForm: boolean = false;

  tabs = [
    {label: 'Document Types', form: 'editDocumentForm', formType: FormType.DOCUMENT},
    {label: 'Designation', form: 'editDesignationForm', formType: FormType.DESIGNATION},
    {label: 'Skills', form: 'editSkillForm', formType: FormType.SKILL},
    {label: 'Verticals', form: 'editVerticalForm', formType: FormType.VERTICAL},
    {label: 'Education', form: 'editEducationForm', formType: FormType.EDUCATION}
  ];

  constructor(
    private service: ApiService,
    private dataService: DataService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private toast: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.dataService.isLoading.next(true);
    this.initializeForms();
    this.handleCurrentTab(0);
  }

  private originalValues: Record<FormType, any[]> = {
    [FormType.DOCUMENT]: [],
    [FormType.DESIGNATION]: [],
    [FormType.SKILL]: [],
    [FormType.VERTICAL]: [],
    [FormType.EDUCATION]: []
  };

  private initializeForms(): void {
    this.editDocumentForm = this.createFormGroup(FormType.DOCUMENT);
    this.editDesignationForm = this.createFormGroup(FormType.DESIGNATION);
    this.editSkillForm = this.createFormGroup(FormType.SKILL);
    this.editVerticalForm = this.createFormGroup(FormType.VERTICAL);
    this.editEducationForm = this.createFormGroup(FormType.EDUCATION);
    this.designationForm = this.fb.group({file: ''});
  }

  private storeOriginalValues(formType: FormType, form: FormGroup): void {
    const control = form.get(formType) as FormArray;
    this.originalValues[formType] = control.controls.map(control => control.value);
  }

  private createFormGroup(formArrayName: FormType): FormGroup {
    return this.fb.group({
      [formArrayName]: this.fb.array([]),
    });
  }

  private fetchData(form: FormGroup, formArrayName: FormType, serviceMethod: (page: number, size: number) => Promise<any>, page: number, size: number): void {
    const control = form.get(formArrayName) as FormArray;
    control.clear();
    this.dataService.isLoading.next(true);
    serviceMethod(page, size)
      .then(res => {
        this.dataService.isLoading.next(false);
        this.data = res.content;
        this.totalDocuments = res.page.totalElements;
        this.pageSize = res.page.size;
        this.currentPage = res.page.number;
        res.content.forEach((item: any) => {
          control.push(this.createFormGroupForItem(item));
          this.originalValues[formArrayName][item.id] = item;
        });
      })
      .catch(error => {
        this.dataService.isLoading.next(false);
        console.error(`Error fetching data:`, error);
      });
  }


  private createFormGroupForItem(item: any): FormGroup {
    return this.fb.group({
      id: [item.id],
      name: [item.name || item.title],
      title: [item.title || item.title],
      active: [item.active],
      isEditable: false,
    });
  }

  getAllDocFiles(page: number, size: number): void {
    this.fetchData(this.editDocumentForm, FormType.DOCUMENT, this.service.getAllFilesPaginated.bind(this.service), page, size);
  }

  getAllDesignation(page: number, size: number): void {
    this.fetchData(this.editDesignationForm, FormType.DESIGNATION, this.service.getDesignationPaginated.bind(this.service), page, size);
  }

  getAllSkill(page: number, size: number): void {
    this.fetchData(this.editSkillForm, FormType.SKILL, this.service.getAllSkillsPaginated.bind(this.service), page, size);
  }

  getAllVerticals(page: number, size: number): void {
    this.fetchData(this.editVerticalForm, FormType.VERTICAL, this.service.getVerticalsPaginated.bind(this.service), page, size);
  }

  getAllEducation(page: number, size: number): void {
    this.fetchData(this.editEducationForm, FormType.EDUCATION, this.service.getEducationPaginated.bind(this.service), page, size);
  }

  handleCurrentTab(event: number) {
    if (this.activeTabIndex !== event) {
      this.currentPage = 0;
      this.pageSize = 12;
    }
    this.activeTabIndex = event;
    const tabActions = [
      this.getAllDocFiles.bind(this, this.currentPage, this.pageSize),
      this.getAllDesignation.bind(this, this.currentPage, this.pageSize),
      this.getAllSkill.bind(this, this.currentPage, this.pageSize),
      this.getAllVerticals.bind(this, this.currentPage, this.pageSize),
      this.getAllEducation.bind(this, this.currentPage, this.pageSize)
    ];
    setTimeout(() => {
      tabActions[event]?.();
    }, 100)
  }

  private serviceMap: Record<FormType, (id: string, data: any) => Observable<any>> = {
    [FormType.SKILL]: (id, data) => this.service.updateSkillType(id, {id, title: data.title}),
    [FormType.DOCUMENT]: (id, data) => this.service.updateDocumentType(id, data),
    [FormType.DESIGNATION]: (id, data) => this.service.updateDesignationType(id, data),
    [FormType.VERTICAL]: (id, data) => this.service.updateVertical(id, data),
    [FormType.EDUCATION]: (id, data) => this.service.updateEducation(id, data),
  };

  handleEditForm(event: { group: FormGroup; type: FormType }) {
    const {group, type} = event;
    const docId = group.get('id')?.value;
    if (!docId) return;
    let currentValues;
    if (type === FormType.SKILL) {
      currentValues = {
        id: group.get('id')?.value,
        title: group.get('title')?.value,
      };
    } else {
      currentValues = {
        id: group.get('id')?.value,
        name: group.get('name')?.value,
      };
    }
    const originalValues = this.originalValues[type][docId];
    let originalStructuredValues;
    if (type === FormType.SKILL) {
      originalStructuredValues = {
        id: originalValues.id,
        title: originalValues.title,
      };
    } else {
      originalStructuredValues = {
        id: originalValues.id,
        name: originalValues.name,
      };
    }
    const hasChanged = JSON.stringify(currentValues) !== JSON.stringify(originalStructuredValues);
    if (!hasChanged) {
      this.toast.info('No changes detected', 'Info');
      this.errorUpdatingForm = true;
      setTimeout(() => this.errorUpdatingForm = false, 100);
      return;
    }
    const data = {...currentValues, id: docId};
    this.serviceMap[type](docId, data).subscribe({
      next: () => {
        this.errorUpdatingForm = true;
        setTimeout(() => this.errorUpdatingForm = false, 100);
        this.storeOriginalValues(type, group);
      },
      error: (error) => this.toast.error(error?.error?.reason || error?.error?.message, 'Error'),
    });
  }

  private statusServiceMap: Record<FormType, (id: string, isActive: boolean) => Observable<any>> = {
    [FormType.SKILL]: (id, isActive) => isActive ? this.service.activeSkillType(id) : this.service.inactiveSkillType(id),
    [FormType.DOCUMENT]: (id, isActive) => isActive ? this.service.activeDocumentType(id) : this.service.inactiveDocumentType(id),
    [FormType.DESIGNATION]: (id, isActive) => isActive ? this.service.activeDesignationType(id) : this.service.inactiveDesignationType(id),
    [FormType.VERTICAL]: (id) => this.service.updateVerticalStatus(id),
    [FormType.EDUCATION]: (id) => this.service.updateEducationStatus(id),
  };

  handleToggleStatus(event: { event: any; group: FormGroup; type: FormType }) {
    const {group, type} = event;
    const docId = group.get('id')?.value;
    if (!docId) return;
    const isActive = event.event.checked;
    this.statusServiceMap[type](docId, isActive).subscribe({
      next: () => group.get('active')?.setValue(isActive),
      error: (e: any) => this.toast.error(e.error?.reason || 'Error occurred', 'Request Failed'),
    });
  }

  private editDocumentServiceMap: Record<FormType, (formData: FormData) => Observable<any>> = {
    [FormType.SKILL]: (formData) => this.service.uploadOrgSkills(formData),
    [FormType.DOCUMENT]: (formData) => this.service.uploadSettingFile(formData),
    [FormType.DESIGNATION]: (formData) => this.service.uploadSettingFile(formData),
    [FormType.VERTICAL]: (formData) => this.service.uploadOrgVerticals(formData),
    [FormType.EDUCATION]: (formData) => this.service.uploadOrgEducation(formData),
  };

  handleEditDocument(group: { type: string, file: File | null }) {
    if (!group.file) return;
    const {type} = group;
    this.logoLoader = true;
    const formData: FormData = new FormData();
    formData.append('file', group.file);
    if (type === FormType.DOCUMENT || type === FormType.DESIGNATION) {
      formData.append('fileName', group.file.name);
      formData.append('type', type);
    }
    this.editDocumentServiceMap[type as FormType](formData).subscribe({
      next: (response: any) => {
        console.log('Upload success response:', response);
        this.resetFile = true;
        setTimeout(() => this.resetFile = false, 0);
        this.toast.success('File uploaded successfully', 'Success', {positionClass: 'toast-top-right'});
        // this.logoLoader = false;
        this.handleCurrentTab(this.activeTabIndex);
      },
      error: (error: any) => {
        console.error('Upload error:', error);
        this.logoLoader = false;
        this.toast.error('File upload failed', error?.error?.message, {positionClass: 'toast-top-right'});
      }
    });
  }

  handlePageChange(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.handleCurrentTab(this.activeTabIndex)
  }

  getFormGroup(formName: string): FormGroup {
    switch (formName) {
      case 'editDocumentForm':
        return this.editDocumentForm;
      case 'editDesignationForm':
        return this.editDesignationForm;
      case 'editSkillForm':
        return this.editSkillForm;
      case 'editVerticalForm':
        return this.editVerticalForm;
      case 'editEducationForm':
        return this.editEducationForm;
      default:
        throw new Error(`Unknown form: ${formName}`);
    }
  }
}


