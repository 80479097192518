import {Injectable} from '@angular/core';
import {SessionMap} from "../../shared/interfaces";
import {Common} from "../../shared/Common";
import {DataService} from "../../services/data.service";
import {ThemeLoaderService} from "../../services/theme-loader.service";
import {ApiService} from "../../services/api.service";
import {Router} from "@angular/router";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  showLoader = false;
  private logoUrlSource = new BehaviorSubject<string | undefined>(undefined);
  logoUrl$ = this.logoUrlSource.asObservable();
  private nameSource = new BehaviorSubject<string | undefined>(undefined);
  name$ = this.nameSource.asObservable();

  constructor(
    private service: ApiService,
    private dataService: DataService,
    private themeLoaderService: ThemeLoaderService,
    private router: Router
  ) {
  }

  userLogin(res: any): void {
    localStorage.setItem('access_token', res.access_token);
    localStorage.setItem('orgId', res.org_id);
    localStorage.setItem('name', res.name);
    localStorage.setItem('userId', res.user_id);
    localStorage.setItem('role', res.role);
    const rootVariable = document.documentElement;
    this.service.getLoginUser().subscribe({
      next: res => {
        const primaryColor = res?.theme?.colour ?? '#76ba991a';
        const secondaryColor = Common.rgbaToHex(Common.hexToRGBA(primaryColor, 0.1));
        const ColorObj = {primaryColor, secondaryColor};
        localStorage.setItem('selectedTheme', JSON.stringify(ColorObj));
        rootVariable.style.setProperty('--primaryColor', primaryColor);
        rootVariable.style.setProperty('--secondaryColor', secondaryColor);
        this.themeLoaderService.setTheme(primaryColor, secondaryColor);
      },
      error: err => {
        console.error('Error fetching user theme:', err);
      },
      complete: () => {
        this.dataService.isLoading.next(false);
      }
    });

    this.showLoader = false;
    this.router.navigate(['/dashboard']);
  }

  fetchLogo(): void {
    this.service.getLogo().subscribe(response => {
      this.logoUrlSource.next(response.logo.fileUrl);
      this.nameSource.next(response.name);
    }, error => {
      console.error('Error fetching logo:', error);
    });
  }

}
