import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ApiService} from "../../../../services/api.service";
import {MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {DataService} from "../../../../services/data.service";
import {MatPaginator} from "@angular/material/paginator";
import {debounceTime, distinctUntilChanged, filter, tap} from "rxjs/operators";
import {fromEvent} from "rxjs";
import {IsDataUpdatedService} from "../../../../services/isDataUpdated.service";
import {ImageCropperComponent} from "../../image-cropper/image-cropper.component";
import {PopUpComponent} from "../../../../util/pop-up/pop-up.component";

@Component({
  selector: 'app-customer-settings',
  templateUrl: './customer-settings.component.html',
  styleUrls: ['./customer-settings.component.css']
})
export class CustomerSettingsComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() customerData: any;
  @Input() refreshFlag: boolean = false;
  @Output() customerImageChange = new EventEmitter<string>();
  isLoading = false;
  customerStatus: boolean;
  customerLogoVisible: boolean;
  customerImage: any;
  logoLoader = false;
  customerLogo: any;
  pageSize = 5;
  audit: any[] = [];
  totalAuditPages: number;
  currentAuditPage: number;
  totalAudit = 0;
  file: File | null;
  @ViewChild('fileInput') fileInput: ElementRef<HTMLDivElement>;
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  @ViewChild('input', {static: true}) input: ElementRef | any;
  errorUploadingImage: boolean = false;

  constructor(
    private service: ApiService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private dataService: DataService,
    private isCustomerDataUpdated: IsDataUpdatedService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['refreshFlag']) {
      if (this.refreshFlag && !this.isLoading) {
        //if(changes['refreshFlag'].currentValue != changes['refreshFlag'].previousValue){
        this.getAuditHistory(this.customerData?.id, this.currentAuditPage, this.pageSize);
      }
    }
  }

  ngOnInit(): void {
    if (this.customerData?.id) {
      this.service.getCustomerById(this.customerData?.id).subscribe({
          next: res => {
            this.customerImage = res.logo;
            this.customerImageChange.emit(res.logo)
          },
          error: err => {
            console.error(err);
          },
          complete: () => {
            // this.previousFormValue = this.consultantForm.value;
            // this.initializingForm = false;
          }
        }
      )
    }
    this.customerStatus = this.customerData?.customerData?.active;
    this.customerLogo = this.customerData?.customerData?.logo ?? '';
    if (typeof this.customerData?.customerData?.logo !== 'undefined') {
      if (this.customerData?.customerData?.logo !== '') {
        this.customerLogo = this.customerData?.customerData?.logo;
        this.customerLogoVisible = true;
      } else {
        this.customerLogoVisible = false;
      }
    } else {
      this.customerLogoVisible = false;
    }
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(
      tap(() => {
        this.getAuditHistory(this.customerData?.id, this.paginator.pageIndex, this.paginator.pageSize);
      })
    ).subscribe();
    if (this.input) {
      fromEvent(this.input?.nativeElement, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => this.getAuditHistory(this.customerData?.id, this.paginator.pageIndex, this.paginator.pageSize)),
        )
        .subscribe();
    }
  }

  toggleCustomerStatus() {
    this.service.updateCustomerStatus(this.customerData.id).subscribe(res => {
      this.isCustomerDataUpdated.setUpdated(true);
      this.customerStatus = res.active;
      this.toastr.success('Status of customer ' + (this.customerStatus ? 'activated' : 'deactivated'), 'Success');
    }), (e: any) => {
      this.toastr.error(e.error.reason, 'Request Failed');
    };
  }

  getAuditHistory(id: string, page: number, size: number): void {
    this.service.getAuditHistory(id, 'Customer', page, size).then(resp => {
      this.audit = resp.content;
      this.totalAuditPages = resp['totalPages'];
      this.paginator.pageIndex = resp.number;
      this.paginator.pageSize = resp.size;
      this.pageSize = resp.size;
      this.totalAudit = resp.totalElements;
    })
  }

  getInitials(firstName: string, lastName: string): string {
    let firstNames = firstName?.trim()?.replace(/_/g, '')?.split(' '),
      initials = firstNames[0]?.substring(0, 1)?.toUpperCase();
    let lastNames = lastName?.trim()?.replace(/_/g, '')?.split(' '),
      initialsLast = lastNames[0]?.substring(0, 1)?.toUpperCase();

    if (firstNames.length > 1) {
      initials += firstNames[firstNames?.length - 1]?.substring(0, 1)?.toUpperCase();
    }
    if (lastNames.length > 1) {
      initialsLast += lastNames[lastNames?.length - 1]?.substring(0, 1)?.toUpperCase();
    }
    return (initials + initialsLast);
  }

  handleFileChange(event: File | null) {
    if (!event) return;
    this.logoLoader = true;
    const formData: FormData = new FormData();
    formData.append('name', event?.name);
    formData.append('file', event);
    this.service.uploadCustomerLogo(formData, this.customerData.id).subscribe({
        next: (res) => {
          this.isCustomerDataUpdated.setUpdated(true);
          this.customerImage = res?.body?.logo;
          this.customerImageChange.emit(this.customerImage);
          this.errorUploadingImage = false;
        },
        error: (err: any) => {
          this.errorUploadingImage = true;
          this.toastr.error('Error', err.error.message);
        },
        complete: () => {
          this.logoLoader = false;
        },
      }
    );
  }

  handleProfileDelete(event: boolean): void {
    if (event) {
      const dialogRef2 = this.dialog.open(PopUpComponent, {
        data: {
          title: 'Alert',
          message: 'Are you sure you want to remove picture?',
          type: 'confirmation'
        }
      });
      dialogRef2.afterClosed().subscribe((res: boolean) => {
        if (res) {
          this.service.deleteCustomerLogo(this.customerData.id).then(res => {
            this.isCustomerDataUpdated.setUpdated(true);
            this.customerImageChange.emit('')
            this.customerImage = '';
          }).catch((error: any) => {
            this.toastr.error('Error', error.error.message);
          });
        }
      })
    }
  }

}
