import {Component, Input, OnInit} from '@angular/core';
import {myTaskDTO, NumberFields} from "../../../../shared/interfaces";
import {renderMobileNumber} from "../../../../common/renderMobileNumber";

@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.css']
})
export class TaskDetailComponent implements OnInit {
  myTask:myTaskDTO;
  @Input() data: myTaskDTO;
  renderMobileNumber = renderMobileNumber;
  constructor() { }

  ngOnInit(): void {
    this.myTask =this.data;
    console.log('this.myTask',this.myTask);
  }

  displayValue(value: any): string {
    return value && value.trim() !== '' ? value : '--';
  }


}
