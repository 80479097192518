<div *ngIf="getSkills()?.displayedSkills; else noSkills">
  {{ getSkills()?.displayedSkills }}
  <span
    *ngIf="getSkills().remainingCount > 0"
    matTooltip="{{ getSkills()?.tooltip }}"
    matTooltipPosition="before"
    matTooltipClass="custom-tooltip"
    class="badge badge-pill badge-light">
    <i class="fas fa-plus fa-xs"></i>
    <span class="pt-1" style="font-size: 11px">
      {{ getSkills()?.remainingCount }} more
    </span>
  </span>
</div>
<ng-template #noSkills>--</ng-template>
