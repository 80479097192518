<div style="padding-top:4px;height: 50px; ">
  <ngx-intl-tel-input
    [cssClass]="modal ? 'modal-class' : 'page-class'"
    [preferredCountries]="preferredCountries"
    [enableAutoCountrySelect]="true"
    [enablePlaceholder]="true"
    [searchCountryFlag]="true"
    [searchCountryField]="[SearchCountryField.Iso2,SearchCountryField.Name]"
    [selectFirstCountry]="false"
    [selectedCountryISO]="handleCountryCode(selectedCountryISO)"
    [maxLength]="15"
    [phoneValidation]="true"
    [separateDialCode]="true"
    [numberFormat]="PhoneNumberFormat.National"
    [formControl]="formControl"
  ></ngx-intl-tel-input>
</div>
