<div class="content-wrapper">
  <h5 class="page-title">Web Data </h5>
  <mat-tab-group mat-align-tabs="start" [selectedIndex]="selected" (selectedIndexChange)="tabOnChange($event)">
    <mat-tab label="Web Candidate">
      <ng-container *ngIf="selected === 0">
        <app-web-candidate-listing [currentPage]="currentPage" [selectedTab]="selected"></app-web-candidate-listing>
      </ng-container>
    </mat-tab>
    <mat-tab label="Web Customer">
      <ng-container *ngIf="selected === 1">
        <app-web-customer-listing [currentPage]="currentPage" [selectedTab]="selected"></app-web-customer-listing>
      </ng-container>
    </mat-tab>
  </mat-tab-group>
</div>
