<div class="content-wrapper">
  <div class="row">
    <div class="w-50 border-right">
      <h6>Settings</h6>
      <div>
        <h6>Upload Solution Logo:</h6>
        <div class="upload_wrapper flex-fill">
          <app-image-upload [selectedImage]="selectedImage"
                            (fileChange)="handleFileChange($event)"
                            [logoLoader]="logoLoader" [errorUploadingImage]="errorUploadingImage"
                            (deleteConsultantProfileConfirmation)="handleProfileDelete($event)"
          >
          </app-image-upload>
        </div>
      </div>
    </div>

    <div class="w-50">
      <div>
        <h6>Activate / Deactivate Solution:</h6>
      </div>
      <div class=" ps-5 pt-3 d-flex align-items-center justify-content-start">
        <div class="ActiveButton pe-2">Deactive</div>
        <mat-slide-toggle
          class="example-margin"
          color="primary"
          [disabled]=false
          [checked]="this.data?.active"
          (change)="toggleActiveSolution($event)">
          <div class="ActiveButton">Active</div>
        </mat-slide-toggle>
      </div>
      <div>
        <div class=" ps-5 pt-3 d-flex align-items-center justify-content-start">
          <mat-slide-toggle
            class="example-margin"
            color="primary"
            [disabled]=false
            [checked]="this.data?.enableSearch"
            (change)="toggleSearchEnable($event)">
            <div class="ActiveButton">Enable Search</div>
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>

</div>
