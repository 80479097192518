<form [formGroup]="consultantForm" class="member-form" ngNativeValidate>
  <!--      (keydown.enter)="$event.preventDefault()">-->
  <div class="consultant_wrapper">
    <h2 class="mb-4">Consultant's Information</h2>
    <div class="form-row mb-2">
      <div class="col-md-4 ms-0">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>First Name</mat-label>
          <input appMaxCharLimitInput matInput placeholder="First Name" formControlName="firstName" appNoSpecialChars
                 required>
          <mat-error *ngIf="this.consultantForm.get('firstName')?.hasError('maxCharLimit')">
            {{MaxCharLimits.INPUT}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4  ms-0">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Middle Name</mat-label>
          <input appMaxCharLimitInput matInput placeholder="Middle Name" formControlName="middleName" appNoSpecialChars>
          <mat-error *ngIf="this.consultantForm.get('middleName')?.hasError('maxCharLimit')">
           {{MaxCharLimits.INPUT}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4 ms-0">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Last Name</mat-label>
          <input appMaxCharLimitInput matInput placeholder="Last Name" formControlName="lastName" appNoSpecialChars>
          <mat-error *ngIf="this.consultantForm.get('lastName')?.hasError('maxCharLimit')">
            {{MaxCharLimits.INPUT}}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="form-row mb-2">
      <div class="col-md-4 ms-0">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Date of Birth (MM/DD/YYYY)</mat-label>
          <input formControlName="dateOfBirth" placeholder="Choose date of birth" matInput [matDatepicker]="dateOfBirth"
                 style="padding-left: 0!important;" readonly>
          <mat-datepicker-toggle matSuffix [for]="dateOfBirth"></mat-datepicker-toggle>
          <mat-datepicker #dateOfBirth></mat-datepicker>
        </mat-form-field>

      </div>
      <div class="col-md-4  ms-0">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>PAN Number</mat-label>
          <input matInput placeholder="PAN Number" formControlName="panNumber" maxlength="10" minlength="10">
          <mat-error *ngIf="consultantForm.get('panNumber')?.hasError('pattern')">
            Invalid PAN Number
          </mat-error>
        </mat-form-field>

      </div>
      <div class="col-md-4 ms-0">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Aadhaar Number</mat-label>
          <input matInput placeholder="Aadhaar Number" formControlName="aadharNumber" maxlength="12" minlength="12">
          <mat-error *ngIf="consultantForm.get('aadharNumber')?.hasError('pattern')">
            Invalid Aadhaar Number
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-4">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Title</mat-label>
          <input appMaxCharLimitInput matInput placeholder="Enter Title" formControlName="title" required>
          <mat-error *ngIf="this.consultantForm.get('title')?.hasError('maxCharLimit')">
            {{MaxCharLimits.INPUT}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group col-md-4">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Experience Years</mat-label>
          <mat-select formControlName="experienceYear" [required]="true">
            <mat-option *ngFor="let year of experienceYears" [value]="year" class="dropItems"
                        (click)="onExperienceAdd(year)">
              <a class="dropdown-item">
                {{ year }}
              </a>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-group col-md-4">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Experience Months</mat-label>

          <mat-select formControlName="experienceMonth" [required]="true">
            <mat-option *ngFor="let month of experienceMonth" [value]="month" class="dropItems"
                        (click)="$event.stopImmediatePropagation()">
              <a class="dropdown-item">
                {{ month }}
              </a>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-4 ">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label for="gender">Gender</mat-label>
          <mat-select id="gender" name="gender" formControlName="gender" [required]="true">
            <mat-option value="Male">Male</mat-option>
            <mat-option value="Female">Female</mat-option>
            <mat-option value="Other">Other</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-group col-md-4">
        <mat-form-field appearance="outline" class="w-100 border-red ">
          <mat-label>Education</mat-label>
          <input #educationAutoCompleter
                 [formControl]="educationControl"
                 [matAutocomplete]="eduAuto"
                 [(ngModel)]="educationName"
                 type="text" matInput placeholder=""
                 aria-describedby="title" required>
          <mat-autocomplete autoActiveFirstOption #eduAuto="matAutocomplete" [displayWith]="displayDocFn">
            <mat-option *ngFor=" let des of filteredEducation| async"
                        (onSelectionChange)="chooseEducationType($event)" [value]="des">
              {{ des.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <div *ngIf="(filteredEducation|async)?.length == 0" class="dropdown-skills position-relative">
          <ul>
            <li class="li_disabled">
              No match Found
            </li>
            <hr class="m-0 p-0">
            <li class="font-weight-bold">
              <div class="d-flex justify-content-between align-items-center" (click)="addEducationType($event)">
                <span>Add Education type:</span>
                <button class="p-0 bg-transparent border-0 fs-20"> +</button>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="form-group col-md-4">
        <passing-year [isSubmitting]="isSubmitting" (passingYearDate)="handleDateChange($event)"
                      [passingDate]="passingYearDate"></passing-year>
      </div>
    </div>

    <div class="form-row">
      <span></span>
      <div class="col-md-4 ms-0 mb-3" style="margin-top:20px">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Email</mat-label>
          <input
            appMaxCharLimitUrl
            matInput
            placeholder="Email"
            formControlName="email"
            type="email"
            required
          />
          <mat-error *ngIf="this.consultantForm.get('email')?.hasError('maxCharLimit')">
            {{MaxCharLimits.INPUT}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4 ms-0">
        <span>Phone Number</span>
        <app-country-phone-input
          [modal]="true"
          [formControl]="getFormControl('number')"
          [selectedCountryISO]="consultantRes?.number?.countryCode">
        </app-country-phone-input>
        <div *ngIf="displayError" class="text-danger">
          {{ this.errorMessage }}
        </div>
      </div>
      <div class="col-md-4  ms-0">
        <span>Emergency Phone Number</span>
        <app-country-phone-input
          [modal]="true"
          [formControl]="getFormControl('emergencyContactNumber')"
          [selectedCountryISO]="consultantRes?.emergencyContactNumber?.countryCode">
        </app-country-phone-input>
        <div *ngIf="displayErrorEmergencyPHone" class="text-danger">
          {{ this.errorMessageEmergencyPhone }}
        </div>
      </div>
    </div>
    <div class="form-row mb-2">
      <div class="col-md-6 ms-0">
        <app-spoc-dropdown [isSubmitting]="isSubmitting" [label]="'Assigned SPOC'" formControlName="spocId"
                           [form]="consultantForm" [selectedSpoc]="consultantRes?.spoc || undefined">
        </app-spoc-dropdown>
      </div>
      <div class="col-md-6 ms-0">
        <div *ngIf="updateURL">
          <mat-form-field appearance="outline" class="w-100 border-red">
            <mat-label> LinkedIn URL</mat-label>
            <input appMaxCharLimitUrl matInput placeholder="LinkedIn URL" formControlName="linkedInURL">
            <mat-error *ngIf="this.consultantForm.get('linkedInURL')?.hasError('maxCharLimit')">
              {{MaxCharLimits.URL}}
            </mat-error>
          </mat-form-field>

        </div>
        <div class="d-flex justify-content-between" *ngIf="!updateURL">
          <div class="ms-2 mt-3">
            <mat-label> LinkedIn URL :</mat-label>
            <span (click)="goToLink(this.linkedInURL)"><a
              style="color: blue; font-style: italic; text-decoration: underline;cursor: pointer">{{ this.linkedInURL }}</a></span>
          </div>
          <div class="me-3 p-0 mt-3">
            <button class="btn btn-create btn-sm theme-btn-color" (click)="updateUrl()">
              Change LinkedIn URL
            </button>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div formGroupName="address">
      <h2 class="mb-4">Address Information</h2>

      <div class="form-row mb-2">
        <div class="col-md-8  ms-0">
          <mat-form-field appearance="outline" class="w-100 border-red">
            <mat-label>Street Address</mat-label>
            <input appMaxCharLimitUrl matInput placeholder="Street Address" formControlName="streetAddress">
            <mat-error>
              {{MaxCharLimits.URL}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4 ms-0">
          <mat-form-field appearance="outline" class="w-100 border-red">
            <mat-label>PinCode</mat-label>
            <input matInput placeholder="PinCode" formControlName="pincode" maxlength="6">
          </mat-form-field>
        </div>
      </div>

      <div class="form-row">
        <div formGroupName="country" class="form-group col-md-4">
          <mat-form-field appearance="outline" class="w-100 border-red">
            <mat-label>Country</mat-label>
            <mat-select [formControl]="countryControl" [value]="selectedCountry" [compareWith]="compareFunction"
                        (valueChange)="selectCountry($event)" placeholder="Country">
              <mat-option>
                <ngx-mat-select-search [formControl]="countryServerCtrl" [searching]="searchCountry"
                                       [placeholderLabel]="'Search Country Name...'"
                                       [noEntriesFoundLabel]="'No Result Found'">
                  <mat-icon ngxMatSelectSearchClear>
                    <div class="d-flex align-items-center justify-content-center mt-sm-1">
                      <a>
                        <svg width="14" height="14" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.2872 1L1 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                          <path d="M1 1L17.2872 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                      </a>
                    </div>
                  </mat-icon>
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let country of countries" [value]="country">
                {{ country.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div formGroupName="state" class="form-group col-md-4">
          <mat-form-field appearance="outline" class="w-100 border-red">
            <mat-label>State</mat-label>
            <mat-select [formControl]="stateControl" [value]="selectedState" [compareWith]="compareFunction"
                        (valueChange)="selectState($event)" placeholder="State"
                        [disabled]="!selectedCountry">
              <mat-option>
                <ngx-mat-select-search [formControl]="stateServerCtrl" [searching]="searchState"
                                       [placeholderLabel]="'Search State Name...'"
                                       [noEntriesFoundLabel]="'No Result Found'">
                  <mat-icon ngxMatSelectSearchClear>
                    <div class="d-flex align-items-center justify-content-center mt-sm-1">
                      <a>
                        <svg width="14" height="14" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.2872 1L1 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                          <path d="M1 1L17.2872 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                      </a>
                    </div>
                  </mat-icon>
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let state of states" [value]="state">
                {{ state.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div formGroupName="city" class="form-group col-md-4">
          <mat-form-field appearance="outline" class="w-100 border-red">
            <mat-label>City</mat-label>
            <mat-select [formControl]="cityControl" [value]="selectedCity" (valueChange)="selectCity($event)"
                        [compareWith]="compareFunction"
                        placeholder="City" [disabled]="!selectedState">
              <mat-option>
                <ngx-mat-select-search [formControl]="cityServerCtrl" [searching]="searchCity"
                                       [placeholderLabel]="'Search City Name...'"
                                       [noEntriesFoundLabel]="'No Result Found'">
                  <mat-icon ngxMatSelectSearchClear>
                    <div class="d-flex align-items-center justify-content-center mt-sm-1">
                      <a>
                        <svg width="14" height="14" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.2872 1L1 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                          <path d="M1 1L17.2872 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                      </a>
                    </div>
                  </mat-icon>
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let city of cities" [value]="city">
                {{ city.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

    </div>
    <hr>
    <div>
      <h2 class="mb-4">Vendor Details</h2>
      <div class="form-row mb-2">
        <div class="form-group col-md-8 ps-0">
          <mat-form-field appearance="outline" class="w-100 border-red mb-3">
            <mat-label>Select Vendor</mat-label>
            <input #autoCompleteInput1
                   [formControl]="vendorControl"
                   [(ngModel)]="vendorName"
                   [matAutocomplete]="auto1"
                   type="text" matInput placeholder="Search Vendor Name"
                   aria-describedby="title" required>
            <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete"
                              [displayWith]="displayVendorFn" (optionSelected)="onVendorChange($event)">
              <mat-option *ngFor=" let vendor of filteredVendors| async"
                          (onSelectionChange)="chooseVendor($event)" [value]="vendor">
                {{ vendor.vendorName }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div>
           <span class="alert-danger bg-transparent">
            {{ errorMessage }}
           </span>
          </div>
        </div>
      </div>

      <div formArrayName="vendorContactIds" *ngIf="isVendorSelected">
        <div *ngFor="let contactGroup of vendorContactIds().controls; let i=index" [formGroupName]="i"
             class="form-row mb-2">
          <div class="form-group col-md-4">
            <mat-form-field appearance="outline" class="w-100 border-red">
              <mat-label>POC Name</mat-label>
              <input #pocAutoCompleter
                     [formControlName]="'pocName'"
                     [matAutocomplete]="auto2"
                     type="text" matInput placeholder="Select poc Name"
                     aria-describedby="title"
                     (change)="errorMessage = undefined"
              />
              <mat-autocomplete [autoActiveFirstOption]="true" #auto2="matAutocomplete">
                <mat-option *ngFor=" let contact of vendorContacts; let k = index"
                            (onSelectionChange)="chooseVendorContact(contact, i)"
                            [disabled]="selectedVendorContactIds.includes(contact.id)"
                >{{ contact.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-group col-md-4">
            <app-country-phone-input
              [modal]="true"
              [formControl]="getFormControlForPhone('phone',i)"
            >
            </app-country-phone-input>
          </div>
          <div class="form-group col-md-3">
            <mat-form-field appearance="outline" class="w-100 border-red">
              <mat-label>Email</mat-label>
              <input matInput placeholder="Enter Email" formControlName="email" readonly>
            </mat-form-field>
          </div>
          <div *ngIf="vendorContactIds().controls.length > 1"
               class="form-group col-md-1 d-flex align-items-center justify-content-center">
            <i (click)="removeItems(i, contactGroup)" class="text-danger fa fa-times cursor-p" aria-hidden="true"></i>
          </div>
        </div>

        <div *ngIf="vendorContactIds().controls.length < 2" class="row mt-2">
          <div class="col-2">
            <h2 class="text-secondary">Optional</h2>
          </div>
          <div class="col-10">
            <div class="d-flex justify-content-end">
              <div class="page-title-right me-3">
                <button type="button" (click)="addItems()" class="cursor-p border rounded-2 btn "><i
                  class="fas fa-plus-circle fa-sm me-2"></i>
                  Add More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr>

    <div *ngIf="consultantError" class="containeroferror">
      <div style="justify-content: center">
        {{ this.consultantError }}
      </div>
    </div>

    <div class="d-flex justify-content-end align-items-baseline mt-4">
      <div class="form-group btns save-form">
        <div class="d-flex justify-content-center">
          <div class="d-flex justify-content-end align-items-center mt-5">
            <button class="action-btn action-btn-cancel me-3" type="button" (click)="close()">Cancel</button>
            <div class="form-group btns save-form m-0">
              <button *ngIf="!showLoader" (click)="updateCandidate()" class="action-btn theme-btn-color m-0"
                      type="submit">Save Details
              </button>
            </div>
            <div class="form-group btns save-form m-0">
              <div class="save-skill" *ngIf="showLoader">
                <button class="action-btn theme-btn-color mx-3 theme-btn-color" disabled>
                  <div class="spinner-border spinner-border-sm me-2" role="status">
                    <span class="sr-only"></span>
                  </div>
                  Save Details
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
