<div [ngClass]="modal ? 'modal-wrapper' : 'content-wrapper'">
  <div class="content-wrapper-main-inner-content">
    <div class="d-flex align-items-center justify-content-between">
      <h5 style="font-size: 20px;font-weight: 400">Skills</h5>
      <div class="d-flex">
        <div class="d-flex align-items-center justify-content-center ms-4">
          <span class="me-2">
            <div style="background:#22C55E;width:10px;height:10px; border-radius: 50%"></div>
          </span>
          <span>Master</span>
        </div>
        <div class="d-flex align-items-center justify-content-center ms-4">
          <span class="me-2">
            <div style="background:#EAB308;width:10px;height:10px; border-radius: 50%"></div>
          </span>
          <span>Intermediate</span>
        </div>
        <div class="d-flex align-items-center justify-content-center ms-4">
          <span class="me-2">
            <div style="background:#EF4444;width:10px;height:10px; border-radius: 50%"></div>
          </span>
          <span>Beginner</span>
        </div>
      </div>
    </div>
    <div class="position-relative">
      <div class="d-flex align-items-center">
        <div class="inputContainer d-flex flex-wrap align-items-center gap-2 p-2 w-100 me-2"
             [ngClass]="{'input-error': hasError}">
          <label
            style="color:lightslategray"
            for="skillInput"
            class="formLabel"
            [class.floating]="skillName !== '' || showDropdown || selectedSkill.length > 0">
            Skills
          </label>
          <span
            *ngFor="let skill of selectedSkill"
            style="border: 1px solid gainsboro; z-index: 999"
            class="cursor-p theme-border-color rounded-pill px-3 py-2 cursor-pointer">
            <span
              class="me-2 rounded-circle d-inline-block"
              [ngStyle]="{'background-color': getLevelColor(skill?.skillType ?? 'MASTER'),'width': '12px','height': '12px'}">
            </span>
            {{ skill.skill?.title || skill?.title }}
            <span class="text-danger cursor-p" (click)="removeSkill($event, skill)">
              <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z"
                    fill="#0F0F0F"></path>
                </g>
              </svg>
            </span>
          </span>

          <div style="flex: 1; width: 100%;">
            <input
              #skillInput
              style="width: 100%; height: 40px; border: none; outline: none"
              id="skillInput"
              type="text"
              class="skill-input"
              [formControl]="skillControl"
              [(ngModel)]="skillName"
              (keyup)="onKeyUp()"
              (focus)="handleInputFocus()"
              [required]="selectedSkill.length === 0"
              (ngModelChange)="handleModelChange()"
              [readOnly]="disabled"
            />
          </div>
        </div>
        <div *ngIf="!removeButton">
          <app-button [width]="'150px'" [height]="'40px'" type="primary"
                      (buttonClick)="addSkill()"
                      [isLoading]="loading"
                      [isDisabled]="disableSaveButton()">
            Save
          </app-button>
        </div>
      </div>
      <div [style.width]=" removeButton ? '100% !important' : 'calc(100% - 156px) !important'"
           *ngIf="showDropdown" class="dropdown-menu show shadow-sm w-100 mt-1" #dropdown>
        <div class="px-3 pt-2">
          <span class="text-muted fw-bold">Relevant skills</span>
        </div>
        <hr class="my-2"/>

        <div class="px-3 pb-2 d-flex flex-wrap gap-2" style="max-height:200px;overflow: auto">
          <span
            *ngFor="let skill of (filteredSkills | async)"
            style="border: 1px solid gainsboro"
            class="cursor-p theme-border-color rounded-pill px-2 py-2 cursor-pointer"
            (click)="selectSkills(skill); $event.stopPropagation()"
          >
            {{ skill?.title }}
          </span>
          <span
            *ngIf="skillName.trim() !== '' && !isSkillAlreadySelected(skillName) && !isSkillInFilteredList(skillName)"
            style="border: 1px solid gainsboro"
            class="cursor-p theme-border-color rounded-pill px-2 py-2 cursor-pointer"
            (click)="addSkillType()"
          >
            {{ skillName }}
          </span>
        </div>
        <div
          *ngIf="(filteredSkills | async)?.length === 0 && isSkillAlreadySelected(skillName) && isSkillInFilteredList(skillName)"
          class="d-flex align-items-center justify-content-center">
          <div>Start typing to get suggestions</div>
        </div>
        <hr class="my-2"/>
        <div>
          <div class="d-flex justify-content-center">
            <div class="flex items-center" *ngFor="let level of skillLevels">
              <input
                style="display:none"
                type="radio"
                id="{{ level.id }}"
                name="skill-level"
                [(ngModel)]="selectedLevel"
                [value]="level.id"
              />
              <label
                htmlFor="{{ level.id }}"
                class="cursor-p px-4 py-2 border m-1 rounded-lg"
                [ngClass]="{
                  'theme-bg-color text-white': selectedLevel === level.id,
                  '': selectedLevel !== level.id
                }"
              >
                {{ level.label }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
