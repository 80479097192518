import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ChangeDetectorRef
} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
  selector: '[appMaxCharLimitUrl]'
})
export class MaxCharLimitUrlDirective implements OnChanges, OnDestroy {
  @Input() appMaxCharLimitInputUrl: string = '500';
  private errorVisible: boolean = false;
  private errorTimeout: any;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private control: NgControl,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['appMaxCharLimitInputUrl']) {
      this.applyLimit();
    }
  }

  @HostListener('input', ['$event']) onInput() {
    this.applyLimit();
  }

  private applyLimit() {
    const input = this.el.nativeElement as HTMLInputElement | HTMLTextAreaElement;
    const maxLimit = Number(this.appMaxCharLimitInputUrl) || 500;

    if (input.value.length > maxLimit) {
      const trimmedValue = input.value.substring(0, maxLimit);
      this.renderer.setProperty(input, 'value', trimmedValue);

      if (this.control?.control) {
        this.control.control.setValue(trimmedValue, {emitEvent: true});
        const existingErrors = this.control.control.errors || {};

        if (!existingErrors['maxCharLimit']) {
          existingErrors['maxCharLimit'] = true;
          this.control.control.setErrors(existingErrors);
          this.control.control.markAsTouched();
          this.control.control.markAsDirty();
          this.cdr.detectChanges();

          if (!this.errorVisible) {
            this.showError();
          }
        }
      }
    } else {
      if (this.control?.control) {
        const errors = {...this.control.control.errors};
        if (errors?.maxCharLimit) {
          delete errors.maxCharLimit;
          this.control.control.setErrors(Object.keys(errors).length ? errors : null);
          this.cdr.detectChanges();
        }
      }
    }
  }

  private showError() {
    this.errorVisible = true;
    this.errorTimeout = setTimeout(() => {
      if (this.control?.control) {
        const errors = {...this.control.control.errors};
        delete errors['maxCharLimit'];
        this.control.control.setErrors(Object.keys(errors).length ? errors : null);
        this.cdr.detectChanges();
      }
      this.errorVisible = false;
    }, 1000);
  }

  ngOnDestroy() {
    if (this.errorTimeout) {
      clearTimeout(this.errorTimeout);
    }
  }
}
