<div class="content-wrapper">
  <div class="ps-3 mt-0 d-flex align-items-center">
<!--    <div class="pe-3">-->
<!--      <img style="border-radius: 50%; width:100px; height: 100px"-->
<!--           [src]="'../../../../../../assets/images/maleDefault.jpg'" alt="logo"/>-->
<!--    </div>-->
    <div class="d-flex flex-column">
      <div>
        <span style="font-weight: 500;">Name</span>: <span
        class="theme-text-color">{{ customerInfoData?.firstName ?? '' }} {{ customerInfoData?.lastName ?? '' }}</span>
      </div>
      <div *ngIf="this.currentRoute === 'candidate'">
        <span style="font-weight: 500;">Title</span>: <span
        class="theme-text-color">{{ customerInfoData?.title ?? '' }}</span>
      </div>
      <div *ngIf="this.currentRoute === 'candidate'">
        <span style="font-weight: 500;">Experience</span>:
        <span class="theme-text-color">{{ customerInfoData?.experienceYear ?? '' }}
          Years {{ customerInfoData?.experienceMonth ?? '' }}
          Months</span>
      </div>
    </div>
  </div>
  <div *ngIf="this.currentRoute === 'candidate'" class="ps-3 pt-1 " style="font-size: 14px">
    Submitted On:
    <span style="font-weight: 500;">{{ customerInfoData?.appliedDate | date:'hh:mm a dd MMM yyyy'  }}</span>
    <span *ngIf="customerInfoData?.updatedDate">
      <span class="ps-4"> Updated By:
        <span style="font-weight: 500;">{{customerInfoData?.updatedBy?.firstName}} {{customerInfoData?.updatedBy?.lastName}} on {{customerInfoData?.updatedDate | date:'hh:mm a dd MMM yyyy' }}
        </span>
      </span>
    </span>
  </div>
  <div *ngIf="this.currentRoute === 'customer'" class="ps-3 pt-1" style="font-size: 14px">
    Submitted On:
    <span style="font-weight: 500;">{{ customerInfoData?.createdOn | date:'hh:mm a dd MMM yyyy'  }}</span>
    <span *ngIf="customerInfoData?.updatedOn">
      <span class="ps-4"> Updated By:
        <span style="font-weight: 500;">{{customerInfoData?.updatedBy?.firstName ?? "--"}} {{customerInfoData?.updatedBy?.lastName ?? "--"}} on {{customerInfoData?.updatedOn | date:'hh:mm a dd MMM yyyy' }}
        </span>
      </span>
    </span>
  </div>
</div>
