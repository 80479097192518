import { Directive, Input, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { QuillEditorComponent } from 'ngx-quill';
import Quill from 'quill';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appQuillMaxCharLimit]'
})
export class QuillMaxCharLimitDirective implements AfterViewInit, OnDestroy {
  @Input() maxCharLimit: number = 100000;
  private quillInstance: Quill | null = null;
  private errorVisible: boolean = false;
  private errorTimeout: any;

  constructor(
    private quillEditor: QuillEditorComponent,
    private control: NgControl,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.quillEditor.quillEditor) {
        this.quillInstance = this.quillEditor.quillEditor;
        this.addTextChangeListener();
      }
    });
  }

  private textChangeListener = () => {
    if (!this.quillInstance) return;
    const text = this.quillInstance.getText() || '';

    if (text.length > this.maxCharLimit) {
      this.quillInstance.deleteText(this.maxCharLimit, this.quillInstance.getLength());
      this.setError(true);
    } else {
      this.setError(false);
    }
  };

  private addTextChangeListener() {
    if (this.quillInstance) {
      this.quillInstance.on('text-change', this.textChangeListener);
    }
  }

  private setError(hasError: boolean) {
    if (!this.control?.control) return;
    const existingErrors = this.control.control.errors || {};

    if (hasError) {
      if (!existingErrors['maxCharLimit']) {
        existingErrors['maxCharLimit'] = true;
        this.control.control.setErrors(existingErrors);
        this.control.control.markAsTouched();
        this.control.control.markAsDirty();
        this.cdr.detectChanges();

        if (!this.errorVisible) {
          this.showError();
        }
      }
    } else {
      if (existingErrors['maxCharLimit']) {
        delete existingErrors['maxCharLimit'];
        this.control.control.setErrors(Object.keys(existingErrors).length ? existingErrors : null);
        this.cdr.detectChanges();
      }
    }
  }

  private showError() {
    this.errorVisible = true;
    this.errorTimeout = setTimeout(() => {
      if (this.control?.control) {
        const errors = { ...this.control.control.errors };
        delete errors['maxCharLimit'];
        this.control.control.setErrors(Object.keys(errors).length ? errors : null);
        this.cdr.detectChanges();
      }
      this.errorVisible = false;
    }, 5000);
  }

  ngOnDestroy(): void {
    if (this.quillInstance) {
      this.quillInstance.off('text-change', this.textChangeListener);
    }
    if (this.errorTimeout) {
      clearTimeout(this.errorTimeout);
    }
  }
}
