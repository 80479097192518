import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../services/api.service';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {ForgotPasswordEmailService} from "../services/forgotPasswordEmail.service";

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css'],
})
export class ForgetPasswordComponent implements OnInit {
  emailFormGroup: FormGroup;
  otpFormGroup: FormGroup | any;
  passwordFormGroup: FormGroup;
  errorMessage = '';
  @ViewChild('passwordInput') passwordInput: ElementRef<HTMLInputElement>;
  @ViewChild('faIconPasswordInputButton') faIconPasswordInputButton: ElementRef<HTMLElement>;
  @ViewChild('confirmPasswordInput') confirmPasswordInput: ElementRef<HTMLInputElement>;
  @ViewChild('faIconConfirmPasswordInputButton') faIconConfirmPasswordInputButton: ElementRef<HTMLElement>;
  emailLoader: boolean = false;
  otpLoader: boolean = false;
  passwordLoader: boolean = false;
  currentStep: number = 1;
  adminMode: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private service: ApiService,
    private emailService: ForgotPasswordEmailService
  ) {
  }

  ngOnInit(): void {
    this.adminMode = this.route.snapshot.url.some(segment => segment.path.includes('admin'));
    this.emailFormGroup = this._formBuilder.group({email: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}')]],});
    this.otpFormGroup = this._formBuilder.group({otp: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(8), Validators.pattern('^[0-9]*$')]],});
    this.passwordFormGroup = this._formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    }, {validator: ConfirmedValidator('password', 'confirmPassword')});
    this.route.params.subscribe(res => {
      if (res.username && res.username != 'NA') {
        this.emailFormGroup.get('email')?.setValue(res.username);
      }
    });
    this.emailFormGroup.get('email')?.valueChanges.pipe(debounceTime(100), distinctUntilChanged()).subscribe(() => {
      this.handleEmailChange();
    });
    if (this.emailService.getInputData()) {
      this.emailFormGroup.get('email')?.setValue(this.emailService.getInputData());
    }
    this.emailService.getInputData();
  }

  sendOtpMail(): void {
    this.emailLoader = true;
    this.errorMessage = ''
    if (this.emailFormGroup.invalid) {
      this.emailLoader = false;
      if (this.emailFormGroup.get('email')?.errors) {
        if (this.emailFormGroup.get('email')?.errors?.required) {
          this.errorMessage = 'Email is required.';
        } else if (this.emailFormGroup.get('email')?.errors?.pattern) {
          this.errorMessage = 'Please enter a valid email address.';
        }
      }
      return;
    }
    const userType = this.adminMode ? 'ADMIN' : 'CLIENT';
    this.service.sendResetMail(this.emailFormGroup.get('email')?.value,userType).then(res => {
      this.emailLoader = false;
      this.currentStep = 2;
    }, (e) => {
      this.errorMessage = e.error.reason;
      this.emailLoader = false;
    });
  }

  get otp() {
    return this.otpFormGroup.get('otp');
  }

  verifyOtp(): void {
    this.otpLoader = true;
    if (this.otpFormGroup.invalid) {
      this.otpLoader = false;
      if (this.otpFormGroup.get('otp')?.errors) {
        if (this.otpFormGroup.get('otp')?.errors?.required) {
          this.errorMessage = 'OTP is required.';
        } else if (this.otpFormGroup.get('otp')?.errors?.pattern) {
          this.errorMessage = 'Please enter a valid 8 digit OTP.';
        }
      }
      return;
    }
    this.errorMessage = '';
    this.service.checkResetPasswordToken(this.otpFormGroup.get('otp')?.value).then(res => {
      if (res === 'valid') {
        this.otpLoader = false;
        this.currentStep = 3;
      } else {
        this.errorMessage = 'OTP is invalid';
        this.otpLoader = false;
      }
    }).catch(err => {
      this.errorMessage = 'An error occurred. Please try again later.';
      this.otpLoader = false;
    });
  }

  changePassword(): void {
    this.errorMessage = '';
    this.passwordLoader = true;
    if (this.passwordFormGroup.get('password')?.value !== this.passwordFormGroup.get('confirmPassword')?.value) {
      this.passwordLoader = false;
      this.errorMessage = 'Password and Confirm Password do not match.';
      return;
    }
    if (this.passwordFormGroup.invalid) {
      this.passwordLoader = false;
      this.errorMessage = 'Please fill out all fields correctly.';
      return;
    }
    const userType = this.adminMode ? 'ADMIN' : 'CLIENT';
    this.service.resetPassword(
      this.otpFormGroup.get('otp')?.value,
      this.passwordFormGroup.get('password')?.value,
      this.emailFormGroup.get('email')?.value,
      userType
    ).then(updated => {
      if (updated) {
        this.currentStep = 4;
      }
      this.passwordLoader = false;
    }).catch(err => {
      this.passwordLoader = false;
      if (err.error && err.error.reason) {
        this.errorMessage = err.error.reason;
      } else {
        this.errorMessage = 'An error occurred. Please try again later.';
      }
    });
  }

  togglePassword(inputType: 'password' | 'confirmPassword'): void {
    let inputElement: HTMLInputElement;
    let iconElement: HTMLElement;
    if (inputType === 'password') {
      inputElement = this.passwordInput.nativeElement;
      iconElement = this.faIconPasswordInputButton.nativeElement;
    } else {
      inputElement = this.confirmPasswordInput.nativeElement;
      iconElement = this.faIconConfirmPasswordInputButton.nativeElement;
    }
    if (inputElement.type === 'text') {
      inputElement.type = 'password';
      iconElement.className = 'fas fa-eye';
    } else {
      inputElement.type = 'text';
      iconElement.className = 'fas fa-eye-slash';
    }
  }

  handleEmailChange(): void {
    const usernameControl = this.emailFormGroup.get('email');
    if (usernameControl?.touched || usernameControl?.dirty) {
      if (usernameControl?.invalid) {
        this.emailLoader = false;
        if (usernameControl.errors) {
          if (usernameControl.errors.required) {
            this.errorMessage = 'Email is required.';
          } else if (usernameControl.errors.pattern) {
            this.errorMessage = 'Please enter a valid email address.';
          }
        }
      } else {
        this.errorMessage = '';
      }
    }
  }
}

export function ConfirmedValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({confirmedValidator: true});
    } else {
      matchingControl.setErrors(null);
    }
  }
}
