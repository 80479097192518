import {Component, OnInit, ViewChild} from '@angular/core';
import {CustomerAnalytics} from "../../../shared/interfaces";
import {ApiService} from "../../../services/api.service";
import {DatePipe} from "@angular/common";
import {MatDialog} from "@angular/material/dialog";
import {debounce} from "lodash";
import {CustomerActivityModalComponent} from "../customer-activity-modal/customer-activity-modal.component";
import {CreateCustomersComponent} from "../manage-customers/create-customers/create-customers.component";
import {MatPaginator} from "@angular/material/paginator";
import {MemberAnalytics, MemberAnalyticsSearch} from "../../interface/admin/MemberAnalytics";


@Component({
  selector: 'app-member-analytics',
  templateUrl: './member-analytics.component.html',
  styleUrls: ['./member-analytics.component.css']
})

export class MemberAnalyticsComponent implements OnInit {
  customerAnalyticsData: MemberAnalytics[] = [];
  currentPage: number = 0;
  pageSize: number = 12;
  totalPages: number = 0;
  totalElements: number = 0;
  showLoader: boolean = false;
  tableHeader: { text: string; label?: string }[] = [
    { text: 'Member Name' },
    { text: 'Device Used', label: 'Browser, Device, OS' },
    { text: 'Location', label: 'City, Region, Country' },
    { text: 'IP Address' },
    { text: 'TimeZone'},
    { text: '2FA Enabled' },
    { text: 'Login Time' },
    { text: 'Logout Time' },
    { text: 'Duration', label: 'hrs min'},
    // { text: 'Activity' },
  ];
  searchFieldName = ['memberName', 'deviceUsed','location','ip','timezone','is2FactorAuthenticationEnabled', 'loginTime', 'logoutTime'];
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  filters: any = {
    memberName: '',
    deviceUsed: '',
    location: '',
    loginTime: '',
    logoutTime: '',
    is2FactorAuthenticationEnabled:undefined,
    ip:'',
    timezone:''
  };

  constructor(
    private apiService: ApiService,
    private datePipe: DatePipe,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.getAllMemberAnalyticsDetails(this.currentPage, this.pageSize, [], this.filters);
  }

  clearFilter() {
    this.filters = {
      memberName: '',
      deviceUsed: '',
      location: '',
      loginTime: '',
      logoutTime: '',
      is2FactorAuthenticationEnabled:undefined,
      ip:'',
      timezone:''
    };
    this.paginator.pageIndex = 0;
    this.getAllMemberAnalyticsDetails(this.currentPage, this.pageSize, [], this.filters);
  }

  isFilterActive(): boolean {
    return this.filters.memberName.trim() !== "" ||
      this.filters.deviceUsed.trim() !== "" ||
      this.filters.location.trim() !== "" ||
      this.filters.loginTime.trim() !== "" ||
      this.filters.logoutTime.trim() !== "" ||
      this.filters.is2FactorAuthenticationEnabled !== undefined ||
      this.filters.ip.trim() !== "" ||
      this.filters.timezone.trim() !== "";
  }


  handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getAllMemberAnalyticsDetails(this.currentPage, this.pageSize, [], this.filters);
  }

  getAllMemberAnalyticsDetails(page: number, size: number, sort: string[], payload: MemberAnalyticsSearch) {
    this.showLoader = true;
    this.apiService.getAllMemberAnalytics(page, size, sort, payload).subscribe(
      (res: any) => {
        this.customerAnalyticsData = res.content;
        this.currentPage = res.page.number;
        this.pageSize = res.page.size;
        this.totalElements = res.page.totalElements;
        this.totalPages = res.page.totalPages;
        this.showLoader = false;
      },
      (error) => {
        console.log('error', error);
        this.showLoader = false;
      }
    );
  }

  onChangeSearch = debounce((value: string, fieldName: string) => {
    if (!fieldName) {
      return;
    } else if (fieldName === 'loginTime' || fieldName === 'logoutTime') {
      this.filters[fieldName] = this.datePipe.transform(value, 'yyyy-MM-dd')
    }else if(fieldName === 'is2FactorAuthenticationEnabled'){
      this.filters[fieldName] = value === "undefined" || value === null ? this.clearFilter() : JSON.parse(value);
    }
    else {
      this.filters[fieldName] = value ?? "";
    }
    this.paginator.pageIndex = 0;
    this.getAllMemberAnalyticsDetails(0, this.pageSize, [], this.filters);
  }, 600);

  formatDateTime(date: any): string {
    return this.datePipe.transform(date, 'dd MMM YYYY hh:mm a') || "--";
  }

  downloadReport() {
    const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
    const fileName = `Member_Report_${timestamp}.csv`;
    this.apiService.downloadMemberReport(
      this.filters?.memberName,
      this.filters?.deviceUsed,
      this.filters?.location,
      this.filters?.loginTime,
      this.filters?.logoutTime,
      this.filters?.is2FactorAuthenticationEnabled,
      this.filters?.ip,
      this.filters?.timezone,
    ).subscribe(blob =>{ const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(objectUrl);});
  }



  openFormModal(customerContactId: number, sessionId: string, data: CustomerAnalytics): void {
    const dialogRef = this.dialog.open(CustomerActivityModalComponent, {
      width: '60%',
      data: {
        customerContactId: customerContactId,
        sessionId: sessionId,
        customerContact: data.customerContact ?? '',
        customerContactEmail: data.customerContactEmail,
        customerContactPhone: data.customerContactPhone,
      }
    })
    dialogRef.afterClosed().subscribe(() => {

    });
  }

  openCustomerModal(id: string): void {
    const dialogRef = this.dialog.open(CreateCustomersComponent, {
      width: '70%',
      data: {
        id: id,
        customerAnalyticsRoute: true,
      }
    })
    dialogRef.afterClosed().subscribe(() => {

    });
  }

  clearDateField(data: string) {
    this.filters[data] = null;
    this.getAllMemberAnalyticsDetails(0, this.pageSize, [], this.filters);
  }
}
