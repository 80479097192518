import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from "../../../../services/navigation.service";

@Component({
  selector: 'app-web-candidate-table-listing',
  templateUrl: './web-candidate-table-listing.component.html',
  styleUrls: ['./web-candidate-table-listing.component.css']
})
export class WebCandidateTableListingComponent implements OnInit {
  currentTabIndex: number = 0;
  selected: number = 0;
  currentPage: number = 0;

  constructor(
    private router: Router,
    private navigationService: NavigationService,
  ) {}

  ngOnInit(): void {
    const state = this.navigationService.getState();
    if (state) {
      this.currentTabIndex = state.tabIndex ?? 0;
      this.selected = this.currentTabIndex;
    } else {
      this.currentTabIndex = 0;
      this.selected = 0;
    }
  }

  tabOnChange(tabIndex: number): void {
    this.selected = tabIndex;
    this.currentTabIndex = tabIndex;
    this.router.navigate([], {
      queryParams: { tab: tabIndex },
      queryParamsHandling: 'merge',
    });
    this.currentPage = 0;
  }

}
