import {Component, OnInit, SimpleChanges} from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {DataService} from "../../../services/data.service";
import {PopUpComponent} from "../../../util/pop-up/pop-up.component";
import {MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {MaxCharLimits} from "../../../common/errorInputMaxValue";

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})

export class UserProfileComponent implements OnInit {
  user: any;
  type = '';
  userProfileForm: FormGroup;
  logoLoader = false;
  showLoader = false;
  displayError = false;
  errorMessage = '';
  loading = false;
  data: any;
  userId: number;
  files: boolean;
  loginType = '';
  role = '';
  selectedImage: string = '';
  errorUploadingImage: boolean = false;
  private initialFormValues: any = {};

  constructor(
    private dataService: DataService,
    private fb: FormBuilder,
    private service: ApiService,
    public dialog: MatDialog,
    public toaster: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.loginType = localStorage.getItem('loginType') ?? '';
    this.role = localStorage.getItem('role') ?? '';
    this.dataService.isLoading.next(true);
    this.userId = Number(localStorage.getItem('orgId'));
    this.userProfileForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: [''],
      email: [{
        value: '',
        disabled: true
      }, [Validators.required, Validators.pattern("[a-zA-Z0-9._%+-]+@[a-z0-9A-Z.-]+.[a-z]{2,}$")]],
      number: ["", Validators.required],
      role: ""
    });
    this.fetchUserDetails();
  }

  fetchUserDetails(): void {
    const userService = this.loginType === 'Vendor' ? this.service.getLoginUser() : this.service.getCustomerLoginUser();
    userService.subscribe({
      next: res => {
        this.user = res;
        setTimeout(() => {
          this.selectedImage = this.loginType === 'Vendor' ? res?.picture : res?.profilePicture;
        }, 100)
        this.userProfileForm.patchValue({
          firstName: this.loginType === 'Vendor' ? res?.firstName : res?.name,
          lastName: res?.lastName,
          email: res?.email,
          number: res?.number?.mobile ?? '',
          role: res?.role
        });
        localStorage.setItem('role', res?.role);
        this.initialFormValues = this.userProfileForm.getRawValue();
      },
      error: err => {
        console.error("Error fetching user details:", err);
      },
      complete: () => {
        this.dataService.isLoading.next(false);
      }
    });
  }

  isFormUpdated(): boolean {
    let normalizedCurrentNumber = this.normalizeNumber(this.userProfileForm.getRawValue().number);
    let normalizedInitialNumber = this.initialFormValues.number;
    const normalizeValue = (value: any) => (value === null || value === undefined || value === '') ? '' : value;
    const currentFormValues = {
      ...this.userProfileForm.getRawValue(),
      number: normalizeValue(normalizedCurrentNumber?.replace(/\s+/g, '')?.replace(/^0/, ''))
    };
    const initialFormValues = {
      ...this.initialFormValues,
      number: normalizeValue(normalizedInitialNumber?.replace(/\s+/g, '')?.replace(/^0/, ''))
    };
    return JSON.stringify(initialFormValues) !== JSON.stringify(currentFormValues);
  }

  normalizeNumber(numberObj: any): string {
    return numberObj?.number || '';
  }

  getFormControl(controlName: string): any {
    return this.userProfileForm.get(controlName);
  }

  uploadFile(file: File): void {
    this.logoLoader = true;
    const formData: FormData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);
    this.logoLoader = true;
    const uploadObservable = this.loginType === 'Vendor' ?
      this.service.uploadAdminPicture(formData, this.userId) :
      this.service.uploadUserProfilePicture(formData, this.user.id);
    uploadObservable.subscribe({
      next: (res) => {
        this.selectedImage = res.body?.picture || res.body?.profilePicture;
        this.errorUploadingImage = false;
      },
      error: (err) => {
        this.errorUploadingImage = true;
        this.toaster.error('Error', err.error.message);
      },
      complete: () => {
        this.logoLoader = false;
      }
    })
  }

  getFormValidationErrors(): string {
    let error = '';
    Object.keys(this.userProfileForm.controls).forEach(key => {
      const controlErrors = this.userProfileForm.get(key)?.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(() => {
          error = error + key;
        });
      }
    });
    return error;
  }

  updateUserProfile(): void {
    this.showLoader = true;
    if (this.userProfileForm.invalid) {
      this.showLoader = false;
      this.errorMessage = 'Please check the fields' + this.getFormValidationErrors();
      return;
    }
    const number = {...this.userProfileForm?.value?.number, mobile: this.userProfileForm?.value?.number?.number};
    const updatedUserData = {
      firstName: this.userProfileForm.get('firstName')?.value,
      lastName: this.userProfileForm.get('lastName')?.value,
      role: this.role,
      number,
    };
    const updateProfile = this.loginType === 'Vendor' ? this.service.updateMemberProfile(updatedUserData) : this.service.updateUser(updatedUserData);
    updateProfile.then(res => {
      this.toaster.success('Profile Updated Successfully');
      this.dataService.companyName.next(res.name);
      console.log('isFormUpdated', this.isFormUpdated())
      this.fetchUserDetails();
    }).catch(e => {
      this.toaster.error(`Error ${e.error.reason}`);
      this.errorMessage = e.error?.reason || 'Error occurred, Please contact server team';
    }).finally(() => {
      this.showLoader = false;
    });
  }


  delete(): void {
    const dialogRef = this.dialog.open(PopUpComponent, {
      data: {
        title: 'Alert',
        message: 'Are you sure you want to delete this?',
        type: 'Delete'
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (!confirmed) return;
      const deleteMethod = this.loginType === 'Vendor'
        ? this.service.deleteVendorPicture()
        : this.service.deleteUserPicture(this.user?.id ?? 0);
      deleteMethod
        .then(() => {
          this.selectedImage = '';
          this.dataService.companyImage.next('');
          this.toaster.success('Profile Picture Deleted', 'Success');
        })
        .catch(error => {
          this.toaster.error('Error deleting profile picture', error.error?.message || 'An error occurred');
          console.error("Error deleting profile picture:", error);
        });
    });
  }

  handleFileChange(event: File | null) {
    if (!event) return;
    this.logoLoader = true;
    this.uploadFile(event);
  }

  handleProfileDelete(event: boolean): void {
    if (event)
      this.delete();
  }

  MaxCharLimits = MaxCharLimits;
}
