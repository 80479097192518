import { Component, OnInit } from '@angular/core';
import {LoginService} from "../../login/service/login.service";

@Component({
  selector: 'app-common-footer',
  templateUrl: './common-footer.component.html',
  styleUrls: ['./common-footer.component.css']
})
export class CommonFooterComponent implements OnInit {

  name: string | undefined;

  constructor(private loginService: LoginService ) {
  }

  ngOnInit(): void {
    this.loginService.name$.subscribe(name => {
      this.name = name;
    });
  }

}
