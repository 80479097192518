import { Component, Input } from '@angular/core';

interface Skill {
  id: number;
  title: string;
  active: boolean;
}

interface CandidateSkill {
  skill: Skill;
  skillType: string;
}

@Component({
  selector: 'app-skills-display',
  templateUrl: './skills-display.component.html',
  styleUrls: ['./skills-display.component.css']
})

export class SkillsDisplayComponent {
  @Input() candidateSkills: CandidateSkill[] = [];

  getSkills() {
    const skills = this.candidateSkills ?? [];
    const displayedSkills = skills.slice(0, 3)?.map(skill => skill?.skill?.title);
    const remainingSkills = skills.slice(3)?.map(skill => skill?.skill?.title).join(', ');
    const remainingCount = skills.length - 3;
    const formattedDisplayedSkills = displayedSkills
      .map((skill, index) => (index < displayedSkills.length - 1 ? skill + ' | ' : skill))
      .join('');
    return {
      displayedSkills: formattedDisplayedSkills,
      tooltip: remainingCount > 0 ? remainingSkills : '',
      remainingCount
    };
  }

}
