<div class="ms-4 pt-2">
  <div *ngIf="currentRoute === 'candidate'">
    <div (click)="navigateToListing()" class="pb-2 text-primary cursor-p"> <- Back to Listing</div>
  </div>
  <div *ngIf="currentRoute === 'customer'">
    <div (click)="navigateToListing()" class="pb-2 text-primary cursor-p"> <- Back to Listing</div>
  </div>
</div>
<app-common-head-component [customerInfoData]="candidateData"></app-common-head-component>

<app-web-candidate-information [customerInfoData]="candidateData" [formFields]="returnFormFields()"
                               [handleCandidateFormSubmit]="handleCandidateFormSubmit.bind(this)">
</app-web-candidate-information>

<div *ngIf="this.currentRoute === 'candidate'">
  <div class="bg-white" style="padding: 5px 0; margin: 10px 30px">
    <app-skills-component
      [id]="candidateData?.id"
      [customerInfoData]="candidateData?.candidateSkills ?? []"
      [handleSkillUpdate]="handleSkillUpdate.bind(this)"
      [limited]="false">
    </app-skills-component>
  </div>
</div>

<div *ngIf="this.currentRoute === 'candidate'">
  <app-attachement-component [getAllDocumentOfWebCandidate]="getAllDocumentOfWebCandidate.bind(this)"
                             [createWebCandidateFileType]="createWebCandidateFileType.bind(this)"
                             [createWebCandidateUrlType]="createWebCandidateUrlType.bind(this)"
                             [deleteWebCandidateDocumentObservable]="deleteWebCandidateDocumentObservable.bind(this)"
                             [inactiveWebCandidateDocumentObservable]="inactiveWebCandidateDocumentObservable.bind(this)"
                             [restoreWebCandidateDocumentObservable]="restoreWebCandidateDocumentObservable.bind(this)"
  ></app-attachement-component>
</div>

<app-communication-component [addWebCandidateNotesObservable]="addWebCandidateNotesObservable.bind(this)"
                             [updateWebCandidateNotesObservable]="updateWebCandidateNotesObservable.bind(this)"
                             [deleteWebCandidateNotesObservable]="deleteWebCandidateNotesObservable.bind(this)"
                             [getAllWebCandidateNotesObservable]="getAllWebCandidateNotesObservable.bind(this)"
></app-communication-component>

<app-setting [customerInfoData]="candidateData"></app-setting>
