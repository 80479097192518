import { Directive,ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNoSpecialChars]',
})
export class NoSpecialCharsDirective {

  private regex: RegExp = new RegExp(/^[a-zA-Z ]*$/); // Allow only letters and spaces
  private specialKeys: string[] = ['Backspace'];

  constructor(private el: ElementRef) { }

  @HostListener('keydown',['$event'])
  onKeyDown(event: KeyboardEvent){
    if(this.specialKeys.includes(event.key)){
      return;
    }
    if(!this.regex.test(event.key)){
      event.preventDefault();
    }
  }
}
