<div class="consultant_wrapper">
  <form [formGroup]="documentForm" ngNativeValidate (submit)="onDocUpload()">
    <div class="row mb-4">
      <div class="col-md-6">
        <h2 class="mb-4">Add Documents</h2>
        <div>
          <mat-form-field appearance="outline" class="w-100 border-red ">
            <mat-label>Document Label</mat-label>
            <input #attachmentAutoCompleter
                   [formControl]="docControl"
                   [matAutocomplete]="auto1"
                   type="text" matInput placeholder="e.g. Pan Card, Aadhar Card "
                   aria-describedby="title" required>
            <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete"
                              [displayWith]="displayDocFn">
              <mat-option *ngFor=" let type of filteredDocs| async"
                          (onSelectionChange)="chooseDocType($event)" [value]="type">
                {{ type.name }}
              </mat-option>
            </mat-autocomplete>

          </mat-form-field>
          <div *ngIf="(filteredDocs|async)?.length == 0" class=" dropdown-skills position-relative">
            <ul>
              <li class="li_disabled">
                No match Found
              </li>
              <hr class="m-0 p-0">
              <li class="font-weight-bold">
                <div class="d-flex justify-content-between align-items-center"
                     (click)="addDocType($event,'doc', attachmentAutoCompleter.value)">
                  <span>Add this Document type:</span>
                  <button type="button" class="p-0 bg-transparent border-0 fs-20"> +</button>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="my-3" *ngIf="typeId=='0'">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Enter type of document</mat-label>
            <input [(ngModel)]="docNameOther" type="text" matInput placeholder="Document name..."
                   required>
          </mat-form-field>
        </div>

        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Start Date (optional)</mat-label>
          <input [(ngModel)]="documentStartDate" (ngModelChange)="updateEndDateMinDate()" formControlName="startDate"
                 matInput [matDatepicker]="startDatePicker" style="padding-left: 0!important;" readonly>
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-100">
          <mat-label>End Date (optional)</mat-label>
          <input [(ngModel)]="documentExpiryDate" formControlName="endDate" matInput [min]="minEndDate"
                 style="padding-left: 0!important;" [matDatepicker]="endDatePicker" [disabled]="!documentStartDate"
                 readonly>
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>

      </div>
      <div class="col-md-6">
        <div class="border my-auto h-100 rounded-3">
          <div class="upload_doc text-center">
            <div class="abc">
              <h2 class="mb-4 font-weight-bold">Upload document</h2>
              <app-image-upload (fileChange)="handleFileChange($event)"
                                [logoLoader]="showloader" [errorUploadingImage]="errorUploadingDoc"
                                [hideButton]="true" [doesSupportDoc]="true" [width]="90" [resetFileTrigger]="resetFile"
              >
              </app-image-upload>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="mt-5">
      <div *ngIf="docError !== ''">
        <div class="mb-2 w-100">
          <span class=" d-flex align-items-center justify-content-center">
            <span class="containeroferror">
               Error : {{ docError }}
            </span>
          </span>
        </div>
      </div>
      <div class="d-flex justify-content-end align-items-center">
        <div class="form-group btns save-form">
          <button *ngIf="!showloader" class="btn btn-save mx-3 theme-btn-color"
                  type="submit">Save Details
          </button>
        </div>
        <div class="form-group btns save-form">
          <div class="save-skill" *ngIf="showloader">
            <button class="btn btn-save mx-3 theme-btn-color" disabled>
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="sr-only"></span>
              </div>
              Save Details
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="document-wrapper mb-5">
  <div class="mx-height-scroll mt-4">
    <div class="col-md-12 d-flex justify-content-end align-items-baseline mx-0" id='search-box2'>
      <mat-slide-toggle
        class="example-margin"
        color="primary"
        [checked]="showArchived"
        (change)="changeArchived($event)">
        <div class="ActiveButton">Show Archived</div>
      </mat-slide-toggle>
    </div>
    <table *ngIf="consultantDocs.length > 0" class="table table-striped" style="cursor:pointer;">
      <thead class="tableview theme-bg-color">
      <tr>
        <th>Document</th>
        <th>Duration</th>
        <th>Uploaded By</th>
        <th>Action</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let doc of consultantDocs, let i=index">
        <tr>
          <td>
            <a style=" font-weight: lighter; margin-right: 45px;text-transform:capitalize;font-size: 16px">
              {{ doc.type }}</a><br>
            <a type="link" (click)="goToLink(doc.url)" class="text-primary"
               style="font-size: 16px;font-weight: lighter;">{{ doc.fileName }}</a>
          </td>
          <td><a style="text-transform:capitalize;font-size: 13px ;font-weight: lighter;">
            <a *ngIf="doc.startDate">start date : {{
                doc.startDate| date:
                  ' dd MMM yyyy '
              }}</a>
            <a *ngIf="!doc.startDate" class="ms-3 mt-1">
              -----NA-----
            </a><br>
            <a *ngIf="doc.endDate">End date :
              {{ doc.endDate |date: ' dd MMM yyyy ' }}
            </a>
            <a *ngIf="!doc.endDate && !doc.startDate" class="ms-3 mt-1">
              -----NA-----
            </a>
          </a>
          </td>
          <td>
            <a style="text-transform:capitalize;font-size: 13px ;font-weight: lighter;">
              <a *ngIf="doc.createdBy">created by : {{ doc.createdBy }}</a>
              <a *ngIf="!doc.createdBy" class="ms-3 mt-1">
                -----NA-----
              </a><br>
              <a *ngIf="doc.createdDate">Created date :
                {{ doc.createdDate |date: '  hh:mm a, dd MMM yyyy' }}
              </a>
              <a *ngIf="!doc.createdDate && !doc.createdDate" class="ms-3 mt-1">
                -----NA-----
              </a>
            </a>
          </td>
          <td>
            <button id="more-btn dropdownMenuButton1" class="more-btn" data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    type="button">
              <span class="more-dot"></span>
              <span class="more-dot"></span>
              <span class="more-dot"></span>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <a *ngIf="doc.active" class="dropdown-item"
                   (click)="deleteConfirmation('Archive',doc.id,true)">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M20.37 8.91003L19.37 10.64L7.24 3.64003L8.24 1.91003L11.28 3.66003L12.64 3.29003L16.97 5.79003L17.34 7.16003L20.37 8.91003ZM6 19V7.00003H11.07L18 11V19C18 19.5305 17.7893 20.0392 17.4142 20.4142C17.0391 20.7893 16.5304 21 16 21H8C7.46957 21 6.96086 20.7893 6.58579 20.4142C6.21071 20.0392 6 19.5305 6 19Z"
                      fill="#ED0F0F"/>
                  </svg>
                  Archive
                </a>
                <a *ngIf="!doc.active" class="dropdown-item" (click)="restoreTrashedDocument(doc.id)">
                  <i class='fas fa-trash-restore text-success mx-1'></i>Restore
                </a>
                <a *ngIf="hideWidget(doc.createdDate)" class="dropdown-item"
                   (click)="deleteConfirmation('Delete',doc.id,false)">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M20.37 8.91003L19.37 10.64L7.24 3.64003L8.24 1.91003L11.28 3.66003L12.64 3.29003L16.97 5.79003L17.34 7.16003L20.37 8.91003ZM6 19V7.00003H11.07L18 11V19C18 19.5305 17.7893 20.0392 17.4142 20.4142C17.0391 20.7893 16.5304 21 16 21H8C7.46957 21 6.96086 20.7893 6.58579 20.4142C6.21071 20.0392 6 19.5305 6 19Z"
                      fill="#ED0F0F"/>
                  </svg>
                  Delete Permanent
                </a>
              </li>
            </ul>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
  <div *ngIf="consultantDocs.length == 0"
       class="empty-state d-flex justify-content-center themeSecondary-bg-color fs-6 mt-3">
    <span>Currently no active documents available</span>
  </div>
  <mat-paginator [length]="totalDocument"
                 [pageSizeOptions]="[5,12,25,50,100]"
                 (page)="handlePageDocument($event)"
                 [hidden]="totalDocument==0"
                 aria-label="Select page">
  </mat-paginator>
</div>
