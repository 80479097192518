import {AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {NgDialogAnimationService} from "ng-dialog-animation";
import {DataService} from "../../../services/data.service";
import {CreateCustomersComponent} from "./create-customers/create-customers.component";
import {Customer} from "../../../shared/interfaces";
import {ApiService} from "../../../services/api.service";
import {debounce,} from "lodash";
import {MatPaginator} from "@angular/material/paginator";
import {debounceTime, distinctUntilChanged, filter, tap} from "rxjs/operators";
import {fromEvent} from "rxjs";
import {IsDataUpdatedService} from "../../../services/isDataUpdated.service";
import {MatDatepicker} from "@angular/material/datepicker";
import {ResetFiltersService} from "../../../services/resetFilters.service";

export interface CustomerSearchPayload {
  customerId?: string;
  customerName?: string;
  location?: string;
  dateSearchCriteria: DateCriteria;
  query: string;
  showArchived: boolean;
}

interface DateCriteria {
  filterType: string;
  from: string;
  to: string;
}

@Component({
  selector: 'app-manage-customers',
  templateUrl: './manage-customers.component.html',
  styleUrls: ['./manage-customers.component.css']
})

export class ManageCustomersComponent implements OnInit, AfterViewInit {
  members: any[] = [];
  customers: Customer[] = [];
  pageSize = 12;
  currentPage = 0;
  totalCustomers = 0;
  length: any;
  message: string | null;
  userId: any;
  role: any;
  firstName: any;
  search = '';
  index: number;
  loading: boolean;
  showLoader: boolean;
  showArchived: boolean = false;
  customerId: string = '';
  searchFields: string[] = ['customerId', 'customerName', 'location', 'dateSearchCriteria']
  customerName: string = '';
  locations: string = '';
  minEndDate: string | null;

  filters: CustomerSearchPayload = {
    customerId: "",
    customerName: "",
    location: "",
    dateSearchCriteria: {
      filterType: "",
      from: "",
      to: "",
    },
    query: "",
    showArchived: false
  };

  @ViewChild('picker') picker1: MatDatepicker<any>;
  @ViewChild('picker1') picker2: MatDatepicker<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('input', {static: true}) input: ElementRef | any;
  @ViewChildren('dropdownDate') dropdowns!: QueryList<ElementRef>;

  constructor(
    private resetFilters: ResetFiltersService,
    public dialog: NgDialogAnimationService,
    private dataService: DataService,
    private service: ApiService,
    private isCustomerDataUpdated: IsDataUpdatedService
  ) {
  }

  ngOnInit(): void {
    this.showLoader = false;
    this.loadCustomers(0, this.pageSize, '', this.filters);
    this.loadCustomers = debounce<any>(this.loadCustomers, 600);
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(
      tap(() => {
        this.loadCustomers(this.paginator.pageIndex, this.paginator.pageSize, '', this.filters);
      })
    ).subscribe();
    if (this.input) {
      fromEvent(this.input?.nativeElement, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => this.loadCustomers(this.paginator.pageIndex, this.paginator.pageSize, '', this.filters)),
        )
        .subscribe();
    }
  }

  async loadCustomers(page: number, size: number, sort: string, payload: CustomerSearchPayload): Promise<void> {
    this.showLoader = true;
    this.dataService.isLoading.next(true);
    this.loading = true;
    if (this.search) {
      this.customers = [];
      this.currentPage = page;
    } else {
      this.currentPage = page;
    }
    await this.service.getCustomers(page, size, '', this.filters).then(res => {
      this.dataService.isLoading.next(false);
      this.loading = false;
      this.customers = res.content;
      this.showLoader = false
      this.length = res.page.totalElements,
      this.paginator.pageIndex = res.page.number;
      this.paginator.pageSize = res.page.size;
      this.pageSize = res.page.size;
      this.totalCustomers = res.page.totalElements;
      if (res.content.length === 0) {
        this.message = 'No Match Found';
      } else {
        this.message = null;
      }
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  clearFilter() {
    this.filters.customerId = '';
    this.filters.customerName = '';
    this.filters.location = '';
    this.filters.dateSearchCriteria.to = ''
    this.filters.dateSearchCriteria.from = ''
    this.filters.dateSearchCriteria.filterType = ''
    this.filters.query = ''
    this.filters.showArchived = false
    this.search=''
    this.resetFilters.emitChange(true);
    this.loadCustomers(0, this.pageSize, '', this.filters);
  }

  isFilterApplied(): boolean {
    return (this.filters?.customerId ?? '') !== '' ||
      (this.filters?.customerName ?? '') !== '' ||
      (this.filters?.location ?? '') !== '' ||
      (this.filters?.dateSearchCriteria?.to ?? '') !== '' ||
      (this.filters?.dateSearchCriteria?.from ?? '') !== '' ||
      (this.filters?.dateSearchCriteria?.filterType ?? '') !== '' ||
      (this.filters?.query ?? '') !== '' ||
      (this.filters?.showArchived ?? false) !== false;
  }


  toggleActiveCustomers(event: any) {
    this.showArchived = event.checked;
    this.filters.showArchived =  event.checked;
    this.currentPage = 0
    this.loadCustomers(this.currentPage, this.pageSize, '', this.filters);
  }

  openDialog(action: string, element: any): void {
    if (action == 'add') {
      const dialogRef = this.dialog.open(CreateCustomersComponent, this.dataService.dialogConfig({
        id: null
      }));
      dialogRef.beforeClosed().subscribe((res) => {
        if (res) {
          const dialogRef = this.dialog.open(CreateCustomersComponent, this.dataService.dialogConfig({
            id: res.id,
            consultantId: res.consultantId
          }));
          dialogRef.afterClosed().subscribe(() => {
            this.isCustomerDataUpdated.setUpdated(false);
            this.loadCustomers(this.currentPage, this.pageSize, '', this.filters);
          });
        }
      });
    } else if (action == 'edit') {
      if (action == 'edit') {
        const dialogRef = this.dialog.open(CreateCustomersComponent, this.dataService.dialogConfig({
          id: element.id,
          customerId: element.customerId,
          customerData: element,
        }));
        dialogRef.beforeClosed().subscribe((res) => {
          if (this.isCustomerDataUpdated.getBoolean()) {
            this.loadCustomers(this.currentPage, this.pageSize, '', this.filters);
            this.isCustomerDataUpdated.setUpdated(false);
          }
        });
      }
    }
  }

  onFiltersChanged(updatedFilters: any) {
    this.filters = updatedFilters;
    this.paginator.pageIndex = 0;
    const fromDate = this.filters.dateSearchCriteria.from;
    const toDate = this.filters.dateSearchCriteria.to;
    const datesValid = (fromDate && toDate) || (!fromDate && !toDate);
    if (datesValid) {
      this.loadCustomers(this.paginator.pageIndex, this.paginator.pageSize, '', this.filters);
    }
  }

  updateSearch() {
    this.filters.query = this.search;
    this.paginator.pageIndex = 0;
    this.loadCustomers(this.paginator.pageIndex, this.paginator.pageSize, '', this.filters);
  }

  dateTypeChanged($event: boolean) {
    if ($event === true) {
      this.filters.dateSearchCriteria.filterType = "UPDATED";
    } else if ($event === false) {
      this.filters.dateSearchCriteria.filterType = "CREATED";
    }
    this.loadCustomers(0, this.paginator.pageSize, '', this.filters);

  }

  protected readonly location = location;
}
