<div>
  <h2>{{ title }}</h2>
  <div class="upload-area w-100">
    <div class="drag-drop-box text-center" [ngStyle]="{ 'width': width + '%',height:height + 'px'}"
         (click)="!(selectedImage || imageSrc) ? uploadFileTrigger() : $event.preventDefault()">
      <div (dragover)="handleDragOver($event)" (drop)="onSelectLogo($event, true)">
        <input #fileInput
               type="file"
               style="display: none"
               [accept]="acceptedFileTypes"
               (change)="onSelectLogo($event, false)"/>

        <!-- If document support is enabled, show the file name -->
        <div *ngIf="doesSupportDoc && file" class="d-flex align-items-center">
          <p class="file-name mb-0 mr-2">{{ getTruncatedDocName() }}</p>
          <button [disabled]="logoLoader" *ngIf="!selectedImage" class="delete-doc-btn" (click)="delete($event)">
            <i class="fas fa-times-circle"></i>
          </button>
        </div>

        <!-- If an image is uploaded and document support is disabled, show image preview -->
        <div *ngIf="imageSrc && !doesSupportDoc; else uploadContent" class="image-preview">
          <img [src]="imageSrc" class="preview-image" alt="Uploaded Image"/>

          <div *ngIf="logoLoader && !errorUploadingImage" class="loader-overlay">
            <div class="spinner"></div>
          </div>

          <div *ngIf="errorUploadingImage" class="retry-overlay" (click)="retryUpload($event)">
            <i class="fas fa-redo retry-icon"></i>
          </div>

          <button [disabled]="logoLoader" *ngIf="!selectedImage" class="delete-btn" (click)="delete($event)">
            <i class="fas fa-times-circle"></i>
          </button>
        </div>

        <!-- Upload UI (shown when no file is uploaded and doesSupportDoc is false) -->
        <ng-template #uploadContent>
          <div class="upload-content" *ngIf="!doesSupportDoc || !file">
            <img src="assets/img_1.png" class="upload-icon"/>
            <p class="upload-text"><b>Drag & Drop or Click to Upload</b></p>
            <span *ngIf="isCsv">Max file size: 2MB (csv)</span>
            <span *ngIf="!doesSupportDoc && !doesSupportAll && !isCsv">Max file size: 2MB (png, jpeg, jpg)</span>
            <span *ngIf="doesSupportDoc && !isCsv">Max file size: 2MB (pdf, doc, docx)</span>
          </div>
        </ng-template>

      </div>
    </div>
  </div>

  <div *ngIf="!hideButton" class="mt-3 d-flex align-items-center justify-content-end">
    <button [disabled]="logoLoader || selectedImage" type="button" (click)="handleUpload()"
            class="btn btn-save theme-btn-color text-white mr-5">
      {{ isFileReadyForUpload ? 'Upload' : 'Select File' }}
    </button>
    <button type="button" [disabled]="!selectedImage" (click)="delete($event)" class="btn delete cursor-pointer">
      Delete
    </button>
  </div>
</div>
