import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {NgxSpinnerService} from "ngx-spinner";
import {DataService} from "./services/data.service";
import {SessionTimeoutService} from "./services/session-timeout.service";
import {ApiService} from "./services/api.service";
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    private titleService: Title,
    private spinner: NgxSpinnerService,
    private data: DataService,
    // private timeoutService: SessionTimeoutService,
  ) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('ProTeamMate | Accelerate IT Talent Hiring ');
    if (this.data.isLoading) {
      setTimeout(() => {
        this.spinner.hide();
      }, 2000);
    }
  }

  ngAfterViewInit() {
    this.data.isLoading.next(true);
    if (window.performance.navigation.type === 1) {
      this.spinner.show();
    }
  }

}
