import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ToastrService} from "ngx-toastr";
import {ApiService} from 'src/app/services/api.service';
import {CreateConsultantsComponent} from "../create-consultants/create-consultants.component";
import {
  ComponentName,
  DocsType,
  Education,
  FormUpdateEvent,
  PlaceDto,
  VendorContact,
  VendorCorp
} from "../../../../shared/interfaces";
import {Observable} from "rxjs/internal/Observable";
import {debounceTime, delay, distinctUntilChanged, filter, map, startWith, takeUntil, tap} from "rxjs/operators";
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {empty} from "rxjs/internal/Observer";
import {Subject} from "rxjs";
import {IsDataUpdatedService} from "../../../../services/isDataUpdated.service";
import {DatePipe} from "@angular/common";
import {CustomValidators} from "../../../../common/custom-validators";
import {MaxCharLimits} from "../../../../common/errorInputMaxValue";

@Component({
  selector: 'app-consultant-info',
  templateUrl: './consultant-info.component.html',
  styleUrls: ['./consultant-info.component.css'],
})
export class ConsultantInfoComponent implements OnInit {
  @Output() passingDate = new EventEmitter<any>();
  errorMessage: string | undefined;
  errorMessageEmergencyPhone: string | undefined;
  consultantForm: FormGroup;
  vendors: VendorCorp = {} as any;
  filteredVendors: Observable<VendorCorp[]>;
  filteredVendorContacts: Observable<VendorContact[]>;
  vendorControl = new FormControl('');
  vendorContactControl = new FormControl();
  filteredEducation: Observable<Education[]>;
  vendorsRes: VendorCorp[] = [];
  vendorContacts: VendorContact[] = [];
  vendorId: string;
  vendorName = '';
  pocName = '';
  consultantError = '';
  experienceMonth: number[] = [];
  experienceYears: number[] = [];
  educationList: Education[] = [];
  passingYearList: any[] = [];
  showLoader = false;
  disableExpMonth = false;
  skills: any[] = [];
  passingYear: string;
  enableAddItem: boolean = false;
  selectedVendorContactIds: number[] = [];
  educationControl = new FormControl('')
  educationName = '';
  educationEditName = '';
  @ViewChild('educationAutoCompleter', {read: MatAutocompleteTrigger})
  educationAutoCompleter: MatAutocompleteTrigger;
  @ViewChild('pocAutoCompleter', {read: MatAutocompleteTrigger})
  pocAutoCompleter: MatAutocompleteTrigger;
  countryControl = new FormControl();
  stateControl = new FormControl('');
  cityControl = new FormControl('');
  countryServerCtrl = new FormControl();
  stateServerCtrl = new FormControl();
  cityServerCtrl = new FormControl();
  selectedCountry: any | undefined;
  selectedState: any | undefined;
  selectedCity: any | undefined;
  searchCountry: boolean = false;
  searchState: boolean = false;
  searchCity: boolean = false;
  displayError: boolean = false;
  displayErrorEmergencyPHone: boolean = false;
  countries: PlaceDto[] = [];
  states: PlaceDto[] = [];
  cities: PlaceDto[] = [];
  previousFormValue: any;
  initializingForm: boolean = true;
  @Output() formUpdated = new EventEmitter<FormUpdateEvent>();
  protected _onDestroy = new Subject<void>();
  @Input() data: any;
  @Output() firstName = new EventEmitter<string>();
  @Output() consultantData = new EventEmitter<any>();
  linkedInURL: string
  updateURL: boolean = true
  passingYearDate: string
  dateChanged: string;
  selectedSpcId: string;
  @ViewChild('SPOCAutoCompleter', {read: MatAutocompleteTrigger})
  autoComplete2: MatAutocompleteTrigger;
  SPOCAutoCompleter: MatAutocompleteTrigger;
  @ViewChild('autoCompleteInput1', {read: MatAutocompleteTrigger})
  autoComplete1: MatAutocompleteTrigger;
  @ViewChild('autoCompleteInput2', {read: MatAutocompleteTrigger})
  autoCompleteInput2: MatAutocompleteTrigger;
  consultantRes: any;
  isVendorSelected: boolean = false;
  isSubmitting: boolean;

  constructor(
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private service: ApiService,
    public dialog: MatDialog,
    public router: Router,
    public snackBar: MatSnackBar,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<CreateConsultantsComponent>,
    private isDataUpdatedService: IsDataUpdatedService,
  ) {
    this.experienceMonth = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,];
    this.experienceYears = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
    window.addEventListener('scroll', this.scrollEvent, true);
    this.consultantForm = this.fb.group({
      id: [''],
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: [''],
      dateOfBirth: [''],
      panNumber: ['', Validators.pattern('^[A-Z]{5}[0-9]{4}[A-Z]$')],
      aadharNumber: ['', Validators.pattern('^[0-9]{12}$')],
      linkedInURL: [''],
      title: ['', Validators.required],
      passingYear: ['', Validators.required],
      experienceYear: [0, Validators.required],
      experienceMonth: [0, Validators.required],
      educationId: ['', Validators.required],
      dateOfJoining: [''],
      number: ['', Validators.required],
      emergencyContactNumber: ['', Validators.required],
      email: ["", [Validators.required, CustomValidators.validEmail()]],
      address: this.fb.group({
        country: this.fb.group({
          id: [''],
          name: [''],
          formattedAddress: [''],
        }),
        state: this.fb.group({
          id: [''],
          name: [''],
          formattedAddress: [''],
        }),
        city: this.fb.group({
          id: [''],
          name: [''],
          formattedAddress: [''],
        }),
        pincode: ['', Validators.pattern("[0-9]{6}")],
        streetAddress: [''],
      }),
      gender: ['', Validators.required],
      vendorId: ['', Validators.required],
      vendorContactIds: this.fb.array([this.createVendorContact()]),
      contactEmail: [''],
      contactNumber: [''],
      spocId: ['', Validators.required],
    });
    if (this.data.id) {
      this.service.getConsultant(this.data.id).subscribe({
          next: res => {
            this.consultantRes = res
            console.log('consultantRes:', this.consultantRes)
            this.linkedInURL = res.linkedInURL;
            if (res.linkedInURL) {
              this.updateURL = false
            }
            this.firstName.emit(res.firstName);
            this.consultantData.emit(res);
            this.consultantForm.get('id')?.setValue(res.id);
            this.consultantForm.get('firstName')?.setValue(res.firstName);
            this.consultantForm.get('middleName')?.setValue(res.middleName);
            this.consultantForm.get('lastName')?.setValue(res.lastName);
            this.consultantForm.get('title')?.setValue(res.title);
            this.consultantForm.get('dateOfBirth')?.setValue(res.dateOfBirth);
            this.consultantForm.get('panNumber')?.setValue(res.panNumber);
            this.consultantForm.get('aadharNumber')?.setValue(res.aadharNumber);
            this.consultantForm.get('linkedInURL')?.setValue(res.linkedInURL);
            this.consultantForm.get('passingYear')?.setValue(res.passingYear);
            this.consultantForm.get('experienceYear')?.setValue(res.experienceYear);
            this.consultantForm.get('experienceMonth')?.setValue(res.experienceMonth);
            this.consultantForm.get('gender')?.setValue(res.gender);
            this.consultantForm.get('dateOfJoining')?.setValue(res.dateOfJoining);
            this.consultantForm.get('location')?.setValue(res.location);
            this.consultantForm.get('contactNumber')?.setValue(res.contact);
            this.consultantForm.get('email')?.setValue(res.email);
            this.consultantForm.get('number')?.setValue(res.number?.mobile);
            this.consultantForm.get('emergencyContactNumber')?.setValue(res.emergencyContactNumber?.mobile);
            this.skills = res.skills;
            if (res.spoc?.firstName) {
              this.selectedSpcId = res.spoc?.id ?? null;
              this.consultantForm.get('spocId')?.setValue(this.selectedSpcId) ?? null;
            }
            if (res.address.country) {
              this.consultantForm.get('address')?.get('pincode')?.setValue(res.address.pincode);
              this.consultantForm.get('address')?.get('streetAddress')?.setValue(res.address.streetAddress);
              this.selectedCountry = res.address.country;
              if (res.address.country) {
                this.countries = [res.address.country];
                this.consultantForm?.get('address')?.get("country")?.get('id')?.setValue(res?.address.country.id);
                this.consultantForm?.get('address')?.get("country")?.get('name')?.setValue(res?.address.country.name);
                this.consultantForm?.get('address')?.get("country")?.get('formattedAddress')?.setValue(res?.address.country.formattedAddress);
                this.countryControl.setValue(this.selectedCountry);
              }
              this.selectedState = res.address.state;
              if (res.address.state) {
                this.states = [res.address.state];
                this.consultantForm?.get('address')?.get("state")?.get('id')?.setValue(res?.address.state.id);
                this.consultantForm?.get('address')?.get("state")?.get('name')?.setValue(res?.address.state.name);
                this.consultantForm?.get('address')?.get("state")?.get('formattedAddress')?.setValue(res?.address.state.formattedAddress);
                this.stateControl.setValue(this.selectedState);
              }
              this.selectedCity = res.address.city
              if (res.address.city) {
                this.cities = [res.address.city]
                this.consultantForm?.get('address')?.get("city")?.get('id')?.setValue(res?.address.city.id);
                this.consultantForm?.get('address')?.get("city")?.get('name')?.setValue(res?.address.city.name);
                this.consultantForm?.get('address')?.get("city")?.get('formattedAddress')?.setValue(res?.address.city.formattedAddress);
                this.cityControl.setValue(this.selectedCity);
              }
            } else {
              this.selectedCountry = {id: 101, name: "India", formatedAddress: "India"};
              this.countries = [{id: 101, name: "India", formattedAddress: "India"}];
            }
            this.vendorControl.setValue(res?.vendor);
            if (res?.passingYear) {
              this.passingYearDate = this.formatDate(res.passingYear);
            }
            this.educationControl.setValue(res?.education);
            if (res.vendor?.id) {
              this.isVendorSelected = true
              this.consultantForm.get('vendorId')?.setValue(res.vendor?.id);
              this.getVendorContacts(res.vendor.id ?? 0, 'EDIT');
            } else {
              this.isVendorSelected = false
            }
            this.getVendors();
            this.getEducationList();
            this.getPassingYearList();
            this.onSearchCountry();
            this.onSearchState();
            this.onSearchCities();
            if (res.education != null) {
              this.chooseEducationType(res.education);
            }
            if (Array.isArray(res.vendorContactDto) && res.vendorContactDto.length > 0) {
              this.chooseVendorContact(res.vendorContactDto);
            } else {
              this.chooseVendorContact(this.vendorContactIds()?.value);
            }
          },
          error: err => {
            console.error(err);
          },
          complete: () => {
            this.previousFormValue = this.consultantForm.value;
            this.initializingForm = false;
          }
        }
      )
    }
    this.consultantForm.valueChanges.pipe(
      debounceTime(500),
      filter(() => !this.initializingForm),
      map(currentValue => {
        const prevValue = JSON.parse(JSON.stringify(this.previousFormValue, (key, value) => value === undefined ? "" : value));
        const currValue = JSON.parse(JSON.stringify(currentValue, (key, value) => value === undefined ? "" : value));
        console.log('currValue', currValue);
        console.log('prevValue', prevValue);
        let prevNumber = prevValue.number;
        let currNumber = currValue.number?.number;
        if (currValue.number?.countryCode === 'IN') {
          if (prevNumber?.startsWith('0')) {
            prevNumber = prevNumber?.replace(/^0+/, '');
          }
          if (currNumber?.startsWith('0')) {
            currNumber = currNumber?.replace(/^0+/, '');
          }
          currNumber = currNumber?.replace(/\s+/g, '');
          prevNumber = prevNumber?.replace(/\s+/g, '');
        }
        let prevEmergencyNumber = prevValue.emergencyContactNumber;
        let currEmergencyNumber = currValue.emergencyContactNumber?.number;
        if (currValue.emergencyContactNumber?.countryCode === 'IN') {
          if (prevEmergencyNumber?.startsWith('0')) {
            prevEmergencyNumber = prevEmergencyNumber?.replace(/^0+/, '');
          }
          if (currEmergencyNumber?.startsWith('0')) {
            currEmergencyNumber = currEmergencyNumber?.replace(/^0+/, '');
          }
          prevEmergencyNumber = prevEmergencyNumber?.replace(/\s+/g, '');
          currEmergencyNumber = currEmergencyNumber?.replace(/\s+/g, '');
        }
        delete prevValue.number;
        delete currValue.number;
        delete prevValue.emergencyContactNumber;
        delete currValue.emergencyContactNumber;
        const isNumberUpdated = JSON.stringify(currValue) !== JSON.stringify(prevValue);
        const isEmergencyNumberUpdated = (prevNumber ?? '') !== (currNumber ?? '') || (prevEmergencyNumber ?? '') !== (currEmergencyNumber ?? '');
        return isNumberUpdated || isEmergencyNumberUpdated;
      }),
      distinctUntilChanged()
    ).subscribe((isFormUpdated: any) => {
      console.log('isFormUpdated', isFormUpdated);
      this.formUpdated.emit({updated: isFormUpdated, componentName: ComponentName.CustomerInfo});
    });
  }

  scrollEvent = (event: any): void => {
    if (this.SPOCAutoCompleter?.panelOpen) {
      this.SPOCAutoCompleter.updatePosition();
    }
  };

  createVendorContact(): FormGroup {
    return this.fb.group({
      id: [''],
      pocName: [''],
      email: [''],
      phone: new FormControl('', Validators.required)
    });
  }

  formatDate(date: string): string {
    const regex = /(\d{4})[-/](\d{1,2})|(\d{1,2})[-/](\d{4})/;
    const match = date.match(regex);
    if (match) {
      if (match[1] && match[2]) {
        return `${match[2].padStart(2, '0')}/${match[1]}`;
      } else if (match[3] && match[4]) {
        return `${match[3].padStart(2, '0')}/${match[4]}`;
      }
    }
    return date;
  }

  compareFunction(optionValue: PlaceDto, selectedValue: PlaceDto): boolean {
    return optionValue && selectedValue && optionValue === selectedValue;
  }

  onExperienceAdd(year: any, event?: any) {
    this.disableExpMonth = false;
    this.consultantForm.get('experienceMonth')?.enable();
  }

  addEducationType($event: MouseEvent) {
    if (this.educationName) {
      let payload = {
        'name': this.educationName,
      }
      this.service.addEducation(payload).subscribe(res => {
        this.isDataUpdatedService.setUpdated(true);
        this.getEducationList();
        this.educationList.push(res.id);
        this.educationName = res.name;
        this.consultantForm.get('educationId')?.setValue(res.id);
        this.displayDocFn(res);
      }, (e) => {
        console.error('error', e.error.message);
      })
    } else if (this.educationEditName) {
      let payload = {
        'name': this.educationEditName,
      }
      this.service.addOrgVertical(payload).then(res => {
        this.isDataUpdatedService.setUpdated(true);
        this.getEducationList();
        this.educationEditName = res.name;
        this.consultantForm.get('educationId')?.setValue(res.id);
        this.displayDocFn(res);
      }, (e) => {
        console.error('error', e.error.message);
      })
    }
  }

  chooseEducationType(data: any) {
    if (data.isUserInput) {
      this.consultantForm.get('educationId')?.setValue(data.source.value.id);
    } else {
      this.educationName = data;
      this.consultantForm.get('educationId')?.setValue(data.id);
    }
  }

  displayDocFn(doc: DocsType): string {
    return doc && doc.name ? doc.name : '';
  }

  goToLink(url: string) {
    window.open(url, " ");
  }

  _vendorsFilter(name: string): VendorCorp[] {
    const filterValue = name.toLowerCase();
    return this.vendorsRes.filter(option => option.vendorName.toLowerCase().includes(filterValue));
  }

  _vendorContactsFilter(name: string): VendorContact[] {
    const filterValue = name.toLowerCase();
    return this.vendorContacts.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  chooseVendor(data: any): void {
    if (data.isUserInput) {
      this.isVendorSelected = true
      this.vendorId = data?.source?.value?.id;
      this.consultantForm.get('vendorId')?.setValue(data?.source?.value?.id);
      if (this.consultantForm.get('vendorId')?.value !== '') {
        this.enableAddItem = true;
      }
      this.vendorName = data.source.value?.vendorName;
      this.pocName = '';
      this.vendorContacts = [];
      this.consultantForm.get('contactEmail')?.setValue('');
      this.consultantForm.get('contactNumber')?.setValue('');
      if (this.consultantForm.get('vendorId') == data.source.value?.id) {
        console.log('vendor id match :' + data.source.value?.id);
      } else {
        const formArray = this.vendorContactIds();
        formArray.reset();
        formArray.removeAt(1);
      }
    } else {
      this.vendorName = data?.vendorName;
      this.vendorId = data?.id;
      if (this.consultantForm.get('vendorId')?.value != this.vendorId) {
        this.consultantForm.get('vendorId')?.setValue(data?.id);
      }
    }
  }

  onVendorChange = (event: MatAutocompleteSelectedEvent) => {
    this.vendorId = event.option.value.id;
    this.getVendorContacts(event.option.value.id, 'ADD');
    this.isVendorSelected = true
  }

  getVendorContacts(vendorId: number, type: string) {
    this.service.getConsultantVendorContacts(vendorId).then(response => {
      this.errorMessage = '';
      if (response.length > 0) {
        if (this.vendorContactIds().length === 0) {
          this.addItems();
        }
        response.map((e: VendorContact) => {
          if (type != 'EDIT') {
            this.selectedVendorContactIds = [];
          }
          e.selected = false;
          this.vendorContacts.push(e);
        });
        this.filteredVendorContacts = this.vendorContactControl.valueChanges.pipe(
          startWith(''),
          map(value => {
            const name = typeof value === 'string' ? value : value?.name ?? "";
            const filteredContacts = name ? this._vendorContactsFilter(name as string) : this.vendorContacts.slice();
            if (response.length > 0) {
              return filteredContacts;
            } else {
              console.log('Sorry! there are no point of contacts for selected vendor.')
              this.errorMessage = "Sorry! there are no point of contacts for selected vendor."
              // @ts-ignore
              return empty();
            }
          })
        )
      } else {
        this.errorMessage = "Sorry! there are no point of contacts for selected vendor."
      }
    });
  }

  chooseVendorContact(vendors: VendorContact | VendorContact[], index?: number): void {
    const formArray = this.vendorContactIds();
    if (Array.isArray(vendors)) {
      while (this.vendorContactIds().length !== 0) {
        this.vendorContactIds().removeAt(0);
      }
    }
    if (vendors && !Array.isArray(vendors) && typeof index === 'number') {
      if (formArray.controls.length) {
        const formGroup = formArray.at(index) as FormGroup;
        if (formGroup) {
          const emailControl = formGroup.get('email'),
            pocNameControl = formGroup.get('pocName'),
            mobileControl = formGroup.get('phone'),
            userIDControl = formGroup.get('id');
          if (emailControl && mobileControl && userIDControl && pocNameControl) {
            const previousVendorId = userIDControl.value;
            if (previousVendorId) {
              this.selectedVendorContactIds = this.selectedVendorContactIds.filter(id => id !== previousVendorId);
            }
            userIDControl.setValue(vendors?.id);
            pocNameControl.setValue(vendors?.name);
            mobileControl.setValue(vendors?.phone?.mobile);
            mobileControl.disable();
            emailControl.setValue(vendors?.email);
            if (!this.selectedVendorContactIds.includes(vendors?.id)) {
              this.selectedVendorContactIds.push(vendors?.id);
            }
          }
        }
      }
    } else if (Array.isArray(vendors)) {
      vendors.forEach(vendorContact => {
        const contactGroup = this.fb.group({
          id: [vendorContact?.id],
          pocName: [vendorContact?.name],
          email: [vendorContact?.email],
          phone: [{value: vendorContact?.phone?.mobile, disabled: true}]
        });

        if (!this.selectedVendorContactIds.includes(vendorContact?.id)) {
          this.selectedVendorContactIds.push(vendorContact?.id);
        }

        formArray.push(contactGroup);
      });
    }
  }

  vendorContactIds(): FormArray {
    return this.consultantForm.get("vendorContactIds") as FormArray;
  }

  addItems(): void {
    const contactGroup = this.fb.group({
      id: [''],
      pocName: [''],
      email: [''],
      phone: ['']
    });
    this.vendorContactIds().push(contactGroup);
  }

  removeItems(index: number, data: any): void {
    const formArray = this.vendorContactIds();
    const removedVendorContact = this.vendorContacts[index];
    if (removedVendorContact) {
      removedVendorContact.selected = false;
    }
    formArray.removeAt(index);
    this.selectedVendorContactIds = this.selectedVendorContactIds.filter((e, i) => e !== data.value.id);
  }

  displayVendorFn(doc: VendorCorp): string {
    return doc && doc.vendorName ? doc.vendorName : '';
  }

  getVendors() {
    this.service.getVendorForConsultant().then(response => {
      response.map((e: VendorCorp) => {
        this.vendorsRes.push(e);
      });
      this.filteredVendors = this.vendorControl.valueChanges.pipe(
        startWith(''),
        map(value => {
            const name = typeof value === 'string' ? value : value?.vendorName ?? "";
            return name ? this._vendorsFilter(name as string) : this.vendorsRes.slice();
          }
        ),
      )
    });
  }

  getEducationList() {
    this.service.getEducationListForConsultants().subscribe({
      next: response => {
        this.educationList = response;
        this.filteredEducation = this.educationControl.valueChanges.pipe(
          startWith(''),
          map(value => {
              const name = typeof value === 'string' ? value.trim() : value?.name.trim() ?? "";
              return name ? this._eduFilter(name as string) : this.educationList.slice();
            }
          ),
        )
      }
    });
  }

  _eduFilter(name: string): Education[] {
    const filterValue = name.toLowerCase();
    return this.educationList.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  getPassingYearList() {
    this.service.getPassingYearListForConsultants().subscribe({
      next: response => {
        this.passingYearList = response;
      }
    })
  }

  updatedPayload() {
    let newPayload = this.consultantForm.value;
    const dateOfBirth = this.consultantForm.get('dateOfBirth')?.value || null;
    const formattedDateOfBirth = this.datePipe.transform(dateOfBirth, 'yyyy-MM-dd');
    newPayload.dateOfBirth = formattedDateOfBirth;
    let selectedSkills: number[] = [];
    newPayload.vendorContactIds = this.selectedVendorContactIds.filter(id => String(id).trim() !== '');
    let number = null;
    let emergencyContactNumber = null;
    if (this.consultantForm?.value?.number) {
      number = {
        countryCode: this.consultantForm.value.number.countryCode,
        dialCode: this.consultantForm.value.number.dialCode,
        e164Number: this.consultantForm.value.number.e164Number,
        internationalNumber: this.consultantForm.value.number.internationalNumber,
        nationalNumber: this.consultantForm.value.number.nationalNumber,
        mobile: this.consultantForm.value.number.number,
      };
    }
    if (this.consultantForm?.value?.emergencyContactNumber) {
      emergencyContactNumber = {
        countryCode: this.consultantForm.value.emergencyContactNumber.countryCode,
        dialCode: this.consultantForm.value.emergencyContactNumber.dialCode,
        e164Number: this.consultantForm.value.emergencyContactNumber.e164Number,
        internationalNumber: this.consultantForm.value.emergencyContactNumber.internationalNumber,
        nationalNumber: this.consultantForm.value.emergencyContactNumber.nationalNumber,
        mobile: this.consultantForm.value.emergencyContactNumber.number,
      };
    }
    if (number) {
      newPayload = {...newPayload, number};
    }
    if (emergencyContactNumber) {
      newPayload = {...newPayload, emergencyContactNumber};
    }
    newPayload = {...newPayload, skills: selectedSkills};
    return newPayload;
  }

  validateForm(): Promise<void> {
    console.log('form', this.consultantForm)
    return new Promise((resolve, reject) => {
      let hasErrors = false;
      Object.values(this.consultantForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsTouched();
          control.setErrors({invalid: true});
          this.isSubmitting = true
          hasErrors = true;
        }
      });
      [this.educationControl, this.vendorControl].forEach(control => {
        if (control.invalid) {
          control.markAsTouched();
          control.setErrors({invalid: true});
          hasErrors = true;
        }
      });
      if (hasErrors) {
        if (this.consultantForm.invalid) {
          this.showLoader = false;
          this.toastr.error('Please fill the required fields before submitting the form.');
          return;
        }
        this.showLoader = false;
        reject();
      } else {
        resolve();
      }
    });
  }

  updateCandidate(): void {
    this.consultantError = '';
    this.showLoader = true;
    this.isSubmitting = true;
    console.log('error',this.consultantForm.getError('maxCharLimit'))
    {
      this.validateForm().then(() => {
        if (this.data?.id) {
          this.service.updateConsultant(this.data?.id, this.updatedPayload()).subscribe({
            next: (res) => {
              this.isDataUpdatedService.setUpdated(true);
              this.showLoader = false;
              this.linkedInURL = res.linkedInURL;
              this.updateURL = false;
              this.formUpdated.emit({
                updated: false,
                componentName: ComponentName.ConsultantInfo,
                consultheaderDetails: {
                  firstName: res.firstName,
                  middleName: res.middleName ?? '',
                  lastName: res.lastName,
                  experienceYear: res.experienceYear,
                  experienceMonth: res.experienceMonth,
                  occupation: res.title,
                  venderName: (res.vendor && res.vendor?.vendorName) ? res.vendor.vendorName : '-'
                }
              });
              this.errorMessage = '';
              this.errorMessageEmergencyPhone = '';
              setTimeout(() => {
                this.showLoader = false;
                this.toastr.success('Consultant Information Saved Successfully', 'Success');
              }, 600);
              this.formUpdated.emit({updated: false, componentName: ComponentName.CustomerInfo});
              this.isSubmitting = false;
            },
            error: err => {
              console.error('err', err);
              this.toastr.error(err.value, 'Request Failed');
              this.showLoader = false;
              this.isSubmitting = false;
            },
            complete: () => {
              // this.dialogRef.close();
            }
          })
        }
      })
    }
  }

  close() {
    this.dialogRef.close();
  }

  onSearchCountry() {
    this.countryServerCtrl.valueChanges.pipe(
      filter(search => !!search),
      tap(() => this.searchCountry = true),
      takeUntil(this._onDestroy),
      debounceTime(500),
      distinctUntilChanged(),
      map(search => {
        return this.getCountries(search)
      }),
      delay(500),
    ).subscribe({
      next: filteredCountries => {
        this.searchCountry = false;
      }, error: err => {
        this.searchCountry = false;
      }
    });
  }

  getCountries(query: string): void {
    this.service.getCountries(query).then((res: any) => {
      this.countries = this.selectedCountry ? [this.selectedCountry, ...res] : res;
      return (res);
    });
  }

  selectCountry(country: PlaceDto): void {
    this.consultantForm?.get("address")?.get("country")?.setValue(country);
    this.selectedState = undefined;
    this.selectedCity = undefined;
    this.selectedCountry = country;
    this.consultantForm?.get("address")?.get("state")?.reset();
    this.consultantForm?.get("address")?.get("city")?.reset();
  }

  onSearchState() {
    this.stateServerCtrl.valueChanges.pipe(
      filter(search => !!search),
      tap(() => this.searchState = true),
      takeUntil(this._onDestroy),
      debounceTime(500),
      distinctUntilChanged(),
      map(search => {
        return this.getStates(search)
      }),
      delay(500),
    ).subscribe({
      next: filteredCountries => {
        this.searchState = false;
      }, error: err => {
        this.searchState = false;
      }
    });
  }

  getStates(query: string): void {
    this.service.getStates(this.consultantForm?.get("address")?.get("country")?.get('id')?.value, query).then(res => {
      this.states = this.selectedState ? [this.selectedState, ...res] : res;
    });
  }

  selectState(state: PlaceDto): void {
    this.consultantForm?.get("address")?.get("state")?.get('id')?.setValue(state.id);
    this.consultantForm?.get("address")?.get("state")?.get('name')?.setValue(state.name);
    this.consultantForm?.get("address")?.get("state")?.get('formattedAddress')?.setValue(state.formattedAddress);
    this.selectedState = state;
    this.selectedCity = undefined;
    this.consultantForm?.get("address")?.get("city")?.reset();
  }

  onSearchCities() {
    this.cityServerCtrl.valueChanges.pipe(
      filter(search => !!search),
      tap(() => this.searchCity = true),
      takeUntil(this._onDestroy),
      debounceTime(500),
      distinctUntilChanged(),
      map(search => {
        return this.getCities(search)
      }),
      delay(500),
    ).subscribe({
      next: filteredCountries => {
        this.searchCity = false;
      }, error: err => {
        this.searchCity = false;
      }
    });
  }

  getCities(query: string): void {
    this.service.getCities(this.consultantForm?.get("address")?.get("state")?.get('id')?.value, query).then(res => {
      this.cities = this.selectedCity ? [this.selectedCity, ...res] : res;
    });
  }

  selectCity(city: PlaceDto): void {
    this.consultantForm?.get("address")?.get("city")?.setValue(city);
  }

  updateUrl() {
    this.updateURL = !this.updateURL;
  }

  handleDateChange($event: any) {
    this.dateChanged = $event
    this.consultantForm.get('passingYear')?.setValue(this.dateChanged);
  }

  getFormControl<T>(controlName: string): any {
    return this.consultantForm.get(controlName);
  }

  getFormControlForPhone(controlName: string, index: number): FormControl {
    return this.vendorContactIds().at(index).get(controlName) as FormControl;
  }

  MaxCharLimits = MaxCharLimits;
}
