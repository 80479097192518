import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../../../services/api.service";
import {ToastrService} from "ngx-toastr";
import {IsDataUpdatedMembersService} from "../../../../services/isDataUpdatedMembers.service";
import {MaxCharLimits} from "../../../../common/errorInputMaxValue";

@Component({
  selector: 'app-create-member',
  templateUrl: './create-member.component.html',
  styleUrls: ['./create-member.component.css']
})
export class CreateMemberComponent implements OnInit {
  memberForm: FormGroup
  showloader = false;
  errorMessage = '';
  userId = '';
  openSalesDropdown = false;
  type = '';
  currentRole: string = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private service: ApiService,
    private dialogRef: MatDialogRef<CreateMemberComponent>,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private isMembersDataUpdated: IsDataUpdatedMembersService,
  ) {
  }

  ngOnInit(): void {
    this.userId = this.data.userId;
    this.memberForm = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["", Validators.pattern('[a-zA-Z0-9._%+-]+@[a-z0-9A-Z.-]+.[a-z]{2,}$')],
      role: "",
      number: [""],
    })
    if (this.data.userId) {
      this.type = 'edit';
      this.service.getMemberById(this.data.userId).then(res => {
        this.currentRole = res?.role;
        this.memberForm.get("firstName")?.setValue(res.firstName);
        this.memberForm.get("lastName")?.setValue(res.lastName);
        this.memberForm.get("email")?.setValue(res.email);
        this.memberForm.get("role")?.setValue(res.role);
        this.memberForm.get("number")?.setValue(res?.number?.mobile);
      })
    } else {
      this.type = 'add';
    }
  };

  addMember(): void {
    if (this.memberForm.invalid) {
      return;
    }
    this.showloader = true;
    let payload = {
      ...this.memberForm.value,
      currentRole: this.currentRole,
      number: {
        countryCode: this.memberForm?.value?.number?.countryCode,
        dialCode: this.memberForm?.value?.number?.dialCode,
        e164Number: this.memberForm?.value?.number?.e164Number,
        internationalNumber: this.memberForm?.value?.number?.internationalNumber,
        nationalNumber: this.memberForm?.value?.number?.nationalNumber,
        mobile: this.memberForm?.value?.number?.number,
      },
    }
    if (this.data.userId) {
      this.service.updateMember(payload, this.data.userId).then(res => {
        this.isMembersDataUpdated.setUpdated(true);
        setTimeout(() => {
          this.toastr.success('Member Update Successfully', 'Success', {
            positionClass: "toast-top-right"
          });
          // this.showloader = false;
          this.dialogRef.close()
        }, 600)
      }, (e) => {
        this.showloader = false;
        this.toastr.error(e.error.message);
      });
    } else {
      this.service.addMember(payload).then(res => {
        this.isMembersDataUpdated.setUpdated(true);
        this.showloader = true;
        this.toastr.success('Member Created Successfully', 'Success', {
          positionClass: "toast-top-right"
        });
        setTimeout(() => {
          this.dialogRef.close()
        }, 600)
      }, (e) => {
        this.showloader = false;
        this.toastr.error(e.error.message);
      });
      this.showloader = false;
    }
  }

  onRoleChange(role: string): void {
    if (role === 'SALES') {
      this.openSalesDropdown = true
    } else {
      this.openSalesDropdown = false;
    }
  }

  onSaleRoleChange(event: any): void {
    const selectedOption = event.value || event;

    if (this.memberForm.value.role === 'SALES') {
      this.memberForm.patchValue({role: selectedOption});
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  getFormControl<T>(controlName: string): any {
    return this.memberForm.get(controlName);
  }

    MaxCharLimits = MaxCharLimits;
}
