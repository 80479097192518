import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ApiService} from "../../../../services/api.service";
import {MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {IsDataUpdatedService} from "../../../../services/isDataUpdated.service";
import {PopUpComponent} from "../../../../util/pop-up/pop-up.component";

@Component({
  selector: 'app-consultant-settings',
  templateUrl: './consultant-settings.component.html',
  styleUrls: ['./consultant-settings.component.css']
})
export class ConsultantSettingsComponent implements OnInit {
  @Input() createConsultantImage: string;
  @Input() consultantData: any;
  @ViewChild('fileInput') fileInput: ElementRef<HTMLDivElement>;
  @Output() consultantImageChange = new EventEmitter<string>();
  file: File | null;
  logoLoader = false;
  type = '';
  consultantImage: string;
  pictureStatus: boolean;
  consultantStatus: boolean;
  consultantId: number;
  showLoader = false;
  customerLogoVisible: boolean;
  featured: boolean = false;
  errorUploadingImage: boolean = false;

  constructor(
    private service: ApiService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private isDataUpdatedService: IsDataUpdatedService,
  ) {

  }

  ngOnInit(): void {
    if (this.consultantData?.id || this.consultantData?.consultantData?.id) {
      this.service.getConsultant((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id).subscribe({
          next: res => {
            this.consultantImage = res?.profilePicture ?? '';
            this.customerLogoVisible = false;
            this.featured = res.featured
          },
          error: err => {
            console.error(err);
          },
        }
      )
    }
    if (this.consultantData?.consultantData) {
      this.consultantImage = this.consultantData.consultantData.profilePicture || null;
      this.pictureStatus = this.consultantData.consultantData.showProfilePicture;
      this.consultantStatus = this.consultantData.consultantData.active;
      this.updateImageSource();
    }
  }

  private updateImageSource() {
    if (this.consultantImage && this.consultantImage != '') {
      this.consultantImageChange.emit(this.consultantImage);
    } else {
      this.consultantImageChange.emit(this.consultantImage);
    }
  }

  toggleProfilePictureStatus(event: any) {
    if (event.checked) {
      this.service.displayConsultantProfilePicture((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id).subscribe((res => {
        this.pictureStatus = true;
        this.isDataUpdatedService.setUpdated(true);
        this.toastr.success('Consultant Profile Enabled At Talent-pool ', 'Success');
      }), (e: any) => {
        console.log(e);
        this.toastr.error(e.message, 'Request Failed');
      });
    } else {
      this.service.hideConsultantProfilePicture((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id).subscribe((res => {
        this.pictureStatus = false;
        this.isDataUpdatedService.setUpdated(true);
        this.toastr.success('Consultant profile Disabled At Talent-pool ', 'Success');
      }), (e: any) => {
        this.toastr.error(e.message, 'Request Failed');
      });
    }
  }

  toggleActiveConsultant(event: any) {
    if (event.checked) {
      this.service.switchConsultantStatus((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id).subscribe({
        next: res => {
          this.consultantStatus = res.active
          this.isDataUpdatedService.setUpdated(true);
          this.toastr.success('Consultant activated', 'Success');
        }
      }), (e: any) => {
        this.toastr.error(e.message, 'Request Failed');
      }
    } else {
      this.service.switchConsultantStatus((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id).subscribe({
        next: res => {
          this.consultantStatus = res.active
          this.isDataUpdatedService.setUpdated(true);
          this.toastr.success('Consultant de-activated ', 'Success');
        }
      }), (e: any) => {
        this.toastr.error(e.message, 'Request Failed');
      }
    }
  }

  toggleFeatureConsultant(event: any) {
    if (event.checked) {
      this.service.featureConsultant((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id).subscribe({
        next: res => {
          this.toastr.success('Consultant has been successfully featured.', 'Success');
        }
      }), (e: any) => {
        this.toastr.error(e.message, 'Request Failed');
      }
    } else {
      this.service.unFeatureConsultant((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id).subscribe({
        next: res => {
          this.toastr.success('Consultant has been successfully unFeatured.', 'Success');
        }
      }), (e: any) => {
        this.toastr.error(e.message, 'Request Failed');
      }
    }
  }

  handleFileChange(event: File | null) {
    if (!event) {
      return;
    }
    this.logoLoader = true;
    const formData = new FormData();
    formData.append('file', event, event.name);
    this.service.uploadConsultantProfilePicture(formData, this.consultantData?.consultantData?.id || this.consultantData?.id).subscribe({
      next: (res) => {
        this.isDataUpdatedService.setUpdated(true);
        this.consultantImage = res.body?.url;
        this.consultantImageChange.emit(this.consultantImage);
        this.errorUploadingImage = false;
      },
      error: (e) => {
        console.error('Request Failed:', e.error.message);
        this.toastr.error(e.error.message, 'Request Failed');
        this.errorUploadingImage = true;
      },
      complete: () => {
        this.logoLoader = false;
      }
    });
  }

  handleProfileDelete(event: boolean): void {
    if (event) {
      const dialogRef2 = this.dialog.open(PopUpComponent, {
        data: {
          title: 'Alert',
          message: 'Are you sure you want to remove picture?',
          type: 'confirmation'
        }
      });
      dialogRef2.afterClosed().subscribe((res: boolean) => {
        if (res) {
          this.service.removeProfilePicture((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id).subscribe({
            next: (res) => {
              this.isDataUpdatedService.setUpdated(true);
              this.consultantImage = '';
              this.customerLogoVisible = false;
              this.consultantImageChange.emit('');
            }, error: (err) => {
              this.toastr.error('Error', err.error.message);
            },
            complete: () => {
            }
          })
        }
      })
    }
  }

}
