<div class="content-wrapper">
  <div class="pb-2">
    <h6>Technology</h6>
  </div>
  <form [formGroup]="technologyForm" (submit)="onSubmit()">
    <app-expandable-texteditor
      label="Compliance"
      [formControl]="getFormControl('compliance')"
      placeholder="Enter compliance details"
    required
    >
    </app-expandable-texteditor>

    <div>
      <div *ngIf="(filteredSkills|async)?.length !== 0 && !exactMatchFound && techStack.value"
           class="dropdown-skills position-relative mt-2">
        <ul>
          <li class="font-weight-bold">
            <div class="d-flex justify-content-between align-items-center" (click)="addSkillType('primary')">
              <span>Add this Skill to Skill Types</span>
              <button class="p-0 bg-transparent border-0 fs-20"> +</button>
            </div>
          </li>
        </ul>
      </div>
      <mat-form-field appearance="outline" class="w-100 border-red w-100 skills_drop">
        <mat-label>Tech-Stack Used *</mat-label>
        <mat-chip-list #chipList aria-label="skill selection" [formControl]="techStack">
          <mat-chip
            *ngFor="let skill of selectedSkill"
            (removed)="removeSkill(skill)">
            {{ skill.title }}
            <button matChipRemove><span>&#x2716;</span></button>
          </mat-chip>
          <input #skillsAutoCompleter
                 placeholder="Add your skills..."
                 [formControl]="techStack"
                 [matAutocomplete]="auto"
                 [matChipInputFor]="chipList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 #autoTrigger='matAutocompleteTrigger'
                 (click)="openAutocomplete(autoTrigger)"
                 (blur)="hideAutoComplete(autoTrigger)"
          >
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectSkills($event)">
          <mat-option *ngFor="let skill of filteredSkills | async" [value]="skill"
                      [disabled]="disableSkill(skill)">
            {{ skill.title }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <div *ngIf="(filteredSkills|async)?.length == 0" class="dropdown-skills position-relative">
        <ul>
          <li class="li_disabled">
            No match Found
          </li>
          <hr class="m-0 p-0">
          <li class="font-weight-bold">
            <div class="d-flex justify-content-between align-items-center" (click)="addSkillType('primary')">
              <span>Add this Skill to Skill Types</span>
              <button class="p-0 bg-transparent border-0 fs-20"> +</button>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div>
      <div>
        <mat-radio-group formControlName="inProduction" class="d-flex align-items-center" required>
          <mat-label class="pe-2 pb-2">In Production:</mat-label>
          <mat-radio-button value="FULL" class="pe-4">Full</mat-radio-button>
          <mat-radio-button value="PARTIAL" class="pe-4">Partial</mat-radio-button>
          <mat-radio-button value="NONE" class="pe-4">None</mat-radio-button>
        </mat-radio-group>
      </div>
      <app-expandable-texteditor
        label="In Production Description"
        placeholder="In Production Description"
        [formControl]="getFormControl('productionDescription')"
        required
      >
      </app-expandable-texteditor>
    </div>

    <div>
      <mat-radio-group formControlName="integration" class="d-flex align-items-center" required>
        <mat-label class="pe-2 pb-2">Integration:</mat-label>
        <mat-radio-button [value]="true" class="pe-4">Yes</mat-radio-button>
        <mat-radio-button [value]="false" class="pe-4">No</mat-radio-button>
      </mat-radio-group>
      <app-expandable-texteditor
        label="Integration Description"
        placeholder="Enter integration description"
        [formControl]="getFormControl('integrationDescription')"
        required
      >
      </app-expandable-texteditor>
      <div *ngIf="this.technologyForm.get('integrationDescription')?.hasError('maxCharLimit')">
        Character limit exceeded
      </div>
    </div>

    <div class="row d-flex align-items-center">
      <div class="col-6">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Solution Type</mat-label>
          <mat-select formControlName="solutionType" required>
            <mat-option value="SAAS_MODEL">SAAS_MODEL</mat-option>
            <mat-option value="DESKTOP_BASED">DESKTOP_BASED</mat-option>
            <mat-option value="WEB_BASED">WEB_BASED</mat-option>
            <mat-option value="CLOUD_BASED">CLOUD_BASED</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="d-flex align-items-center col-6 pb-3">
        <mat-radio-group formControlName="whiteLabled" required>
          <mat-label class="pe-2 pb-2">White Labled:</mat-label>
          <mat-radio-button value="FULL" class="pe-4">Full</mat-radio-button>
          <mat-radio-button value="PARTIAL" class="pe-4">Partial</mat-radio-button>
          <mat-radio-button value="NONE" class="pe-4">None</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div>
      <mat-radio-group formControlName="sellingModel" class="d-flex align-items-center" required>
        <mat-label class="pe-2 pb-2">Selling model:</mat-label>
        <mat-radio-button value="SAAS" class="pe-4">SAAS model</mat-radio-button>
        <mat-radio-button value="ONE_TIME_SELLING" class="pe-4">One Time Selling model</mat-radio-button>
      </mat-radio-group>
      <app-expandable-texteditor
        label="Selling Model Description"
        placeholder="Enter selling model description"
        [formControl]="getFormControl('sellingModelDescription')"
        required
      >
      </app-expandable-texteditor>
    </div>

    <div>
      <mat-radio-group formControlName="demoReady" class="d-flex align-items-center" required>
        <mat-label class="pe-2 pb-2">Demo Ready:</mat-label>
        <mat-radio-button [value]="true" class="pe-4">Yes</mat-radio-button>
        <mat-radio-button [value]="false" class="pe-4">No</mat-radio-button>
      </mat-radio-group>

      <app-expandable-texteditor
        label="Demo Ready Description"
        [formControl]="getFormControl('demoReadyDescription')"
        placeholder="Enter demo ready description"
        required
      >
      </app-expandable-texteditor>
    </div>

    <app-expandable-texteditor
      label="Implementation Timeframe"
      [formControl]="getFormControl('implementationTimeframe')"
      placeholder="Enter implementation timeframe"
      required
    >
    </app-expandable-texteditor>

    <app-expandable-texteditor
      label="Effort involved"
      [formControl]="getFormControl('effortInvolved')"
      placeholder="Effort involved"
      required
    >
    </app-expandable-texteditor>

    <app-expandable-texteditor
      label="Pricing Details"
      placeholder="Pricing Details"
      [formControl]="getFormControl('pricingDetails')"
      required
    >
    </app-expandable-texteditor>

    <div class="d-flex align-items-center justify-content-end">
      <button *ngIf="!showloader" mat-raised-button class="theme-bg-color text-white">Save Details</button>
      <div *ngIf="showloader" class="d-flex align-items-center justify-content-end">
        <button mat-raised-button class="theme-bg-color text-white">
          <div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only"></span>
          </div>
          Save Details
        </button>
      </div>
    </div>

  </form>
</div>
