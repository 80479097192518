import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../../../../services/api.service";
import {ToastrService} from "ngx-toastr";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Observable} from "rxjs/internal/Observable";
import {customerContact, DocsType, DocType, userDT} from "../../../../../shared/interfaces";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {IsDataUpdatedService} from "../../../../../services/isDataUpdated.service";
import {IsDataUpdatedForModalsService} from "../../../../../services/isDataUpdatedForModals.service";
import {MaxCharLimits} from "../../../../../common/errorInputMaxValue";

@Component({
  selector: 'app-edit-customer-contact',
  templateUrl: './edit-customer-contact.component.html',
  styleUrls: ['./edit-customer-contact.component.css']
})

export class EditCustomerContactComponent implements OnInit {
  customerContactForm: FormGroup;
  filteredDesignation: Observable<DocsType[]>;
  showloader = false;
  desTypeName = '';
  verticalName: '';
  @ViewChild('designationAutoCompleter', {read: MatAutocompleteTrigger})
  designationAutoCompleter: MatAutocompleteTrigger;
  @ViewChild('verticalAutoCompleter', {read: MatAutocompleteTrigger})
  verticalAutoCompleter: MatAutocompleteTrigger;
  designationId: '';
  verticalId: '';
  desEditTypeName = '';
  verticalEditName = '';
  designationRes: DocType[] = [];
  verticalList: any[] = [];
  SPOC_Options: userDT[] = [];
  selectedSpcId: string;
  errorMessage = '';
  showArchived: boolean = false;
  displayError: boolean = false;
  totalPages = 0;
  currentPage: number = 0;
  setVertical: DocType[];
  setDesignation: DocType[];
  setSpoc: userDT[];
  contactDT: customerContact;
  role: string | null;
  emailStatus: boolean = false

  constructor(
    @Inject(MAT_DIALOG_DATA) public customerContactData: any,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private service: ApiService,
    private toastr: ToastrService,
    public snackBar: MatSnackBar,
    public editCustomerDialogueRef: MatDialogRef<EditCustomerContactComponent>,
    private isCustomerDataUpdated: IsDataUpdatedService,
    private IsDataUpdatedForModal: IsDataUpdatedForModalsService
  ) {
  }

  ngOnInit(): void {
    console.log('(this.customerContactData', this.customerContactData);
    this.role = localStorage.getItem('role');
    this.getSPOC();
    this.getVerticalList();
    this.getDesignation();
    // this.getContacts(0,this.showArchived);

    this.customerContactForm = this.fb.group({
      firstName: ["", Validators.required],
      lastName: [""],
      email: ["", [Validators.required, Validators.pattern("[a-zA-Z0-9._%+-]+@[a-z0-9A-Z.-]+.[a-z]{2,}$")]],
      number: [""],
      designation: [''],
      linkedIn: [''],
      vertical: [''],
      spoc: [''],
      spocId: [''],
      active: [''],
    });

    if (this.customerContactData.customerContact.id) {
      this.contactDT = this.customerContactData.customerContact;
      this.customerContactForm.get('firstName')?.setValue(this.contactDT?.firstName);
      this.customerContactForm.get('lastName')?.setValue(this.contactDT?.lastName);
      this.customerContactForm.get('email')?.setValue(this.contactDT.email);
      this.customerContactForm.get('number')?.setValue(this.contactDT?.number?.mobile);
      this.customerContactForm.get('linkedIn')?.setValue(this.contactDT.linkedIn);
    }
  }

  change(e: any) {
    if (e.checked) {
      this.emailStatus = true;
    } else {
      this.emailStatus = false;
    }
  }

  onKeyPress(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    const numericValue = inputValue.replace(/\D/g, '');
    if (numericValue.length === 10) {
      event.preventDefault();
      this.displayError = false;
      this.errorMessage = "";
    } else {
      this.displayError = true;
      this.errorMessage = "Please enter a valid 10-digit mobile number";
    }
    inputElement.value = numericValue;
  }

  getFormValidationErrors(): string {
    let error = '';
    Object.keys(this.customerContactForm.controls).forEach(key => {
      const controlErrors = this.customerContactForm.get(key)?.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          // console.log(' Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          error = error + key + ', ';
        });
      }
    });
    return error;
  }

  updatedPayload() {
    return {
      ...this.customerContactForm.value,
      number: {
        countryCode: this.customerContactForm?.value?.number?.countryCode,
        dialCode: this.customerContactForm?.value?.number?.dialCode,
        e164Number: this.customerContactForm?.value?.number?.e164Number,
        internationalNumber: this.customerContactForm?.value?.number?.internationalNumber,
        nationalNumber: this.customerContactForm?.value?.number?.nationalNumber,
        mobile: this.customerContactForm?.value?.number?.number,
      }
    }
  }

  updateContact() {
    this.errorMessage = '';
    if (this.customerContactForm.invalid) {
      this.showloader = false;
      this.errorMessage = 'please check the fields ' + this.errorMessage , this.getFormValidationErrors() + " ";
      return;
    }
    this.service.updateCustomerContact(this.updatedPayload(), this.contactDT.id).subscribe(
      (res) => {
        this.isCustomerDataUpdated.setUpdated(true);
        this.IsDataUpdatedForModal.setUpdated(true);
        this.toastr.success('Customer contact updated successfully.', 'Success');
        this.editCustomerDialogueRef.close();
        this.errorMessage = '';
      },
      (error) => {
        if (error.error && error.error.code === 4000) {
          this.toastr.error('Sorry! Customer contact with this email already exists.', 'Error');
        } else if (error.error.status == 500) {

          this.errorMessage = error.error.message;
        } else if (error.error.status == 400) {
          this.errorMessage = error.error.message;
        }
        this.editCustomerDialogueRef.close();
      }
    );
  }

  getSPOC() {
    this.service.getSpocList().subscribe((res: any) => {
      this.SPOC_Options = res;
      if (this.SPOC_Options.length > 0) {
        console.log(" spoc data : ", this.contactDT.spoc.id);
        console.log(" spoc data : ", this.SPOC_Options);
        this.setSpoc = this.SPOC_Options.filter(option => option.userId == this.contactDT.spoc.id);
        if (this.setSpoc) {
          const existingSpoc = {
            userId: this.setSpoc[0].userId ?? '',
            role: this.setSpoc[0].role ?? '',
            firstName: this.setSpoc[0].firstName ?? '',
            lastName: this.setSpoc[0].lastName ?? '',
          };
          console.log(" spoc data : ", existingSpoc);
          this.customerContactForm.get('spoc')?.setValue(existingSpoc);
          this.customerContactForm.get('spocId')?.setValue(existingSpoc.userId)
        } else {
          console.error('spoc option not found:', this.contactDT.spoc);
        }
      }
    });
  }

  compareSpoc(contact1: any, contact2: any): boolean {
    return contact1 && contact2 ? contact1.userId === contact2.userId : contact1 === contact2;
  }

  chooseSpoc(event: any) {
    console.log('SpocId', event);
    this.selectedSpcId = event?.source?.value?.userId
    console.log('spocId', event?.source?.value?.userId);
    this.customerContactForm.get('spocId')?.setValue(event?.source?.value?.userId)
  }

  getDesignation(): void {
    this.service.getDesignation().then(resp => {
      resp.map((e: DocType) => {
        if (e.active == true) {
          this.designationRes.push(e);
        }
      });

      if (this.designationRes.length > 0) {
        this.setDesignation = this.designationRes.filter(option => option.name === this.contactDT.designation);
        if (this.setDesignation) {
          this.customerContactForm.get('designation')?.setValue(this.setDesignation[0]?.name);
        } else {
          console.error('Designation option not found:', this.contactDT.designation);
        }
      }
    });
  }

  getVerticalList() {
    this.service.getVerticalListForCustomerContacts().subscribe({
      next: response => {
        response.map((e: any) => {
          if (e.active == true) {
            this.verticalList.push(e);
          }
        });
        console.log('list :', this.verticalList);

        if (this.verticalList.length > 0) {
          this.setVertical = this.verticalList.filter(option => option.name === this.contactDT.vertical);
          if (this.setVertical) {
            this.customerContactForm.get('vertical')?.setValue(this.setVertical[0]?.name);
          } else {
            console.error('vertical option not found:', this.contactDT.vertical);
          }
        }
      }
    })
  }

  addDesType($event: MouseEvent) {
    if (this.desTypeName) {
      let payload = {
        'name': this.desTypeName,
      }
      this.service.addOrgDesType(payload).then(res => {
        this.isCustomerDataUpdated.setUpdated(true);
        this.IsDataUpdatedForModal.setUpdated(true);
        this.getDesignation();
        this.desTypeName = res.name;
        this.customerContactForm.get('designation')?.setValue(res.name);
        this.displayDocFn(res);
      }, (e) => {
        console.log('error', e.error.message);
      })
    } else if (this.desEditTypeName) {
      let payload = {
        'name': this.desEditTypeName,
      }
      this.service.addOrgDesType(payload).then(res => {
        this.isCustomerDataUpdated.setUpdated(true);
        this.IsDataUpdatedForModal.setUpdated(true);
        this.getDesignation();
        this.desEditTypeName = res.name;
        this.customerContactForm.get('designation')?.setValue(res.name);
        this.displayDocFn(res);
      }, (e) => {
        console.log('error', e.error.message);
      })
    }
  }

  chooseDesType(data: any, group?: any) {
    if (data.isUserInput && !group) {
      this.designationId = data?.source?.value?.id;
      this.desTypeName = data.source.value?.name;
      this.customerContactForm.get('designation')?.setValue(data.source.value.name);
    } else if (data.isUserInput && group) {
      this.designationId = data?.source?.value?.id;
      this.desEditTypeName = data.source.value?.name;
      group.get('designation')?.setValue(data.source.value.name);
    }
  }

  chooseVerticalType(data: any, group?: any) {
    if (data.isUserInput && !group) {
      this.verticalId = data?.source?.value?.id;
      this.verticalName = data.source.value?.name;
      this.customerContactForm.get('vertical')?.setValue(data.source.value.name);
    } else if (data.isUserInput && group) {
      this.verticalId = data?.source?.value?.id;
      this.verticalEditName = data.source.value?.name;
      group.get('vertical')?.setValue(data.source.value.name);
    }
  }

  displayDocFn(doc: DocsType): string {
    return doc && doc.name ? doc.name : '';
  }

  closeEditCustomer() {
    console.log('Child edit customer close clicked');
    // this.dialog.open(PopUpComponent, {
    //   data: {
    //     message: 'Do you want to close this without saving',
    //     type: 'confirmation'
    //   }
    // });
    this.editCustomerDialogueRef.close();
  }

  getFormControl<T>(controlName: string): any {
    return this.customerContactForm.get(controlName);
  }

  MaxCharLimits = MaxCharLimits;
}
