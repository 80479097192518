<div class="content-wrapper">
  <h4 class="ms-3">Dashboard</h4>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-3 col-md-3 mt-4 mb-4">
        <div class="card border-0">
          <div class="card-body">
            <h6>CONSULTANTS</h6>
            <hr>
            <canvas id="pie-chart-Consultants" width="800" height="235"></canvas>
            <div id="total-consultants-container" class="total-container">
              <span id="total-consultants" class="total-count"></span>
            </div>
            <div class="total-container"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 mt-4 mb-4">
        <div class="card border-0">
          <div class="card-body">
            <h6>VENDORS</h6>
            <hr>
            <canvas id="doughnut-chart" width="800" height="235"></canvas>
            <div id="total-vendors-container" class="total-container">
              <span id="total-Vendors" class="total-count"></span>
            </div>
            <div class="total-container"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 mt-4 mb-4">
        <div class="card border-0" style="box-shadow: 0 0 10px #b7c0ce33">
          <div class="card-body">
            <h6>CUSTOMERS</h6>
            <hr>
            <canvas id="pie-chart-Customers" width="800" height="235"></canvas>
            <div id="total-customers-container" class="total-container">
              <span id="total-customers" class="total-count"></span>
            </div>
            <div class="total-container"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 mt-4 mb-4">
        <div class="card border-0">
          <div class="card-body">
            <h6>TASKS</h6>
            <hr>
            <canvas id="pie-chart-TAsks" width="800" height="235"></canvas>
            <div id="total-unread-Task-container" class="total-container">
              <span id="total-unread-Task" class="total-count"></span>
            </div>
            <div id="total-Task-container" class="total-container">
              <span id="total-Task" class="total-count"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <h6>Expiring Documents</h6>
              <hr/>
              <table class="table table-hover">
                <thead class="tableview theme-bg-color">
                <tr>
                  <th>Vendor name</th>
                  <th>Document type</th>
                  <th>File name</th>
                  <th>Start date</th>
                  <th>End date</th>
                  <th>Expiry date left</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let doc of expiringDocs">
                  <tr>
                    <td>{{ doc.vendorName }}</td>
                    <td style="text-transform: capitalize;color: #3b5998;">{{ doc.type ? doc.type : '--' }}</td>
                    <td><a href="{{doc.url}} " target="_blank">{{ doc.fileName ? doc.fileName : '--' }}</a></td>
                    <td>{{ doc.startDate | date }}</td>
                    <td>{{ doc.endDate | date }}</td>
                    <td *ngIf="lastDays(doc.endDate) > 0"
                        style="text-transform: capitalize;color: orange;font-weight: bold">{{ lastDays(doc.endDate) }}
                      Days
                      Left
                    </td>
                    <td *ngIf="lastDays(doc.endDate)=== 0"
                        style="text-transform: capitalize;color: #ef7474;font-weight: bold">Expiring Soon
                    </td>
                    <td *ngIf="lastDays(doc.endDate)< 0"
                        style="text-transform: capitalize;color: red;font-weight: bold">
                      Expired
                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
              <mat-paginator [length]="totalDocuments"
                             [pageSize]="pageSize"
                             [pageSizeOptions]="[10,12,25,50,100]"
                             [hidden]="totalDocuments == 0"
                             aria-label="Select page">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
