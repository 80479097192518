<div class="content-wrapper-main-inner-content">
  <div class="row mb-3">
    <div class="col-md-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h5 class="page-title">Two Factor Authentication</h5>

      </div>
    </div>
  </div>
  <div class="col-md-12 companyProfile_wrapper">
    <div class="companyProfile_header theme-bg-color">
      <h5>Choose an Authentication Method</h5>
    </div>
    <div class="companyProfile_content">

      <div class="d-flex justify-content-center " style="width:60%;">
        <form (ngSubmit)="twoFactorAuthentication()" style="width:100%;">
          <div class="form-check mb-3" (click)="selectedAuthMethod = 'none'">
            <input
              class="form-check-input"
              type="radio"
              name="authMethod"
              id="none"
              value="none"
              [(ngModel)]="selectedAuthMethod"
            />
            <label class="form-check-label" for="none">None</label>
          </div>
          <div class="form-check mb-3" (click)="selectedAuthMethod = 'email'">
            <input
              class="form-check-input"
              type="radio"
              name="authMethod"
              id="email"
              value="email"
              [(ngModel)]="selectedAuthMethod"
            />
            <label class="form-check-label" for="email">
              Email Address
              <small class="text-muted d-block">
                Receive authentication code via email on
                <span class = "format-email">{{formatEmail(email)}}</span>
              </small>
            </label>
          </div>
          <div class="text-center">
            <button class="btn action-btn action-btn-cancel me-3 border-0 theme-btn-color" type="submit" [disabled]="isSaveDisabled()">Save</button>
          </div>
        </form>
      </div>
    </div>
</div>
</div>
