import {ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {ApiService} from "../../../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Candidate} from "../../../../shared/interfaces";
import {ToastrService} from "ngx-toastr";
import {Observable} from "rxjs/internal/Observable";
import {catchError, tap} from "rxjs/operators";
import {throwError} from "rxjs";
import {NavigationService} from "../../../../services/navigation.service";

@Component({
  selector: 'app-web-candidates',
  templateUrl: './web-candidates.component.html',
  styleUrls: ['./web-candidates.component.css']
})

export class WebCandidatesComponent implements OnInit {
  candidateData: Candidate;
  @Output() verificationStatusUpdated = new EventEmitter<string>();
  formFields = [
    {label: 'First Name', type: 'text', required: true, formControlName: 'firstName'},
    {label: 'Last Name', type: 'text', required: false, formControlName: 'lastName'},
    {label: 'Job Title', type: 'text', required: true, formControlName: 'title'},
    {label: 'Experience Years', type: 'number', required: true, formControlName: 'experienceYear'},
    {label: 'Experience Months', type: 'number', required: true, formControlName: 'experienceMonth'},
    {label: 'Phone Number', type: 'tel', required: true, formControlName: 'number'},
    {label: 'Email', type: 'email', required: true, formControlName: 'email'},
    {label: 'CTC', type: 'text', required: true, formControlName: 'currentCtc'},
    {label: 'ECTC', type: 'text', required: true, formControlName: 'expectedCtc'},
    {label: 'LinkedIn URL', type: 'url', required: true, formControlName: 'linkedInURL'},
    {
      label: 'Rate Your English Communication Skills',
      type: 'range',
      required: true,
      formControlName: 'communicationSkillsRating'
    },
  ];
  formField1 = [
    {label: 'First Name', type: 'text', required: true, formControlName: 'firstName'},
    {label: 'Last Name', type: 'text', required: false, formControlName: 'lastName'},
    {label: 'Company Name', type: 'text', required: true, formControlName: 'company'},
    {label: 'Phone Number', type: 'tel', required: true, formControlName: 'number'},
    {label: 'Email', type: 'email', required: true, formControlName: 'email'},
    {label: 'LinkedIn URL', type: 'url', required: true, formControlName: 'linkedInURL'},
  ];
  currentRoute = ''

  constructor(private service: ApiService,
              private route: ActivatedRoute,
              private toastr: ToastrService,
              private router: Router,
              private cdr: ChangeDetectorRef,
              private navigationService: NavigationService,
  ) {
  }

  ngOnInit(): void {
    const id = this.route.snapshot.params['id'];
    if (this.route?.snapshot?.url[1]?.path === 'candidate') {
      this.currentRoute = 'candidate'
      this.getSingleJobCandidate(Number(id))
    } else if (this.route?.snapshot?.url[1]?.path === 'customer') {
      this.currentRoute = 'customer'
      this.getSingleWebClient(Number(id))
    }
    if (id != null) {
    }
  }

  returnFormFields() {
    if (this.currentRoute === 'customer') {
      return this.formField1
    } else {
      return this.formFields
    }
  }

  getSingleJobCandidate(jobCandidateId: number): void {
    this.service.getSingleJobCandidate(jobCandidateId).subscribe((res: any) => {
      this.candidateData = res;
      this.cdr.detectChanges();
    })
  }

  getSingleWebClient(webCandidateId: number): void {
    this.service.getWebClient(webCandidateId).subscribe((res: any) => {
      this.candidateData = res;
      this.cdr.detectChanges();
    });
  }

  handleCandidateFormSubmit(webCandidateId: number, payload: any): Observable<any> {
    if (this.currentRoute === 'customer') {
      return this.service.updateWebClient(this.route.snapshot.params['id'], payload).pipe(
        tap(() => this.toastr.success('Successfully updated web customer')),
        catchError((error) => {
          this.toastr.error('Error updating web candidate:' + error.message)
          return throwError(() => error)
        })
      )
    } else {
      return this.service.updateSingleJobCandidate(this.route.snapshot.params['id'], payload).pipe(
        tap(() => this.toastr.success('Successfully updated web candidate')),
        catchError((error) => {
          this.toastr.error('Error updating candidate information: ' + error.message);
          return throwError(() => error);
        })
      );
    }
  }

  handleSkillUpdate(jobCandidateId: number, payload: { skills: number[] }): Observable<any> {
    return this.service.updateCandidateSkills(jobCandidateId, payload).pipe(
      tap((res) => {
        this.toastr.success('Skills updated successfully!');
      }),
      catchError((error) => {
        this.toastr.error('Error updating skills: ' + error.message);
        return throwError(error);
      })
    );
  }

  getAllDocumentOfWebCandidate(webCandidateId: number, page: number, size: number, active: boolean): Observable<any> {
    return this.service.getAllDocumentOfWebCandidate(webCandidateId, page, size, active).pipe(
      tap((res: any) => {
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }

  createWebCandidateFileType(webCandidateId: number, typeId: number, docName: string, file: File): Observable<any> {
    return this.service.addWebCandidateFileDocument(this.route.snapshot.params['id'], typeId, docName, file).pipe(
      tap(() => this.toastr.success('Document Uploaded successfully!')),
      catchError((error) => {
        this.toastr.error('Error updating skills: ' + error.message);
        return throwError(error);
      })
    );
  }

  createWebCandidateUrlType(webCandidateId: number, url: string, typeId: number): Observable<any> {
    return this.service.addWebCandidateUrlDocument(this.route.snapshot.params['id'], {url: url, typeId: typeId}).pipe(
      tap(() => this.toastr.success('URL Uploaded successfully!')),
      catchError((error) => {
        this.toastr.error('Error uploading URL: ' + error.message);
        return throwError(error);
      })
    );
  }

  addWebCandidateNotesObservable(webCandidateId: number, payload: { description: string }): Observable<any> {
    if (this.currentRoute === 'candidate') {
      return this.service.addWebCandidateNotes(webCandidateId, payload).pipe(
        tap(() => this.toastr.success('Note added successfully!')),
        catchError((error) => {
          this.toastr.error('Error adding note: ' + error.message);
          return throwError(error);
        })
      );
    } else if (this.currentRoute === 'customer') {
      return this.service.addNoteForCustomerContact(webCandidateId, payload).pipe(
        tap(() => this.toastr.success('Note added successfully!')),
        catchError((error) => {
          this.toastr.error('Error adding note: ' + error.message);
          return throwError(error);
        })
      );
    }
    return throwError(new Error('Route not handled'));
  }

  updateWebCandidateNotesObservable(webCandidateId: number, communicationId: number, payload: {
    description: string
  }): Observable<any> {
    if (this.currentRoute === 'candidate') {
      return this.service.updateWebCandidateNotes(webCandidateId, communicationId, payload).pipe(
        tap(() => this.toastr.success('Note updated successfully!')),
        catchError((error) => {
          this.toastr.error('Error updating note: ' + error.message);
          return throwError(error);
        })
      );
    } else if (this.currentRoute === 'customer') {
      return this.service.updateNoteForWebClient(webCandidateId, communicationId, payload).pipe(
        tap(() => this.toastr.success('Note updated successfully!')),
        catchError((error) => {
          this.toastr.error('Error updating note: ' + error.message);
          return throwError(error);
        })
      );
    }
    return throwError(new Error('Route not handled'));
  }

  deleteWebCandidateNotesObservable(webCandidateId: number, communicationId: number): Observable<any> {
    if (this.currentRoute === 'candidate') {
      return this.service.deleteWebCandidateNotes(webCandidateId, communicationId).pipe(
        tap(() => this.toastr.success('Note deleted successfully!')),
        catchError((error) => {
          this.toastr.error('Error deleting note: ' + error.message);
          return throwError(error);
        })
      );
    } else if (this.currentRoute === 'customer') {
      return this.service.deleteNoteForWebClient(webCandidateId, communicationId).pipe(
        tap(() => this.toastr.success('Note deleted successfully!')),
        catchError((error) => {
          this.toastr.error('Error deleting note: ' + error.message);
          return throwError(error);
        })
      );
    }
    return throwError(new Error('Route not handled'));
  }

  getAllWebCandidateNotesObservable(webCandidateId: number): Observable<any> {
    if (this.currentRoute === 'candidate') {
      return this.service.getAllWebCandidateNotes(webCandidateId).pipe(
        tap((res) => {
        }),
        catchError((error) => {
          this.toastr.error('Error retrieving notes: ' + error.message);
          return throwError(error);
        })
      );
    } else if (this.currentRoute === 'customer') {
      return this.service.getNotesForCustomerContact(webCandidateId).pipe(
        tap((res) => {
        }),
        catchError((error) => {
          this.toastr.error('Error retrieving notes: ' + error.message);
          return throwError(error);
        })
      );
    }
    return throwError(new Error('Route not handled'));
  }

  deleteWebCandidateDocumentObservable(webCandidateId: number, documentId: number): Observable<any> {
    return this.service.deleteWebCandidateDocument(webCandidateId, documentId).pipe(
      tap((res) => {
      }),
      catchError((error) => {
        this.toastr.error('Error deleting document: ' + error.message);
        return throwError(error);
      })
    );
  }

  inactiveWebCandidateDocumentObservable(webCandidateId: number, documentId: number): Observable<any> {
    return this.service.inactiveWebCandidateDocument(webCandidateId, documentId).pipe(
      tap((res) => {
      }),
      catchError((error) => {
        this.toastr.error('Error inactivating document: ' + error.message);
        return throwError(error);
      })
    );
  }

  restoreWebCandidateDocumentObservable(webCandidateId: number, documentId: number): Observable<any> {
    return this.service.restoreWebCandidateDocument(webCandidateId, documentId).pipe(
      tap((res) => {
      }),
      catchError((error) => {
        this.toastr.error('Error restoring document: ' + error.message);
        return throwError(error);
      })
    );
  }

  navigateToListing() {
    const state = this.navigationService.getState();
    if (this.currentRoute === 'candidate') {
      if (state) {
        this.router.navigateByUrl(state.previousUrl).then(() => {
          window.scrollTo(0, state.scrollPosition);
        });
      } else {
        this.router.navigate(['dashboard/web_data'], {queryParams: {tab: 0}});
      }
    } else if (this.currentRoute === 'customer') {
      if (state) {
        this.router.navigateByUrl(state.previousUrl).then(() => {
          window.scrollTo(0, state.scrollPosition);
        });
      } else {
        this.router.navigate(['dashboard/web_data'], {queryParams: {tab: 1}});
      }
    }
  }

}
