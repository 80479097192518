<div class="mx-4">
  <div class="row card-shadow my-4 p-4 pb-2">
    <h3 class="font-weight-bold p-0">Customer Information:</h3>
    <div class="row p-0">
      <div class="d-flex flex-wrap col-md-12 m-0 p-0">
        <p class="subHeadings-labels col-md-6">Customer name:<span
          class="subHeadings-values"> {{ myTask.myTask?.customer?.companyName ?? '--' }}</span></p>
        <p class="subHeadings-labels col-md-6">Customer contact name:<span
          class="subHeadings-values"> {{ myTask.myTask?.sender?.name ?? '--' }}</span>
        </p>
      </div>
      <div class="d-flex flex-wrap col-md-12 m-0 p-0">
        <p class="subHeadings-labels col-md-6">Contact number:<span
          class="subHeadings-values"> {{ renderMobileNumber(myTask.myTask?.customer?.number) ?? '--' }}</span></p>
        <p class="subHeadings-labels col-md-6">Contact email:<span
          class="subHeadings-values"> {{ myTask.myTask?.customer?.email ?? '--' }}</span></p>
      </div>
      <div class="d-flex flex-wrap col-md-12 m-0 p-0">
        <p class="subHeadings-labels col-md-6">Assigned SPOC:<span
          class="subHeadings-values"> {{ myTask.myTask?.assignee?.firstName ?? '--' }} {{ myTask.myTask?.assignee?.lastName ?? '' }}</span>
        </p></div>
    </div>
  </div>

  <div class="row card-shadow my-4 p-4 pb-2">
    <h3 class="font-weight-bold p-0">Customer Enquiry:</h3>
    <div class="row p-0">
      <div class="d-flex flex-wrap col-md-12 m-0 p-0">
        <p *ngIf="myTask.myTask?.subject?.consultantId" class="subHeadings-labels col-md-6">Consultant ID: <span
          class="subHeadings-values"> {{ myTask.myTask?.subject?.consultantId ?? '--' }}</span></p>
        <p *ngIf="myTask.myTask?.subject?.solutionId" class="subHeadings-labels col-md-6">SolutionId ID: <span
          class="subHeadings-values"> {{ myTask.myTask?.subject?.solutionId ?? '--' }}</span></p>
        <p *ngIf="myTask.myTask?.subject?.solutionId" class="subHeadings-labels col-md-6">Solution Name: <span
          class="subHeadings-values"> {{ myTask.myTask?.subject?.name ?? '--' }}</span></p>
        <p *ngIf="!myTask.myTask?.subject?.solutionId" class="subHeadings-labels col-md-6">Working TimeZone: <span
          class="subHeadings-values"> {{ displayValue(myTask.myTask?.workTimeZone ?? '--' )}}</span></p>
      </div>
      <div class="d-flex flex-wrap col-md-12 m-0 p-0">
        <p *ngIf="!myTask.myTask?.subject?.solutionId" class="subHeadings-labels col-md-6">No. of Positions: <span
          class="subHeadings-values"> {{ displayValue(myTask.myTask?.job?.noOfOpenings ?? '--') }}</span></p>
        <p *ngIf="!myTask.myTask?.subject?.solutionId" class="subHeadings-labels col-md-6">Duration: <span
          class="subHeadings-values"> {{ displayValue(myTask.myTask?.duration ?? '--' )}} <span *ngIf="myTask.myTask?.duration" >months</span> </span></p>
      </div>
      <p class="subHeadings-labels col-md-6">Request received on:
        <span class="subHeadings-values">
          {{ myTask.myTask?.startDate ? (myTask.myTask?.startDate | date: 'd MMM y, h:mm a') : '--' }}
        </span>
      </p>
      <p class="subHeadings-labels col-md-6">Request closed on:
        <span class="subHeadings-values">
          {{ myTask.myTask?.closingDate ? (myTask.myTask?.closingDate | date: 'd MMM y, h:mm a') : '--' }}
        </span>
      </p>
      <p *ngIf="myTask.myTask?.subject?.solutionId" class="subHeadings-labels col-md-6">Tentative start time:
        <span class="subHeadings-values">
          {{ myTask.myTask?.tentativeStartTime ?? '--'}} weeks
        </span>
      </p>
      <div class="d-flex flex-wrap col-md-12 m-0 p-0">
        <p *ngIf="!myTask.myTask?.subject?.solutionId" class="subHeadings-labels col-md-12">Job Description: <span
          class="subHeadings-values"> {{ displayValue(myTask.myTask?.jobDescription ?? '--' )}}</span></p>
        <p *ngIf="myTask.myTask?.subject?.solutionId" class="subHeadings-labels col-md-12">Notes: <span
          class="subHeadings-values"> {{ displayValue(myTask.myTask?.notes ?? '--' )}}</span></p>
      </div>
    </div>
  </div>
</div>
