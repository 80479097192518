export interface GenerateOTPResponse{
  message: Message;
  userRequestId: string;
}

export interface VerifyOTPRequest{
  otp: string;
  userRequestId: string;
  sessionId?: any;
}

export interface ResendOTPRequest{
  username: string;
  userRequestId: string;
}

export enum Message{
  OTP_REQUIRED = 'OTP_REQUIRED',
  OTP_SENT = 'OTP_SENT'
}
