import {Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {DataService} from 'src/app/services/data.service';
import {ApiService} from "../../services/api.service";
import {LoggedInUser} from "../../shared/interfaces";
import {MatTooltipModule, TooltipPosition} from "@angular/material/tooltip";
import {UnreadTaskService} from "../../services/getUnreadTask.service";
import {CompanyProfileService} from "../../services/companyProfileService";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnChanges {
  imports: [MatTooltipModule, TooltipPosition,];
  orgId: any;
  isLoading = false;
  organisation: any;
  companyName = '';
  customerCompanyName = '';
  companyImage: string | null = null;
  customerCompanyImage = '';
  webCustomer: boolean = false
  role: string | null;
  sideBarCollapse: boolean = false
  sideBarCollapseSetting: boolean = false
  sideBarCollapseReport: boolean = false
  sideBarCollapseCreate: boolean = false
  loginType: string | '';
  customersStatusOptions: string[] = ['UNVERIFIED', 'VERIFIED', 'ONHOLD'];
  unreadTasks: number = 0;
  @Input() sidebarFlag: boolean = false;
  @Input('matTooltipClass') tooltipClass: any
  @ViewChild('manageVendors') manageVendors: ElementRef<HTMLLinkElement>;
  @ViewChild('home') home: ElementRef<HTMLLinkElement>;
  @ViewChild('dashboard') dashboard: ElementRef<HTMLLinkElement>;
  @ViewChild('manageProfile') manageProfile: ElementRef<HTMLLinkElement>;
  @ViewChild('manageCustomers') manageCustomers: ElementRef<HTMLLinkElement>;
  @ViewChild('customersContact') customersContact: ElementRef<HTMLLinkElement>;
  @ViewChild('manageConsultants') manageConsultants: ElementRef<HTMLLinkElement>;
  @ViewChild('manageMyTasks') manageMyTasks: ElementRef<HTMLLinkElement>;
  @ViewChild('manageMembers') manageMembers: ElementRef<HTMLLinkElement>;
  @ViewChild('reports') reports: ElementRef<HTMLLinkElement>;
  @ViewChild('createPartner') createPartner: ElementRef<HTMLLinkElement>;
  @ViewChild('settings') settings: ElementRef<HTMLLinkElement>;
  @ViewChild('auditReport') auditReport: ElementRef<HTMLLinkElement>;
  @ViewChild('jobs') jobs: ElementRef<HTMLLinkElement>;
  @ViewChild('clientDashboard') clientDashboard: ElementRef<HTMLLinkElement>;
  @ViewChild('talentPool') talentPool: ElementRef<HTMLLinkElement>;
  @ViewChild('solutionPool') solutionPool: ElementRef<HTMLLinkElement>;
  @ViewChild('myList') myList: ElementRef<HTMLLinkElement>;
  @ViewChild('clientJobs') clientJobs: ElementRef<HTMLLinkElement>;
  @ViewChild('clientThemes') clientThemes: ElementRef<HTMLLinkElement>;
  @ViewChild('adminThemes') adminThemes: ElementRef<HTMLLinkElement>;
  @ViewChild('solutions') solutions: ElementRef<HTMLLinkElement>;
  @ViewChild('webCandidate') webCandidate: ElementRef<HTMLLinkElement>;
  @ViewChild('memberReports') memberReports: ElementRef<HTMLLinkElement>;

  // @ViewChild('clientMessage') clientMessage: ElementRef<HTMLLinkElement>;

  constructor(
    private router: Router,
    public data: DataService,
    private service: ApiService,
    private unReadTaskService: UnreadTaskService,
    private companyProfileService: CompanyProfileService,
  ) {
  }

  ngAfterViewInit(): void {
    this.applyClass(location.pathname);
    this.role = localStorage.getItem('role');
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes, 'changges')
    console.log(this.sidebarFlag, 'sidebar flag')
  }

  ngOnInit(): void {
    if (localStorage.getItem('loginType') === 'Vendor')
      this.getAllUnreadTasks();
    this.companyProfileService.getImageUrl().subscribe({
      next: (result) => {
        this.companyImage = result ?? null;
      }
    })
    this.unReadTaskService.hasTaskIdBeenClicked.subscribe((value: boolean) => {
      if (value) {
        this.service.getAllUnreadTasks().subscribe((res) => {
          this.unreadTasks = res;
          this.unReadTaskService.emitChange(false);
        })
      }
    });
    this.data.isLoading.next(true);
    const rootVariable = document.documentElement;
    this.role = localStorage.getItem('role');
    this.loginType = localStorage.getItem('loginType') ?? '';
    if (this.loginType == 'Client') {
      this.service.getCustomerLoginUser().subscribe({
        next: res => {
          this.customerCompanyName = res.customer.companyName;
          this.customerCompanyImage = res.customer.logo;
          this.webCustomer = res.customer.webCustomer;
          this.customersStatusOptions = [res.verificationStatus];
          console.log(this.customersStatusOptions)
        },
        error: err => {
        },
        complete: () => {
          this.data.isLoading.next(false);
        }
      });
    }
    this.data.companyName.subscribe(res => {
      if (res) {
        this.companyName = res;
      }
    });
    this.data.companyImage.subscribe(res => {
    });

    this.data.isLoading.subscribe(res => {
      this.isLoading = res;
    });
    if (localStorage.getItem('orgId')) {
      this.orgId = localStorage.getItem('orgId');
      this.service.getOrganisation(this.orgId).subscribe({
        next: res => {
          this.organisation = res;
          this.companyName = this.organisation.name;
          this.companyImage = this.organisation.logo;
        },
        error: err => {

        }, complete: () => {
          this.data.isLoading.next(false);
        }
      });
    } else {
      this.companyName = 'Unknown';
      this.companyImage = 'Unknown';
    }

    this.router.events.subscribe((res: any) => {
      if (res instanceof NavigationEnd) {
        this.applyClass(res['url']);
      }
    });
  }

  SideBarCollapse(): void {
    this.sideBarCollapse = !this.sideBarCollapse;
  }

  SideBarCollapseSetting(): void {
    this.sideBarCollapseSetting = !this.sideBarCollapseSetting;
  }

  SideBarCollapseReport(): void {
    this.sideBarCollapseReport = !this.sideBarCollapseReport;
  }

  SideBarCollapseCreate(): void {
    this.sideBarCollapseCreate = !this.sideBarCollapseCreate;
  }

  applyClass(input: string): void {
    const resetAllClasses = () => {
      const sideTabs = [
        this.dashboard,
        this.home,
        this.manageVendors,
        this.manageMembers,
        this.reports,
        this.manageMyTasks,
        this.jobs,
        this.manageCustomers,
        this.customersContact,
        this.manageConsultants,
        this.manageProfile,
        this.auditReport,
        this.createPartner,
        this.settings,
        this.clientDashboard,
        this.talentPool,
        this.solutionPool,
        this.myList,
        this.clientJobs,
        this.clientThemes,
        this.adminThemes,
        this.solutions,
        this.webCandidate,
        this.memberReports
      ];
      sideTabs.forEach(tab => {
        if (tab && tab.nativeElement) {
          tab.nativeElement.className = 'side-menu_item nav-link';
        }
      });
    };

    const setClass = (tab: any, className: string) => {
      if (tab && tab.nativeElement) {
        tab.nativeElement.className = className;
      }
    };
    resetAllClasses();
    if (this.role === 'TA') {
      setClass(this.dashboard, 'side-menu_item nav-link');
      setClass(this.home, 'side-menu_item nav-link');
    } else if (this.role === 'BSE' || this.role === 'BSM') {
      const elements = [
        this.manageConsultants,
        this.manageMembers,
        this.manageMyTasks,
        this.jobs,
        this.manageCustomers,
      ];
      elements.forEach(element => setClass(element, 'side-menu_item nav-link'));
    } else if (this.loginType === 'Client') {
      const elements = [
        this.clientDashboard,
        this.talentPool,
        this.solutionPool,
        this.myList,
        this.clientJobs,
        this.clientThemes,
        this.settings,
      ];
      elements.forEach(element => setClass(element, 'side-menu_item nav-link'));
    } else {
      const elements = [
        this.dashboard,
        this.home,
        this.manageVendors,
        this.manageMembers,
        this.reports,
        this.manageMyTasks,
        this.manageProfile,
        this.manageCustomers,
        this.manageConsultants,
        this.jobs,
      ];
      elements.forEach(element => setClass(element, 'side-menu_item nav-link'));
    }

    const activeSidebarMapping: { [key: string]: any } = {
      '/dashboard/admin-vendor': this.manageVendors,
      '/dashboard/manageProfile': this.manageProfile,
      '/dashboard/manageCustomers': this.manageCustomers,
      '/dashboard/manageMembers': this.manageMembers,
      '/dashboard/customer-analytics': this.reports,
      '/dashboard/manageConsultants': this.manageConsultants,
      '/dashboard/customerContact': this.customersContact,
      '/dashboard/manageMyTasks': this.manageMyTasks,
      '/dashboard/createPartner': this.createPartner,
      '/dashboard/jobs': this.jobs,
      '/dashboard/themes': this.adminThemes,
      '/dashboard/solutions': this.solutions,
      '/dashboard/audit': this.auditReport,
      '/dashboard/settings': this.settings,
      '/dashboard/home': this.home,
      '/home/dashboard': this.dashboard,
      '/client': this.clientDashboard,
      '/client/talent_pool': this.talentPool,
      '/client/solution_pool': this.solutionPool,
      '/client/myList': this.myList,
      '/client/client_jobs': this.clientJobs,
      '/client/themes': this.clientThemes,
      '/dashboard/web-candidate': this.webCandidate,
      '/dashboard/member-analytics': this.memberReports
    };

    if (input.startsWith('/dashboard/edit-solution')) {
      setClass(this.solutions, 'side-menu_item sidebar-active');
    } else if (input.startsWith('/client/detail_view')) {
      setClass(this.solutionPool, 'side-menu_item sidebar-active');
    } else if (input.startsWith('/dashboard/web_data')) {
      setClass(this.webCandidate, 'side-menu_item sidebar-active');
    } else {
      const activeElement = activeSidebarMapping[input];
      if (activeElement) {
        setClass(activeElement, 'side-menu_item sidebar-active');
      }
    }
  }

  getAllUnreadTasks() {
    this.service.getAllUnreadTasks().subscribe((res) => {
      this.unreadTasks = res
    })
  }

  protected readonly LoggedInUser = LoggedInUser;
}
