<div class="consultant_wrapper">
  <h2 class="mb-4">Add Contacts</h2>
  <form [formGroup]="customerContactForm" ngNativeValidate (submit)="addContact()">
    <div class="form-row mb-2">
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red ">
          <mat-label>First Name</mat-label>
          <input appMaxCharLimitInput matInput placeholder="First Name" formControlName="firstName" appNoSpecialChars>
          <mat-error *ngIf="this.customerContactForm.get('firstName')?.hasError('maxCharLimit')">
            {{MaxCharLimits.INPUT }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red ">
          <mat-label>Last Name</mat-label>
          <input appMaxCharLimitInput matInput placeholder="Last Name" formControlName="lastName" appNoSpecialChars>
          <mat-error *ngIf="this.customerContactForm.get('lastName')?.hasError('maxCharLimit')">
            {{MaxCharLimits.INPUT }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="form-row mb-2">
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red ">
          <mat-label>Designation</mat-label>
          <input #designationAutoCompleter [formControl]="desControl" [(ngModel)]="desTypeName"
                 [matAutocomplete]="desAuto" z
                 type="text" matInput placeholder="e.g. CEO, HR, CTO"
                 aria-describedby="title">
          <mat-autocomplete autoActiveFirstOption #desAuto="matAutocomplete" [displayWith]="displayDocFn">
            <mat-option *ngFor=" let des of filteredDesignation| async"
                        (onSelectionChange)="chooseDesType($event)" [value]="des">
              {{ des.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div *ngIf="(filteredDesignation|async)?.length == 0" class=" dropdown-skills position-relative">
          <ul class="mat-autocomplete-panel mat-autocomplete-visible mw-100 h-50">
            <li class="li_disabled mat-option-disabled">
              No match Found
            </li>
            <hr class="m-0 p-0">
            <li class="font-weight-bold mat-option">
              <div class="d-flex justify-content-between align-items-center"
                   (click)="addDesType($event)">
                <span>Add this Designation type:</span>
                <button class="p-0 bg-transparent border-0 fs-20"> +</button>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Email id</mat-label>
          <input appMaxCharLimitUrl matInput placeholder="Email" formControlName="email" type="email">
          <mat-error *ngIf="this.customerContactForm.get('email')?.hasError('maxCharLimit')">
            {{MaxCharLimits.URL }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="form-row mb-2">
      <div class="col-md-6">
        <div>
          <app-country-phone-input
            [modal]="true"
            [formControl]="getFormControl('number')">
          </app-country-phone-input>
        </div>
      </div>

      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>LinkedIn URL</mat-label>
          <input appMaxCharLimitUrl matInput placeholder="LinkedIn URL" formControlName="linkedIn"
                 #inputMobileNo type="text">
          <mat-error *ngIf="this.customerContactForm.get('linkedIn')?.hasError('maxCharLimit')">
            {{MaxCharLimits.URL }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="form-row mb-2">
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Vertical</mat-label>
          <input #verticalAutoCompleter [formControl]="verticalControl" [(ngModel)]="verticalName"
                 [matAutocomplete]="autoVertical"
                 type="text" matInput placeholder="e.g. CEO, HR, CTO"
                 aria-describedby="title">
          <mat-autocomplete autoActiveFirstOption #autoVertical="matAutocomplete" [displayWith]="displayDocFn">
            <mat-option *ngFor=" let vertical of filteredVertical| async"
                        (onSelectionChange)="chooseVerticalType($event)" [value]="vertical">
              {{ vertical.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div *ngIf="(filteredVertical|async)?.length == 0 && (verticalName!=null && verticalName!='')"
             class=" dropdown-skills position-relative">
          <ul class="mat-autocomplete-panel mat-autocomplete-visible mw-100 h-50">
            <li class="li_disabled mat-option-disabled">
              No match Found
            </li>
            <hr class="m-0 p-0">
            <li class="font-weight-bold mat-option">
              <div class="d-flex justify-content-between align-items-center"
                   (click)="addVertical($event)">
                <span>Add this vertical:</span>
                <button class="p-0 bg-transparent border-0 fs-20"> +</button>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Assigned SPOC</mat-label>
          <input #SPOCAutoCompleter type="text" matInput placeholder="Assigned SPOC" [formControl]="spocControl"
                 [matAutocomplete]="autoSPOC" required>
          <mat-autocomplete #autoSPOC="matAutocomplete" (optionSelected)="chooseSpoc($event)"
                            [displayWith]="formatSPOC">
            <mat-option *ngFor="let option of filteredSpoc | async" [value]="option">
              {{ formatSPOC(option) }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

    </div>
    <div class="mt-2">
      <div class="form-group mb-2">
        <mat-slide-toggle formControlName="sendEmail"
                          class="example-margin"
                          color="primary"
                          [disabled]=false
                          [checked]="emailStatus"
                          (change)="change($event)">
          <div class="ActiveButton">Send Email</div>
        </mat-slide-toggle>
      </div>
    </div>
    <div class="d-flex justify-content-end align-items-baseline mt-4">
      <div class="save-skill">
        <button (click)="addContact()" *ngIf="!showloader" class="btn btn-save mx-3 theme-bg-color"
                type="submit">Save details
        </button>
        <div *ngIf="showloader" disabled>
          <button class="btn btn-saveform" disabled>
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="document-wrapper mb-5">
  <div class="mx-height-scroll mt-4">
    <div class="col-md-12 d-flex justify-content-end align-items-baseline mx-0" id='search-box2'>
      <mat-slide-toggle
        class="example-margin"
        color="primary"
        [checked]="showArchived"
        (change)="changeArchived($event)">
        <div class="ActiveButton">Show Archived</div>
      </mat-slide-toggle>
    </div>

    <div *ngIf="customerContacts.length > 0" class="mx-height-scroll mt-4">
      <form>
        <table class="table table-striped" style="cursor:pointer;">
          <thead class="tableview theme-bg-color text-white">
          <tr>
            <th>Name</th>
            <th>Designation</th>
            <th>Email Id</th>
            <th>Mobile Number</th>
            <th>LinkedIn URL</th>
            <th>SPOC</th>
            <th><span class="ms-2"> Email Status </span></th>
            <th>Date</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let contact of customerContacts;">
            <tr>
              <td style="margin-right: 45px;text-transform:capitalize;font-size: 14px">
                {{ contact?.firstName ?? '---' }}
              </td>
              <td style="margin-right: 45px;font-size: 14px">
                {{ contact?.designation }}
              </td>
              <td style="margin-right: 45px;font-size: 14px">
                {{ contact?.email }}
              </td>
              <td style="margin-right: 45px;font-size: 14px">
                {{ renderMobileNumber(contact?.number) }}
              </td>
              <td style="margin-right: 45px; font-size: 14px">
                <span>
                  <ng-container *ngIf="contact.linkedIn; else noLink">
                    <a (click)="goToLink(contact.linkedIn)" style="color: blue; font-style: italic; text-decoration: underline; cursor: pointer">
                      Link
                    </a>
                  </ng-container>
                  <ng-template #noLink>
                    <div style="font-style: italic;">--</div>
                  </ng-template>
                </span>
              </td>
              <td style="margin-right: 45px;font-size: 14px">
                {{ contact?.spoc?.firstName + " " + contact?.spoc?.lastName }}
              </td>
              <td style="margin-right: 45px;font-size: 14px" *ngIf="contact.sentEmailStatus===true ">
                <span class="ms-3"> Email Sent</span>
              </td>
              <td style="margin-right: 45px;font-size: 14px" *ngIf="contact.sentEmailStatus===false ">
                <button (click)="confirmationSendEmail(contact.id)" class="btn btn-save  theme-bg-color"
                        type="submit">Send Email
                </button>
              </td>
              <td style="margin-right: 45px;font-size: 14px">
                <div class="d-flex flex-column">
                  <span *ngIf="contact.updatedOn" style="font-size: 13px;">
                  Updated On {{ contact.updatedOn | date: 'dd MMM y hh:mm a' }}
                </span>
                  <span class="p-0 m-0" *ngIf="contact.createdOn" style="font-size: 13px;">
                      Created On {{ contact.createdOn | date: 'dd MMM y hh:mm a' }}
                </span>
                </div>
              </td>
              <td style="margin-right: 45px;text-transform:lowercase;font-size: 14px">
                <button class="d-flex align-items-center justify-content-center" mat-icon-button
                        [matMenuTriggerFor]="menu">
                  <div class="fs-2 mb-2">...</div>
                </button>

                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="editContact(contact)">
                    <span>Edit</span>
                  </button>
                  <button mat-menu-item (click)="toggleContactStatus(contact.active, contact.id)">
                    <span>{{ contact.active ? 'Deactivate Customer' : 'Activate Customer' }}</span>
                  </button>
                </mat-menu>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </form>
    </div>
    <div>
      <mat-paginator [length]="totalElements"
                     [pageSizeOptions]="[5,10,12,25,50,100]"
                     [pageSize]="pageSize"
                     [hidden]="totalElements==0"
                     aria-label="Select page">
      </mat-paginator>
    </div>
  </div>

  <div *ngIf="customerContacts.length == 0" class="d-flex align-items-center justify-content-center mt-2 fs-6">
    <span>Currently no active Contacts available</span>
  </div>
</div>
