import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {Solution, SolutionListingPayload} from "../../../shared/interfaces";
import {finalize, tap} from "rxjs/operators";
import {MatPaginator} from "@angular/material/paginator";
import {Router} from "@angular/router";
import {debounce} from "lodash";
import {ResetFiltersService} from "../../../services/resetFilters.service";
import {SolutionNavigationService} from "../../../services/solutionNavigationService";
import {NavigationService} from "../../../services/navigation.service";

@Component({
  selector: 'app-solutions',
  templateUrl: './solutions.component.html',
  styleUrls: ['./solutions.component.css']
})
export class SolutionsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  tableHeader: string[] = ['Solution ID', 'Solution Name', 'Vendor Name', 'Industry Vertical', 'Solution Type', 'Tech Stack', 'Demo Ready', 'Solution Description', 'Date']
  searchFieldName = ['solutionId', 'solutionName', 'vendorName', 'industryVertical', 'solutionTypes', 'techStack', 'demoReady', '', 'dateSearchCriteria'];
  totalElements: number = 0;
  pageSize: number = 0;
  totalPages: number = 0;
  currentPage: number = 0;
  showLoader: boolean = false;
  solutionData: Solution[] = [];
  search: string = ''

  filters: SolutionListingPayload = {
    query: "",
    showActive: true,
    solutionId: "",
    solutionName: "",
    informationTags: [],
    industryVertical: [],
    solutionTypes: [],
    techStacks: [],
    demoReady: undefined,
    vendorName: "",
    enableSearch: true,
    dateSearchCriteria: {
      filterType: "CREATED",
      from: "",
      to: "",
    }
  };

  constructor(
    private apiService: ApiService,
    private solutionNavigationService: SolutionNavigationService,
    private router: Router,
    private resetFilters: ResetFiltersService,
    private navigationService: NavigationService,
  ) {

  }

  ngOnInit(): void {
    this.navigationService.clearState();
    const state = this.solutionNavigationService.getState();
    if (state) {
      this.currentPage = state.pageNumber;
      window.scrollTo(0, state.scrollPosition);
      this.getAllSolutionsForAdmin(this.currentPage, this.pageSize, [], this.filters);
    }
    this.getAllSolutionsForAdmin(this.currentPage, this.pageSize, [], this.filters);
    this.getAllSolutionsForAdmin = debounce<any>(this.getAllSolutionsForAdmin, 600);
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(
      tap(() => {
        this.getAllSolutionsForAdmin(
          this.paginator?.pageIndex ?? 0,
          this.paginator?.pageSize ?? 0,
          [],
          this.filters
        );
      })
    ).subscribe();
  }

  updateSearch() {
    this.filters.query = this.search;
    this.paginator.pageIndex = 0;
    this.getAllSolutionsForAdmin(this.currentPage, this.pageSize, [], this.filters);
  }

  getAllSolutionsForAdmin = (page: number, size: number, sort: string[], payload: SolutionListingPayload) => {
    this.apiService.getAllSolutionsForAdmin(page, size, sort, payload).pipe(
      finalize(() => this.solutionNavigationService.clearState())
    ).subscribe((res) => {
      this.solutionData = res.content
      this.totalPages = res.page.totalPages;
      this.totalElements = res.page.totalElements;
      this.currentPage = res.page.number
      this.pageSize = res.page.size;
      if (this.paginator) {
        this.paginator.pageIndex = res.page?.number
        this.paginator.pageSize = res.page?.size;
      }
    }, error => {
      console.log('error', error)
    })
  }

  formatIndustryVertical(value: any) {
    const formattedValues = value?.map((res: any) => {
      if (Array.isArray(res.name)) {
        return res.name.join('');
      } else {
        return res.name;
      }
    }) || [];

    return {
      displayed: formattedValues.slice(0, 2),
      tooltip: formattedValues.slice(2)
    };
  }

  formatTechStackUsed(value: any) {
    const formattedValues = value?.map((res: any) => {
      if (Array.isArray(res.title)) {
        return res.title.join('');
      } else {
        return res.title;
      }
    }) || [];

    return {
      displayed: formattedValues.slice(0, 2),
      tooltip: formattedValues.slice(2)
    };
  }


  getTruncatedDescription(description: any): { displayed: string, tooltip: string } {
    if (description.length > 50) {
      return {
        displayed: this.stripHtmlTags(description.slice(0, 50) + '...'),
        tooltip: this.stripHtmlTags(description)
      };
    } else {
      return {
        displayed: this.stripHtmlTags(description),
        tooltip: ''
      };
    }
  }

  stripHtmlTags(html: string): string {
    return html?.replace(/<[^>]*>/g, '');
  }

  navigateToEditSolution(solutionId: number) {
    const scrollPosition = window.scrollY;
    this.solutionNavigationService.saveState(scrollPosition, this.currentPage, this.router.url);
    this.router.navigate(['/dashboard/edit-solution', solutionId]);
  }

  onFiltersChanged(updatedFilters: any) {
    this.filters = {
      ...updatedFilters,
      query: this.search,
    };
    Object.keys(this.filters).forEach((key) => {
      const filters = this.filters as any;
      if (typeof filters[key] === "string") {
        filters[key] = filters[key].trim();
        if (filters[key] === "") {
          delete filters[key];
        }
      }
    });
    this.paginator.pageIndex = 0;
    this.getAllSolutionsForAdmin(0, this.pageSize, [], this.filters);
  }



  dateTypeChanged($event: boolean) {
    if ($event) {
      this.filters.dateSearchCriteria.filterType = "UPDATED";
    } else if (!$event) {
      this.filters.dateSearchCriteria.filterType = "CREATED";
    }
    this.getAllSolutionsForAdmin(this.currentPage, this.pageSize, [], this.filters);
  }

  clearFilter() {
    this.filters = {
      query: "",
      showActive: true,
      solutionId: "",
      solutionName: "",
      informationTags: [],
      industryVertical: [],
      solutionTypes: [],
      techStacks: [],
      demoReady: undefined,
      vendorName: "",
      dateSearchCriteria: {
        filterType: "CREATED",
        from: "",
        to: "",
      }
    };
    this.search=''
    this.filters.enableSearch = true;
    this.filters.showActive = true
    this.resetFilters.emitChange(true);
    this.getAllSolutionsForAdmin(this.currentPage, this.pageSize, [], this.filters);
  }

  isFilterActive(): boolean {
    return !!(
      (this.filters.query && this.filters.query.trim() !== "") ||
      !this.filters.showActive ||
      !this.filters.enableSearch ||
      (this.filters.solutionId && this.filters.solutionId.trim() !== "") ||
      (this.filters.solutionName && this.filters.solutionName.trim() !== "") ||
      this.filters.informationTags.length > 0 ||
      this.filters.industryVertical.length > 0 ||
      this.filters.solutionTypes.length > 0 ||
      this.filters.techStacks.length > 0 ||
      this.filters.demoReady !== undefined ||
      (this.filters.vendorName && this.filters.vendorName.trim() !== "") ||
      (this.filters.dateSearchCriteria.from && this.filters.dateSearchCriteria.from.trim() !== "") ||
      (this.filters.dateSearchCriteria.to && this.filters.dateSearchCriteria.to.trim() !== "")
    );
  }



  toggleSolutions(event: any) {
    if (event.checked) {
      this.filters.showActive = true
      this.getAllSolutionsForAdmin(this.paginator.pageIndex, this.paginator.pageSize, [], this.filters);
    } else {
      this.filters.showActive = false
      this.getAllSolutionsForAdmin(this.paginator.pageIndex, this.paginator.pageSize, [], this.filters);
    }
  }

  toggleSearchEnableSolution(event: any) {
    if (event.checked) {
      this.filters.enableSearch = true;
      this.getAllSolutionsForAdmin(this.paginator.pageIndex, this.paginator.pageSize, [], this.filters);
    } else {
      this.filters.enableSearch = false;
      this.getAllSolutionsForAdmin(this.paginator.pageIndex, this.paginator.pageSize, [], this.filters);
    }
  }
}
