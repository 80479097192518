import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {AbstractControl, FormArray, FormGroup} from "@angular/forms";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {group} from "@angular/animations";
import {ImageUploadComponent} from "../../../../common/image-upload/image-upload.component";
import {PageEvent} from "@angular/material/paginator";
import {ToastrService} from "ngx-toastr";

export enum FormType {
  DOCUMENT = 'docRows',
  DESIGNATION = 'desRows',
  SKILL = 'skillRows',
  VERTICAL = 'verticalRows',
  EDUCATION = 'educationRows'
}

@Component({
  selector: 'app-portal-setting',
  templateUrl: './portal-setting.component.html',
  styleUrls: ['./portal-setting.component.css']
})

export class PortalSettingComponent implements OnInit, OnChanges {
  @Input() data!: any[];
  @Input() title: string = '';
  @Input() form!: FormGroup;
  @Input() totalDocuments!: number;
  @Input() pageSize!: number;
  @Input() formType!: FormType;
  @Input() currentPage!: number;
  @Input() errorUpdatingForm!: boolean;
  @Input() logoLoader: boolean = false;
  @Input() resetFile!: boolean;
  @Output() editForm = new EventEmitter<{ group: FormGroup, type: FormType }>();
  @Output() editDocument = new EventEmitter<{ type: FormType, file: File | null }>();
  @Output() toggleStatus = new EventEmitter<{ event: any, group: FormGroup, type: FormType }>();
  @Output() pageChange = new EventEmitter<PageEvent>();
  @ViewChild('imageUploader') imageUploader!: ImageUploadComponent;
  protected environment = environment;
  role: string | null;
  selectedImage: string
  errorUploadingImage: boolean = false;
  editingRowId: string | null = null;


  constructor(private toaster: ToastrService,) {

  }

  ngOnInit(): void {
    this.role = localStorage.getItem('role');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['errorUpdatingForm'] && changes['errorUpdatingForm'].currentValue) {
      this.editingRowId = null;
    }
    if (this.errorUpdatingForm) {
      this.editingRowId = null;
    }
  }

  triggerUpload() {
    if (this.imageUploader) {
      if (!this.imageUploader.file) {
        this.imageUploader.uploadFileTrigger();
      } else {
        this.pageSize = 12
        this.currentPage = 0
        this.editDocument.emit({type: this.formType, file: this.imageUploader.file || null});
      }
    }
  }

  resetDocFile(): void {
    if (this.imageUploader) {
      this.imageUploader.resetFile();
    }
  }

  public getEnvironment(): any {
    return this.environment;
  }

  public getCsvFileName(formType: FormType): string {
    switch (formType) {
      case FormType.DOCUMENT:
        return 'sample-document-type.csv';
      case FormType.DESIGNATION:
        return 'sample-designation.csv';
      case FormType.SKILL:
        return 'sample-skill.csv';
      case FormType.VERTICAL:
        return 'sample-vertical.csv';
      case FormType.EDUCATION:
        return 'sample-education.csv';
      default:
        return '';
    }
  }

  onPageChange(newPage: PageEvent) {
    this.pageSize = newPage.pageSize
    this.currentPage = newPage.pageIndex
    this.pageChange.emit(newPage);
  }

  get getDocFormControls(): FormArray {
    return (this.form?.get(this.formType) as FormArray) ?? new FormArray([]);
  }

  originalValue: string = '';

  onEdit(group: FormGroup, formType: FormType) {
    if (this.editingRowId !== null && this.editingRowId !== group.get('id')?.value) {
      this.resetPreviousValue();
    }
    this.editingRowId = group.get('id')?.value;
    this.originalValue = group.get(formType === FormType.SKILL ? 'title' : 'name')?.value;
  }

  onSave(group: FormGroup, formType: FormType) {
    const controlName = formType === FormType.SKILL ? 'title' : 'name';
    let inputValue = group.get(controlName)?.value?.trim();

    if (!inputValue) {
      this.toaster.error('Value cannot be empty!', 'Error');
      group.get(controlName)?.setValue(this.originalValue);
      return;
    }

    group.get(controlName)?.setValue(inputValue);
    this.editForm.emit({ group, type: formType });
    this.editingRowId = null;
  }


  resetPreviousValue() {
    if (this.editingRowId) {
      const formArray = this.getDocFormControls;
      const prevGroup = formArray.controls.find(
        (control) => control.get('id')?.value === this.editingRowId
      );
      if (prevGroup) {
        prevGroup.get(this.formType === FormType.SKILL ? 'title' : 'name')?.setValue(this.originalValue);
      }
    }
  }

  onToggle(event: MatSlideToggleChange, group: FormGroup, formType: FormType) {
    this.toggleStatus.emit({event, group, type: formType});
  }

  changeControl(group: AbstractControl): FormGroup {
    return group as FormGroup;
  }

  FormType = FormType;
  protected readonly group = group;

  getTableName(formType: FormType): string {
    switch (formType) {
      case FormType.DOCUMENT:
        return 'Document Types';
      case FormType.DESIGNATION:
        return 'Designation';
      case FormType.SKILL:
        return 'Skills';
      case FormType.VERTICAL:
        return 'Verticals';
      case FormType.EDUCATION:
        return 'Education';
      default:
        return '';
    }
  }

  onCancelEdit() {
    this.resetPreviousValue();
    this.editingRowId = null;
  }

}
