import {Component, ElementRef, Inject, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ApiService} from "../../../../services/api.service";
import {
  ChildFormUpdates,
  ComponentName,
  CreateCustomer,
  FormUpdateEvent,
} from "../../../../shared/interfaces";
import {MatSnackBar} from "@angular/material/snack-bar";
import {PopUpComponent} from "../../../../util/pop-up/pop-up.component";
import {ToastrService} from "ngx-toastr";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {ImageCropperComponent} from "../../image-cropper/image-cropper.component";

@Component({
  selector: 'app-create-customers',
  templateUrl: './create-customers.component.html',
  styleUrls: ['./create-customers.component.css']
})
export class CreateCustomersComponent implements OnInit {

  customerForm: FormGroup;
  errorMessage: string | null;
  visible1 = true;
  visible2 = false;
  showloader = false;
  loading = false;
  customer: CreateCustomer = {} as any;
  type = '';
  customerError = '';
  role: string = '';
  companyName: '';
  cusDT: any;
  customerImage: any;
  logoLoader = false;
  dragOver: boolean = false;
  file: File | null;
  logoCid: string = '';
  customerLogo: any;
  audit: any[] = [];
  isRouteCustomerAnalytics = false
  @ViewChild('fileInput') fileInput: ElementRef<HTMLDivElement>;
  childFormUpdates: ChildFormUpdates = this.initializeChildFormUpdates();
  @Output() refreshOutputFlag: boolean = false;
  @Output() isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public customerData: any,
    private fb: FormBuilder,
    private service: ApiService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<CreateCustomersComponent>,
  ) {
  }

  ngOnInit(): void {
    if (this.customerData?.customerAnalyticsRoute) {
      this.isRouteCustomerAnalytics = true
    }
    this.customerLogo = this.customerData?.customerData?.logo
    this.dialogRef.disableClose = true;
    this.cusDT = this.customerData.customerDt;
    this.role = localStorage.getItem('role') ?? '';
    this.customerForm = this.fb.group({
      companyName: ["", Validators.required],
    });
    if (this.customerData.id) {
      this.type = 'edit';
      this.visible1 = false;
      this.visible2 = true;
      this.customerImage = this.cusDT?.logo;
      this.customer.logo = this.cusDT?.ve;
    } else {
      this.type = 'create';
      this.visible1 = true;
      this.visible2 = false;
    }
  }

  handleImageChange(event: any) {
    this.customerLogo = event
  }

  initializeChildFormUpdates(): ChildFormUpdates {
    const updates: ChildFormUpdates = {};
    for (const componentName of Object.values(ComponentName)) {
      updates[componentName] = false;
    }
    return updates;
  }

  handleFormUpdate(event: FormUpdateEvent) {
    this.childFormUpdates[event.componentName] = event.updated;
  }

  onSelectLogo(event: any, dragEvent?: boolean): void {
    event.preventDefault();
    this.logoLoader = true;
    this.file = this.getFileFromEvent(event, dragEvent);

    if (!this.file) {
      this.logoLoader = false;
      return;
    }

    if (this.isAllowedFileType(this.file.type)) {
      this.openImageCropperDialog(this.file, event);
    } else {
      this.handleUnsupportedFileType(this.file.type);
    }
  }

  getFileFromEvent(event: any, dragEvent?: boolean): File | null {
    if (dragEvent) {
      return event.dataTransfer?.files[0] || null;
    } else {
      return event.target?.files?.[0] || null;
    }
  }

  isAllowedFileType(fileType: string): boolean {
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    const disallowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    return allowedTypes.includes(fileType) && !disallowedTypes.includes(fileType);
  }

  openImageCropperDialog(file: File, event: any): void {
    const dialogRef = this.dialog.open(ImageCropperComponent, {
      width: '60%',
      data: {file}
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.processCropperResult(result);
      } else {
        this.handleDialogCloseWithoutResult();
      }
      (event.target as HTMLInputElement).value = '';
    });
  }

  processCropperResult(result: any): void {
    if (result.blob instanceof Blob) {
      this.handleBlobResult(result.blob);
    } else if (result.base64) {
      this.handleBase64Result(result.base64);
    } else {
      console.error('Result is not a valid Blob or base64 string:', result);
      this.resetFile();
    }
  }

  handleUnsupportedFileType(fileType: string): void {
    const errorMessage = fileType.includes('pdf') || fileType.includes('doc') ? 'PDF and DOC files are not allowed.' : 'Unsupported file type.';
    console.error(errorMessage);
    this.toastr.error(errorMessage, 'Error');
    this.logoLoader = false;
  }

  handleBlobResult(blob: Blob): void {
    const filename = this.file?.name || 'cropped_image.png';
    const file = new File([blob], filename, {type: blob.type});
    this.uploadFile(file);
  }

  handleBase64Result(base64: string): void {
    const file = this.base64ToFile(base64, this.file?.name || 'cropped_image.png');
    if (file) {
      this.uploadFile(file);
    } else {
      console.error('Failed to convert base64 to file');
      this.resetFile();
    }
  }

  uploadFile(file: File): void {
    const formData: FormData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);

    const uploadObservable = this.type === 'edit' || this.visible2
      ? this.service.uploadCustomerLogo(formData, this.customerData.id)
      : this.service.uploadNewCustomerLogo(formData);

    uploadObservable.subscribe(res => {
      if (res.type === 4) {
        this.updateCustomerData(res.body.logo, res.body.url, res.body.cid);
      }
    });
  }

  updateCustomerData(logo: string, url: string, cid: string): void {
    this.logoLoader = false;
    this.customerImage = logo || url;
    this.customerData.logo = this.customerImage;
    this.customerLogo = this.customerImage;
    if (cid) {
      this.logoCid = cid;
    }
  }

  resetFile(): void {
    this.file = null;
    this.logoLoader = false;
  }

  base64ToFile(data: string, filename: string): File | null {
    if (!data) {
      console.error('No data provided to base64ToFile');
      return null;
    }

    const arr = data.split(',');
    if (arr.length < 2) {
      console.error('Invalid base64 data:', data);
      return null;
    }

    const mimeMatch = arr[0].match(/:(.*?);/);
    if (!mimeMatch) {
      console.error('Invalid MIME type in base64 data:', arr[0]);
      return null;
    }

    const mime = mimeMatch[1];
    const bstr = atob(arr[1]);
    const u8arr = new Uint8Array(bstr.length);
    for (let i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, {type: mime});
  }

  handleDialogCloseWithoutResult(): void {
    console.error('Dialog closed without result');
    this.resetFile();
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    setTimeout(() => {
      this.dragOver = true;
    }, 2000);
  }

  onDragEnter(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    setTimeout(() => {
      this.dragOver = false;
    }, 2000);
  }

  remove(): void {
    this.logoLoader = true;
    this.customerImage = '';
    this.customerLogo = '';
    this.logoLoader = false;
  }

  delete(): void {
    this.logoLoader = true;
    this.service.deleteCustomerLogo(this.customerData.id).then(res => {
      this.customerImage = null;
      this.customerData.logo = "";
      this.logoLoader = false;
    }).catch((error: any) => {
      if (error) {
        this.logoLoader = false;
      }
    });
  }

  close() {
    const isDirtyForm = Object.values(this.childFormUpdates).some(value => value === true);
    if (isDirtyForm) {
      const confirmationBox = this.dialog.open(PopUpComponent, {
        data: {
          message: 'Do you want to close this without saving',
          type: 'confirmation'
        }
      });
      confirmationBox.afterClosed().subscribe((res) => {
        if (res) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  setCompanyName(e: any) {
    this.companyName = e
  }

  addNewCustomer(): void {
    let input: CreateCustomer = this.customerForm?.value;
    input.logoCid = this.logoCid;
    input.logo = this.customerImage;
    this.customerError = '';
    // @ts-ignore
    const descriptionValue = this.customerForm.get('companyName').value;
    if (descriptionValue.invalid || !descriptionValue.trim()) {
      this.showloader = false;
      this.toastr.info('Customer cannot be created empty or contain only whitespace.', 'Error');
      return;
    }
    this.showloader = true;
    if (this.customerForm.invalid) {
      this.showloader = false;
      this.customerError = 'error in ' + this.getFormValidationErrors() + 'see console for details';
      return;
    } else {
      this.service.createCustomer(input).subscribe({
        next: res => {
          this.loading = false;
          this.customerData = res
          console.log(this.customerData, 'data res')
          this.companyName = res.companyName;
          this.type = 'create';
          this.dialogRef.close({id: res.id})
        }, error: (e) => {
          if (e.error) {
            this.loading = false;
            this.dialogRef.disableClose;
          }
        }
      })
    }
  }

  getFormValidationErrors(): string {
    let error = '';
    Object.keys(this.customerForm.controls).forEach(key => {
      const controlErrors = this.customerForm.get(key)?.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          error = error + key + ', ';
        });
      }
    });
    return error;
  }

  updatedPayload() {
    let newPayload = this.customerForm.value;
    return newPayload;
  }

  onTabChange(event: MatTabChangeEvent): void {
    if (event.index === 3) {
      //this.callAuditHistoryApi();//Call the method to fetch audit history
      console.log("Api call from  create customer");
      this.refreshOutputFlag = true;
      this.isLoading = true;
    }
  }

}
