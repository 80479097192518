import {Component, Input, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Candidate, CustomerWeb, NumberFields} from "../../../../shared/interfaces";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {ApiService} from "../../../../services/api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {NgDialogAnimationService} from "ng-dialog-animation";
import {ResetFiltersService} from "../../../../services/resetFilters.service";
import {Router} from "@angular/router";
import {NavigationService} from "../../../../services/navigation.service";
import {finalize} from "rxjs/operators";
import {SolutionNavigationService} from "../../../../services/solutionNavigationService";
import {renderMobileNumber} from "../../../../common/renderMobileNumber";
import {SortTable} from "../../admin-vendors/admin-vendors.component";

@Component({
  selector: 'app-web-customer-listing',
  templateUrl: './web-customer-listing.component.html',
  styleUrls: ['./web-customer-listing.component.css']
})

export class WebCustomerListingComponent implements OnInit, OnDestroy {
  searchFieldNameForCustomer: string[] = ['customerId', 'contactName', 'companyName', 'email', 'mobile', 'verificationStatusRequests', 'location', 'dateSearchCriteria',];
  customerFilters = {
    query: '',
    contactName: '',
    companyName: '',
    email: '',
    emailSentStatus: '',
    mobile: '',
    verificationStatusRequests: [],
    location: '',
    customerId: '',
    dateSearchCriteria: {filterType: 'CREATED', from: '', to: '',},
  };
  customers: CustomerWeb[] = [];
  showLoader: boolean = true;
  search = '';
  customerSearch = '';
  pageSize = 12;
  totalCustomer = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  @Input() currentPage!: number;
  @Input() selectedTab!: number;
  sorts: SortTable[] = [];
  sort = '';

  constructor(
    private service: ApiService,
    public snackBar: MatSnackBar,
    public dialog: NgDialogAnimationService,
    private resetFilters: ResetFiltersService,
    private router: Router,
    private navigationService: NavigationService,
    private SolutionNavigationService: SolutionNavigationService,
  ) {
  }

  ngOnInit(): void {
    this.SolutionNavigationService.clearState()
    this.sorts.push({active: false, field: 'customerId', direction: null});
    const state = this.navigationService.getState();
    if (state) {
      this.currentPage = state.pageNumber;
      window.scrollTo(0, state.scrollPosition);
      this.getAllWebCustomers(this.currentPage, this.pageSize, this.customerFilters);
    } else {
      this.getAllWebCustomers(0, this.pageSize, this.customerFilters);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedTab'] && !changes['selectedTab'].firstChange) {
      this.getAllWebCustomers(0, this.pageSize, this.customerFilters);
    }
  }

  onFiltersChangedCustomer(updatedFilters: any) {
    const filteredFilters = {...updatedFilters};
    delete filteredFilters.locationsPref;
    this.customerFilters = {
      ...filteredFilters,
      query: this.customerSearch,
      locationPreference: updatedFilters?.locationsPref ?? []
    };
    this.paginator.pageIndex = 0;
    this.customerFilters.query = this.customerSearch;
    this.getAllWebCustomers(0, this.pageSize, this.customerFilters);
  }

  onCustomerPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getAllWebCustomers(this.currentPage, this.pageSize, this.customerFilters);
  }

  updateCustomerSearch() {
    this.customerFilters.query = this.customerSearch;
    this.paginator.pageIndex = 0;
    this.getAllWebCustomers(this.currentPage, this.pageSize, this.customerFilters);
  }

  getAllWebCustomers(page: number, size: number, payload: any): void {
    this.showLoader = true;
    this.service.getWebCustomer(page, size, this.sort, payload).pipe(
      finalize(() => this.navigationService.clearState())
    ).subscribe({
      next: res => {
        this.customers = res.content;
        this.paginator.pageIndex = res.page.number;
        this.paginator.pageSize = res.page.size;
        this.pageSize = res.page.size;
        this.totalCustomer = res.page.totalElements;
        this.showLoader = false;
      },
      error: err => {
        console.log('error', err);
        this.showLoader = false;
      },
      complete: () => {
        this.showLoader = false;
      }
    });
  }

  clearFilter() {
    this.customerFilters = {
      query: '',
      contactName: '',
      customerId: '',
      companyName: '',
      email: '',
      emailSentStatus: '',
      mobile: '',
      verificationStatusRequests: [],
      location: '',
      dateSearchCriteria: {filterType: 'CREATED', from: '', to: '',},
    };
    this.customerSearch = ''
    this.resetFilters.emitChange(true);
    this.getAllWebCustomers(0, this.pageSize, this.customerFilters);
  }

  isFilterApplied(): boolean {
    return (
      (this.customerFilters?.query?.trim() ?? '') !== '' ||
      (this.customerFilters?.contactName?.trim() ?? '') !== '' ||
      (this.customerFilters?.customerId?.trim() ?? '') !== '' ||
      (this.customerFilters?.companyName?.trim() ?? '') !== '' ||
      (this.customerFilters?.email?.trim() ?? '') !== '' ||
      (this.customerFilters?.emailSentStatus?.trim() ?? '') !== '' ||
      (this.customerFilters?.mobile?.trim() ?? '') !== '' ||
      (this.customerFilters?.verificationStatusRequests?.length ?? 0) > 0 ||
      (this.customerFilters?.location?.trim() ?? '') !== '' ||
      (this.customerFilters?.dateSearchCriteria?.from ?? '') !== '' ||
      (this.customerFilters?.dateSearchCriteria?.to ?? '') !== ''
    );
  }


  navigateToEditSolution(solutionId: number) {
    const scrollPosition = window.scrollY;
    this.navigationService.saveState(scrollPosition, this.currentPage, this.router.url, 1);
    this.router.navigate(['/dashboard/web_data/customer', solutionId]);
  }

  getSort(field: string): SortTable {
    return this.sorts.find(column => column.field === field)!;
  }

  toggleSort(field: string): void {
    const sortColumn = this.getSort(field);
    this.sorts.forEach(column => {
      if (column.field !== field) {
        column.active = false;
        column.direction = null;
      }
    });
    if (!sortColumn.active) {
      sortColumn.active = true;
      sortColumn.direction = 'asc';
    } else if (sortColumn.direction === 'asc') {
      sortColumn.direction = 'desc';
    } else {
      sortColumn.direction = null;
      sortColumn.active = false;
    }
    if (sortColumn.direction) {
      this.sort = `id,${sortColumn.direction}`;
      this.getAllWebCustomers(0, this.pageSize, this.customerFilters);

    } else {
      this.sort = '';
      this.getAllWebCustomers(0, this.pageSize, this.customerFilters);
    }
  }

  ngOnDestroy(): void {
  }

  dateTypeChanged($event: boolean) {
    if ($event === true) {
      this.customerFilters.dateSearchCriteria.filterType = "UPDATED";
    } else if ($event === false) {
      this.customerFilters.dateSearchCriteria.filterType = "CREATED";
    }
    this.getAllWebCustomers(0, this.pageSize, this.customerFilters);
  }

  renderMobileNumber = renderMobileNumber;
}
