import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Observable} from "rxjs/internal/Observable";
import {DocsType, DocType, FormUpdateEvent} from "../../../../shared/interfaces";
import {map, startWith} from "rxjs/operators";
import {ApiService} from "../../../../services/api.service";
import {PopUpComponent} from "../../../../util/pop-up/pop-up.component";
import {MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import * as moment from "moment/moment";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {DatePipe} from "@angular/common";
import {MatPaginator} from "@angular/material/paginator";
import {IsDataUpdatedService} from "../../../../services/isDataUpdated.service";

@Component({
  selector: 'app-consultant-documents',
  templateUrl: './consultant-documents.component.html',
  styleUrls: ['./consultant-documents.component.css']
})
export class ConsultantDocumentsComponent implements OnInit {
  filteredDocs: Observable<DocsType[]>;
  typeId = '';
  docTypeName = '';
  docNameOther = '';
  consultantDocuments = '';
  documentExpiryDate: '' | undefined;
  documentStartDate: '' | undefined;
  @ViewChild('docInput') docInput: ElementRef<HTMLDivElement>;
  @ViewChild('documentSelectDiv') documentSelectDiv: ElementRef<HTMLDivElement>;
  typeRes: DocsType[] = [];
  doc: File | any;
  docError = '';
  docName = '';
  docId = '';
  showloader = false;
  vendorDocuments = '';
  consultantDocs: any[] = [];
  totalPages = 0;
  currentPage: number = 0;
  showArchived: boolean = false;
  documentForm: FormGroup;
  minEndDate: string | null;
  @ViewChild('attachmentAutoCompleter', {read: MatAutocompleteTrigger})
  attachmentAutoCompleter: MatAutocompleteTrigger;
  docControl = new FormControl('')
  @Input() consultantData: any;
  previousFormValue: any;
  initializingForm: boolean = true;
  @Output() formUpdated = new EventEmitter<FormUpdateEvent>();
  totalDocument = 0;
  pageSize = 5;
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  @ViewChild('input', {static: true}) input: ElementRef | any;
  errorUploadingDoc: boolean = false;
  resetFile: boolean = false;

  constructor(
    private service: ApiService,
    public dialog: MatDialog,
    private toaster: ToastrService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private isDataUpdatedService: IsDataUpdatedService,
  ) {

  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
    this.getDocType();
    this.getConsultantDocuments(this.currentPage, this.pageSize, this.showArchived);
    this.documentForm = this.fb.group({
      startDate: [""],
      endDate: [""],
      document: [""],
    });
  }

  scrollEvent = (event: any): void => {
    if (this.attachmentAutoCompleter.panelOpen)
      this.attachmentAutoCompleter.updatePosition();
  };

  chooseDocType(data: any): void {
    const _type = data?.source?.value?.id;
    if (data.isUserInput) {
      this.typeId = _type;
      this.docTypeName = data.source.value?.name;
    } else {
      return
    }
  }

  handlePageDocument(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getConsultantDocuments(this.currentPage, this.pageSize, this.showArchived);
  }

  getDocType() {
    this.service.getDocumentType().then(response => {
      this.typeRes = [];
      response.map((e: DocType) => {
        if (e.active == true) {
          this.typeRes.push(e);
        }
      });
      this.filteredDocs = this.docControl.valueChanges.pipe(
        startWith(''),
        map(value => {
            const name = typeof value === 'string' ? value.trim() : value?.name.trim() ?? "";
            return name ? this._docsFilter(name as string) : this.typeRes.slice();
          }
        ),
      )
      this.typeRes.push({name: 'others', id: 0, seq: '0', active: true});
    });
  }

  addDocType(event: any, type: string, docTypeName: string): void {
    if (type == 'doc') {
      let payload = {
        'name': docTypeName,
      }
      this.service.addOrgDocType(payload).then(res => {
        this.isDataUpdatedService.setUpdated(true);
        this.getDocType();
        this.typeId = res?.id;
        this.displayDocFn(res);
      }, (e) => {
        console.log('error', e.error.message);
      })
    }
  }

  _docsFilter(name: string): DocsType[] {
    const filterValue = name.toLowerCase();
    return this.typeRes.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  displayDocFn(doc: DocsType): string {
    return doc && doc.name ? doc.name : '';
  }

  onSelectDocument(event: any, dragEvent: boolean): void {
    if (dragEvent) {
      this.doc = event.dataTransfer.files[0];
    } else {
      this.doc = event.target.files[0];
    }
    if (this.doc?.size > 10485760) {
      this.docError = 'File Size should not exceed 10 MB';
    } else if (this.typeId == null) {
      this.docError = 'Please Select Document Type';
    } else {
      this.docError = '';
      const formData: FormData = new FormData();
      formData.append('fileName', this.doc?.name ?? '');
      formData.append('file', this.doc);
      formData.append('name', this.typeId);
      this.consultantDocuments = this.doc?.name ?? '';
      event.preventDefault();
    }
  }

  fileSizeFormattor(bytes: number): string {
    var units = ['B', 'KB', 'MB', 'GB', 'TB'],
      i;
    for (i = 0; bytes >= 1024 && i < 4; i++) {
      bytes /= 1024;
    }
    return bytes?.toFixed(2) + units[i];
  }

  deleteConfirmation(action: string, docId: string, archived: boolean) {
    if (action === 'Delete') {
      const dialogRef2 = this.dialog.open(PopUpComponent, {
        data: {
          title: 'Alert',
          message: 'Are you sure you want to Delete this document?',
          type: 'confirmation'
        }
      });
      dialogRef2.afterClosed().subscribe((res) => {
        if (res) {
          this.service.consultantDocumentDelete(this.consultantData?.id, docId).then(() => {
            this.isDataUpdatedService.setUpdated(true);
            this.getConsultantDocuments(0, this.pageSize, this.showArchived);
            this.consultantDocuments = '';
            this.doc = null;
            this.docName = '';
            this.toaster.success('Document Deleted', 'Success');
          }).catch((err) => {
            this.toaster.error(err.error.reason, 'Request Failed');
          })
        }
      })
    } else if (action === 'Archive') {
      const dialogRef2 = this.dialog.open(PopUpComponent, {
        data: {
          title: 'Alert',
          message: 'Are you sure you want to Archive this document?',
          type: 'confirmation'
        }
      });
      dialogRef2.afterClosed().subscribe((res) => {
        if (res) {
          this.service.consultantDocumentTrash(docId).then(() => {
            this.isDataUpdatedService.setUpdated(true);
            this.getConsultantDocuments(0, this.pageSize, this.showArchived);
            this.vendorDocuments = '';
            this.doc = null;
            this.docName = '';
            this.toaster.success('Document Archived', 'Success');
          }).catch((err) => {
            this.toaster.error(err.error.reason, 'Request Failed');
          })
        }
      })
    }
  }

  getConsultantDocuments(page: number, size: number, archived: boolean): void {
    this.service.getConsultantDocuments(this.consultantData?.id, page, size, archived).subscribe(res => {
      this.consultantDocs = res.content;
      this.totalDocument = res.totalElements
      this.paginator.pageIndex = res.number;
      this.paginator.pageSize = res.size;
      this.pageSize = res.size;
      this.currentPage = res.number;
      this.typeId = '';
      if (this.consultantDocuments !== '') {
        this.consultantDocuments = res.content.url;
        this.docId = res.content.id;
      }
    });
  }

  goToLink(url: string) {
    window.open(url, " ");
  }

  updateEndDateMinDate() {
    if (this.documentStartDate) {
      const startDate = new Date(this.documentStartDate);
      this.documentExpiryDate = '';
      startDate.setDate(startDate.getDate() + 1);
      this.minEndDate = startDate.toISOString().split('T')[0];
    } else {
      this.minEndDate = null;
    }
  }

  onDocUpload(): void {
    this.showloader = true;
    if (this.docControl.invalid) {
      this.toaster.error('Document label is required');
      this.showloader = false;
      return;
    }
    const formData: FormData = new FormData();
    formData.append('fileName', this.docName);
    formData.append('file', this.doc);
    formData.append('startDate', this.datePipe.transform(this.documentStartDate, 'yyyy-MM-dd') ?? '');
    formData.append('endDate', this.datePipe.transform(this.documentExpiryDate, 'yyyy-MM-dd') ?? '');
    if (this.docNameOther && this.typeId == '0') {
      formData.append('name', this.docNameOther);
    }
    formData.append('typeId', this.typeId);
    this.service.uploadConsultantDocument(formData, this.consultantData.id).subscribe((res => {
        if (res.type === 4) {
          this.isDataUpdatedService.setUpdated(true);
          this.docTypeName = '';
          this.showloader = false;
          this.vendorDocuments = res.body.url
          this.docId = res.body.id;
          this.doc = null;
          this.typeId = '';
          this.documentStartDate = '';
          this.documentExpiryDate = '';
          this.docTypeName = '';
          this.docControl.reset();
          this.docControl.setErrors(null);
          this.errorUploadingDoc = false;
          this.toaster.success('Document Successfully uploaded');
          this.resetFile = true;
          setTimeout(() => this.resetFile = false, 0);
          this.getConsultantDocuments(this.currentPage, this.pageSize, this.showArchived);
          if (this.docError != '') {
            this.docError = '';
          }
        }
      }), (e) => {
        this.showloader = false;
        this.errorUploadingDoc = true
        this.toaster.error('Document error on upload');
        if (e.error.status == 400) {
          this.docError = 'Please select a valid file  ';
        } else if (e.error.status == 500) {
          this.docError = e.error.message;
        }
      }
    );
  }

  restoreTrashedDocument(docId: string) {
    this.service.consultantDocumentRestore(docId).then(() => {
      this.isDataUpdatedService.setUpdated(true);
      this.getConsultantDocuments(0, this.pageSize, this.showArchived);
      this.toaster.success('Document restored', 'Success');
    })
  }

  hideWidget(creationTime: any) {
    let date = moment(creationTime).diff(new Date(), "days");
    return date === 0;
  }

  changeArchived(event: any) {
    this.showArchived = event.checked;
    this.getConsultantDocuments(this.currentPage, this.pageSize, this.showArchived);
  }

  handleFileChange(event: File | null) {
    if (!event) {
      return;
    } else {
      this.doc = event
    }
  }

}
