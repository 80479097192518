<div class="content-wrapper">
  <div class="row mb-3">
    <div class="col-md-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h5 class="page-title">Manage Company Profile</h5>
      </div>
    </div>
  </div>
  <div class="col-md-12 companyProfile_wrapper">
    <div class="companyProfile_header theme-bg-color">
      <h5>Company Profile</h5>
      <span class="font-weight-light">Update your company logo and details here</span>
    </div>
    <div class="companyProfile_content">
      <div class="col-md-8">
        <form [formGroup]="organisationForm" class="form-wrapper" (submit)="updateOrganisation()">
          <div class="form-group">
            <div class="form-row mb-2">
              <div class="form-group col-md-12">
                <mat-form-field appearance="outline" class="w-100 border-red">
                  <mat-label>Company name</mat-label>
                  <input appMaxCharLimitInput matInput formControlName="name" type="text" id="inputfirstName" placeholder="Company name"
                         appNoSpecialChars [readOnly]="role!='ADMIN'">
                  <mat-error>
                    {{MaxCharLimits.INPUT}}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-group col-md-12">
                <mat-form-field appearance="outline" class="w-100 border-red">
                  <mat-label>Website</mat-label>
                  <input appMaxCharLimitUrl matInput formControlName="webUrl" id="inputEmail" placeholder="Company website"
                         [readOnly]="role!='ADMIN'">
                  <mat-error>
                    {{MaxCharLimits.URL}}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="form-group btns d-flex justify-content-end save-form :save-form-disbale">
            <button *ngIf="!showloader " class="btn btn-save theme-btn-color" type="submit">Save Details</button>
            <div *ngIf="showloader" disabled>
              <button id="submitButton1" class="btn btn-saveform" disabled>
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-md-4 border-left">
        <div class="vendor_wrapper mt-0 h-100">
          <div class="upload_doc text-center">
            <div class="mb-4">
              <h5>Upload company logo</h5>
            </div>
            <app-image-upload [selectedImage]="selectedImage"
                              (fileChange)="handleFileChange($event)"
                              [logoLoader]="logoLoader" [errorUploadingImage]="errorUploadingImage"
                              (deleteConsultantProfileConfirmation)="handleProfileDelete($event)"
                              [height]="162" [width]="500"
            >
            </app-image-upload>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
