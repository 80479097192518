import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {debounceTime, delay, distinctUntilChanged, filter, map, takeUntil, tap} from "rxjs/operators";
import {ComponentName, CreateCustomer, FormUpdateEvent, PlaceDto} from "../../../../shared/interfaces";
import {MatDialogRef} from "@angular/material/dialog";
import {ApiService} from "../../../../services/api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ToastrService} from "ngx-toastr";
import {NgDialogAnimationService} from "ng-dialog-animation";
import {CreateCustomersComponent} from "../create-customers/create-customers.component";
import {Subject} from "rxjs";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {IsDataUpdatedService} from "../../../../services/isDataUpdated.service";
import {CustomValidators} from "../../../../common/custom-validators";
import {MaxCharLimits} from "../../../../common/errorInputMaxValue";

@Component({
  selector: 'app-customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.css']
})

export class CustomerInfoComponent implements OnInit {
  @Input() customerData: any;
  @Output() companyName = new EventEmitter<string>();
  @Output() customerDt = new EventEmitter<any>();
  countryControl = new FormControl();
  selectedCountry: any | undefined;
  selectedState: any | undefined;
  selectedCity: any | undefined;
  countryServerCtrl = new FormControl();
  stateServerCtrl = new FormControl();
  cityServerCtrl = new FormControl();
  searchState: boolean = false;
  searchCity: boolean = false;
  searchCountry: boolean = false;
  stateControl = new FormControl('');
  cityControl = new FormControl('');
  customerForm: FormGroup;
  errorMessage: string | null;
  visible1 = true;
  visible2 = false;
  showloader = false;
  displayError = false;
  loading = false;
  customer: CreateCustomer = {} as any;
  type = '';
  customerError = '';
  protected _onDestroy = new Subject<void>();
  countries: PlaceDto[] = [];
  states: PlaceDto[] = [];
  cities: PlaceDto[] = [];
  role: string = '';
  categories = [
    {value: 'PREMIUM', label: 'Tier 1 - Premium Customer'},
    {value: 'REGULAR', label: 'Tier 2 - Regular Customer'},
    {value: 'PARTNER', label: 'Tier 3 - Partner Customer'}
  ];
  previousFormValue: any;
  initializingForm: boolean = true;
  @Output() formUpdated = new EventEmitter<FormUpdateEvent>();
  @Input() isRouteCustomerAnalytics!: boolean;
  @ViewChild('autoCompleteInput1', {read: MatAutocompleteTrigger})
  autoComplete1: MatAutocompleteTrigger;
  @ViewChild('autoCompleteInput2', {read: MatAutocompleteTrigger})
  autoComplete2: MatAutocompleteTrigger;
  customerRes: any

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CreateCustomersComponent>,
    private service: ApiService,
    public snackBar: MatSnackBar,
    private toastr: ToastrService,
    public dialog: NgDialogAnimationService,
    private isCustomerDataUpdated: IsDataUpdatedService,
  ) {
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
    this.role = localStorage.getItem('role') ?? '';
    this.customerForm = this.fb.group({
      customerId: [""],
      companyName: [""],
      ceoName: ["", [Validators.required, Validators.pattern(/^[A-Za-z ]+$/)]],
      number: ["", Validators.required],
      email: ["", [Validators.required, CustomValidators.validEmail()]],
      website: [""],
      category: [""],
      address: this.fb.group({
          country: this.fb.group({
            id: [''],
            name: [''],
            formattedAddress: [''],
          }, Validators.required),
          state: this.fb.group({
            id: [''],
            name: [''],
            formattedAddress: [''],
          }, Validators.required),
          city: this.fb.group({
            id: [''],
            name: [''],
            formattedAddress: [''],
          }, Validators.required),
          streetAddress: [''],
          postalCode: [''],
        }
      ),
    });
    if (this.customerData.id) {
      this.type = 'edit';
      this.visible1 = false;
      this.visible2 = true;
      this.service.getCustomerById(this.customerData.id).subscribe(
        {
          next: (res) => {
            this.customerDt.emit(res);
            this.customerRes = res
            this.companyName.emit(res.companyName);
            this.customerForm.get("id")?.setValue(res.id);
            this.customerForm.get("customerId")?.setValue(res.customerId);
            this.customerForm.get("companyName")?.setValue(res.companyName);
            this.customerForm.get("ceoName")?.setValue(res.ceoName);
            this.customerForm.get("number")?.setValue(res.number?.mobile);
            this.customerForm.get("email")?.setValue(res.email);
            this.customerForm.get("website")?.setValue(res.website);
            this.customerForm.get("category")?.setValue(res.category);
            this.customerForm.get('address')?.get("streetAddress")?.setValue(res.address.streetAddress);
            this.customerForm.get('address')?.get("postalCode")?.setValue(res.address.postalCode);
            if (res.address.country) {
              this.selectedCountry = res.address.country;
              if (res.address.country) {
                this.countries = [res.address.country];
                this.customerForm?.get('address')?.get("country")?.get('id')?.setValue(res?.address.country.id);
                this.customerForm?.get('address')?.get("country")?.get('name')?.setValue(res?.address.country.name);
                this.customerForm?.get('address')?.get("country")?.get('formattedAddress')?.setValue(res?.address.state.formattedAddress);
                this.countryControl.setValue(this.selectedCountry);
              }
              this.selectedState = res.address.state;
              if (res.address.state) {
                this.states = [res.address.state];
                this.customerForm?.get('address')?.get("state")?.get('id')?.setValue(res?.address.state.id);
                this.customerForm?.get('address')?.get("state")?.get('name')?.setValue(res?.address.state.name);
                this.customerForm?.get('address')?.get("state")?.get('formattedAddress')?.setValue(res?.address.state.formattedAddress);
                this.stateControl.setValue(this.selectedState);
              }
              this.selectedCity = res.address.city
              if (res.address.city) {
                this.cities = [res.address.city]
                this.customerForm?.get('address')?.get("city")?.get('id')?.setValue(res?.address.city.id);
                this.customerForm?.get('address')?.get("city")?.get('name')?.setValue(res?.address.city.name);
                this.customerForm?.get('address')?.get("city")?.get('formattedAddress')?.setValue(res?.address.state.formattedAddress);
                this.cityControl.setValue(this.selectedCity);
              }
            } else {
              this.selectedCountry = {id: 101, name: "India", formatedAddress: "India"};
              this.countries = [{id: 101, name: "India", formattedAddress: "India"}];
            }
            this.onSearchCountry();
            this.onSearchState();
            this.onSearchCities();
          },
          error: err => {
            console.error('err', err);
          },
          complete: () => {
            this.previousFormValue = this.customerForm.value;
            this.initializingForm = false;
          }
        }
      )
    }
    this.customerForm.valueChanges.pipe(
      debounceTime(500),
      filter(() => !this.initializingForm),
      map(currentValue => {
        const prevValue = {...this.previousFormValue};
        const currValue = {...currentValue};
        console.log('prevValue', prevValue);
        console.log('currValue', currValue);
        let prevNumber = prevValue.number ?? '';
        let currNumber = currValue.number?.number ?? '';
        console.log('currNumber', currNumber);
        console.log('prevNumber', prevNumber);
        if (currValue.number?.countryCode === 'IN') {
          if (prevNumber?.startsWith('0')) {
            prevNumber = prevNumber.replace(/^0+/, '');
          }
          if (currNumber?.startsWith('0')) {
            currNumber = currNumber.replace(/^0+/, '');
          }
          prevNumber = prevNumber.replace(/\s+/g, '');
          currNumber = currNumber.replace(/\s+/g, '');
        }

        delete prevValue.number;
        delete currValue.number;
        console.log('currValue!', currValue);
        console.log('prevValue!', prevValue);
        return JSON.stringify(currValue) !== JSON.stringify(prevValue) || prevNumber !== currNumber;
      }),
      distinctUntilChanged()
    ).subscribe(isFormUpdated => {
      console.log('isFormUpdated',isFormUpdated);
      this.formUpdated.emit({updated: isFormUpdated, componentName: ComponentName.CustomerInfo});
    });

    this.checkAndDisableForm();
  }

  checkAndDisableForm() {
    if (this.isRouteCustomerAnalytics) {
      this.customerForm.disable();
    }
  }

  scrollEvent = (event: any): void => {
    if (this.autoComplete1?.panelOpen) {
      this.autoComplete1.updatePosition();
    } else if (this.autoComplete2?.panelOpen) {
      this.autoComplete2.updatePosition();
    }
  }

  close() {
    this.dialogRef.close();
  }

  onInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    const numericValue = inputValue.replace(/\D/g, '');
    if (numericValue.length === 10) {
      this.displayError = false;
      this.errorMessage = "";
    } else {
      this.displayError = true;
      this.errorMessage = "Please enter a valid 10-digit mobile number";
    }
    inputElement.value = numericValue;
  }

  updateCustomer(): void {
    this.customerError = '';
    this.showloader = true;
    if (this.customerForm.invalid) {
      this.showloader = false;
      this.customerError = 'error in ' + this.getFormValidationErrors() + 'see console for details';
      return;
    }
    if (this.customerData.id) {
      this.service.updateCustomer(this.customerData.id, this.updatedPayload()).subscribe({
        next: () => {
          this.isCustomerDataUpdated.setUpdated(true);
          this.toastr.success('Customer Information Updated ', 'Success');
          this.formUpdated.emit({updated: false, componentName: ComponentName.CustomerInfo});
          this.errorMessage = '';
        },
        error: err => {
          console.log('err', err);
          this.dialogRef.disableClose
        },
      })
    }
  }

  getFormValidationErrors(): string {
    let error = '';
    Object.keys(this.customerForm.controls).forEach(key => {
      const controlErrors = this.customerForm.get(key)?.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          error = error + key + ', ';
        });
      }
    });
    return error;
  }

  updatedPayload() {
    return {
      ...this.customerForm.value,
      number: {
        countryCode: this.customerForm?.value?.number?.countryCode,
        dialCode: this.customerForm?.value?.number?.dialCode,
        e164Number: this.customerForm?.value?.number?.e164Number,
        internationalNumber: this.customerForm?.value?.number?.internationalNumber,
        nationalNumber: this.customerForm?.value?.number?.nationalNumber,
        mobile: this.customerForm?.value?.number?.number,
      }
    };
  }

  compareFunction(optionValue: PlaceDto, selectedValue: PlaceDto): boolean {
    return optionValue && selectedValue && optionValue === selectedValue;
  }

  selectCountry(country: PlaceDto): void {
    this.customerForm?.get("address")?.get("country")?.setValue(country);
    this.selectedState = undefined;
    this.selectedCity = undefined;
    this.selectedCountry = country;
    this.customerForm?.get("address")?.get("state")?.reset();
    this.customerForm?.get("address")?.get("city")?.reset();
    this.onSearchState();
  }

  onSearchCountry() {
    this.countryServerCtrl.valueChanges.pipe(
      filter(search => !!search),
      tap(() => this.searchCountry = true),
      takeUntil(this._onDestroy),
      debounceTime(500),
      distinctUntilChanged(),
      map(search => {
        return this.getCountries(search)
      }),
      delay(500),
    ).subscribe({
      next: filteredCountries => {
        this.searchCountry = false;
      }, error: err => {
        this.searchCountry = false;
      }
    });
  }

  getCountries(query: string): void {
    this.service.getCountries(query).then((res: any) => {
      this.countries = this.selectedCountry ? [this.selectedCountry, ...res] : res;
      return (res);
    });
  }

  onSearchState() {
    this.stateServerCtrl.valueChanges.pipe(
      filter(search => !!search),
      tap(() => this.searchState = true),
      takeUntil(this._onDestroy),
      debounceTime(500),
      distinctUntilChanged(),
      map(search => {
        return this.getStates(search)
      }),
      delay(500),
    ).subscribe({
      next: filteredCountries => {
        this.searchState = false;
      }, error: err => {
        this.searchState = false;
      }
    });
  }

  getStates(query: string): void {
    this.service.getStates(this.customerForm?.get("address")?.get("country")?.get('id')?.value, query).then(res => {
      this.states = this.selectedState ? [this.selectedState, ...res] : res;
    });
  }

  selectState(state: PlaceDto): void {
    this.customerForm?.get("address")?.get("state")?.get('id')?.setValue(state.id);
    this.customerForm?.get("address")?.get("state")?.get('name')?.setValue(state.name);
    this.customerForm?.get("address")?.get("state")?.get('formattedAddress')?.setValue(state.formattedAddress);
    this.selectedState = state;
    this.selectedCity = undefined;
    this.customerForm?.get("address")?.get("city")?.reset();
  }

  onSearchCities() {
    this.cityServerCtrl.valueChanges.pipe(
      filter(search => !!search),
      tap(() => this.searchCity = true),
      takeUntil(this._onDestroy),
      debounceTime(500),
      distinctUntilChanged(),
      map(search => {
        return this.getCities(search)
      }),
      delay(500),
    ).subscribe({
      next: filteredCountries => {
        this.searchCity = false;
      }, error: err => {
        this.searchCity = false;
      }
    });
  }

  getCities(query: string): void {
    this.service.getCities(this.customerForm?.get("address")?.get("state")?.get('id')?.value, query).then(res => {
      this.cities = this.selectedCity ? [this.selectedCity, ...res] : res;
    });
  }

  selectCity(city: string): void {
    this.customerForm?.get("address")?.get("city")?.setValue(city);
  }

  getFormControl<T>(controlName: string): any {
    return this.customerForm.get(controlName);
  }

  restrictToAlphabet(event: any) {
    event.target.value = event.target.value.replace(/[^A-Za-z ]/g, '');
    this.customerForm.get('ceoName')?.setValue(event.target.value, {emitEvent: false});
  }

  MaxCharLimits = MaxCharLimits;
}
