import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {ApiService} from "../../services/api.service";
import {NgDialogAnimationService} from "ng-dialog-animation";
import {ToastrService} from "ngx-toastr";
import {ResetFiltersService} from "../../services/resetFilters.service";
import {MatPaginator} from "@angular/material/paginator";
import {SolutionsData} from "../../shared/interfaces";

export interface SolutionFiltersDto {
  query: string;
  solutionId: string;
  solutionName: string;
  informationTags: number[];
  IndustryVertical: number[];
  solutionTypes: any[];
  techStacks: any[];
  enableSearch: boolean ;
  showActive?: boolean;
}

@Component({
  selector: 'app-solution-pool',
  templateUrl: './solution-pool.component.html',
  styleUrls: ['./solution-pool.component.css']
})
export class SolutionPoolComponent implements OnInit {
  search = '';
  pageSize = 12;
  currentPage = 0;
  totalPages = 0;
  totalSolutions: any;
  solutions: SolutionsData[]=[]
  searchFieldName = ['solutionId', 'solutionName', 'InformationTag', 'IndustryVertical', 'solutionTypes', 'techStack', ''];
  filters: SolutionFiltersDto = {
      query: '',
      solutionId: '',
      solutionName: '',
      informationTags: [],
      IndustryVertical: [],
      solutionTypes: [],
      techStacks: [],
      enableSearch:true,
  };
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  @ViewChild('input', { static: true }) input: ElementRef | any;
  constructor(
      private apiService: ApiService,
      public dialog: NgDialogAnimationService,
      private toastr: ToastrService,
      private resetFilters: ResetFiltersService,
  ) {
  }



  ngOnInit(): void {
    this.fetchSolutions(0,this.pageSize,this.filters)
  }



  fetchSolutions(page: number, size: number,payload: SolutionFiltersDto): void {
    this.apiService.getSolutions(page, size,payload).subscribe(res => {
          this.solutions = res.content;
          this.currentPage = res.page.number;
          this.pageSize =  res.page.size;
          this.totalPages = res.page.totalPages;
          this.paginator.pageIndex = res.page.number;
          this.paginator.pageSize = res.page.size;
          this.totalSolutions = res.page.totalElements;
        },
        (error) => {
          console.error(error);
        }
    );
  }

    handlePage(e: any) {
        this.currentPage = e.pageIndex;
        this.pageSize = e.pageSize;
        this.fetchSolutions(   this.currentPage,this.pageSize,this.filters)

    }
    updateSearch() {
        this.filters.query = this.search;
        this.paginator.pageIndex = 0;
        this.fetchSolutions(this.paginator.pageIndex, this.pageSize, this.filters);
    }

  clearFilter(){
    this.filters.query = '';
    this.filters.solutionId = '';
    this.filters.solutionName = '';
    this.filters.informationTags = [];
    this.filters.IndustryVertical = [];
    this.filters.solutionTypes = [];
    this.filters.techStacks = [];
    this.search = '';
      this.resetFilters.emitChange(true);
      this.fetchSolutions(0, this.pageSize, this.filters);
  }
  isFilterSelected(): boolean {
    return !!(
      this.filters.query ||
      this.filters.solutionId ||
      this.filters.solutionName ||
      this.filters.informationTags.length ||
      this.filters.IndustryVertical.length ||
      this.filters.solutionTypes.length ||
      this.filters.techStacks.length
    );
  }

  onFiltersChanged(updatedFilters: any) {
      this.filters = {
          ...updatedFilters,
          query: this.search
      };
      this.paginator.pageIndex = 0;
      this.filters.query = this.search;
      this.fetchSolutions(this.paginator.pageIndex, this.pageSize, this.filters);
  }

  formatData(value: any): string {
    if (!value) {
      return '--';
    }
    if (Array.isArray(value)) {
      return value
        .map((info: any) => {
          if (!info.industryVerticals || !Array.isArray(info.industryVerticals)) {
            // console.warn('formatData: industryVerticals is not an array:', info.industryVerticals);
            return '--';
          }
          return info.industryVerticals
            .map((vertical: any) => vertical.name)
            .join(", ");
        })
        .join("; ");
    } else {
      if (!value.industryVerticals || !Array.isArray(value.industryVerticals)) {
        // console.warn('formatData: industryVerticals is not an array:', value.industryVerticals);
        return '--';
      }
      return value.industryVerticals
        .map((vertical: any) => vertical.name)
        .join(", ");
    }
  }
  formatDataInformationTag(value: any): string {
    if (!value) {
      return '--';
    }
    if (Array.isArray(value)) {
      return value
        .map((info: any) => {
          if (!info.informationTag || !Array.isArray(info.informationTag)) {
            // console.warn('formatData: industryVerticals is not an array:', info.industryVerticals);
            return '--';
          }
          return info.informationTag
            .map((vertical: any) => vertical.tagName)
            .join(", ");
        })
        .join("; ");
    } else {
      if (!value.informationTag || !Array.isArray(value.informationTag)) {
        // console.warn('formatData: industryVerticals is not an array:', value.industryVerticals);
        return '--';
      }
      return value.informationTag
        .map((vertical: any) => vertical.tagName)
        .join(", ");
    }
  }
    formatTeckStack(techStackUsed: any) {
        if (!techStackUsed) {
            return '--';
        }
      return  techStackUsed?.map((res:any)=> res.title)
          .join(", ");
    }

  getSkills(skills: any): { skillsString: string, pendingSkillsCount: number, pendingSkills: string[] } {
    let skillsString = '';
    let count = 0;
    let pendingCount = 0;
    let pendingSkills: string[] = [];

    if (skills.length === 0) {
      skillsString = '--';
    } else {
      skills.forEach((e: any, index: number) => {
        if (count < 5) {
          skillsString += e.title;
          if (count < 4 && index < skills.length - 1) {
            skillsString += ' | ';
          }
          count++;
        } else {
          pendingSkills.push(e.title);
          pendingCount++;
        }
      });

    }


    return {
      skillsString: skillsString,
      pendingSkillsCount: pendingCount,
      pendingSkills: pendingSkills
    };
  }

  stripHtmlTags(html: string): string {
    return html?.replace(/<[^>]*>/g, ' ');
  }

}
