import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from "../../../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {DeletePopupComponent} from "../../../delete-popup/delete-popup.component";
import {Candidate, Members} from "../../../../shared/interfaces";
import {Observable} from "rxjs/internal/Observable";
import {CustomerContactDialogComponent} from "../customer-contact-dialog/customer-contact-dialog.component";
import {map, startWith} from "rxjs/operators";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit, OnChanges {
  statusForm: FormGroup;
  @Input() customerInfoData!: Candidate;
  loading: boolean = false;
  currentRoute: string = '';
  candidateData: any;
  formGroup: FormGroup;
  verificationStatus: string | null = null;
  isDisabled: boolean = true;
  spocControl = new FormControl('');
  role: string | null;
  filteredSpoc: Observable<Members[]>;
  @ViewChild('SPOCAutoCompleter', {read: MatAutocompleteTrigger})
  SPOCAutoCompleter: MatAutocompleteTrigger;
  selectedSpcId: string;
  form: FormControl;
  SPOC_Options: Members[] = [];


  constructor(
    private toastr: ToastrService,
    private service: ApiService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,
  ) {
    this.formGroup = this.fb.group({
      verificationStatus: ['']
    });
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
    this.role = localStorage.getItem('role');
    this.filteredSpoc = this.spocControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterSPOC(value))
    );
    this.getSPOC()
    const webCandidateId = this.route?.snapshot?.params['id'];
    this.statusForm = new FormGroup({
      status: new FormControl('', Validators.required),
    });
    if (this.route?.snapshot?.url[1]?.path === 'candidate') {
      this.currentRoute = 'candidate'
      this.service.getSingleJobCandidate(webCandidateId).subscribe(
        (data) => {
          if (data.spoc) {
            this.spocControl.setValue(data.spoc);
          }
        }
      )
    } else if (this.route?.snapshot?.url[1]?.path === 'customer') {
      this.currentRoute = 'customer'
      this.getSingleWebClient(webCandidateId);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['customerInfoData'] && changes['customerInfoData'].currentValue) {
      const initialStatus = this.customerInfoData.status || 'PENDING';
      this.statusForm.patchValue({status: initialStatus});
      this.isDisabled = initialStatus !== 'SHORTLISTED';
    }
  }

  getSPOC() {
    this.service.getSpocList().subscribe((res: any) => {
      this.SPOC_Options = res.filter((active: any) => active.active);
      this.filteredSpoc = this.spocControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterSPOC(value))
      );
    });
  }

  private _filterSPOC(value: string): Members[] {
    if (typeof value !== 'string') {
      return [];
    }
    const filterValue = value.toLowerCase();
    return this.SPOC_Options.filter(option =>
      (option?.firstName && option.firstName.toLowerCase().includes(filterValue)) ||
      (option?.lastName && option.lastName.toLowerCase().includes(filterValue)) ||
      (option?.role && option.role.toLowerCase().includes(filterValue))
    );
  }

  chooseSpoc(event: any) {
    this.selectedSpcId = event?.option?.value?.userId
    this.form.get('spocId')?.setValue(event?.option?.value?.userId)
  }

  formatSPOC(option: Members): string {
    if (!option) {
      return '';
    }
    return option.role + ' | ' + option.firstName + ' ' + option.lastName;
  }

  scrollEvent = (event: any): void => {
    if (this.SPOCAutoCompleter?.panelOpen) {
      this.SPOCAutoCompleter.updatePosition();
    }
  };


  onSubmit() {
    this.loading = true
    if (this.statusForm.valid) {
      const selectedStatus = this.statusForm.value.status ? this.statusForm.value.status : this.route.snapshot.params['status'];
      const selectedSpoc = this.spocControl.value['userId'];
      this.service.changeStatusOfWebCandidate(this.route.snapshot.params['id'], {
        status: selectedStatus,
        spocId: selectedSpoc
      }).subscribe((res) => {
        if (selectedStatus === 'SHORTLISTED') {
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
        this.loading = false
        this.toastr.success('Status updated successfully', 'Success');
      }, (err) => {
        this.toastr.error('Failed to updated', 'Error');
        this.loading = false
      })
    }
  }

  onSubmitSpoc() {

  }

  upgradeToConsultant() {
    const dialogRef = this.dialog.open(DeletePopupComponent, {
      width: '300px',
      data: {
        message: 'Are you sure you want to upgrade this candidate to Consultant?',
        type: 'Delete'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.service.upgradeWebCandidate(this.route.snapshot.params['id']).subscribe(
          (res) => {
            this.toastr.success('Candidate upgraded to Consultant successfully.');
            this.router.navigate(['/dashboard/web_data'], {queryParams: {tab: 0}});
          },
          (err) => {
            if (err.status === 400) {
              this.toastr.error('Upgrade failed. Invalid candidate data.', 'Error');
            } else if (err.status === 500) {
              this.toastr.error('Server error during upgrade. Please try again later.', 'Error');
            } else {
              this.toastr.error('An unexpected error occurred during the upgrade.', 'Error');
            }
          }
        );
      }
    });
  }

  getSingleWebClient(webCandidateId: number): void {
    this.service.getWebClient(webCandidateId).subscribe((res: any) => {
      this.candidateData = res;
      const status = res.verificationStatus === 'INPROGRESS' ? 'VERIFIED' : res.verificationStatus;
      this.verificationStatus = status;
      this.formGroup.patchValue({
        verificationStatus: status
      });
    });
  }

  openCustomerContactDialog() {
    const dialogRef = this.dialog.open(CustomerContactDialogComponent, {
      width: '500px',
      data: {
        id: this.route.snapshot.params['id'],
        SPOC: this.candidateData.spoc
      },
    });
  }

}
