import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VendorsComponent } from './vendors/vendors.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AdminVendorsComponent } from './admin/admin-vendors/admin-vendors.component';
import { ContentRoutingModule } from "./content-routing.module";
import { LayoutComponent } from './layout/layout.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "../material.module";
import { UtilModule } from "../util/util.module";
import { SidebarComponent } from './sidebar/sidebar.component';
import { ManageProfileComponent } from './admin/manage-profile/manage-profile.component';
import { DeletePopupComponent } from './delete-popup/delete-popup.component';
import { ManageMembersComponent } from './admin/manage-members/manage-members.component';
import { MatCardModule } from "@angular/material/card";
import { CreateMemberComponent } from './admin/manage-members/create-member/create-member.component';
import { UserProfileComponent } from './admin/user-profile/user-profile.component';
import { CreatePartnerComponent } from './admin/create-partner/create-partner.component';
import { ChangePasswordComponent } from './admin/user-profile/change-password/change-password.component';
import { FilterComponent } from './vendors/filter/filter.component';
import { ModifyVendorComponent } from './admin/admin-vendors/modify-vendor/modify-vendor.component';
import { VendorDetailComponent } from './vendors/vendor-detail/vendor-detail.component';
import { SettingsComponent } from './admin/settings/settings.component';
import { CompletePartnerComponent } from './admin/create-partner/complete-partner/complete-partner.component';
import {NgxPaginationModule} from "ngx-pagination";
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import { ScrollingModule } from '@angular/cdk/scrolling';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import { AuditReportComponent } from './admin/audit-report/audit-report.component';
import {MatInputModule} from "@angular/material/input";
import { JobsComponent } from './admin/jobs/jobs.component';
import { CreateJobComponent } from './admin/jobs/create-job/create-job.component';
import {MatExpansionModule} from "@angular/material/expansion";
import {MatChipsModule} from "@angular/material/chips";
import {MatSelectModule} from "@angular/material/select";
import {MatCheckboxModule} from "@angular/material/checkbox";
import { TabsFooterComponent } from './tabs-footer/tabs-footer.component';
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import { ManageConsultantsComponent } from './admin/manage-consultants/manage-consultants.component';
import { CreateConsultantsComponent } from './admin/manage-consultants/create-consultants/create-consultants.component';
import { ManageCustomersComponent } from './admin/manage-customers/manage-customers.component';
import { CreateCustomersComponent } from './admin/manage-customers/create-customers/create-customers.component';
import { ConsultantInfoComponent } from './admin/manage-consultants/consultant-info/consultant-info.component';
import { CustomerContactsComponent } from './admin/manage-customers/customer-contacts/customer-contacts.component';
import { ConsultantDocumentsComponent } from './admin/manage-consultants/consultant-documents/consultant-documents.component';
import {ConsultantSkillsComponent} from "./admin/manage-consultants/consultant-skills/consultant-skills.component";
import { ConsultantStatusComponent } from './admin/manage-consultants/consultant-status/consultant-status.component';
import { CustomerCommunicationComponent } from './admin/manage-customers/customer-communication/customer-communication.component';
import { ConsultantCommunicationsComponent } from './admin/manage-consultants/consultant-communications/consultant-communications.component';
import { ConsultantSettingsComponent } from './admin/manage-consultants/consultant-settings/consultant-settings.component';
import {CustomerSettingsComponent} from "./admin/manage-customers/customer-settings/customer-settings.component";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatRadioModule} from "@angular/material/radio";
import { MyTasksComponent } from './admin/my-tasks/my-tasks.component';
import { ViewMyTaskComponent } from './admin/my-tasks/view-my-task/view-my-task.component';
import { DialogboxChangeAssigneeComponent } from './admin/my-tasks/dialogbox-change-assignee/dialogbox-change-assignee.component';
import { CommentUpdateDialogBoxComponent } from './admin/my-tasks/comment-update-dialog-box/comment-update-dialog-box.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from "@angular/material/paginator";
import { CustomerInfoComponent } from './admin/manage-customers/customer-info/customer-info.component';
import { EditCustomerContactComponent } from './admin/manage-customers/customer-contacts/edit-customer-contact/edit-customer-contact.component';
import { EditVendorContactComponent } from './admin/admin-vendors/vendor-contacts/edit-vendor-contact/edit-vendor-contact.component';
import { VendorInfoComponent } from './admin/admin-vendors/vendor-info/vendor-info.component';
import { VendorSkillsComponent } from './admin/admin-vendors/vendor-skills/vendor-skills.component';
import { VendorDocumentsComponent } from './admin/admin-vendors/vendor-documents/vendor-documents.component';
import { VendorContactsComponent } from './admin/admin-vendors/vendor-contacts/vendor-contacts.component';
import { VendorSocialLinksComponent } from './admin/admin-vendors/vendor-social-links/vendor-social-links.component';
import { VendorCommunicationsComponent } from './admin/admin-vendors/vendor-communications/vendor-communications.component';
import { VendorSettingsComponent } from './admin/admin-vendors/vendor-settings/vendor-settings.component';
import {CustomersContactsComponent} from "./admin/customer-contacts/customers-contacts.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { ConsultantDeployedComponent } from './admin/manage-consultants/consultant-deployed/consultant-deployed.component';
import {
  ConsultantsDeployModalComponent
} from "./admin/manage-consultants/consultants-deploy-modal/consultants-deploy-modal.component";
import {
  ConsultantAddVideoModalComponent
} from "./admin/manage-consultants/consultants-add-video-modal/consultants-add-video-modal.component";
import { CustomerAnalyticsComponent } from './admin/customer-analytics/customer-analytics.component';
import { CustomerActivityModalComponent } from './admin/customer-activity-modal/customer-activity-modal.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import { ImageCropperComponent } from './admin/image-cropper/image-cropper.component';
import {ImageCropperModule} from "ngx-image-cropper";
import { AdvanceSearchFiltersComponent } from './admin/advance-search-filters/advance-search-filters.component';
import { PassingYearComponent } from './admin/manage-consultants/date-of-birth/passing-year.component';
import { SolutionsComponent } from './admin/solutions/solutions.component';
import { SolutionInformationComponent } from './admin/solutions/solution-information/solution-information.component';
import { SolutionTechnologyComponent } from './admin/solutions/solution-settings/solution-technology/solution-technology.component';
import {SolutionPoolComponent} from "../client/solution-pool/solution-pool.component";
import { CreateSolutionComponent } from './admin/solutions/create-solution/create-solution.component';
import { ExpandableTextareaComponent } from './admin/solutions/expandable-textarea/expandable-textarea.component';
import { AttachmentComponent } from './admin/solutions/attachment/attachment.component';
import { SolutionCommunicationComponent } from './admin/solutions/solution-communication/solution-communication.component';
import { SolutionSettingsComponent } from './admin/solutions/solution-settings/solution-settings.component';
import { SolutionRatingComponent } from './admin/solutions/solution-rating/solution-rating.component';
import {SolutionDetailViewComponent} from "../client/solution-pool/solution-detail-view/solution-detail-view.component";
import {AngularEditorModule} from "@kolkov/angular-editor";
import {QuillModule} from "ngx-quill";
import { ExpandableTexteditorComponent } from './admin/solutions/expandable-texteditor/expandable-texteditor.component';
import { WebCandidateTableListingComponent } from './admin/webcandidates/web-candidate-table-listing/web-candidate-table-listing.component';
import { WebCandidatesComponent } from './admin/webcandidates/web-candidates/web-candidates.component';
import { AttachementComponentComponent } from './admin/common/attachement-component/attachement-component.component';
import { CommunicationComponentComponent } from './admin/common/communication-component/communication-component.component';
import { SkillsComponentComponent } from './admin/common/skills-component/skills-component.component';
import { CommonHeadComponentComponent } from './admin/common/common-head-component/common-head-component.component';
import { WebCandidateInformationComponent } from './admin/webcandidates/web-candidate-information/web-candidate-information.component';
import { AddAttachmentModalComponent } from './admin/common/add-attachment-modal/add-attachment-modal.component';
import { ButtonComponent } from './admin/common/button/button.component';
import { SettingComponent } from './admin/webcandidates/setting/setting.component';
import { CustomerContactDialogComponent } from './admin/webcandidates/customer-contact-dialog/customer-contact-dialog.component';
import { ReassignCustomerComponent } from './admin/reassign-customer/reassign-customer.component';
import {JobshareComponent} from "../common/jobshare/jobshare.component";
import { MemberAnalyticsComponent } from './admin/member-analytics/member-analytics.component';
import { TwoFaComponent } from './admin/two-fa/two-fa.component';
import { NotFoundResultComponent } from '../not-found-result/not-found-result.component';
import { CountryPhoneInputComponent } from './admin/common/country-phone-input/country-phone-input.component';
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
import {SkillsDisplayComponent} from "./admin/common/skills-display/skills-display.component";
import { WebCandidateListingComponent } from './admin/webcandidates/web-candidate-listing/web-candidate-listing.component';
import { WebCustomerListingComponent } from './admin/webcandidates/web-customer-listing/web-customer-listing.component';
import {ImageUploadComponent} from "../common/image-upload/image-upload.component";
import {NoSpecialCharsDirective} from "../directives/no-special-chars.directive";
import { PortalSettingComponent } from './admin/settings/portal-setting/portal-setting.component';
import {SpocDropdownComponent} from "../common/spoc-dropdown/spoc-dropdown.component";
import {MaxCharLimitDirective} from "../directives/max-char-limit-input/max-char-limit.directive";
import {MaxCharLimitUrlDirective} from "../directives/max-char-limit-url/max-char-limit-url.directive";
import {MaxCharLimitTextareaDirective} from "../directives/max-char-limit-textarea/max-char-limit-textarea.directive";
import {QuillMaxCharLimitDirective} from "../directives/max-char-limit-texteditor/max-char-limit-texteditor.directives";

@NgModule({
  declarations: [
    VendorsComponent,
    HeaderComponent,
    FooterComponent,
    AdminVendorsComponent,
    LayoutComponent,
    SidebarComponent,
    ManageProfileComponent,
    DeletePopupComponent,
    ManageMembersComponent,
    CreateMemberComponent,
    UserProfileComponent,
    ChangePasswordComponent,
    UserProfileComponent,
    CreatePartnerComponent,
    FilterComponent,
    ModifyVendorComponent,
    VendorDetailComponent,
    SettingsComponent,
    CompletePartnerComponent,
    DashboardComponent,
    AuditReportComponent,
    JobsComponent,
    CreateJobComponent,
    TabsFooterComponent,
    ManageConsultantsComponent,
    CreateConsultantsComponent,
    ManageCustomersComponent,
    CreateCustomersComponent,
    ConsultantInfoComponent,
    CustomerContactsComponent,
    ConsultantDocumentsComponent,
    ConsultantSkillsComponent,
    ConsultantStatusComponent,
    CustomerCommunicationComponent,
    ConsultantCommunicationsComponent,
    ConsultantSettingsComponent,
    CustomerSettingsComponent,
    MyTasksComponent,
    ViewMyTaskComponent,
    DialogboxChangeAssigneeComponent,
    CommentUpdateDialogBoxComponent,
    CustomerInfoComponent,
    EditCustomerContactComponent,
    VendorInfoComponent,
    VendorSkillsComponent,
    VendorDocumentsComponent,
    VendorContactsComponent,
    VendorSocialLinksComponent,
    VendorCommunicationsComponent,
    VendorSettingsComponent,
    EditVendorContactComponent,
    CustomersContactsComponent,
    ConsultantDeployedComponent,
    ConsultantsDeployModalComponent,
    ConsultantAddVideoModalComponent,
    CustomerAnalyticsComponent,
    CustomerActivityModalComponent,
    ImageCropperComponent,
    AdvanceSearchFiltersComponent,
    PassingYearComponent,
    SolutionsComponent,
    SolutionInformationComponent,
    SolutionTechnologyComponent,
    CreateSolutionComponent,
    ExpandableTextareaComponent,
    AttachmentComponent,
    SolutionCommunicationComponent,
    SolutionSettingsComponent,
    SolutionRatingComponent,
    SolutionPoolComponent,
    SolutionDetailViewComponent,
    ExpandableTexteditorComponent,
    WebCandidateTableListingComponent,
    WebCandidatesComponent,
    AttachementComponentComponent,
    CommunicationComponentComponent,
    SkillsComponentComponent,
    CommonHeadComponentComponent,
    WebCandidateInformationComponent,
    AddAttachmentModalComponent,
    ButtonComponent,
    SettingComponent,
    CustomerContactDialogComponent,
    ReassignCustomerComponent,
    JobshareComponent,
    MemberAnalyticsComponent,
    TwoFaComponent,
    NotFoundResultComponent,
    CountryPhoneInputComponent,
    SkillsDisplayComponent,
    WebCandidateListingComponent,
    WebCustomerListingComponent,
    ExpandableTexteditorComponent,
    ImageUploadComponent,
    NoSpecialCharsDirective,
    MaxCharLimitDirective,
    MaxCharLimitUrlDirective,
    MaxCharLimitTextareaDirective,
    PortalSettingComponent,
    SpocDropdownComponent,
    QuillMaxCharLimitDirective
  ],
    exports: [
        HeaderComponent,
        FooterComponent,
        FilterComponent,
        AdvanceSearchFiltersComponent,
        ExpandableTexteditorComponent,
        NotFoundResultComponent,
        SkillsDisplayComponent,
        SkillsComponentComponent,
        CountryPhoneInputComponent,
    ],
  imports: [
    FormsModule,
    MaterialModule,
    UtilModule,
    ReactiveFormsModule,
    CommonModule,
    ContentRoutingModule,
    MatCardModule,
    NgxPaginationModule,
    MatAutocompleteModule,
    ScrollingModule,
    MatSlideToggleModule,
    MatInputModule,
    MatExpansionModule,
    MatChipsModule,
    MatSelectModule,
    MatCheckboxModule,
    NgxMatSelectSearchModule,
    MatDatepickerModule,
    MatRadioModule,
    MatFormFieldModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    ImageCropperModule,
    AngularEditorModule,
    QuillModule.forRoot(),
    NgxIntlTelInputModule,

  ]
})
export class ContentModule { }

