<div class=" content-wrapper-main-inner-content">
  <div class="member_wrapper " mat-dialog-title>
    <div class="d-flex justify-content-between">
      <div>

      </div>
      <h4 class="">Register</h4>
      <a style="cursor: pointer" class="cursor-pointer" (click)="close()">
        <svg width="20" height="20" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.2872 1L1 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
          <path d="M1 1L17.2872 17.2871" stroke="black" stroke-width="2"
                stroke-linecap="round"/>
        </svg>
      </a>
    </div>
  </div>
  <mat-dialog-content class="mat-typography m-0 ">
    <form [formGroup]="registerNowForm" class="member-form" novalidate>

      <div class="d-flex">
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100 border-red">
            <mat-label>First Name</mat-label>
            <input formControlName="firstName" type="text" matInput
                   aria-describedby="title" required>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100 border-red">
            <mat-label>Last Name</mat-label>
            <input formControlName="lastName" type="text" matInput
                   aria-describedby="title">
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex">
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100 border-red">
            <mat-label>Work Email Address</mat-label>
            <input formControlName="email" type="email" matInput
                   aria-describedby="title" required (input)="onKeyPressEmail($event)">
          </mat-form-field>
          <div *ngIf="displayErrorEmail" class="text-danger mb-3">
            {{ this.errorMessageEmail }}
          </div>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100 border-red">
            <mat-label>Company</mat-label>
            <input formControlName="companyName" type="text" matInput
                   aria-describedby="title" required>
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-6">
        <ngx-intl-tel-input
          [cssClass]="'custom'"
          [preferredCountries]="preferredCountries"
          [enableAutoCountrySelect]="true"
          [enablePlaceholder]="true"
          [searchCountryFlag]="true"
          [searchCountryField]="[
          SearchCountryField.Iso2,
          SearchCountryField.Name
        ]"
          [selectFirstCountry]="false"
          [selectedCountryISO]="CountryISO.India"
          [maxLength]="15"
          [phoneValidation]="true"
          [separateDialCode]="true"
          [numberFormat]="PhoneNumberFormat.National"
          formControlName="number"
        ></ngx-intl-tel-input>
      </div>
      <div class="d-flex justify-content-end align-items-center mt-3 ">
        <button class="btn btn-secondary me-3" type="button" style="border-radius: 50px; background: #a9a9a9; border: 0"
                (click)="close()">Cancel
        </button>
        <div class="form-group btns save-form m-0">
          <button (click)="SubmitNow()" class="btn btn-success m-0" style="border-radius: 50px ; background: #71BF45; border: 0;
"
                  type="submit">Submit
          </button>
        </div>
      </div>
    </form>
  </mat-dialog-content>
</div>
