<form [formGroup]="customerForm" class="member-form" (submit)="updateCustomer()" ngNativeValidate>
  <div class="consultant_wrapper">
    <h2 class="mb-4">Customer Information</h2>
    <div class="form-row mb-2">
      <div class="col-md-6 ms-0">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Company Name</mat-label>
          <input appMaxCharLimitInput matInput placeholder="Company Name" formControlName="companyName" required   [readonly]="this.role=='BSE'" appNoSpecialChars >
          <mat-error *ngIf="this.customerForm.get('companyName')?.hasError('maxCharLimit')">
            {{MaxCharLimits.INPUT}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Website</mat-label>
          <input appMaxCharLimitUrl matInput placeholder="Website" formControlName="website" required  [readonly]="this.role=='BSE'">
          <mat-error *ngIf="this.customerForm.get('website')?.hasError('maxCharLimit')">
            {{MaxCharLimits.URL}}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="form-row mb-2">
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Primary Contact Name</mat-label>
          <input appMaxCharLimitInput matInput placeholder="Contact Name" formControlName="ceoName" required  [readonly]="this.role=='BSE'" (input)="restrictToAlphabet($event)" appNoSpecialChars>
          <mat-error>
            {{MaxCharLimits.INPUT}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <app-country-phone-input
          [modal]="true"
          [formControl]="getFormControl('number')"
          [selectedCountryISO]="customerRes?.number?.countryCode">
        </app-country-phone-input>
        <div *ngIf="displayError" class="text-danger">
          {{ errorMessage }}
        </div>
      </div>
    </div>

    <div class="form-row mb-2">
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Primary Contact Email</mat-label>
          <input appMaxCharLimitUrl matInput placeholder="Email" formControlName="email" type="email" required  [readonly]="this.role=='BSE'">
          <mat-error *ngIf="customerForm.controls['email'].touched">
          </mat-error>
          <mat-error *ngIf="this.customerForm.get('email')?.hasError('maxCharLimit')">
            {{MaxCharLimits.URL}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Customer Category</mat-label>
          <mat-select placeholder="Category" formControlName="category" required [disabled]="this.role === 'BSE'">
            <mat-option *ngFor="let category of categories" [value]="category.value">
              {{ category.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="consultant_wrapper" formGroupName="address">
    <h2 class="mb-4">Address information</h2>
    <div class="form-row mb-2">
      <div class="col-md-8">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Street address</mat-label>
          <input appMaxCharLimitInput matInput type="text" formControlName="streetAddress"
                 #streetAddress  [readonly]="this.role=='BSE'">
          <mat-error>
            {{MaxCharLimits.URL}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Postal code</mat-label>
          <input matInput type="text" formControlName="postalCode"  minlength="6" maxlength="6" type="text"
                 #postalCode  [readonly]="this.role=='BSE'">
        </mat-form-field>
      </div>
    </div>
    <div class="form-row mb-2">
      <div formGroupName="country" class="col-md-4">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Country</mat-label>
          <mat-select [formControl]="countryControl" [(value)]="selectedCountry"
                      (valueChange)="selectCountry($event)" placeholder="Country" [compareWith]="compareFunction"
                      [disabled]="this.role=='BSE' || this.isRouteCustomerAnalytics">
            <mat-option>
              <ngx-mat-select-search [formControl]="countryServerCtrl" [searching]="searchCountry"
                                     [placeholderLabel]="'Search Country Name...'"
                                     [noEntriesFoundLabel]="'No Result Found'" required>
                <mat-icon ngxMatSelectSearchClear>
                  <div class="d-flex align-items-center justify-content-center mt-sm-1">
                    <a>
                      <svg width="14" height="14" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.2872 1L1 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        <path d="M1 1L17.2872 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                      </svg>
                    </a>
                  </div>
                </mat-icon>
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let country of countries" [value]="country">
              {{country.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div formGroupName="state" class="col-md-4">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>State</mat-label>
          <mat-select [formControl]="stateControl" [(value)]="selectedState"  [compareWith]="compareFunction" (valueChange)="selectState($event)"
                      placeholder="State"  [disabled]="!selectedCountry || this.role=='BSE' || this.isRouteCustomerAnalytics" >
            <mat-option>
              <ngx-mat-select-search [formControl]="stateServerCtrl" [searching]="searchState"
                                     [placeholderLabel]="'Search State Name...'"
                                     [noEntriesFoundLabel]="'No Result Found'">
                <mat-icon ngxMatSelectSearchClear>
                  <div class="d-flex align-items-center justify-content-center mt-sm-1">
                    <a>
                      <svg width="14" height="14" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.2872 1L1 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        <path d="M1 1L17.2872 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                      </svg>
                    </a>
                  </div>
                </mat-icon>
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let state of states" [value]="state">
              {{state.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div formGroupName="city" class="col-md-4">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>City</mat-label>
          <mat-select [formControl]="cityControl" [(value)]="selectedCity"  [compareWith]="compareFunction" (valueChange)="selectCity($event)"
                      placeholder="City"  [disabled]="!selectedState || this.role=='BSE' || this.isRouteCustomerAnalytics" >
            <mat-option>
              <ngx-mat-select-search [formControl]="cityServerCtrl" [searching]="searchCity"
                                     [placeholderLabel]="'Search City Name...'"
                                     [noEntriesFoundLabel]="'No Result Found'">
                <mat-icon ngxMatSelectSearchClear>
                  <div class="d-flex align-items-center justify-content-center mt-sm-1">
                    <a>
                      <svg width="14" height="14" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.2872 1L1 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        <path d="M1 1L17.2872 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                      </svg>
                    </a>
                  </div>
                </mat-icon>
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let city of cities" [value]="city">
              {{city.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="this.role!='BSE'"  class="d-flex justify-content-end align-items-baseline mt-4">
      <div class="form-group btns save-form">
        <div class="d-flex justify-content-center">
          <button type="button" class="action-btn action-btn-cancel me-3" (click)="close()" >Cancel</button>
          <button class="action-btn theme-btn-color" type="submit" [disabled]="this.isRouteCustomerAnalytics">Save details</button>
        </div>
      </div>
    </div>
  </div>
</form>
