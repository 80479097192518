import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.css']
})
export class ImageCropperComponent implements OnInit {
  @ViewChild('myInput') myInputVariable!: ElementRef;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  scale = 1;
  minScale: number = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  minImageWidth: number = 200;
  minImageHeight: number = 200;
  @Output() imageCroppedEvent = new EventEmitter<string>();
  loading: boolean = false;
  error: string;

  constructor(
    public dialogRef: MatDialogRef<ImageCropperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit(): void {
    if (this.data?.file) {
      this.loadImage(this.data.file);
    }
  }

  loadImage(file: File): void {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageChangedEvent = {
        target: {
          files: [file],
          result: event.target.result
        }
      };
      this.showCropper = true;
    };
    reader.readAsDataURL(file);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event;
    this.imageCroppedEvent.emit(this.croppedImage);
  }

  imageLoaded() {
    // Check image dimensions
    const image = this.imageChangedEvent.target;
    if (image.naturalWidth < this.minImageWidth || image.naturalHeight < this.minImageHeight) {
      this.error = `Image dimensions are too small. Minimum size is ${this.minImageWidth}x${this.minImageHeight}px.`;
      this.showCropper = false;
    } else {
      this.showCropper = true;
    }
    this.loading = false;
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    console.log('Load failed');
  }

  zoomOut() {
    if (this.scale > this.minScale) {
      this.scale -= 0.1;
      this.transform = {
        ...this.transform,
        scale: this.scale,
      };
    }
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  save() {
    this.imageCroppedEvent.emit(this.croppedImage);
    this.dialogRef.close(this.croppedImage);
  }

  close() {
    this.dialogRef.close();
  }
}
