<form [formGroup]="vendorForm" (submit)="updateVendor()" ngNativeValidate class="vendor_form">
  <div class="vendor_wrapper shadow-sm border">
    <h2 class="mb-4">Vendor's Information</h2>
    <div class="form-row mb-2">
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Vendor Company Name</mat-label>
          <input appMaxCharLimitInput matInput formControlName="vendorName" type="text" required
                 [readonly]="role=='TA' || openDialogType=='READONLY'" appNoSpecialChars/>
          <div *ngIf="field == 'vendorName'" class="alert">
            <div>
              {{ this.errorMessage }}
            </div>
          </div>
          <mat-error *ngIf="this.vendorForm.get('vendorName')?.hasError('maxCharLimit')">
            {{MaxCharLimits.INPUT}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>CEO Name</mat-label>
          <input appMaxCharLimitUrl matInput formControlName="ceoName" type="text" required
                 [readonly]="role=='TA' || openDialogType=='READONLY'" appNoSpecialChars>
          <mat-error *ngIf="this.vendorForm.get('ceoName')?.hasError('maxCharLimit')">
            {{MaxCharLimits.INPUT}}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="form-row mb-2">
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Website</mat-label>
          <input appMaxCharLimitUrl matInput formControlName="websiteUrl" type="text" [(ngModel)]="this.prevLink2"
                 required [readonly]="role=='TA' || openDialogType=='READONLY'">
          <mat-error *ngIf="vendorForm.get('websiteUrl')?.hasError('invalidUrl') && vendorForm.get('websiteUrl')?.touched">
            Please enter a valid URL (e.g., https://www.example.com).
          </mat-error>
          <mat-error *ngIf="this.vendorForm.get('websiteUrl')?.hasError('maxCharLimit')">
            {{MaxCharLimits.URL}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Company Email id</mat-label>
          <input appMaxCharLimitUrl matInput type="email" formControlName="emailAddress"
                 (change)="errorMessage = null" required
                 [readonly]="role=='TA' || openDialogType=='READONLY'">
          <mat-error *ngIf="vendorForm.get('emailAddress')?.invalid && vendorForm.get('emailAddress')?.touched">
            Please enter a valid email address (e.g., example@email.com).
          </mat-error>
          <mat-error *ngIf="this.vendorForm.get('emailAddress')?.hasError('maxCharLimit')">
            Max 500 character
          </mat-error>
        </mat-form-field>

      </div>
    </div>

    <div class="form-row mb-3 pb-1 mb-4">
      <div class="col-md-6">
        <div>
          <span class="ms-2">Mobile</span>
          <app-country-phone-input
            [modal]="true"
            [formControl]="getFormControl('number')"
            [selectedCountryISO]="vendor.number?.countryCode">
          </app-country-phone-input>
        </div>
      </div>
      <div class="col-md-6">
        <div>
          <span class="ms-2">Phone Number</span>
          <app-country-phone-input
            [modal]="true"
            [formControl]="getFormControl('phoneNumber')"
            [selectedCountryISO]="vendor?.phoneNumber?.countryCode">
          </app-country-phone-input>
        </div>
        <div CLASS="text-danger">
          {{ this.errorMessagePhone }}
        </div>
      </div>
    </div>

    <div class="form-row mb-2">
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>No. of Employees</mat-label>
          <input #noEmployeesAutoCompleter
                 type="text"
                 matInput
                 placeholder="Number Of Employees"
                 [formControl]="employeeRange"
                 [matAutocomplete]="auto"
                 required
          />
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onRangeSelected($event)">
            <mat-option *ngFor="let range of filteredRange | async" [value]="range">
              {{ range }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>GST number</mat-label>
          <input matInput type="text" formControlName="gstNumber"
                 #inputgstNumber [readonly]="role=='TA' || openDialogType=='READONLY'">
          <mat-error *ngIf="vendorForm.get('gstNumber')?.invalid && vendorForm.get('gstNumber')?.touched">
            Please enter a valid GST number (e.g., 22AAAAA0000A1Z5).
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="form-row mb-2">
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>SPOC</mat-label>
          <input #SPOCAutoCompleter type="text" matInput placeholder="SPOC" [formControl]="spocControl"
                 [matAutocomplete]="autoSPOC" required>
          <mat-autocomplete #autoSPOC="matAutocomplete" (optionSelected)="chooseSpoc($event)"
                            [displayWith]="formatSPOC">
            <mat-option *ngFor="let option of filteredSpoc | async" [value]="option">
              {{ formatSPOC(option) }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="vendor_wrapper shadow-sm border">
    <h2 class="mb-4">Address information</h2>
    <div class="form-row mb-2">
      <div formGroupName="address" class="col-md-8">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Street address</mat-label>
          <input appMaxCharLimitUrl matInput type="text" formControlName="streetAddress"
                 [readonly]="role=='TA' || openDialogType=='READONLY'">
          <mat-error>
            {{MaxCharLimits.URL}}
          </mat-error>
        </mat-form-field>
      </div>
      <div formGroupName="address" class="col-md-4">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Postal code</mat-label>
          <input matInput type="text" maxlength="6" minlength="6" formControlName="postalCode"
                 [readonly]="role=='TA' || openDialogType=='READONLY'">
        </mat-form-field>
      </div>
    </div>

    <div formGroupName="address" class="form-row">
      <div formGroupName="country" class="col-md-4">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Country</mat-label>
          <mat-select [formControl]="countryControl" [(value)]="selectedCountry" [compareWith]="compareFunction"
                      (valueChange)="selectCountry($event)" placeholder="Country">
            <mat-option>
              <ngx-mat-select-search [formControl]="countryServerCtrl" [searching]="searchCountry"
                                     [placeholderLabel]="'Search Country Name...'"
                                     [noEntriesFoundLabel]="'No Result Found'">
                <mat-icon ngxMatSelectSearchClear>
                  <div class="d-flex align-items-center justify-content-center mt-sm-1">
                    <a>
                      <svg width="14" height="14" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.2872 1L1 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        <path d="M1 1L17.2872 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                      </svg>
                    </a>
                  </div>
                </mat-icon>
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let country of countries" [value]="country">
              {{ country.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div formGroupName="state" class="col-md-4">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>State</mat-label>
          <mat-select [formControl]="stateControl" [(value)]="selectedState" (valueChange)="selectState($event)"
                      [compareWith]="compareFunction"
                      placeholder="State" [disabled]="!selectedCountry">
            <mat-option>
              <ngx-mat-select-search [formControl]="stateServerCtrl" [searching]="searchState"
                                     [placeholderLabel]="'Search State Name...'"
                                     [noEntriesFoundLabel]="'No Result Found'">
                <mat-icon ngxMatSelectSearchClear>
                  <div class="d-flex align-items-center justify-content-center mt-sm-1">
                    <a>
                      <svg width="14" height="14" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.2872 1L1 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        <path d="M1 1L17.2872 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                      </svg>
                    </a>
                  </div>
                </mat-icon>
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let state of states" [value]="state">
              {{ state.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div formGroupName="city" class="col-md-4">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>City</mat-label>
          <mat-select [formControl]="cityControl" [(value)]="selectedCity" (valueChange)="selectCity($event)"
                      [compareWith]="compareFunction"
                      placeholder="City" [disabled]="!selectedState || openDialogType=='READONLY'">
            <mat-option>
              <ngx-mat-select-search [formControl]="cityServerCtrl" [searching]="searchCity"
                                     [placeholderLabel]="'Search City Name...'"
                                     [noEntriesFoundLabel]="'No Result Found'">
                <mat-icon ngxMatSelectSearchClear>
                  <div class="d-flex align-items-center justify-content-center mt-sm-1">
                    <a>
                      <svg width="14" height="14" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.2872 1L1 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        <path d="M1 1L17.2872 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                      </svg>
                    </a>
                  </div>
                </mat-icon>
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let city of cities" [value]="city">
              {{ city.name }}
            </mat-option>
          </mat-select>


        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center align-items-center mt-4">
    <div *ngIf="this.errors.size>0" class="alert alert-danger">
      {{ getError() }}
    </div>
  </div>

  <div *ngIf="openDialogType!='READONLY'" class="d-flex justify-content-end align-items-center mt-4">
    <div class="form-group btns save-form">
      <button *ngIf="!showloader" class="btn btn-save mx-3 theme-btn-color"
              type="submit">
        Save details
      </button>

      <div *ngIf="showloader" disabled>
        <button class="btn btn-save theme-btn-color" disabled>
          <div class="spinner-border spinner-border-sm me-2" role="status">
            <span class="sr-only"></span>
          </div>
          Save Details
        </button>
      </div>
    </div>
  </div>

</form>
