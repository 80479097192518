import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../../../services/api.service";
import {
  CandidateSkills,
  Customer,
  customerContact,
  Skill,
  Skills,
  TimeZone
} from "../../../../shared/interfaces";
import {Observable} from "rxjs/internal/Observable";
import {catchError, map, startWith, tap} from "rxjs/operators";
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {ToastrService} from "ngx-toastr";
import {IsDataUpdatedService} from "../../../../services/isDataUpdated.service";
import {QuillEditorComponent} from "ngx-quill";
import {throwError} from "rxjs";
import {MaxCharLimits} from "../../../../common/errorInputMaxValue";

@Component({
  selector: 'app-create-job',
  templateUrl: './create-job.component.html',
  styleUrls: ['./create-job.component.css']
})

export class CreateJobComponent implements OnInit {
  @ViewChild('quillEditor') quillEditor: QuillEditorComponent;
  skillControl = new FormControl();
  jobId: any;
  visible2 = false;
  errorMessage: string | null;
  type: any;
  jobTypes: any[] = [];
  workLocations: any[] = [];
  jobForm: FormGroup;
  customerClients: Customer[] = []
  selectedCustomerClient!: Customer;
  jobError = '';
  showloader = false;
  jobStatus = false;
  jobCreatedBy: boolean = false;
  skillName = '';
  allSkillsRes: any = [];
  skills: any[] = [];
  contactId: string;
  edit: boolean = false;
  clientContactList: customerContact[] = [];
  skillCtrl = new FormControl('');
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  clientControl = new FormControl('');
  filteredSkills: Observable<any[]>;
  exactMatchFound: boolean = false;
  filteredOptions: Observable<Customer[]>;
  @ViewChild("chipGrid") chipGrid: ElementRef;
  @ViewChild('clientNameAutoCompleter', {read: MatAutocompleteTrigger})
  clientNameAutoCompleter: MatAutocompleteTrigger;
  @ViewChild('skillAutoCompleter', {read: MatAutocompleteTrigger})
  skillAutoCompleter: MatAutocompleteTrigger;
  subject: string;
  durations: any[] = [];
  @ViewChild('timeZoneAutoCompleter', {read: MatAutocompleteTrigger})
  timeZoneAutoCompleter: MatAutocompleteTrigger;
  workTimeZoneControl = new FormControl('(GMT +05:30) IST');
  workTimeZones: TimeZone[];
  filteredTimeZones: Observable<TimeZone[]>;
  workTimeZone: any[] = [];
  content: '';
  isSubmitting: boolean = false;
  selectedSkills: { skillId: number; skillType: string }[] = []
  skillError: boolean = false;
  jobSkills: CandidateSkills[] = [];
  editJob:boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              private service: ApiService,
              private dialogRef: MatDialogRef<CreateJobComponent>,
              private toaster: ToastrService,
              private isJobsDataUpdated: IsDataUpdatedService,
              private toastr: ToastrService,
              private isDataUpdatedService: IsDataUpdatedService,
  ) {
    this.jobTypes = ['Permanent', 'Contract'];
    this.workLocations = ['Onsite', 'Hybrid', 'Remote'];
    this.durations = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
    this.jobForm = this.fb.group({
      title: ['', Validators.required],
      description: [''],
      contactId: ['', Validators.required],
      clientName: [''],
      customerContact: [''],
      duration: [1],
      minExperience: ['', Validators.required],
      maxExperience: ['', Validators.required],
      noOfOpenings: ['1', Validators.pattern("[0-9]{1,15}")],
      notes: [''],
      skills: [''],
      active: [false],
      status: [''],
      tentativeStartDate: [''],
      workTimeZone: [''],
      jobTypes: [''],
      workLocations: [''],
    }, { validators: this.experienceValidator });

    this.jobForm.get('maxExperience')?.valueChanges.subscribe(() => {
      this.jobForm.get('maxExperience')?.markAsTouched();  // Mark field as touched
      this.jobForm.updateValueAndValidity();  // Force validation
    });

    if (this.data.jobId) {
      this.type = 'edit';
      this.service.getJobDetail(this.data.jobId).subscribe(res => {
        this.jobSkills = res.skills;
        if(res.createdByClient){
          this.editJob = true;
        }else {
          this.editJob = false;
        }
        console.log('response', res)
        this.populateJobForm(res);
        this.setFormControlsDisabled(res.createdByClient);
      });
    }else {
      this.editJob = false;
    }
    this.service.getSkills().then((res: any) => {
      this.allSkillsRes = res;
    }).then(() => {
      this.performFilteration();
    });
    this.getCustomerClients();
    this.getWorkTimeZone();
  }

  experienceValidator(formGroup: FormGroup) {
    const minExp = formGroup.get('minExperience')?.value;
    const maxExpControl = formGroup.get('maxExperience');

    if (maxExpControl?.touched && minExp !== null && maxExpControl.value !== null && maxExpControl.value <= minExp) {
      return { experienceInvalid: true };
    }
    return null;
  }



  private populateJobForm(res: any): void {
    this.jobForm.get('title')?.setValue(res.title);
    this.jobForm.get('description')?.setValue(res.description);
    this.jobForm.get('id')?.setValue(res.id);
    this.clientControl.setValue(res?.customerContact?.customer);
    this.getAllClientContacts(this.clientControl?.value?.id, 'EDIT');
    this.selectedCustomerClient = res.customerContact;
    this.jobForm.get('customerContact')?.setValue(res.customerContact);
    this.jobForm.get('minExperience')?.setValue(res.minExperience);
    this.jobForm.get('maxExperience')?.setValue(res.maxExperience);
    this.jobForm.get('jobTypes')?.setValue(res.jobTypes);
    this.jobForm.get('workLocations')?.setValue(res.workLocations);
    this.jobForm.get('noOfOpenings')?.setValue(res.noOfOpenings);
    this.jobForm.get('notes')?.setValue(res.notes);
    this.jobForm.get('active')?.setValue(res.active);
    this.jobForm.get('duration')?.setValue(res.duration);
    this.jobForm.get('workTimeZone')?.setValue(res.workTimeZone);
    this.workTimeZoneControl.setValue(res.workTimeZone);
    this.skills = res.skills;
    this.jobStatus = res.active;
    this.jobForm.get('contactId')?.setValue(res?.customerContact?.id);
    this.jobCreatedBy = res.createdByClient;
  }

  private setFormControlsDisabled(createdByClient: boolean): void {
    const controlsToDisable = ['title', 'description', 'customerContact', 'minExperience', 'maxExperience',
      'noOfOpenings', 'contactId', 'duration', 'workTimeZone'];
    if (createdByClient) {
      this.workTimeZoneControl.disable();
      this.clientControl.disable();
      this.skillControl.disable();
      controlsToDisable.forEach(control => {
        this.jobForm.get(control)?.disable();
      });
    }
    if (!createdByClient) {
      this.clientControl.disable();
    }
  }

  scrollEvent = (): void => {
    if (this.clientNameAutoCompleter?.panelOpen) {
      this.clientNameAutoCompleter.updatePosition();
    } else if (this.skillAutoCompleter?.panelOpen) {
      this.skillAutoCompleter.updatePosition();
    } else if (this.timeZoneAutoCompleter?.panelOpen) {
      this.timeZoneAutoCompleter.updatePosition();
    }
  };

  compareContacts(contact1: any, contact2: any): boolean {
    return contact1 && contact2 ? contact1.id === contact2.id : contact1 === contact2;
  }

  getCustomerClients() {
    this.service.getActiveCustomerClients().subscribe((response) => {
      this.customerClients = response;
      // @ts-ignore
      this.filteredOptions = this.clientControl.valueChanges.pipe(
        startWith(''),
        map(value => {
          const name = typeof value === 'string' ? value : value?.name ?? "";
          return name ? this._filter(name as string) : this.customerClients.slice();
        }),
      );
    });
  }

  _filter(name: string): Customer[] {
    const filterValue = name.toLowerCase();
    return this.customerClients.filter((options: Customer) => options.companyName.toLowerCase().includes(filterValue));
  }

  displayDocFn(doc: Customer) {
    return (doc && (doc.companyName || doc.customerId)) ? doc.customerId + ' | ' + doc.companyName : '';
  }

  performFilteration(): void {
    this.filteredSkills = this.skillCtrl.valueChanges.pipe(
      startWith(''),
      map(value => {
          const name = typeof value === 'string' ? value.trim() : value?.title.trim() ?? "";
          return name ? this._skillFilter(name as string || '') : this.allSkillsRes.slice();
        }
      ),
    );
  }

  private _skillFilter(name: string): Skill[] {
    const filterValue = name.toLowerCase();
    this.exactMatchFound = this.allSkillsRes.some((skill: Skills) => skill.title.toLowerCase() == filterValue);
    return this.allSkillsRes.filter((option: { title: string; id: any; }) =>
      option.title.toLowerCase().includes(filterValue)
    );
  }

  getWorkTimeZone() {
    this.service.getWorkTimeZone().subscribe((res: TimeZone[]) => {
      this.workTimeZones = res;
      this.filteredTimeZones = this.workTimeZoneControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterTimeZones(value))
      );
    });
  }

  onTimeZoneSelected(event: any) {
    const selectedTimeZone = event.option.value;
    this.jobForm.get('workTimeZone')?.setValue(selectedTimeZone);
  }

  private _filterTimeZones(value: string): TimeZone[] {
    const filterValue = value.toLowerCase();
    return this.workTimeZones.filter(timeZone =>
      timeZone.label.toLowerCase().includes(filterValue) ||
      timeZone.value.toLowerCase().includes(filterValue)
    );
  }

  validateForm(): Promise<void> {
    return new Promise((resolve, reject) => {
      let hasErrors = false;
      Object.values(this.jobForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsTouched();
          control.setErrors({invalid: true});
          hasErrors = true;
        }
      });

      if (this.jobForm.hasError('experienceInvalid')) {
        this.toaster.error('Maximum experience should be greater than minimum experience.');
        this.showloader = false;
        reject();
        return;
      }

      if (hasErrors) {
        if (this.jobForm.invalid) {
          this.showloader = false;
          if (this.jobForm.value.contactId === '' && this.jobForm.value.customerContact === '') {
            this.toaster.error('Select the client contact. If unavailable, create it first, then create the job.');

          } else {
            this.toaster.error('Please fill the required fields before submitting the form.');
          }
          return;
        }
        this.showloader = false;

        reject();
      } else {
        resolve();
      }
    });
  }

  updatedPayload() {
    let newPayload = this.jobForm.value;
    if (!newPayload['workTimeZone']) {
      newPayload['workTimeZone'] = this.workTimeZoneControl.value;
    }
    newPayload['status'] = 'POSTED';
    newPayload['contactId'] = this.jobForm.get('contactId')?.value ?? '';
    newPayload['noOfOpenings'] = this.jobForm.get('noOfOpenings')?.value ?? '';
    newPayload['title'] = this.jobForm.get('title')?.value ?? '';
    newPayload['description'] = this.jobForm.get('description')?.value ?? '';
    newPayload['minExperience'] = this.jobForm.get('minExperience')?.value ?? '';
    newPayload['maxExperience'] = this.jobForm.get('maxExperience')?.value ?? '';
    console.log(' this.selectedSkills', this.selectedSkills)
    newPayload = {
      ...newPayload,
      skills: this.selectedSkills,
      customerId: this.jobForm.value.customerContact?.customer?.id
    };

    return newPayload;
  }

  addJob(): void {
    this.validateForm().then( () => {
      this.updatedPayload();
    this.isSubmitting = true
    this.showloader = true;
    this.jobError = '';
    if (this.data.jobId) {
      if (this.selectedSkills.length === 0) {
        this.showloader = false;
        return;
      }
      this.service.updateAdminJob(this.updatedPayload(), this.data.jobId).then(res => {
        this.isJobsDataUpdated.setUpdated(true);
        this.dialogRef.close();
      }, (e) => {

        this.close();
        if (e.error) {
          this.isSubmitting = false;
          this.dialogRef.disableClose;
        } else if (e.error.status == 400) {
          e.error.message;
          this.errorMessage = e.error.message;
          console.log('error-400', this.errorMessage)
        } else if (e.error.status == 500) {
          this.errorMessage = e.error.message;
          console.log('error-500', this.errorMessage)
        }
      })
    } else {

        if (this.selectedSkills.length === 0) {
          this.showloader = false;
          return;
        }
        this.service.addAdminJob(this.updatedPayload()).subscribe({
          next: response => {
            this.isJobsDataUpdated.setUpdated(true);
            this.dialogRef.close();
          },
          error: err => {
            this.showloader = false;
            this.toaster.error(err.error.message);
            console.error(err);
            this.dialogRef.disableClose;
          }
        })
    }
    })

  }

  close() {
    this.dialogRef.close();
  }

  toggleProfileJobStatus(event: any) {
    if (event.checked === true) {
      this.service.jobActive(this.data.jobId).subscribe({
        next: res => {
          this.isJobsDataUpdated.setUpdated(true);
          this.jobStatus = true;
          this.toaster.success('Job Status Enabled ', 'Success');
        }
      }), (e: any) => {
        this.toaster.error(e.message, 'Request Failed');
      }
    } else {
      this.service.jobInActive(this.data.jobId).subscribe({
        next: res => {
          this.isJobsDataUpdated.setUpdated(true);
          this.jobStatus = false;
          this.toaster.success('Job Status Disabled ', 'Success');
        }
      }), (e: any) => {
        this.toaster.error(e.message, 'Request Failed');
      }
    }
  }

  getAllClientContacts(selectClient: any, type: string) {
    this.service.getContactsByCustomerId((type == 'ADD') ? selectClient.option.value.id : selectClient).then((res) => {
      this.clientContactList = res;
    });
  }

  selectContactId(event: any) {
    this.jobForm.get('contactId')?.setValue(event.value.id)
  }

  getFormControl(controlName: string): FormControl {
    return this.jobForm.get(controlName) as FormControl;
  }

  handleSkillUpdate(consultantId: number, payload: { skills: number[] }): Observable<any> {
    return this.service.updateConsultantSkills(consultantId, payload).pipe(
      tap((res) => {
        this.isDataUpdatedService.setUpdated(true);
        this.toastr.success('Skills updated successfully!');
      }),
      catchError((error) => {
        this.toastr.error('Error updating skills: ' + error.message);
        return throwError(error);
      })
    );
  }

  handleSelectedSkills(event: { skillId: number; skillType: string }[]) {
    this.selectedSkills = event
  }

  handleSkillErrors(event: boolean) {
    this.skillError = event;
  }


  validateMinExperience(controlName: string) {
    let control = this.jobForm.get(controlName);
    let value = control?.value;

    if (value < 0) {
      control?.setErrors({ min: true });
    } else if (value > 29) {
      control?.setErrors({ max: true });
    } else {
      control?.setErrors(null);
    }
  }

  validateMaxExperience(controlName: string) {
    let control = this.jobForm.get(controlName);
    let value = control?.value;

    if (value < 1) {
      control?.setErrors({ min: true });
    } else if (value > 30) {
      control?.setErrors({ max: true });
    } else {
      control?.setErrors(null);
    }
  }


  MaxCharLimits = MaxCharLimits;
}
