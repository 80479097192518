import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  ViewChild,
  forwardRef
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl, FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator, Validators
} from "@angular/forms";
import {Members, Spoc} from "../../shared/interfaces";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {ApiService} from "../../services/api.service";

@Component({
  selector: 'app-spoc-dropdown',
  templateUrl: './spoc-dropdown.component.html',
  styleUrls: ['./spoc-dropdown.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SpocDropdownComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SpocDropdownComponent),
      multi: true
    }
  ]
})
export class SpocDropdownComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {
  @Input() label: string = '';
  @Input() placeHolder: string = 'Select SPOC';
  @Input() selectedSpoc!: any;
  @Input() form!: FormGroup;
  @ViewChild('SPOCAutoCompleter', {read: MatAutocompleteTrigger}) SPOCAutoCompleter!: MatAutocompleteTrigger;
  spocControl = new FormControl('', Validators.required);
  selectedSpcId: number | null = null;
  filteredSpoc!: Observable<Members[]>;
  SPOC_Options: Members[] = [];
  @Input() isSubmitting!: boolean;

  private onChange: (spocId: number | null) => void = () => {
  };

  private onTouched: () => void = () => {
  };


  constructor(private service: ApiService) {
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
    this.getSPOC();

    this.spocControl.valueChanges.subscribe(value => {
      this.validateSpoc(value);
    });
  }

  validateSpoc(value: any): void {
    if (typeof value === 'string') {
      const matchedSpoc = this.SPOC_Options.find(spoc => this.formatSPOC(spoc) === value);
      if (!matchedSpoc) {
        this.spocControl.setErrors({invalid: true});
      } else {
        this.spocControl.setErrors(null);
        this.selectedSpcId = matchedSpoc.userId;
      }
      this.onChange(this.selectedSpcId);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedSpoc'] && this.selectedSpoc) {
      const matchedSpoc = this.SPOC_Options.find(spoc => spoc.userId === this.selectedSpoc.id);
      if (matchedSpoc) {
        this.spocControl.setValue(matchedSpoc);
        this.selectedSpcId = matchedSpoc.userId;
        this.onChange(this.selectedSpcId);
      }
    }
    if (changes['isSubmitting'] && changes['isSubmitting'].currentValue) {
      this.spocControl.markAsTouched();
      this.spocControl.updateValueAndValidity();
    }
  }

  scrollEvent = (): void => {
    if (this.SPOCAutoCompleter?.panelOpen) {
      this.SPOCAutoCompleter.updatePosition();
    }
  };

  getSPOC() {
    this.service.getSpocList().subscribe((res: any) => {
      this.SPOC_Options = res.filter((active: any) => active.active);
      this.filteredSpoc = this.spocControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterSPOC(value))
      );
      if (this.selectedSpoc) {
        const matchedSpoc = this.SPOC_Options.find(spoc => spoc.userId === this.selectedSpoc.id);
        if (matchedSpoc) {
          this.spocControl.setValue(matchedSpoc);
          this.selectedSpcId = matchedSpoc.userId;
          this.onChange(this.selectedSpcId);
        }
      }
    });
  }

  private _filterSPOC(value: string): Members[] {
    if (typeof value !== 'string') return [];
    const filterValue = value.toLowerCase();
    return this.SPOC_Options.filter(option =>
      option.firstName?.toLowerCase().includes(filterValue) ||
      option.lastName?.toLowerCase().includes(filterValue) ||
      option.role?.toLowerCase().includes(filterValue)
    );
  }

  chooseSpoc(event: any) {
    const selectedSpoc = event?.option?.value;
    this.selectedSpcId = selectedSpoc?.userId || null;
    this.onChange(this.selectedSpcId);
    this.spocControl.setValue(selectedSpoc);
    this.spocControl.markAsTouched();
  }

  writeValue(spocId: number | null): void {
    this.selectedSpcId = spocId;
    const matchedSpoc = this.SPOC_Options.find(spoc => spoc.userId === spocId);
    this.spocControl.setValue(matchedSpoc || '');
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(): ValidationErrors | null {
    return this.spocControl.valid ? null : {invalidSpoc: true};
  }

  formatSPOC(option: Members): string {
    if (!option) {
      return '';
    }
    return option.role + ' | ' + option.firstName + ' ' + option.lastName;
  }

}
