import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ComponentName, Consultant, FormUpdateEvent, Skill} from "../../../../shared/interfaces";
import {FormControl} from "@angular/forms";
import {throwError} from "rxjs";
import {ApiService} from "../../../../services/api.service";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ToastrService} from "ngx-toastr";
import {IsDataUpdatedService} from "../../../../services/isDataUpdated.service";
import {Observable} from "rxjs/internal/Observable";
import {catchError, tap} from "rxjs/operators";

@Component({
  selector: 'app-consultant-skills',
  templateUrl: './consultant-skills.component.html',
  styleUrls: ['./consultant-skills.component.css']
})
export class ConsultantSkillsComponent implements OnInit {
  rating: number[] = [];
  consultant: Consultant = {} as any;
  consultantRating: string | null;
  consultantRatingForm: FormControl;
  @Input() data: any;
  @Input() primarySkill: Skill[];
  @Input() secondarySkill: Skill[];
  @Output() formUpdated = new EventEmitter<FormUpdateEvent>();
  private isFormUpdated: boolean = false;

  constructor(
    private service: ApiService,
    public router: Router,
    public snackBar: MatSnackBar,
    private toastr: ToastrService,
    private isDataUpdatedService: IsDataUpdatedService,
  ) {
    this.rating = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    this.consultantRatingForm = new FormControl();
  }

  ngOnInit(): void {
    this.consultant.id = this.data.consultantId;
    if (this.data.id && this.data.consultantData) {
      this.consultantRating = this.data.consultantData?.communicationRating;
      this.consultantRatingForm.setValue(this.consultantRating);
    }
    // this.formUpdated.emit({updated: this.isFormUpdated, componentName: ComponentName.ConsultantSkills});
  }

  addRating(rate: any): void {
    this.service.updateConsultantRating(this.data.id, rate)
      .subscribe((res) => {
        this.isDataUpdatedService.setUpdated(true);
        // this.data = res;
        this.toastr.success('Modified English Communication & Articulation Rating', 'Success');
        this.isFormUpdated = true;
        // this.formUpdated.emit({updated: this.isFormUpdated, componentName: ComponentName.ConsultantSkills});
      }, (e) => {
        console.error('error', e.error.message);
        this.toastr.error(e.error.message, 'Request Failed');
      });
  }

  handleSkillUpdate(consultantId: number, payload: { skills: number[] }): Observable<any> {
    return this.service.updateConsultantSkills(consultantId, payload).pipe(
      tap((res) => {
        this.isDataUpdatedService.setUpdated(true);
        this.toastr.success('Skills updated successfully!');
        // this.formUpdated.emit({updated: false, componentName: ComponentName.ConsultantSkills});
      }),
      catchError((error) => {
        this.toastr.error('Error updating skills: ' + error.message);
        return throwError(error);
      })
    );
  }

  handleSkillDataUpdated(event: boolean) {
    this.isFormUpdated = !event;
    // this.formUpdated.emit({updated: this.isFormUpdated, componentName: ComponentName.ConsultantSkills});
  }
}
