import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {PopUpComponent} from "../../../util/pop-up/pop-up.component";
import {DataService} from "../../../services/data.service";
import {NgDialogAnimationService} from "ng-dialog-animation";
import {CreateJobComponent} from "./create-job/create-job.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {debounce} from "lodash";
import {adminJob} from "../../../shared/interfaces";
import {MatPaginator} from "@angular/material/paginator";
import {debounceTime, distinctUntilChanged, filter, tap} from "rxjs/operators";
import {fromEvent} from "rxjs";
import {IsDataUpdatedService} from "../../../services/isDataUpdated.service";
import {ResetFiltersService} from "../../../services/resetFilters.service";
import {JobshareComponent} from "../../../common/jobshare/jobshare.component";

export interface JobSearchPayload {
  jobId?: string;
  jobTitle?: string;
  from?: string;
  to?: string;
  clientName?: string;
  experienceYears?: { min: number, max: number }[];
  createdBy?: string
}

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.css']
})

export class JobsComponent implements OnInit, AfterViewInit {
  jobs: adminJob[] = [];
  showArchived: boolean = false;
  currentPage = 0;
  totalPages = 0;
  showLoader: boolean = true;
  search = '';
  pageSize = 12;
  totalJobs = 0;
  searchFields: string[] = ['jobId', 'jobTitle', 'postingDate', 'clientName', 'ExperienceYear', 'createdBy', '', '', '','']
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  @ViewChild('input', {static: true}) input: ElementRef | any;
  filters: JobSearchPayload = {
    jobId: "",
    jobTitle: "",
    from: "",
    to: "",
    clientName: "",
    experienceYears: [],
    createdBy: '',
  };

  constructor(
    private resetFilters: ResetFiltersService,
    private service: ApiService,
    private dataService: DataService,
    public snackBar: MatSnackBar,
    public dialog: NgDialogAnimationService,
    private isJobsDataUpdated: IsDataUpdatedService,
  ) {
  }

  ngOnInit(): void {
    this.getAllJobs(this.currentPage, this.pageSize, this.showArchived, this.filters.jobId, this.filters.jobTitle, this.filters.clientName,
      this.filters.from, this.filters.to, this.filters.experienceYears, this.filters.createdBy);
    this.getAllJobs = debounce<any>(this.getAllJobs, 600);
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(
      tap(() => {
        this.getAllJobs(this.paginator.pageIndex, this.paginator.pageSize, this.showArchived, this.filters.jobId, this.filters.jobTitle,
          this.filters.clientName, this.filters.from, this.filters.to, this.filters.experienceYears, this.filters.createdBy);

      })
    ).subscribe();
    if (this.input) {
      fromEvent(this.input?.nativeElement, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => this.getAllJobs(this.paginator.pageIndex, this.paginator.pageSize, this.showArchived, this.filters.jobId,
            this.filters.jobTitle, this.filters.clientName, this.filters.from, this.filters.to, this.filters.experienceYears, this.filters.createdBy)
          ),
        )
        .subscribe();
    }
  }

  onFiltersChanged(updatedFilters: any) {
    this.filters = updatedFilters;
    this.currentPage = 0;
    const fromDate = this.filters.from;
    const toDate = this.filters.to;
    const datesValid = (fromDate && toDate) || (!fromDate && !toDate);
    this.paginator.pageIndex = 0;
    if (datesValid) {
      this.getAllJobs(0, this.pageSize, this.showArchived, this.filters.jobId, this.filters.jobTitle, this.filters.clientName,
        this.filters.from, this.filters.to, this.filters.experienceYears, this.filters.createdBy);
    }
    ;
  }

  clearFilter() {
    this.filters.jobTitle = '';
    this.filters.jobId = '';
    this.filters.from = '';
    this.filters.to = '';
    this.filters.clientName = ''
    this.filters.experienceYears = []
    this.filters.createdBy = ''
    this.search=''
    this.showArchived = false
    this.resetFilters.emitChange(true);
    this.getAllJobs(this.currentPage, this.pageSize, this.showArchived, this.filters.jobId, this.filters.jobTitle, this.filters.clientName, this.filters.from,
      this.filters.to, this.filters.experienceYears, this.filters.createdBy);

  }
  isFilterApplied(): boolean {
    return (this.filters?.jobTitle ?? '') !== '' ||
      (this.filters?.jobId ?? '') !== '' ||
      (this.filters?.from ?? '') !== '' ||
      (this.filters?.to ?? '') !== '' ||
      (this.filters?.clientName ?? '') !== '' ||
      (Array.isArray(this.filters?.experienceYears) && this.filters.experienceYears.length > 0) ||
      (this.filters?.createdBy ?? '') !== '' ||
      (this.search ?? '') !== '' ||
      !!this.showArchived;
  }




  getAllJobs(page: number, size: number, archived: boolean, jobId?: string, jobTitle?: string, clientName?: string,
             from?: string, to?: string, experience?: { min: number; max: number; }[], createdBy?: string) {
    this.showLoader = true;
    this.service.getJobs(page, size, this.search, archived, jobId, jobTitle, clientName, from, to, experience, createdBy).subscribe(res => {
      this.jobs = res.content;
      this.currentPage = res.page.number;
      this.totalPages = res.page.totalPages
      this.showLoader = false
      this.paginator.pageIndex = res.page.number;
      this.paginator.pageSize = res.page.size;
      this.pageSize = res.page.size;
      this.totalJobs = res.page.totalElements;
    })
  }

  stripHtml(html: string): string {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;
    const breaks = tempElement.getElementsByTagName('br');
    for (let i = breaks.length - 1; i >= 0; i--) {
      breaks[i].replaceWith('\n');
    }
    const paragraphs = tempElement.getElementsByTagName('p');
    for (let i = paragraphs.length - 1; i >= 0; i--) {
      paragraphs[i].replaceWith(`${paragraphs[i].textContent?.trim()}\n\n`);
    }
    const lists = tempElement.querySelectorAll('ul, ol');
    lists.forEach((list) => {
      const isOrdered = list.tagName === 'OL';
      const items = Array.from(list.getElementsByTagName('li'));
      const listText = items
        .map((item, index) => {
          const prefix = isOrdered ? `${index + 1}. ` : '• ';
          return `${prefix}${item.textContent?.trim()}`;
        })
        .join('\n');
      list.replaceWith(`\n${listText}\n`);
    });
    const text = tempElement.textContent || '';
    const formattedText = text.replace(/\bResponsibilities:/g, '\nResponsibilities:');
    return formattedText.trim();
  }

  removeDuplicates(text: string): string {
    const lines = text.split('\n');
    const seen = new Set<string>();
    const filteredLines = lines.filter((line) => {
      const normalizedLine = line.trim().toLowerCase();
      if (seen.has(normalizedLine) || normalizedLine.startsWith('job title' || 'Job Description ')) {
        return false;
      }
      seen.add(normalizedLine);
      return true;
    });
    return filteredLines.join('\n').trim();
  }

  getJobDataForCopy(job: any) {
    const jobDescription = job.description || "No description available";
    const plainTextDescription = this.removeDuplicates(this.stripHtml(jobDescription));
    const payloadString = `Job ID: ${job.id}\nJob Title: ${job.title}\n${plainTextDescription}\n\n`;
    this.copyToClipboard(payloadString);
  }

  copyToClipboard(data: string) {
    navigator.clipboard.writeText(data).then(() => {
      this.openSnackBar("Job description copied to clipboard", " ");
    }).catch(err => {
      console.error("Could not copy text: ", err);
    });
  }

  getSkills(skills: any) {
    let skillString = ''
    if (skills.length > 0) {
      skills.forEach((e: any) => {
        skillString = skillString + (skillString == '' ? '' : ', ') + e['title'];
      });
    } else {
      skillString = '--';
    }

    return skillString;
  }

  changeArchived(event: any) {
    console.log('value', event);
    this.showArchived = event.checked;
    this.getAllJobs(this.currentPage, this.pageSize, this.showArchived, this.filters.jobId, this.filters.jobTitle, this.filters.clientName, this.filters.from,
      this.filters.to, this.filters.experienceYears, this.filters.createdBy);

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  openDialogs(action: string, element: any): void {
    if (action === 'delete') {
      const dialogRef = this.dialog.open(PopUpComponent, {
        data: {
          message: 'Are you sure to delete',
          type: 'confirmation'
        }
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.service.deleteJob([element.id]).then(res => {
            this.getAllJobs(this.currentPage, this.pageSize, this.showArchived, this.filters.jobId, this.filters.jobTitle, this.filters.clientName, this.filters.from,
              this.filters.to, this.filters.experienceYears, this.filters.createdBy);

          });
        }
      });
    } else if (action == 'new') {
      const dialogRef = this.dialog.open(CreateJobComponent, this.dataService.jobDialogConfig({
        jobId: null
      }));
      dialogRef.beforeClosed().subscribe((res) => {
        this.isJobsDataUpdated.setUpdated(false);
        this.getAllJobs(this.currentPage, this.pageSize, this.showArchived, this.filters.jobId, this.filters.jobTitle, this.filters.clientName, this.filters.from,
          this.filters.to, this.filters.experienceYears, this.filters.createdBy);

      });
    } else if (action == 'edit') {
      const dialogRef = this.dialog.open(CreateJobComponent, this.dataService.jobDialogConfig({
        jobId: element.id
      }));
      dialogRef.afterClosed().subscribe(() => {
        if (this.isJobsDataUpdated.getBoolean()) {
          this.getAllJobs(this.currentPage, this.pageSize, this.showArchived, this.filters.jobId, this.filters.jobTitle, this.filters.clientName, this.filters.from,
            this.filters.to, this.filters.experienceYears, this.filters.createdBy);

          this.isJobsDataUpdated.setUpdated(false);
        }
      });
    }
  }

  deleteConfirmation(action: string, jobId: number): void {
    if (action === 'Delete') {
      const dialogRef1 = this.dialog.open(PopUpComponent, {
        data: {
          title: 'Alert',
          message: 'Are you sure you want to delete this Job?',
          type: 'Delete'
        }
      });
      dialogRef1.afterClosed().subscribe((res) => {
        if (res) {
          this.service.deleteJob(jobId).then(res => {
            this.getAllJobs(this.currentPage, this.pageSize, this.showArchived, this.filters.jobId, this.filters.jobTitle, this.filters.clientName, this.filters.from,
              this.filters.to, this.filters.experienceYears, this.filters.createdBy);
          });
        }
      });
    }
  }

  handleShareModalOpen(jobId:number) {
    const dialogRef = this.dialog.open(JobshareComponent, {
      width:'350px',
      data:jobId
    });
    dialogRef.afterClosed().subscribe((res) => {

    })
  }
}
