import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../services/api.service";
import {Router} from "@angular/router";
import {Message, ResendOTPRequest, VerifyOTPRequest} from "../login-interface/interface";
import {LoginService} from "../service/login.service";
import {interval, Subscription} from "rxjs";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-otp-login',
  templateUrl: './otp-login.component.html',
  styleUrls: ['./otp-login.component.css']
})
export class OtpLoginComponent implements OnInit {
  otpFormGroup: FormGroup | any;
  otpLoader: boolean = false;
  errorMessage= '';
  infoMessage = ''
  otpSent : boolean = false;
  username : string = '';
  timer: number = environment.otpDuration;
  resendLimitExceeded: boolean = false;
  isResendDisabled: boolean = true;
  timerSubscription !: Subscription;
  userRequestId = localStorage.getItem('userRequestId');
  existingSession : any;
  sessions : {userEmail: string, sessionId: string}[] = []
   constructor(
    private service : ApiService,
    private route: Router,
    private fb: FormBuilder,
    private loginService: LoginService
  ) {
    const navigation = this.route.getCurrentNavigation();
    this.username = navigation ?.extras?.state?.['username'] || '';
    this.startResendTimer();
  }

  ngOnInit(): void {
    if(!this.username){
      this.route.navigate(['/admin/login']);
    }
    this.otpFormGroup = this.fb.group({otp: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('^[0-9]*$')]]});
    this.sessions = JSON.parse(localStorage.getItem('sessions') || '[]');
  }

  resendOtp(): void{
    this.resetResendTimer();

    const payload: ResendOTPRequest = {
      username: this.username,
      userRequestId: this.userRequestId || '',
    };
    this.service.resendOTP(payload).then(res => {
        if(res.message === Message.OTP_SENT){
          this.otpSent = true;
            this.infoMessage = 'OTP Sent Successfully';
            setTimeout(() => this.otpSent = false, 10000);
        }
    }).catch(err => {
      if(err.error.code === 429){
        this.resendLimitExceeded = true;
      }
        this.errorMessage = err ? err.error.reason : 'An error occurred. Please try again later.';
    });
  }

  startResendTimer():void{
    this.isResendDisabled = true;
    this.timer = environment.otpDuration;
    this.timerSubscription = interval(1000).subscribe(() => {
      this.timer--;
      if(this.timer === 0){
        this.isResendDisabled = false;
        this.timerSubscription.unsubscribe();
      }
    });
  }

  resetResendTimer(): void {
    if(this.timerSubscription){
      this.timerSubscription.unsubscribe();
    }
    this.startResendTimer();
  }



  get otp() {
    return this.otpFormGroup.get('otp');
  }

  allowOnlyDigits(event: KeyboardEvent): void {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete', 'Control', 'v'];
      const key = event.key;
      if (allowedKeys.includes(key)) {
        return;
      }
      if (!/^[0-9]$/.test(key)) {
        event.preventDefault();
    }
  }


  verifyOtp(): void {
    this.otpLoader = true;
    this.existingSession = this.sessions.find(
      (map: any) => map.userEmail === this.username
    );

    console.log(this.existingSession,"existing session");
    console.log(this.existingSession?.sessionId,"session id of existing session")

    if (this.otpFormGroup.invalid) {
      this.otpLoader = false;
      if (this.otpFormGroup.get('otp')?.errors) {
        if (this.otpFormGroup.get('otp')?.errors?.required) {
          this.errorMessage = 'OTP is required.';
        } else if (this.otpFormGroup.get('otp')?.errors?.pattern) {
          this.errorMessage = 'Please enter a valid 8 digit OTP.';
        }
      }
      return;
    }
    this.errorMessage = '';
    const payload: VerifyOTPRequest = {
      otp: this.otpFormGroup.get('otp')?.value,
      userRequestId : this.userRequestId || '',
      sessionId :this.existingSession?.sessionId
    };
    this.service.verifyOTP(payload).then(res => {
      if(res.session_id){
        if(!this.existingSession || this.existingSession?.sessionId !== res.sessionId){
          this.sessions = this.sessions.filter(s => s.userEmail !== res.user_email)
          this.sessions.push({userEmail: res.user_email, sessionId: res.session_id});
          localStorage.setItem('sessions', JSON.stringify(this.sessions))
        }
       this.loginService.userLogin(res);
      } else {
        this.errorMessage = 'OTP is invalid';
        this.otpLoader = false;
      }
    }).catch(err => {
      this.errorMessage = err ? err.error.reason : 'An error occurred. Please try again later.';
      this.otpLoader = false;
    });
  }
}
