import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {FormGroup, FormBuilder} from '@angular/forms';
import {DataService} from "../../../services/data.service";
import {SidebarComponent} from "../../sidebar/sidebar.component";
import {MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {CompanyProfileService} from "../../../services/companyProfileService";
import {MaxCharLimits} from "../../../common/errorInputMaxValue";

@Component({
  selector: 'app-manage-profile',
  templateUrl: './manage-profile.component.html',
  styleUrls: ['./manage-profile.component.css']
})
export class ManageProfileComponent implements OnInit {

  company: any;
  companyImage: '' | null;
  type = '';
  organisationForm: FormGroup;
  logoLoader = false;
  showloader = false;
  errorMessage = '';
  loading = false;
  file: File | null;
  data: any;
  orgId: any;
  role: any;
  @ViewChild('fileInput') fileInput: ElementRef<HTMLDivElement>;
  @ViewChild(SidebarComponent) sidebar: SidebarComponent;
  selectedImage: string;
  errorUploadingImage: boolean;

  constructor(
    private dataService: DataService,
    private fb: FormBuilder,
    private service: ApiService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private companyProfileService: CompanyProfileService,
  ) {
  }

  ngOnInit(): void {
    this.dataService.isLoading.next(true);
    this.orgId = localStorage.getItem('orgId');
    this.role = localStorage.getItem('role');
    this.organisationForm = this.fb.group({
      name: [""],
      webUrl: "",
    });
    this.service.getOrganisation(this.orgId).subscribe({
      next: res => {
        this.company = res;
        this.companyImage = res.logo;
        this.selectedImage = res.logo
        this.companyProfileService.setImageUrl(res?.logo)
        this.organisationForm.get("name")?.setValue(res.name);
        this.organisationForm.get("webUrl")?.setValue(res.webUrl);
        this.loading = false;
      },
      error: err => {

      },
      complete: () => {
        this.dataService.isLoading.next(false);
      }
    });
  }

  uploadFile(file: File): void {
    const formData: FormData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);
    this.logoLoader = true;
    this.service.uploadCompanyLogo(formData, this.orgId).subscribe({
      next: res => {
        this.updateCustomerData(res);
        this.selectedImage = res?.logo;
        this.companyProfileService.setImageUrl(res?.logo)
        this.errorUploadingImage = false;
      },
      error: err => {
        this.errorUploadingImage = true;
        this.toastr.error('Error', err.error.message);
      },
      complete: () => {
        this.logoLoader = false;
      }
    })
  }

  updateCustomerData(res: any): void {
    this.companyImage = res?.body?.logo;
    this.dataService.companyImage.next(res.body?.logo);
    this.company.logo = this.companyImage;
  }

  delete(): void {
    this.logoLoader = true;
    this.service.deleteCompanyLogo(this.orgId).then((res => {
      this.companyImage = null;
      this.selectedImage = '';
      this.dataService.companyImage.next('');
      this.companyProfileService.setImageUrl(null)
      this.logoLoader = false;
    }));
  }

  updateOrganisation(): void {
    if (this.role == 'ADMIN') {
      this.showloader = true;
      this.service.updateOrganisation(this.orgId, this.organisationForm.value).then(res => {
        this.showloader = false;
        this.dataService.companyName.next(res.name);
      }, (e) => {
        this.showloader = false;
        if (e.error.status == 400) {
          this.errorMessage = e.error.message;
        } else if (e.error.status == 500) {
          this.errorMessage = e.error.message;
        } else if (e.error.status == 409) {
          this.errorMessage = e.error.message;
        } else {
          this.errorMessage = 'Error occurred,Please contact server team';
        }
      });
    }
  }

  handleFileChange(event: File | null) {
    if (event) {
      this.uploadFile(event);
    }
  }

  handleProfileDelete(event: boolean) {
    if (event)
      this.delete();
  }

   MaxCharLimits = MaxCharLimits;
}
