<div class="content-wrapper-main-inner-content">
  <div class="row mb-3">
    <div class="col-md-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h5 class="page-title">Manage User Profile</h5>
      </div>
    </div>
  </div>
  <div class="col-md-12 companyProfile_wrapper">
    <div class="companyProfile_header theme-bg-color">
      <h5>Add Personal Information</h5>
    </div>
    <div class="companyProfile_content">
      <div class="create-vendor">
        <form [formGroup]="userProfileForm" class="form-wrapper" (submit)="updateUserProfile()">
          <div class="row form-group">
            <div class="col-md-6 form-group border-right">
              <div class="profile-details">
                <app-image-upload [selectedImage]="selectedImage"
                                  (fileChange)="handleFileChange($event)"
                                  [logoLoader]="logoLoader" [errorUploadingImage]="errorUploadingImage"
                                  (deleteConsultantProfileConfirmation)="handleProfileDelete($event)"
                >
                </app-image-upload>
              </div>
            </div>

            <div class="col-md-6">
              <mat-form-field appearance="outline" class="w-100 border-red mb-1">
                <mat-label>First Name</mat-label>
                <input appMaxCharLimitInput class="custom-input-fileds" matInput formControlName="firstName" type="text"
                       required placeholder="First name">
                <mat-error
                  *ngIf="userProfileForm.get('firstName')?.hasError('required') && (userProfileForm.get('firstName')?.touched)">
                  First Name is required.
                </mat-error>
                <mat-error
                  *ngIf="userProfileForm.get('firstName')?.hasError('maxCharLimit')">
                  {{MaxCharLimits.INPUT}}
                </mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" class="w-100 border-red mb-1">
                <mat-label>Last Name</mat-label>
                <input appMaxCharLimitInput class="custom-input-fileds" matInput
                       formControlName="lastName" type="text" placeholder="Last name">
                <mat-error
                  *ngIf="userProfileForm.get('lastName')?.hasError('maxCharLimit')">
                  {{MaxCharLimits.INPUT}}
                </mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" class="w-100 border-red mb-1">
                <mat-label>Email Id</mat-label>
                <input class="custom-input-fileds" matInput formControlName="email" type="email"
                       [readonly]="true"
                       placeholder="Email Id">
              </mat-form-field>

              <div>
                <app-country-phone-input
                  [formControl]="getFormControl('number')"
                  [selectedCountryISO]="user?.number?.countryCode"
                >
                </app-country-phone-input>
                <mat-error style="margin-top: 12px; font-size: 12px"
                           *ngIf="userProfileForm.get('number')?.hasError('required') && (userProfileForm.get('number')?.touched)">
                  Phone number is required.
                </mat-error>
                <mat-error style="margin-top: 12px; font-size: 12px"
                           *ngIf="userProfileForm.get('number')?.hasError('validatePhoneNumber') && (userProfileForm.get('number')?.touched)">
                  Phone number is Invalid.
                </mat-error>
              </div>

              <div *ngIf="displayError" class="text-danger">
                {{ this.errorMessage }}
              </div>
            </div>
          </div>
          <div class="form-group btns d-flex justify-content-end save-form ">
            <button *ngIf="!showLoader" [disabled]="!isFormUpdated()" class="btn btn-save theme-btn-color mt-3"
                    type="submit">Save
            </button>
            <div *ngIf="showLoader" disabled>
              <button id="submitButton1" class="btn btn-saveform" disabled>
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
