<div>
  <div class="content-wrapper">
    <div class="row mt-3">
      <div class="col-md-4">
        <div class="d-flex justify-content-between mat-head">
          <h5>{{ title }}</h5>
        </div>
        <app-image-upload #imageUploader [selectedImage]="selectedImage"
                          [logoLoader]="logoLoader" [errorUploadingImage]="errorUploadingImage"
                          [doesSupportDoc]="true" [resetFileTrigger]="resetFile" [hideButton]="true"
                          [isCsv]="true"
        >
        </app-image-upload>
        <div class="mt-3">
          <div class="form-group btns save-form">
            <button class="action-btn action-btn-cancel" (click)="resetDocFile()"
                    type="submit">Cancel
            </button>
            <button
              class="action-btn float-right theme-btn-color" (click)="triggerUpload()"
              type="submit">Upload
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="d-flex align-items-end justify-content-end">
          <a type="link" [href]="getEnvironment().url + getCsvFileName(formType)">Download Sample File</a>
        </div>
        <div *ngIf="data.length >0">
          <div class="justify-content-center">
            <form [formGroup]="form">
              <table class="table table-striped" style="cursor:pointer;">
                <thead class="tableview theme-bg-color">
                <tr>
                  <th>{{ getTableName(formType) }}</th>
                  <th *ngIf="role!=='MANAGER'">Actions</th>
                </tr>
                </thead>
                <tbody>

                <ng-container formArrayName="{{formType}}"
                              *ngFor="let group of getDocFormControls.controls; index as i;">
                  <tr [formGroupName]="i">
                    <td style="width: 50%">
                      <a *ngIf="editingRowId !== group.get('id')?.value">
                        {{ formType === FormType.SKILL ? group.get('title')?.value : group.get('name')?.value }}
                      </a>
                      <mat-form-field *ngIf="editingRowId === group.get('id')?.value" class="ml-3">
                        <input matInput type="text"
                               [formControlName]="formType === FormType.SKILL ? 'title' : 'name'"
                               placeholder="Name">
                      </mat-form-field>
                    </td>
                    <td style="width: 50%" *ngIf="editingRowId !== group.get('id')?.value && role !== 'MANAGER'">
                      <a type="button" (click)="onEdit(changeControl(group),formType)">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V6C3 5.45 3.19567 4.97933 3.587 4.588C3.979 4.196 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.021 4.196 20.413 4.588C20.8043 4.97933 21 5.45 21 6V12H19V10H5V20H12V22H5ZM22.125 17L20 14.875L20.725 14.15C20.9083 13.9667 21.1417 13.875 21.425 13.875C21.7083 13.875 21.9417 13.9667 22.125 14.15L22.85 14.875C23.0333 15.0583 23.125 15.2917 23.125 15.575C23.125 15.8583 23.0333 16.0917 22.85 16.275L22.125 17ZM14 23V20.875L19.3 15.575L21.425 17.7L16.125 23H14Z"
                            fill="#545454"/>
                        </svg>
                      </a>
                      <mat-slide-toggle
                        class="example-margin mx-3"
                        color="primary"
                        [disabled]="false"
                        [checked]="group.get('active')?.value"
                        (change)="onToggle($event, changeControl(group),formType)">
                        <div class="ActiveButton">{{ group.get('active')?.value ? 'Enabled' : 'Disabled' }}</div>
                      </mat-slide-toggle>
                    </td>
                    <td *ngIf="editingRowId === group.get('id')?.value" style="width: 200px">
                      <div class="d-flex">
                        <div class="me-3">
                          <mat-icon class="done m-2" (click)="onCancelEdit()">
                            <span class="badge bg-secondary text-white">Cancel</span>
                          </mat-icon>
                        </div>
                        <div class="me-3">
                          <mat-icon class="done m-2" (click)="onSave(changeControl(group),formType)">
                            <span class="badge bg-success text-white">Save</span>
                          </mat-icon>
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-container>

                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>
    </div>
    <mat-paginator [length]="totalDocuments"
                   [pageSize]="pageSize"
                   [pageIndex]="currentPage"
                   [pageSizeOptions]="[12,25,50,100]"
                   [hidden]="totalDocuments==0"
                   (page)="onPageChange($event)">
      aria-label="Select page">
    </mat-paginator>
  </div>
</div>
