import {Component, ElementRef, Input, OnInit, ViewChild, OnChanges, SimpleChanges} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {ApiService} from "../../../../../services/api.service";
import {CreateSolutionTechnology, Skill, Solution} from "../../../../../shared/interfaces";
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {Observable} from "rxjs/internal/Observable";
import {map, startWith} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-solution-technology',
  templateUrl: './solution-technology.component.html',
  styleUrls: ['./solution-technology.component.css']
})
export class SolutionTechnologyComponent implements OnInit, OnChanges {
  technologyForm: FormGroup;
  @Input() createSolutionId: number;
  skills: any[] = [];
  separatorKeysCodes: number[] = [13, 188];
  selectedSkill: { id: number; title: string }[] = [];
  filteredSkills: Observable<Skill[]>;
  @ViewChild("chipGrid") chipGrid: ElementRef;
  @ViewChild("skillsAutoCompleter") skillsAutoCompleter: ElementRef;
  disabledSkills: Skill[] = [];
  skillsRes: Skill[] = [];
  exactMatchFound: boolean = false;
  solutionId: number;
  @Input() technologyData: Solution;
  techStack = new FormControl('', [this.createValidator(this.selectedSkill)]);
  skillName = '';
  showloader: boolean = false

  constructor(
    private fb: FormBuilder,
    private service: ApiService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) {
  }

  createValidator(selectedItems: any[]): ValidatorFn {
    return () => {
      return selectedItems && selectedItems.length > 0 ? null : {empty: true};
    };
  }

  updateValidation(control: FormControl, selectedItems: any[]): void {
    control.setValidators([this.createValidator(selectedItems)]);
    control.updateValueAndValidity();
  }


  updateTagValidation() {
    this.updateValidation(this.techStack, this.selectedSkill);
  }

  ngOnInit(): void {
    this.solutionId = this.route?.snapshot?.params['solutionId'];
    this.getSkillType();
    this.technologyForm = this.fb.group({
      compliance: ['', Validators.nullValidator],
      techStackIds: [[], Validators.required],
      inProduction: ['', Validators.required],
      productionDescription: ['', Validators.nullValidator],
      integration: ['', Validators.required],
      integrationDescription: ['', Validators.nullValidator],
      solutionType: ['', Validators.nullValidator],
      whiteLabled: ['', Validators.required],
      sellingModel: ['', Validators.required],
      sellingModelDescription: ['', Validators.nullValidator],
      demoReady: ['', Validators.required],
      demoReadyDescription: ['', Validators.nullValidator],
      implementationTimeframe: ['', Validators.nullValidator],
      effortInvolved: ['', Validators.nullValidator],
      pricingDetails: ['', Validators.nullValidator]
    });
    if (this.technologyData) {
      this.populateTechnologyForm();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.technologyData && changes.technologyData.currentValue) {
      this.populateTechnologyForm();
    }
    if (changes.selectedTags) {
      this.updateTagValidation();
    }
  }

  getFormControl(controlName: string): FormControl {
    return this.technologyForm.get(controlName) as FormControl;
  }

  onSubmit(): void {
    console.log(this.technologyForm);
    console.log(this.technologyForm.get('integrationDescription')?.hasError('maxCharLimit'));
    if (this.technologyForm.get('techStackIds')?.value === []) {
      this.toastr.error('Please select at least one Tech Stack.', 'Error');
    }
    if (this.createSolutionId ?? this.solutionId) {
      if (this.technologyForm.valid) {
        this.createSolutionTechnology(this.createSolutionId ?? this.solutionId, this.technologyForm.value);
      }
    } else {
      this.toastr.warning('Please fill the Solution Information first', 'Missing Information');
    }
  }

  createSolutionTechnology(solutionId: number, payload: CreateSolutionTechnology) {
    this.showloader = true
    this.service.createSolutionTechnology(solutionId, payload).subscribe({
      next: () => {
        this.toastr.success('Solution technology created successfully', 'Success');
        this.showloader = false
      },
      error: (error) => {
        console.error('Error creating solution technology:', error.message);
        this.toastr.error('Failed to Create Solution Technology.', 'Error');
        this.showloader = false
      }
    });
  }

  getSkillType(): void {
    this.service.getSkills().then((res: any[]) => {
      this.skillsRes = res.filter((title) => title.title !== "");
      this.filteredSkills = this.techStack.valueChanges.pipe(
        startWith(''),
        map(value => this._filterSkills(value))
      );
    });
  }

  private _filterSkills(value: string | Skill): Skill[] {
    const filterValue = typeof value === 'string' ? value.trim().toLowerCase() : value?.title.trim().toLowerCase() ?? '';
    this.exactMatchFound = this.skillsRes?.some(skill => skill.title.toLowerCase() === filterValue);
    return this.skillsRes.filter(skill =>
      skill.title?.toLowerCase()?.includes(filterValue)
    );
  }

  removeSkill(skill: any): void {
    const index = this.selectedSkill.indexOf(skill);
    if (index >= 0) {
      this.selectedSkill.splice(index, 1);
    }
    this.technologyForm.patchValue({
      techStackIds: this.selectedSkill?.map((res) => res.id)
    });
    this.updateTagValidation();
  }

  selectSkills(event: MatAutocompleteSelectedEvent): void {
    const skill = event.option.value;
    this.updateTagValidation();
    if (!this.selectedSkill) {
      this.selectedSkill = [];
    }
    this.selectedSkill.push(skill);
    this.techStack.setValue('');
    if (!this.disabledSkills) {
      this.disabledSkills = [];
    }
    this.disabledSkills.push({...skill, disabled: true});
    this.technologyForm.patchValue({
      techStackIds: this.selectedSkill?.map((res) => res.id)
    });
  }

  disableSkill(skill: any): boolean {
    return this.selectedSkill?.some(selected => selected.id === skill.id) || this.skills?.some(s => s.id === skill.id);
  }

  addSkillType(type: string): void {

    if (type === 'primary') {
      const payload = {
        'title': this.techStack.value,
      };
      this.service.addOrgSkills(payload).then(res => {
        this.getSkillType();
        this.selectedSkill.push(res);
        this.techStack.setValue('');
        this.skillsAutoCompleter.nativeElement.value = '';
        this.toastr.success('Skill added successfully', 'Success');
      }).catch(e => {
        console.error('error', e.error.message);
        this.toastr.error('Failed to add skill.', 'Error');
      });
    }
  }

  openAutocomplete(trigger: MatAutocompleteTrigger): void {
    trigger.openPanel();
    setTimeout(() => {
      trigger.updatePosition();
    }, 300);
  }

  hideAutoComplete(trigger: MatAutocompleteTrigger): void {
    trigger.openPanel();
    setTimeout(() => {
      trigger.closePanel();
    }, 200);
  }

  private populateTechnologyForm(): void {
    if (this.technologyData && this.technologyData.solutionTechnology) {
      const solutionTech = this.technologyData.solutionTechnology;

      this.technologyForm.patchValue({
        compliance: solutionTech.compliance,
        inProduction: solutionTech.inProduction,
        productionDescription: solutionTech.productionDescription,
        integration: solutionTech.integration,
        integrationDescription: solutionTech.integrationDescription,
        solutionType: solutionTech.solutionType,
        whiteLabled: solutionTech.whiteLabled,
        sellingModel: solutionTech.sellingModel,
        sellingModelDescription: solutionTech.sellingModelDescription,
        demoReady: solutionTech.demoReady,
        demoReadyDescription: solutionTech.demoReadyDescription,
        implementationTimeframe: solutionTech.implementationTimeframe,
        effortInvolved: solutionTech.effortInvolved,
        pricingDetails: solutionTech.pricingDetails
      });
      this.selectedSkill = solutionTech.techStackUsed?.map((tech) => {
        return {id: tech.id, title: tech.title};
      });
      this.updateTagValidation()
      this.technologyForm.patchValue({techStackIds: this.selectedSkill?.map((res) => res.id)});
    }
  }
}
