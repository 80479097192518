import {Component, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Customer, FormUpdateEvent} from "../../../../shared/interfaces";
import {Observable} from "rxjs";
import {ApiService} from "../../../../services/api.service";
import {ToastrService} from "ngx-toastr";
import {map, startWith} from "rxjs/operators";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DatePipe} from "@angular/common";
import {IsDataUpdatedService} from "../../../../services/isDataUpdated.service";
import {IsDataUpdatedForModalsService} from "../../../../services/isDataUpdatedForModals.service";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {MaxCharLimits} from "../../../../common/errorInputMaxValue";

@Component({
  selector: 'app-consultants-deploy-modal',
  templateUrl: './consultants-deploy-modal.component.html',
  styleUrls: ['./consultants-deploy-modal.component.css']
})
export class ConsultantsDeployModalComponent implements OnInit {
  selectedTier = '';
  status: string;
  runTimeStatus: boolean;
  showLoader = false;
  deployedForm: FormGroup;
  auditHistory: any[] = [];
  clientControl = new FormControl('', [Validators.required]);
  customerClients: Customer[] = [];
  clientContactList: any[] = [];
  isContactDisable = false;
  filteredOptions: Observable<Customer[]>;
  customerId: string;
  daysListForm: FormControl;
  availabilityToJoin: string | null;
  minEndDate: string | null;
  documentExpiryDate: '' | undefined;
  documentStartDate: string = '';
  previousFormValue: any;
  initializingForm: boolean = true;
  isDisable = false;
  loader: boolean = false;
  @Output() formUpdated = new EventEmitter<FormUpdateEvent>();
  contractPeriodValue: string = '';
  @ViewChild('NameAutoCompleter', {read: MatAutocompleteTrigger})
  NameAutoCompleter: MatAutocompleteTrigger;

  constructor(
    @Inject(MAT_DIALOG_DATA) public consultantId: any,
    private service: ApiService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ConsultantsDeployModalComponent>,
    private datePipe: DatePipe,
    private isDataUpdatedService: IsDataUpdatedService,
    private isAssignmentDataUpdated: IsDataUpdatedForModalsService,
  ) {
    this.daysListForm = new FormControl();
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
    if (this.consultantId.consultantDeployHistoryId != null) {
      this.isDisable = true
      this.service.getConsultantContractById(this.consultantId.consultantDeployHistoryId).subscribe(data => {
        const startDate = new Date(data.contractStartDate);
        const endDate = new Date(data.contractEndDate);
        const engagementCategory = this.mapEngagementTypeToCategory(data.engagementCategory);
        this.deployedForm.patchValue({
          customerId: data?.customerId ?? '',
          customerName: data?.customerName ?? '',
          contractStartDate: this.datePipe.transform(startDate, 'yyyy-MM-dd'),
          contractEndDate: this.datePipe.transform(endDate, 'yyyy-MM-dd'),
          contractPeriod: data.contractPeriod ?? '',
          workMode: data.workMode ?? '',
          tier: data.tier ?? '',
          engagementType: data.engagementType ?? '',
          engagementCategory: engagementCategory,
          note: data.note ?? ''
        });
        this.clientControl.setValue(`${data.customer.customerId} ${data?.customer?.companyName}`);
        this.clientControl.disable();
        this.customerId = data.customerId;
        this.availabilityToJoin = data.availabilityToJoin;
        this.minEndDate = data.contractStartDate ? this.datePipe.transform(new Date(new Date(data.contractStartDate).getTime() + 24 * 60 * 60 * 1000), 'yyyy-MM-dd') : null;
        this.documentExpiryDate = data.documentExpiryDate;
        this.documentStartDate = this.datePipe.transform(data.contractStartDate, 'yyyy-MM-dd') ?? '';
        this.initializingForm = false;
      });
    }
    this.deployedForm = this.fb.group({
      customerId: [""],
      contractStartDate: [new Date()],
      contractEndDate: [new Date()],
      contractPeriod: [""],
      workMode: ["", Validators.required],
      tier: [""],
      engagementType: [""],
      note: [""],
      engagementCategory: [""]
    });

    this.deployedForm.get('contractStartDate')?.valueChanges.subscribe(() => {
      const startDateString = this.deployedForm.get('contractStartDate')?.value;
      if (startDateString) {
        this.documentExpiryDate = '';
        this.contractPeriodValue = "";
        this.calculateMonths();
      }
    });

    this.deployedForm.get('contractEndDate')?.valueChanges.subscribe(() => {
      const startDateString = this.deployedForm.get('contractStartDate')?.value;
      const endDateString = this.deployedForm.get('contractEndDate')?.value;
      if (startDateString && endDateString) {
        this.calculateMonths();
      }
    });
    this.deployedForm.get('engagementType')?.disable();
    this.deployedForm.get('contractPeriod')?.disable();
    this.deployedForm.get('engagementCategory')?.disable();
    this.getCustomerClients();
    this.getDeployedHistory();
  }

  mapEngagementTypeToCategory(engagementType: string): string {
    if (engagementType === 'PREMIUM') {
      return 'Tier 1 - Premium Customer'
    } else if (engagementType === 'REGULAR') {
      return 'Tier 2 - Regular Customer'
    } else if (engagementType === 'PARTNER') {
      return 'Tier 3 - Partner Customer'
    }
    return ''
  }

  scrollEvent = (): void => {
    if (this.NameAutoCompleter?.panelOpen)
      this.NameAutoCompleter.updatePosition();
  };

  updatedPayload() {
    let newPayload = this.deployedForm.value;
    const tierMap: { [key: string]: string } = {
      'Tier 1 - Premium Customer': 'PREMIUM',
      'Tier 2 - Regular Customer': 'REGULAR',
      'Tier 3 - Partner Customer': 'PARTNER'
    };
    const tierValue = newPayload.engagementCategory ? tierMap[newPayload.engagementCategory] : '';
    newPayload = {
      ...newPayload,
      engagementCategory: tierValue,
      customerId: this.customerId
    };
    return newPayload;
  }

  validateForm(): Promise<void> {
    return new Promise((resolve, reject) => {
      let hasErrors = false;
      Object.values(this.deployedForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsTouched();
          hasErrors = true;
        }
      });
      if (hasErrors) {
        reject();
      } else {
        resolve();
      }
    });
  }

  addDeployedHistory(): void {
    this.validateForm().then(() => {
      this.showLoader = true
      if (this.consultantId.consultantDeployHistoryId) {
        this.service.updateConsultantContract(this.consultantId.consultantDeployHistoryId, this.updatedPayload()).subscribe(() => {
          this.isDataUpdatedService.setUpdated(true);
          this.isAssignmentDataUpdated.setUpdated(true);
          this.showLoader = false;
          this.dialogRef.close();
          this.deployedForm.reset();
          this.runTimeStatus = false;
          this.getDeployedHistory();
        }, (e) => {
          console.log(e)
          this.showLoader = false;
          this.toastr.error(`${e.error?.message}`, 'Request Failed');
        });
      } else {
        this.service.uploadConsultantDeployedHistory(this.updatedPayload(), this.consultantId.id).then(() => {
          this.isDataUpdatedService.setUpdated(true);
          this.showLoader = false;
          this.dialogRef.close();
          this.runTimeStatus = false;
          this.getDeployedHistory();
        }, (e) => {
          console.log(e)
          this.showLoader = false;
          this.toastr.error(`${e.error?.message}`, 'Request Failed');
        });
      }
    })
  }

  calculateMonths(): void {
    const startDateString = this.deployedForm.get('contractStartDate')?.value;
    const endDateString = this.deployedForm.get('contractEndDate')?.value;
    if (startDateString && endDateString) {
      const startDate = new Date(startDateString);
      const endDate = new Date(endDateString);
      endDate.setDate(endDate.getDate() + 1);
      const startYear = startDate.getFullYear();
      const startMonth = startDate.getMonth();
      const startDay = startDate.getDate();
      const endYear = endDate.getFullYear();
      const endMonth = endDate.getMonth();
      const endDay = endDate.getDate();
      let years = endYear - startYear;
      let months = endMonth - startMonth;
      let days = endDay - startDay;
      if (days < 0) {
        months--;
        days += new Date(endYear, endMonth, 0).getDate();
      }
      if (months < 0) {
        years--;
        months += 12;
      }
      let periodString = '';
      if (years > 0) {
        if (years === 1) {
          periodString += `${years} year `;
        } else {
          periodString += `${years} years `;
        }
      }
      if (months > 0) {
        periodString += `${months} month `;
      }
      if (days > 0) {
        periodString += `${days} days`;
      }
      this.deployedForm.get('contractPeriod')?.setValue(periodString.trim());
    } else {
      console.error('Both start date and end date are required.');
    }
  }

  close() {
    this.dialogRef.close();
  }

  getDeployedHistory(): void {
    this.service.getConsultantDeployedHistory(this.consultantId?.id).subscribe(resp => {
      this.auditHistory = resp;
    })
  }

  getCustomerClients() {
    this.service.getActiveCustomerClients().subscribe((response) => {
      this.customerClients = response;
      this.customerClients.forEach((client) => {
        this.customerId = client.id;
      });
      // @ts-ignore
      this.filteredOptions = this.clientControl.valueChanges.pipe(
        startWith(''),
        map(value => {
          this.customerId = value.id;
          const name = typeof value === 'string' ? value : value?.name ?? "";
          return name ? this._filter(name as string) : this.customerClients.slice();
        }),
      );
    });
  }

  _filter(name: string): Customer[] {
    const filterValue = name.toLowerCase();
    return this.customerClients.filter((options: Customer) => options.companyName.toLowerCase().includes(filterValue));
  }

  displayDocFn(doc: Customer) {
    return (doc && (doc.companyName || doc.customerId)) ? doc.customerId + ' | ' + doc.companyName : '';
  }

  getAllClientContacts(selectClient: any, type: string) {
    this.selectedTier = selectClient.option.value.category;
    if (this.selectedTier === 'PREMIUM') {
      this.deployedForm.get('engagementCategory')?.setValue('Tier 1 - Premium Customer');
    } else if (this.selectedTier === 'REGULAR') {
      this.deployedForm.get('engagementCategory')?.setValue('Tier 2 - Regular Customer');
    } else if (this.selectedTier === 'PARTNER') {
      this.deployedForm.get('engagementCategory')?.setValue('Tier 3 - Partner Customer');
    } else {
      this.deployedForm.get('engagementCategory')?.setValue('');
    }
    this.service.getContactsByCustomerId((type == 'ADD') ? selectClient.option.value.id : selectClient).then((res) => {
      this.clientContactList = res;
      this.isContactDisable = true;
    });
  }

  updateEndDateMinDate() {
    if (this.documentStartDate) {
      const startDate = new Date(this.documentStartDate);
      startDate.setDate(startDate.getDate() + 1);
      this.deployedForm.get('contractPeriod')?.reset();
      this.deployedForm.get('contractEndDate')?.reset('');
      this.minEndDate = this.datePipe.transform(startDate, 'yyyy-MM-dd');
      this.calculateMonths();
    } else {
      this.minEndDate = null;
    }
  }

  MaxCharLimits = MaxCharLimits;
}
