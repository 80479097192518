<section class="login__wrapper">
  <div class="row">
    <div class="col-lg-6 col-md-12 form-section">
      <div class="logo-container">
        <img src="../../assets/adminLogo.png" alt="Logo" class="logo"/>
      </div>
      <div class="form-container">
        <div *ngIf="currentStep === 1" class="form-content">
          <h4 class="heading_2">Register Now</h4>
          <p class="subtext">Create a new account on ProTeamMate</p>

          <form [formGroup]="registerNowForm" (submit)="Submit()">
            <div class="d-flex">
              <div class="form-group mb-4 me-3">
                <label class="d-flex justify-content-between" for="firstName">
                  <div>First Name <span style="color: red;">*</span>
                  </div>
                  <div class="text-danger" *ngIf="registerNowForm.get('firstName')?.invalid && registerNowForm.get('firstName')?.touched">
                    First name is required.
                  </div>
                </label>
                <input formControlName="firstName" type="text" id="firstName" placeholder="" required/>
              </div>

              <div class="form-group mb-4">
                <label class="d-flex justify-content-between" for="firstName">
                  <div>Last Name
                  </div>
                </label>
                <input formControlName="lastName" type="text" id="lastName" placeholder=""/>
              </div>
            </div>

            <div class="form-group mb-4">
              <label class="d-flex justify-content-between" for="firstName">
                <div>Company Name<span style="color: red;">*</span></div>
                <div class="text-danger" *ngIf="registerNowForm.get('companyName')?.invalid && registerNowForm.get('companyName')?.touched">
                  Company Name is required.
                </div>
              </label>
              <input formControlName="companyName" type="text" id="companyName" placeholder="" required/>
            </div>

            <div class="form-group mb-4">
              <label class="d-flex justify-content-between" for="firstName">
                <div>Work Email<span style="color: red;">*</span></div>
                <div class="text-danger" *ngIf="registerNowForm.get('email')?.invalid && registerNowForm.get('email')?.touched">
                  <span *ngIf="registerNowForm.get('email')?.errors?.['required']">Email is required.</span>
                  <span *ngIf="registerNowForm.get('email')?.errors?.['email']">Enter a valid email address.</span>
                </div>
              </label>
              <input formControlName="email" type="text" id="email" placeholder="username@mailid.com" required/>
            </div>
            <div class="mb-3">
              <label class="d-flex justify-content-between" for="firstName">
                <div>Contact No <span style="color: red;">*</span></div>
                <div class="text-danger" style="font-size: 15px" *ngIf="registerNowForm.get('number')?.invalid && registerNowForm.get('number')?.touched">
                Valid contact number is required.
                </div>
              </label>
              <ngx-intl-tel-input
                [cssClass]="'custom'"
                [preferredCountries]="preferredCountries"
                [enableAutoCountrySelect]="true"
                [enablePlaceholder]="true"
                [searchCountryFlag]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                [selectFirstCountry]="false"
                [selectedCountryISO]="CountryISO.India"
                [maxLength]="15"
                [phoneValidation]="true"
                [separateDialCode]="true"
                [numberFormat]="PhoneNumberFormat.National"
                formControlName="number"
              ></ngx-intl-tel-input>
            </div>
            <button *ngIf="!registerNowLoader" class="btn btn-theme w-100" type="submit">Submit</button>
            <div *ngIf="registerNowLoader" class="loader-wrapper">
              <button class="btn btn-theme w-100" disabled>
                <div class="spinner-border" role="status"></div>
              </button>
            </div>
            <div *ngIf="errorMessage" class="text-danger" style="padding-top: 12px">
              {{ errorMessage }}
            </div>
          </form>
          <div class="d-flex justify-content-center  mt-4 ">
              <span style="font-size: 13px"> Already have an account?
                <span style="color: #1177bd ; cursor: pointer">
                  <a [routerLink]="GoToLoginPage()">Login</a>
                </span>
              </span>
          </div>
        </div>
        <div *ngIf="currentStep === 2" class="form-content">
          <div class="form-group mb-4">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <svg fill="#26C263" width="120px" height="120x" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"
                   stroke="#26C263">
                <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M745.472 352.256c-8.192-4.096-20.48-4.096-28.672 0L462.848 614.4 344.064 491.52c-8.192-8.192-20.48-8.192-28.672 0s-8.192 20.48-4.096 28.672L446.464 655.36h4.096s4.096 0 4.096 4.096h16.384s4.096 0 4.096-4.096h4.096l270.335-274.432c0-8.192 0-20.48-4.096-28.672z"/>
                  <path
                    d="M512 1024C229.376 1024 0 794.624 0 512S229.376 0 512 0s512 229.376 512 512-229.376 512-512 512zm0-983.04C253.952 40.96 40.96 253.952 40.96 512S253.952 983.04 512 983.04 983.04 770.048 983.04 512 770.048 40.96 512 40.96z"/>
                </g>
              </svg>
              <h4 class="mb-3 mt-2">Account Registered</h4>
              <p class="mb-3">Your registration has been done successfully.</p>
              <button type="button" class="btn btn-primary" [routerLink]="['/login']">
                Back to Login
              </button>
            </div>
          </div>
        </div>
      </div>
      <app-common-footer></app-common-footer>
    </div>
    <div class="col-lg-6 col-md-12 col-sm-12 bg-img">
      <div class="informeson">
        <img src="../../assets/ClientLoginBackground.png" alt="Background Image"/>
      </div>
    </div>
  </div>
</section>
