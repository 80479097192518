<div class="row vendor_wrapper">
  <div class="col-md-4 d-flex flex-column">
    <div class="upload_wrapper flex-fill">
      <div class="upload_doc text-center mb-5">
        <div>
          <h2 class="font-weight-bold">Upload company logo</h2>
        </div>
      </div>
      <div>
        <app-image-upload [selectedImage]="customerImage"
                          (fileChange)="handleFileChange($event)"
                          [logoLoader]="logoLoader" [errorUploadingImage]="errorUploadingImage"
                          (deleteConsultantProfileConfirmation)="handleProfileDelete($event)"
        >
        </app-image-upload>
        <hr/>
        <div class="form-group btns save-form text-center mt-5">
          <h3>Activate/Deactivate customer</h3>
          <mat-slide-toggle
            class="example-margin"
            color="primary"
            [disabled]=false
            [checked]="customerStatus"
            (change)="toggleCustomerStatus()">
            <div class="ActiveButton">ACTIVE</div>
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-8">
    <div class="justify-content-center mx-height-scroll mt-2" style="overflow: auto;max-height: 65vh;">
      <table class="table audit_table table-striped" style="cursor:pointer;">
        <thead class="tableview theme-bg-color">
        <tr>
          <th>Audit History</th>
          <th>Created Date</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let audits of audit">
          <tr>
            <td>
              <div class="d-flex align-items-baseline">
                <div class="circle me-2">
                  <span
                    class="initials theme-bg-color"> {{ getInitials(audits?.user?.firstName ?? '', audits?.user?.lastName ?? '') }} </span>
                </div>
                <p> {{ audits.user?.firstName ?? '' }} {{ audits?.user?.lastName ?? '' }} {{ audits?.actionDetails ?? '' }}</p>
              </div>
            </td>
            <td>
              <div style="font-weight: lighter; font-size: 14px">
                <p>{{ audits.date | date: 'h:mm a, d MMM y' }}</p>
              </div>
            </td>
        </ng-container>
        </tbody>
      </table>
      <div *ngIf="audit.length == 0" class="d-flex justify-content-center mt-8 empty_table_wrapper theme-bg-color m-0">
        <span> No Result Found</span>
      </div>
    </div>
    <mat-paginator [length]="totalAudit"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="[5,10,12,25,50,100]"
                   [hidden]="totalAudit==0"
                   aria-label="Select page">
    </mat-paginator>

  </div>

</div>
