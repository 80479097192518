<div class="consultant_wrapper">
  <div class="row">
    <div class="col-md-6 border-right">
      <app-image-upload [title]="'Profile picture'" [selectedImage]="consultantImage"
                        (fileChange)="handleFileChange($event)"
                        [logoLoader]="logoLoader" [errorUploadingImage]="errorUploadingImage"
                        (deleteConsultantProfileConfirmation)="handleProfileDelete($event)"
      >
      </app-image-upload>
    </div>

    <div class="col-md-6 m-auto">
      <div class="d-grid justify-content-center">
        <mat-slide-toggle
          class="example-margin mb-4"
          color="primary"
          [disabled]=false
          [checked]="pictureStatus"
          (change)="toggleProfilePictureStatus($event)">
          <div class="ActiveButton">Display Profile Picture</div>
        </mat-slide-toggle>

        <mat-slide-toggle
          class="example-margin"
          color="primary"
          [disabled]=false
          [checked]="consultantStatus"
          (change)="toggleActiveConsultant($event)">
          <div class="ActiveButton">Active Consultant</div>
        </mat-slide-toggle>


        <mat-slide-toggle
          class="example-margin pt-3"
          color="primary"
          [disabled]=false
          [checked]="featured"
          (change)="toggleFeatureConsultant($event)">
          <div class="ActiveButton">Feature Consultant</div>
        </mat-slide-toggle>
      </div>
    </div>
  </div>
</div>

