import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {ImageCropperComponent} from "../../content/admin/image-cropper/image-cropper.component";

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.css']
})
export class ImageUploadComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() selectedImage: string;
  @Input() doesSupportDoc: boolean = false;
  @Input() doesSupportAll: boolean = false;
  @Input() width: number;
  @Input() height: number;
  @Input() primaryButton: string = '';
  @Input() secondaryButton: string = '';
  @Input() isCsv: boolean = false;
  @Output() fileChange = new EventEmitter<File | null>();
  @Output() deleteConsultantProfileConfirmation = new EventEmitter<boolean>();
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  file: File | null = null;
  imageSrc: string = '';
  isFileReadyForUpload: boolean = false;
  @Input() logoLoader!: boolean;
  @Input() errorUploadingImage!: boolean;
  @Input() hideButton: boolean = false;
  @Input() resetFileTrigger!: boolean;

  constructor(
    public dialog: MatDialog,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit(): void {
    if (this.selectedImage) {
      this.imageSrc = this.selectedImage;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedImage'] && changes['selectedImage']?.currentValue) {
      this.imageSrc = this.selectedImage;
    }
    if (this.selectedImage === '' && !this.logoLoader) {
      setTimeout(() => {
        this.resetFile();
      }, 100);
    }
    if (changes['resetFileTrigger']) {
      this.resetFile();
    }
  }

  uploadFileTrigger(): void {
    this.fileInput.nativeElement.click();
  }

  onSelectLogo(event: any, dragEvent?: boolean): void {
    event.preventDefault();
    this.file = dragEvent ? event.dataTransfer.files[0] : event.target.files[0];
    if (!this.file) return;

    const allowedImages = ['image/jpeg', 'image/png', 'image/jpg'];
    const allowedDocs = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ];
    const allowedCsv = ['text/csv', 'application/vnd.ms-excel'];

    let allowedTypes = this.doesSupportDoc
      ? allowedDocs
      : this.doesSupportAll
        ? [...allowedDocs, ...allowedImages]
        : allowedImages;

    if (this.isCsv) {
      allowedTypes = allowedCsv;
    }

    const maxSize = 2 * 1024 * 1024; // 2MB

    if (!allowedTypes.includes(this.file.type)) {
      this.toastr.error('Unsupported file type.', 'Error');
      this.resetFile();
      return;
    }

    if (this.file.size > maxSize) {
      this.toastr.error('File size exceeds 2MB. Please select a smaller file.', 'Error');
      this.resetFile();
      return;
    }

    if (this.isCsv) {
      this.isFileReadyForUpload = true;
      this.fileChange.emit(this.file);
      return;
    }

    if (this.doesSupportDoc) {
      this.isFileReadyForUpload = true;
      this.fileChange.emit(this.file);
      return;
    }

    if (allowedImages.includes(this.file.type)) {
      const dialogRef = this.dialog.open(ImageCropperComponent, {
        width: '60%',
        data: {file: this.file}
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result?.blob instanceof Blob) {
          this.handleBlobResult(result.blob);
        } else if (result?.base64) {
          this.handleBase64Result(result.base64);
        }
      });
    } else {
      this.processFile(this.file);
    }
  }

  handleBlobResult(blob: Blob): void {
    const file = new File([blob], this.file?.name || 'cropped_image.png', {type: blob.type});
    this.processFile(file);
  }

  handleBase64Result(base64: string): void {
    const file = this.base64ToFile(base64, this.file?.name || 'cropped_image.png');
    if (file) {
      this.processFile(file);
    } else {
      this.resetFile();
    }
  }

  processFile(file: File): void {
    this.file = file;
    const reader = new FileReader();
    reader.onload = (e: any) => {
      if (file.type.startsWith('image/')) {
        this.imageSrc = e.target.result;
      }
      this.isFileReadyForUpload = true;
    };
    reader.readAsDataURL(file);
  }

  resetFile(): void {
    this.file = null;
    this.imageSrc = '';
    this.isFileReadyForUpload = false;
    if (this.fileInput?.nativeElement) {
      this.fileInput.nativeElement.value = '';
    }
    this.fileChange.emit(null);
  }

  base64ToFile(data: string, filename: string): File | null {
    const arr = data.split(',');
    const mimeMatch = arr[0].match(/:(.*?);/);
    if (!mimeMatch) return null;
    const mime = mimeMatch[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
  }

  handleDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  delete(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.selectedImage) {
      this.deleteConsultantProfileConfirmation.emit(true);
    } else {
      this.resetFile();
    }
    this.fileInput.nativeElement.value = '';
  }

  handleUpload(): void {
    if (!this.file) {
      this.uploadFileTrigger();
    } else {
      this.isFileReadyForUpload = false;
      this.fileChange.emit(this.file);
    }
  }

  retryUpload(event: Event): void {
    event.stopPropagation();
    if (this.file) {
      this.fileChange.emit(this.file);
    }
  }

  getTruncatedDocName(): string {
    if (!this.file?.name) return '';
    const maxLength = window.innerWidth <= 820 ? 4 : this.file.name.length;
    return this.file.name.length > maxLength ? this.file.name.substring(0, maxLength) + '...' : this.file.name;
  }

  get acceptedFileTypes(): string {
    return this.isCsv ? '.csv' : (this.doesSupportAll ? '.png, .jpeg, .jpg, application/pdf, .xls, .xlsx, .doc, .docx' : (this.doesSupportDoc ? 'application/pdf, .xls, .xlsx, .doc, .docx' : '.png, .jpeg, .jpg'));
  }
}
