import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../services/api.service';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ForgotPasswordEmailService} from "../services/forgotPasswordEmail.service";
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-register-now',
  templateUrl: './register-now.component.html',
  styleUrls: ['./register-now.component.css'],
})
export class RegisterNowComponent implements OnInit {
  registerNowForm: FormGroup;
  countryCode: string[] = [];
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  errorMessage = '';
  @ViewChild('passwordInput') passwordInput: ElementRef<HTMLInputElement>;
  @ViewChild('faIconPasswordInputButton') faIconPasswordInputButton: ElementRef<HTMLElement>;
  @ViewChild('confirmPasswordInput') confirmPasswordInput: ElementRef<HTMLInputElement>;
  @ViewChild('faIconConfirmPasswordInputButton') faIconConfirmPasswordInputButton: ElementRef<HTMLElement>;
  registerNowLoader: boolean = false;
  currentStep: number = 1;

  constructor(
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private service: ApiService,
    private emailService: ForgotPasswordEmailService
  ) {
  }

  ngOnInit(): void {
    this.registerNowForm = this._formBuilder.group({
      firstName: ['', Validators.required],
      lastName: [''],
      companyName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      number: [null, Validators.required],
    });
  }



  Submit(): void {
    this.registerNowLoader = true;
    this.errorMessage = ''
    if (this.registerNowForm.invalid) {
      this.registerNowForm.markAllAsTouched();
      this.registerNowLoader = false;
      this.errorMessage = 'Please fill in all required fields correctly.';
      return;
    }
    let payload = {
      ...this.registerNowForm.value,
      number: {
        countryCode: this.registerNowForm?.value?.number?.countryCode,
        dialCode: this.registerNowForm?.value?.number?.dialCode,
        e164Number: this.registerNowForm?.value?.number?.e164Number,
        internationalNumber: this.registerNowForm?.value?.number?.internationalNumber,
        nationalNumber: this.registerNowForm?.value?.number?.nationalNumber,
        mobile: this.registerNowForm?.value?.number?.number,
      }
    }
    this.service.registerNow(payload).subscribe(
      (response) => {
        this.registerNowLoader = false;
        this.currentStep = 2;
        console.log('Response:', response);
      },
      (error) => {
        this.registerNowLoader = false;
        this.errorMessage = error?.error?.reason || 'Something went wrong, please try again.';
        console.error('Error:', error);
      }
    );
  }

  GoToLoginPage(): string {
    return '/';
  }

  getFormControl<T>(controlName: string): any {
    return this.registerNowForm.get(controlName);
  }
}


