import {Component, OnInit, Input, Output, EventEmitter, forwardRef, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormControlName} from '@angular/forms';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from "ngx-intl-tel-input";

@Component({
  selector: 'app-country-phone-input',
  templateUrl: './country-phone-input.component.html',
  styleUrls: ['./country-phone-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountryPhoneInputComponent),
      multi: true
    }
  ],
})
export class CountryPhoneInputComponent implements OnInit, ControlValueAccessor {
  SearchCountryField = SearchCountryField;
  PhoneNumberFormat = PhoneNumberFormat;
  @Input() modal : boolean = false;
  @Input() required : boolean = false;
  @Input() formControl: FormControl;
  @Input() preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  @Input() selectedCountryISO: any = CountryISO.India;
  @Output() countryChange = new EventEmitter<string>();

  private innerValue: string;

  constructor() { }

  ngOnInit(): void {
  }

  get value(): string {
    return this.innerValue;
  }

  set value(val: string) {
    this.innerValue = val;
    this.onChange(val);
    this.onTouched();
  }

  writeValue(value: string): void {
    this.innerValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Handle the disabled state if necessary
  }

  onCountryChange(event: any) {
    this.value = event; // Update the value when the country changes
    this.countryChange.emit(event);
  }

  private onChange = (_: any) => {};
  private onTouched = () => {};

  handleCountryCode(countryCode: string): CountryISO {
    return countryCode ? countryCode.toLowerCase() as CountryISO : CountryISO.India;
  }
}
