import {Component, Input, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Candidate} from "../../../../shared/interfaces";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {ApiService} from "../../../../services/api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {NgDialogAnimationService} from "ng-dialog-animation";
import {ResetFiltersService} from "../../../../services/resetFilters.service";
import {Router} from "@angular/router";
import {NavigationService} from "../../../../services/navigation.service";
import {finalize} from "rxjs/operators";
import {SolutionNavigationService} from "../../../../services/solutionNavigationService";
import {SortTable} from "../../admin-vendors/admin-vendors.component";
import {cloneDeep} from "lodash";

@Component({
  selector: 'app-web-candidate-listing',
  templateUrl: './web-candidate-listing.component.html',
  styleUrls: ['./web-candidate-listing.component.css']
})

export class WebCandidateListingComponent implements OnInit {
  tableHeader: string[] = ['Candidate ID', 'Candidate Name', 'Job Title', 'Experience', 'Skills', 'Location Preference', 'Notice(Days)', 'Status', 'Date'];
  searchFieldName: string[] = ['candidateId', 'candidateName', 'title', 'ExperienceYear', 'skills', 'locationsPref', 'noticePeriod', 'statusWebCustomer', 'dateSearchCriteria',];
  filters = {
    dateSearchCriteria: {filterType: 'CREATED', from: '', to: ''},
    locationPreference: [],
    noticePeriod: [],
    status: [],
    skills: [],
    candidateName: '',
    query: '',
    title: '',
    candidateId:'',
    experienceYears:[]
  };
  candidates: Candidate[] = [];
  candidatesCopy: any[] = []
  showLoader: boolean = true;
  search = '';
  pageSize = 12;
  totalEmployee = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  @Input() currentPage!: number;
  @Input() selectedTab!: number;
  sorts: SortTable[] = [];
  sort = '';

  constructor(
    private service: ApiService,
    public snackBar: MatSnackBar,
    public dialog: NgDialogAnimationService,
    private resetFilters: ResetFiltersService,
    private router: Router,
    private navigationService: NavigationService,
    private SolutionNavigationService: SolutionNavigationService,
  ) {
  }

  ngOnInit(): void {
    this.SolutionNavigationService.clearState()
    this.sorts.push({active: false, field: 'candidateId', direction: null});
    const state = this.navigationService.getState();
    if (state) {
      this.currentPage = state.pageNumber;
      window.scrollTo(0, state.scrollPosition);
      this.getAllWebCandidates(this.currentPage, this.pageSize, this.filters);
    } else {
      this.getAllWebCandidates(0, this.pageSize, this.filters);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedTab'] && !changes['selectedTab'].firstChange) {
      this.getAllWebCandidates(0, this.pageSize, this.filters);
    }
  }

  onFiltersChanged(updatedFilters: any) {
    const filteredFilters = {...updatedFilters};
    delete filteredFilters.locationsPref;
    this.filters = {
      ...filteredFilters,
      query: this.search,
      locationPreference: updatedFilters?.locationsPref ?? []
    };
    this.paginator.pageIndex = 0;
    this.filters.query = this.search;
    this.getAllWebCandidates(0, this.pageSize, this.filters);
  }

  onPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getAllWebCandidates(this.currentPage, this.pageSize, this.filters);
  }

  updateSearch() {
    this.filters.query = this.search;
    this.paginator.pageIndex = 0;
    this.getAllWebCandidates(this.currentPage, this.pageSize, this.filters);
  }

  getAllWebCandidates(page: number, size: number, payload: any): void {
    this.showLoader = true;
    this.service.getWebCandidate(page, size, this.sort, payload).pipe(
      finalize(()=> this.navigationService.clearState())
    ).subscribe({
      next: res => {
        this.candidatesCopy = cloneDeep(res.content);
        this.candidates = res.content;
        this.paginator.pageIndex = res.page.number;
        this.paginator.pageSize = res.page.size;
        this.pageSize = res.page.size;
        this.totalEmployee = res.page.totalElements;
        this.showLoader = false;
      },
      error: err => {
        console.log('error', err);
        this.showLoader = false;
      },
      complete: () => {
        this.showLoader = false;
      }
    });
  }

  clearFilter() {
    console.log('Before',this.filters)
    this.filters = {
      dateSearchCriteria: {
        filterType: 'CREATED',
        from: '',
        to: '',
      },
      locationPreference: [],
      noticePeriod: [],
      status: [],
      skills: [],
      candidateName: '',
      query: '',
      title: '',
      candidateId:'',
      experienceYears: []
    };
    this.search=''
    this.resetFilters.emitChange(true);
    console.log('After',this.filters)
    this.getAllWebCandidates(0, this.pageSize, this.filters);
  }
  isFilterApplied(): boolean {
    return (
      (this.filters?.dateSearchCriteria?.from ?? '') !== '' ||
      (this.filters?.dateSearchCriteria?.to ?? '') !== '' ||
      (this.filters?.experienceYears?.length ?? 0) > 0 ||
      (this.filters?.locationPreference?.length ?? 0) > 0 ||
      (this.filters?.noticePeriod?.length ?? 0) > 0 ||
      (this.filters?.status?.length ?? 0) > 0 ||
      (this.filters?.skills?.length ?? 0) > 0 ||
      (this.filters?.candidateName?.trim() ?? '') !== '' ||
      (this.filters?.candidateId?.trim() ?? '') !== '' ||
      (this.filters?.query?.trim() ?? '') !== '' ||
      (this.filters?.title?.trim() ?? '') !== ''
    );
  }



  formatLocationData(data: any) {
    if (Array.isArray(data)) {
      return data.map(item => item.location);
    }
    return [];
  }

  navigateToEditSolution(solutionId: number) {
    const scrollPosition = window.scrollY;
    this.navigationService.saveState(scrollPosition, this.currentPage, this.router.url, 0);
    this.router.navigate(['/dashboard/web_data/candidate', solutionId]);
  }

  getSort(field: string): SortTable {
    return this.sorts.find(column => column.field === field)!;
  }

  toggleSort(field: string): void {
    const sortColumn = this.getSort(field);
    this.sorts.forEach(column => {
      if (column.field !== field) {
        column.active = false;
        column.direction = null;
      }
    });
    if (!sortColumn.active) {
      sortColumn.active = true;
      sortColumn.direction = 'asc';
    } else if (sortColumn.direction === 'asc') {
      sortColumn.direction = 'desc';
    } else {
      sortColumn.direction = null;
      sortColumn.active = false;
    }
    if (sortColumn.direction) {
      this.sort = `id,${sortColumn.direction}`;
      this.getAllWebCandidates(0, this.pageSize, this.filters);

    } else {
      this.sort = '';
      this.getAllWebCandidates(0, this.pageSize, this.filters);
    }
  }


  dateTypeChanged($event: boolean) {
    if ($event === true) {
      this.filters.dateSearchCriteria.filterType = "UPDATED";
    } else if ($event === false) {
      this.filters.dateSearchCriteria.filterType = "CREATED";
    }
    this.getAllWebCandidates(0, this.pageSize, this.filters);
  }
}
