import {NumberFields} from "../shared/interfaces";
import {parsePhoneNumber} from 'libphonenumber-js';

export const renderMobileNumber = (number: NumberFields | undefined) => {
  const mobile = number?.mobile !== undefined && number.mobile !== null ? String(number.mobile) : '--';
  const e164Number = number?.internationalNumber !== undefined && number.internationalNumber !== null ? String(number.internationalNumber) : '--';
  let formattedNumber = '--';
  try {
    const phoneNumber = mobile.startsWith('+') ? parsePhoneNumber(mobile) : parsePhoneNumber(e164Number);
    formattedNumber = phoneNumber.formatInternational();
  } catch (error) {
    formattedNumber = mobile.startsWith('+') ? mobile : e164Number;
  }
  return formattedNumber;
}
