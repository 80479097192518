import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../services/api.service";
import {Router} from "@angular/router";
import {DataService} from "../../services/data.service";
import {Common} from "../../shared/Common";
import {ThemeLoaderService} from "../../services/theme-loader.service";
import Chart from 'chart.js/auto/auto.mjs';
import {Consultant, FeaturedConsultantPayload, Skill} from "../../shared/interfaces";
import {MatDialog} from "@angular/material/dialog";
import {RegisterNow} from "../register-now/register/register-now";
import {MatPaginator} from "@angular/material/paginator";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {Observable} from "rxjs/internal/Observable";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {LoginService} from "../service/login.service";

@Component({
  selector: 'app-login-client',
  templateUrl: './login-client.component.html',
  styleUrls: ['./login-client.component.scss'],
})
export class LoginClientComponent implements OnInit {
  email: string = '';
  loginRadio: FormGroup | any;
  visible1 = true;
  visible2 = false;
  password = '';
  sessions : {userEmail: string, sessionId: string}[] = [];
  loginform: FormGroup;
  showloader = false;
  errorMessage: string = '';
  search = '';
  filters: FeaturedConsultantPayload = {
    consultantId: "",
    title: "",
    experienceYears: [],
    skills: [],
    query: "",
  };
  consultants: Consultant[] = [];
  totalElements: any;
  pageSize = 10;
  currentPage = 0;
  totalPages = 0;
  @ViewChild('loginPasswordInput') loginPasswordInput: ElementRef<HTMLInputElement>;
  @ViewChild('faIconLoginPasswordInputButton') faIconLoginPasswordInputButton: ElementRef<HTMLElement>;
  consultantId: string = '';
  @ViewChildren('dropdownExperience, dropdownSkills') dropdowns!: QueryList<ElementRef>;
  @ViewChild("chipGrid") chipGrid: ElementRef;
  @ViewChild('input', {static: true}) input: ElementRef | any;
  skillControl = new FormControl('');
  members: any[] = [];
  message: string | null;
  userId: any;
  role: any;
  firstName: any;
  index: number;
  loading: boolean;
  active: boolean = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  logoUrl: string | undefined;

  constructor(
    private fb: FormBuilder,
    private service: ApiService,
    private router: Router,
    private dataService: DataService,
    private themeLoaderService: ThemeLoaderService,
    public dialog: MatDialog,
    private loginService: LoginService
  ) {
  }

  ngOnInit(): void {

    this.sessions = JSON.parse(localStorage.getItem('sessions') || '[]');
    this.loginRadio = this.fb.group({
      type: [''],
    });
    localStorage.setItem('loginType', 'Client');
    this.loginRadio.get('type').setValue('Client');
    this.loginform = this.fb.group({
      username: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
      password: ['', Validators.required]
    });
    if (localStorage.getItem('access_token') && localStorage.getItem('role') == 'Sales') {
      this.router.navigate(['/client']);
    } else if (localStorage.getItem('access_token') && localStorage.getItem('role') == 'ADMIN') {
      this.router.navigate(['/dashboard']);
    }
    this.loginform.get('username')?.valueChanges.pipe(debounceTime(100), distinctUntilChanged()).subscribe(() => {
      this.handleEmailChange();
    });
    this.loginform.get('password')?.valueChanges.subscribe(() => {
      this.handlepasswordChange();
    });
    // this.fetchLogo();
    this.loginService.fetchLogo();

    this.loginService.logoUrl$.subscribe(logoUrl => {
      this.logoUrl = logoUrl;
    });

  }

  login(): void {
    if (this.loginform.invalid) {
      this.showloader = false;
      if (this.loginform.get('password')?.errors) {
        if (this.loginform.get('password')?.errors?.required) {
          this.errorMessage = 'Password cannot be empty.';
        }
      }
      if (this.loginform.get('username')?.errors) {
        if (this.loginform.get('username')?.errors?.required) {
          this.errorMessage = 'Email is required.';
        } else if (this.loginform.get('username')?.errors?.pattern) {
          this.errorMessage = 'Please enter a valid email address.';
        }
      }
      if (this.loginform.get('username')?.errors || this.loginform.get('password')?.errors) {
        return;
      }
    } else {
      this.errorMessage = '';
    }
    this.showloader = true;
    this.errorMessage = '';
    const rootVariable = document.documentElement;
    let payload = this.loginform.value;
    if (this.sessions) {
      this.sessions.forEach(map => {
        if (map.userEmail === payload.username) {
          payload.sessionId = map.sessionId;
        }
      });
    }
    this.service.customerLogin(payload).then(res => {
      localStorage.setItem('access_token', res.access_token);
      localStorage.setItem('orgId', res.org_id);
      localStorage.setItem('userEmail', res.user_email);
      localStorage.setItem('userId', res.user_id);
      localStorage.setItem('role', res.role);
      if(res.session_id){
        let existingSession = this.sessions.find((map: any) => map.userEmail === res.user_email);   //existing session of current user
        if(!existingSession || res.session_id !== existingSession.sessionId){
          this.sessions = this.sessions.filter(s => s.userEmail !== res.user_email)
          this.sessions.push({userEmail: res.user_email, sessionId: res.session_id});
          localStorage.setItem('sessions', JSON.stringify(this.sessions));
        }
      }
      this.service.getCustomerLoginUser().subscribe({
        next: res => {
          const primaryColor = res?.user?.theme?.colour ?? '#76ba991a';
          const secondaryColor = Common.rgbaToHex(Common.hexToRGBA(primaryColor, 0.1));
          const ColorObj = {primaryColor, secondaryColor};
          localStorage.setItem('selectedTheme', JSON.stringify(ColorObj));
          rootVariable.style.setProperty('--primaryColor', primaryColor);
          rootVariable.style.setProperty('--secondaryColor', secondaryColor);
          this.themeLoaderService.setTheme(primaryColor, secondaryColor);
        },
        error: err => {
          console.error('Error fetching user theme:', err);
        },
        complete: () => {
          this.dataService.isLoading.next(false);
        }
      });
      this.showloader = false;
      this.router.navigate(['/client']);
    })
      .catch(e => {
        this.showloader = false;
        if (e.error) {
          this.errorMessage = e.error.reason;
        }else {
          this.errorMessage = 'An unexpected error occurred. Please try again.';
        }
      });
  }

  togglePassword(): void {
    if (this.loginPasswordInput.nativeElement.type == 'text') {
      this.loginPasswordInput.nativeElement.type = 'password';
      this.faIconLoginPasswordInputButton.nativeElement.className = 'fas fa-eye';
    } else {
      this.faIconLoginPasswordInputButton.nativeElement.className = 'fas fa-eye-slash';
      this.loginPasswordInput.nativeElement.type = 'text';
    }
  }

  getForgotPassword(): string {
      return '/forget-password';
  }


  openRegisterNow(): string {
    return '/register-now/';
  }




  handleEmailChange(): void {
    const usernameControl = this.loginform.get('username');
    if (usernameControl?.touched || usernameControl?.dirty) {
      if (usernameControl?.invalid) {
        this.showloader = false;
        if (usernameControl.errors) {
          if (usernameControl.errors.required) {
            this.errorMessage = 'Email is required.';
          } else if (usernameControl.errors.pattern) {
            this.errorMessage = 'Please enter a valid email address.';
          }
        }
      } else {
        this.errorMessage = '';
      }
    }
  }

  handlepasswordChange(): void {
    const passwordControl = this.loginform.get('password');
    if (passwordControl?.touched || passwordControl?.dirty) {
      if (passwordControl?.invalid) {
        this.showloader = false;
        if (passwordControl.errors) {
          if (passwordControl.errors.required) {
            this.errorMessage = 'Password cannot be empty.';
          }
        }
      } else {
        this.errorMessage = '';
      }
    }
  }

}
