import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ApiService} from "../../../../services/api.service";
import {ToastrService} from "ngx-toastr";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith, switchMap,
  takeUntil,
  tap
} from "rxjs/operators";
import {
  ComponentName,
  Customer,
  FormUpdateEvent,
  PlaceDto,
  ResumeDto,
  VideoDto,
  VideoToggleType
} from "../../../../shared/interfaces";
import {Observable} from "rxjs/internal/Observable";
import {MatDialog} from "@angular/material/dialog";
import {Subject, Subscription} from "rxjs";
import {PopUpComponent} from "../../../../util/pop-up/pop-up.component";
import {ConsultantViewVideoComponent} from "../../../../client/consultant-view-video/consultant-view-video.component";
import {IsDataUpdatedService} from "../../../../services/isDataUpdated.service";
import {MaxCharLimits} from "../../../../common/errorInputMaxValue";

@Component({
  selector: 'app-consultant-status',
  templateUrl: './consultant-status.component.html',
  styleUrls: ['./consultant-status.component.css']
})
export class ConsultantStatusComponent implements OnInit,OnDestroy {
  @Input() consultantData: any;
  daysList: number[] = [];
  statusList: any[] = ['AVAILABLE', 'NOT_AVAILABLE', 'HOLD', 'DEPLOYED'];
  status: string
  runTimeStatus: boolean
  searchStatus: boolean
  showloader = false;
  selectedCities: any[] = [];
  deployedForm: FormGroup;
  auditHistory: any[] = [];
  clientControl = new FormControl('');
  customerClients: Customer[] = []
  filteredOptions: Observable<Customer[]>;
  customerid: any;
  daysListForm: FormControl;
  availabilityToJoin: string | null;
  showLoader = false;
  cityServerCtrl = new FormControl();
  cities: PlaceDto[] = [];
  cityControl = new FormControl('');
  searchCity: boolean = false;
  summaryForm: FormGroup;
  videoUrlForm: FormGroup;
  videoUrl:string;
  resumeUrl:string;
  summaryValue: string = '';
  jobType: string = '';
  errorMessage = '';
  file: File | undefined;
  dragOver: boolean = false;
  logoLoader = false;
  showSelectedCities: boolean
  videoVisible:boolean;
  resumeData!: ResumeDto
  videoData!: VideoDto
  protected _onDestroy = new Subject<void>();
  private searchSubscription: Subscription;  previousFormValue: any;
  consultantId: string;
  initializingForm: boolean = true;
  @Output() formUpdated = new EventEmitter<FormUpdateEvent>();
  form: FormGroup;
  @ViewChild('cityList') cityList: ElementRef<HTMLInputElement>;
  cityform!: FormGroup;
  loading: boolean = false
  loadingCity: boolean = false
  @Input() closeIcon = 'close';
  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private service: ApiService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private isDataUpdatedService: IsDataUpdatedService,
  ) {
    this.form = new FormGroup({
      document: new FormControl(null),
    });
    this.daysList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];
    this.daysListForm = new FormControl();
    this.summaryForm = this.formBuilder.group({
      summary: ['']
    });
    this.cityform = this.fb.group({
      cityControl: ['']
    });
  }

  preferredLocationPayload: any = {
    jobType: '',
    cities: []
  };

  ngOnInit(): void {
    this.videoUrlForm = new FormGroup({
      videoUrl: new FormControl('')
    });
    this.getConsultantVideo((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id);
    this.getConsultantResume((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id);
    if (this.consultantData.consultantData != null) {
      this.searchStatus = this.consultantData.consultantData.enableSearch ?? false;
      this.status = this.consultantData.consultantData.status;
    }
    this.deployedForm = this.fb.group({
      customerId: [""],
      contractStartDate: [""],
      contractEndDate: [""],
      contractPeriod: [""],
      workMode: [""],
      tier: [""],
      engagementType: [""],
      summary: [""],
      address: this.fb.group({
        country: [""],
        state: [""],
        city: [""],
      }),
    });
    if (this.consultantData?.consultantData?.id || this.consultantData?.id) {
      this.availabilityToJoin = this.consultantData?.consultantData?.availabilityToJoin;
      this.daysListForm.setValue(this.availabilityToJoin);
      this.summaryForm = this.formBuilder.group({
        summary: [this.consultantData.consultantData && this.consultantData?.consultantData?.summary ? this.consultantData?.consultantData?.summary : '']
      });
      this.selectedCities = this.consultantData?.consultantData?.preferredLocations;
      this.cityform = this.fb.group({
        cityControl: [this.selectedCities]
      });
      this.jobType = this.consultantData?.consultantData?.jobType ?? 'REMOTE';
      this.showSelectedCities = this.consultantData?.consultantData?.preferredLocations?.length > 0;
      this.cityControl.setValue(this.selectedCities);
      this.videoUrl= this.consultantData?.consultantData?.video?.url ;
      this.videoVisible = this.consultantData?.consultantData?.video?.visible ;
      this.resumeUrl = this.consultantData?.consultantData?.resume?.fileUrl ;
    }
    this.previousFormValue = this.deployedForm.value;
    this.initializingForm = false;
    this.getCustomerClients();
    this.getDeployedHistory();
    this.onSearchCities()
    this.deployedForm.valueChanges.pipe(
      debounceTime(500),
      filter(() => !this.initializingForm),
      map(currentValue => JSON.stringify(currentValue) !== JSON.stringify(this.previousFormValue)),
      distinctUntilChanged()
    ).subscribe(isFormUpdated => {
      this.formUpdated.emit({updated: isFormUpdated, componentName: ComponentName.ConsultantStatus});
    });
  }

  getConsultantVideo(consultantId: number): void {
    this.service.getConsultantVideo(consultantId).subscribe(
      (res: VideoDto) => {
        this.videoData = res;
      },
      (error: any) => {
        console.error('Error occurred:', error.error.text);
      }
    );
  }

  openVideoModal(): void {
    const dialogRef = this.dialog.open(ConsultantViewVideoComponent, {
      width: '50%',
      height: 'auto',
      data: {
        url: this.videoUrl,
      }
    })
    dialogRef.afterClosed().subscribe(() => {
    });
  }
  downloadResume(): void {
    window.open((this.resumeUrl) ? this.resumeUrl : '');
  }
  toggleConsultantVideo(event: any) {
    if (event.checked) {
      this.service.toggleConsultantVideo((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id, VideoToggleType.Enable).subscribe((res => {
        this.toastr.success('Consultant Video Enabled', 'Success');
        this.isDataUpdatedService.setUpdated(true);
      }), (e: any) => {
        this.toastr.error(e.message, 'Request Failed');
      });
    } else {
      this.service.toggleConsultantVideo((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id, VideoToggleType.Disable).subscribe((res => {
        this.toastr.success('Consultant Video Disabled ', 'Success');
        this.isDataUpdatedService.setUpdated(true);
      }), (e: any) => {
        this.toastr.error(e.message, 'Request Failed');
      });
    }
  }

  deleteVideo(): void {
    const dialogDeleteVideo = this.dialog.open(PopUpComponent, {
      data: {
        title: 'Alert',
        message: 'Are you sure you want to delete this ? ',
        type: 'Delete'
      }
    });
    dialogDeleteVideo.afterClosed().subscribe((res: any) => {
      if (res) {
        this.service.deleteConsultantVideo((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id).subscribe(() => {
          this.videoData = new VideoDto();
          this.videoUrlForm.setValue({
            videoUrl: ''
          });
          this.isDataUpdatedService.setUpdated(true);
          this.videoUrl = '';
          this.videoData.visible = false;
          this.toastr.success('Consultant Video Deleted', 'Success');
        }, (e: any) => {
          this.toastr.error(e.message, 'Request Failed');
        });
      }
    });
  }


  hasSummary(): boolean {
    return !!(this.consultantData.consultantData && this.consultantData.consultantData.summary);
  }

  compareCities(c1: PlaceDto, c2: PlaceDto): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  getConsultantResume(consultantId: number): void {
    this.service.getConsultantResume(consultantId).subscribe(
      (res: any) => {
        this.resumeData = res
      },
      (error: any) => {
        console.error('Error occurred:', error.error.text);
      }
    );
  }

  toggleResumeStatus(event: any) {
    if (event.checked) {
      this.service.displayConsultantResume((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id).subscribe((res => {
        this.resumeData.resumeStatus = true
        this.isDataUpdatedService.setUpdated(true);
        this.toastr.success('Consultant Resume Enabled', 'Success');
      }), (e: any) => {
        this.toastr.error(e.message, 'Request Failed');
      });
    } else {
      this.service.hideConsultantResume((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id).subscribe((res => {
        this.resumeData.resumeStatus = false
        this.isDataUpdatedService.setUpdated(true);
        this.toastr.success('Consultant Resume Disabled ', 'Success');
      }), (e: any) => {
        this.toastr.error(e.message, 'Request Failed');
      });
    }
  }


  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    setTimeout(() => {
      this.dragOver = true;
    }, 2000);
  }

  onDragEnter(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    setTimeout(() => {
      this.dragOver = false;
    }, 2000);
  }

  onSelectFile(event: any, dragEvent?: boolean): void {
    event.preventDefault();
    this.errorMessage = '';
    if (event.dataTransfer && event.dataTransfer.items && event.dataTransfer.items[0].kind !== 'file') {
      this.errorMessage = 'Only files are allowed.';
      return;
    }
    if (dragEvent) {
      this.file = event.dataTransfer.files[0];
    } else {
      this.file = event.target.files[0];
    }
    if (this.file) {
      this.logoLoader = true;
      this.dragOver = false;
      const formData: FormData = new FormData();
      formData.append('name', this.file.name);
      formData.append('file', this.file);
      event.preventDefault();
      this.service.uploadConsultantResume(formData, (this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id).subscribe(
        (res) => {
          this.isDataUpdatedService.setUpdated(true);
          this.resumeUrl = res.body?.resume?.fileUrl
          if (res.type === 3) {
            this.toastr.success('Consultant Resume Uploaded', 'Success');
            this.logoLoader = false;
          }
        },
        (error) => {
          this.logoLoader = false;
          console.error('Error uploading consultant resume:', error);
        }
      );
    }
  }

  deleteConfirmation(consultantId: string): void {
    const dialogDeleteProfile = this.dialog.open(PopUpComponent, {
      data: {
        title: 'Alert',
        message: 'Are you sure you want to delete this ? ',
        type: 'Delete'
      }
    });
    dialogDeleteProfile.afterClosed().subscribe((res: any) => {
      if (res) {
        this.service.deleteConsultantResume(consultantId).then(() => {
          this.isDataUpdatedService.setUpdated(true);
          this.file = undefined;
          this.resumeData.resumeName = '';
          this.toastr.success('Consultant Resume Deleted', 'Success');
        })
          .catch(error => {
            console.error("Error deleting consultant's resume:", error);
          });
      }
    });
  }

  // getCities(query: any): void {
  //   this.service.getAllCities(query).subscribe(res => {
  //     this.cities = res;
  //   });
  // }

  getCities(search: string): Observable<any> {
    return this.service.getAllCities(search);
  }

  removeCity(cityToRemove: any) {
    this.selectedCities = this.selectedCities.filter(city => city.id !== cityToRemove.id);
    this.updatePreferredLocationPayload();
  }

  selectCities(event: any) {
    const uniqueCities = new Set(event.value.map((city: any) => city.id));
    this.selectedCities = Array.from(uniqueCities).map((id: any) => event.value.find((city: any) => city.id === id));
    this.cityControl.setValue(this.selectedCities);
    // this.updatePreferredLocationPayload();
    // this.onSearchCities();
  }

  updatePreferredLocationPayload() {
    let selectedCityIds = this.selectedCities.map(city => city.id);
    this.preferredLocationPayload.jobType = this.jobType;
    this.preferredLocationPayload.cities = selectedCityIds;
  }

  onSearchCities() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }

    this.searchSubscription = this.cityServerCtrl.valueChanges.pipe(
      filter(search => !!search),
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => this.searchCity = true),
      switchMap((search: any) => this.getCities(search)),
      takeUntil(this._onDestroy)
    ).subscribe(
      (cities:PlaceDto[])=> {
        this.cities = cities;
        this.searchCity = false;
      },
      () => this.searchCity = false,
    );
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }


  updatePreferredLocation(): void {
    this.preferredLocationPayload.jobType = this.jobType;
    if (this.jobType === 'REMOTE') {
      this.preferredLocationPayload.cities = [];
    }
    this.loadingCity = true
    if (this.consultantData?.consultantData?.id || this.consultantData?.id) {
      this.service.updatePreferredLocation((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id, this.preferredLocationPayload).subscribe({
        next: () => {
          this.isDataUpdatedService.setUpdated(true);
          this.toastr.success('Consultant Information Saved Successfully', 'Success');
          this.showSelectedCities = true;
          this.loadingCity = false;
        },
        error: err => {
          console.error('err', err);
          this.toastr.error(err.value, 'Request Failed');
          this.showSelectedCities = false;
          this.loadingCity = false;
        }
      })
    }
  }

  updateSummary(): void {
    this.summaryValue = this.summaryForm.value.summary;
    if (!this.videoUrl){
      this.videoUrl = this.videoUrlForm.value.videoUrl;
    }
    let selectedCityIds = this.selectedCities?.map(city => city.id);
    const payload = {
      videoUrl: this.videoUrl,
      summary: this.summaryValue,
      jobType: this.jobType,
      cities: selectedCityIds,
    };
    this.loading = true
    if (this.consultantData?.consultantData?.id || this.consultantData?.id) {
      this.service.updateSummary((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id, payload).subscribe({
        next: () => {
          this.isDataUpdatedService.setUpdated(true);
          this.toastr.success('Consultant Information Saved Successfully', 'Success');
          this.loading = false;
        },
        error: err => {
          console.error('err', err);
          this.toastr.error(err.value, 'Request Failed');
          this.loading = false;
        }
      })
    }
  }

  onAvailabilityDays(days: any): void {
    this.service.availabilityToJoin((this.consultantData?.consultantData?.id) ?
      this.consultantData?.consultantData?.id : this.consultantData?.id, days).subscribe((res) => {
      this.isDataUpdatedService.setUpdated(true);
      this.toastr.success('Availability to Join Updated', 'Success');
    }, (e) => {
      console.error('error', e.error.message);
      this.toastr.error(e.error.message, 'Request Failed');
    });
  }

  getDeployedHistory(): void {
    this.service.getConsultantDeployedHistory((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id).subscribe(resp => {
      this.auditHistory = resp;
    })
  }

  getCustomerClients() {
    this.service.getActiveCustomerClients().subscribe((response) => {
      this.customerClients = response;
      this.customerClients.forEach((client) => {
        this.customerid = client.id;
      });
      // @ts-ignore
      this.filteredOptions = this.clientControl.valueChanges.pipe(
        startWith(''),
        map(value => {
          this.customerid = value.id;
          const name = typeof value === 'string' ? value : value?.name ?? "";
          return name ? this._filter(name as string) : this.customerClients.slice();
        }),
      );
    });
  }

  _filter(name: string): Customer[] {
    const filterValue = name.toLowerCase();
    return this.customerClients.filter((options: Customer) => options.companyName.toLowerCase().includes(filterValue));
  }


  change(e: any) {
    this.runTimeStatus = e
    if (e.checked) {
      this.service.addConsultantSearchFlag((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id).subscribe(res => {
        this.searchStatus = res.active;
        this.isDataUpdatedService.setUpdated(true);
        this.toastr.success('Consultant Search ' + (this.searchStatus ? 'Enabled' : 'Disabled'), 'Success');
      }), (e: any) => {
        this.toastr.error(e.error.reason, 'Request Failed');
      }
    } else {
      this.service.removeConsultantSearchFlag((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id).subscribe(res => {
        this.searchStatus = res.active;
        this.isDataUpdatedService.setUpdated(true);
        this.toastr.success('Consultant Search ' + (this.searchStatus ? 'Enabled' : 'Disabled'), 'Success');
      }), (e: any) => {
        this.toastr.error(e.error.reason, 'Request Failed');
      }
    }
  }

    onStatusChange(e: any) {
            this.service.consultantStatus((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id, e.value).subscribe(res => {
                this.status = res.status;
              this.isDataUpdatedService.setUpdated(true);
                this.toastr.success('Status Changed ' + this.status, 'Success');
            });
    }

  onRadioChange(event: any) {
    this.jobType = event.value;
  }

  MaxCharLimits = MaxCharLimits;
}

