<div>
    <div class="d-flex align-items-center justify-content-between member_wrapper theme-bg-color" mat-dialog-title>
      <div class="fs-5">
        Assignment Details:
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <div (click)="close()" class="btn close-btn">
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5143 1L1.22705 17.2871" stroke="#fff" stroke-width="2" stroke-linecap="round"/>
            <path d="M1.22705 1L17.5143 17.2871" stroke="#fff" stroke-width="2" stroke-linecap="round"/>
          </svg>
        </div>
      </div>
    </div>
  <mat-dialog-content class="mat-typography m-0">
    <form [formGroup]="deployedForm" (submit)="addDeployedHistory()">
      <div class="">
        <div class="row mb-3" *ngIf="!isDisable">
          <mat-form-field appearance="outline" class="w-100 border-red">
            <mat-label>Client Name</mat-label>
            <input #NameAutoCompleter
                   type="text"
                   placeholder="Pick one"
                   aria-label="Number"
                   matInput
                   [disabled]="isDisable"
                   [formControl]="clientControl"
                   [matAutocomplete]="auto1" required>
            <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayDocFn"
                              (optionSelected)="getAllClientContacts($event,'ADD')">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{ option.companyName }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="row mb-3" *ngIf="isDisable">
          <mat-form-field appearance="outline" class="w-100 border-red">
            <mat-label>Client Name</mat-label>
            <input type="text"
                   placeholder="Pick one"
                   aria-label="Number"
                   matInput
                   [formControl]="clientControl"
                   required>
          </mat-form-field>
        </div>

        <div class="row">
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100 mb-3">
              <mat-label>Contract Start Date</mat-label>
              <input [(ngModel)]="documentStartDate" (ngModelChange)="updateEndDateMinDate()"
                     matInput [matDatepicker]="startDatePicker" formControlName="contractStartDate" required readonly>
              <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100 mb-3">
              <mat-label>Contract End Date</mat-label>
              <input [(ngModel)]="documentExpiryDate" [min]="minEndDate"
                     matInput [matDatepicker]="endDatePicker" formControlName="contractEndDate" [disabled]="!documentStartDate" required readonly>
              <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #endDatePicker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100 border-red mb-3" >
              <mat-label>Contract Period</mat-label>
              <input type="text" [(ngModel)]="contractPeriodValue" matInput placeholder="Contract Period"
                     formControlName="contractPeriod" aria-describedby="title" required>
            </mat-form-field>
          </div>

          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100 mb-3">
              <mat-label>Type of Engagement</mat-label>
              <input type="text" matInput
                     formControlName="engagementCategory" aria-describedby="title" required>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <label class="ms-sm-1 mb-3">Work Mode <span class="fs-5 text-danger">{{'*'}}</span></label>
          <mat-radio-group formControlName="workMode">
            <mat-radio-button value="REMOTE" class="custom-radio-button">
              <span>Remote</span>
            </mat-radio-button>
            <mat-radio-button value="HYBRID" class="custom-radio-button">
              <span>Hybrid</span>
            </mat-radio-button>
            <mat-radio-button value="ONSITE" class="custom-radio-button">
              <span>Onsite</span>
            </mat-radio-button>
            <mat-error *ngIf="deployedForm.get('workMode')?.hasError('required') && deployedForm.get('workMode')?.touched">
              This field is required
            </mat-error>
          </mat-radio-group>

          <div class="mt-3">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Notes</mat-label>
              <textarea appMaxCharLimitTextarea matInput formControlName="note" placeholder="Enter Notes"></textarea>
              <mat-error *ngIf="this.deployedForm.get('note')?.hasError('maxCharLimit')">
               {{MaxCharLimits.NOTES}}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="form-group btns d-flex justify-content-end save-form mt-5">
            <button *ngIf="!showLoader" class="btn btn-save theme-btn-color" type="submit">
              {{this.consultantId.consultantDeployHistoryId ? 'Update Details' : 'Save Details'}}
            </button>
            <div *ngIf="showLoader" disabled>
              <button class="btn btn-save theme-btn-color" disabled>
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only"></span>
                </div>
                {{this.consultantId.consultantDeployHistoryId ? 'Update Details' : 'Save Details'}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
</div>


